export const iconGo = require("../assets/go.png");
export const iconAdd = require("../assets/add.png");
export const iconLive = require("../assets/live.png");
export const iconEdit = require("../assets/edit.png");
export const iconMenu = require("../assets/menu2.png");
export const iconCloud = require("../assets/cloud.png");
export const iconDelete = require("../assets/delete.png");
export const iconSearch = require("../assets/search.png");
export const iconCamera = require("../assets/camera.png");
export const iconUpload = require("../assets/upload.png");
export const iconFilter = require("../assets/filter.png");
export const iconAttachment = require("../assets/attach.png");
export const iconDownload = require("../assets/download.png");
export const iconGalleryDark = require("../assets/gallery.png");
export const iconLeftArrow = require("../assets/left-arrow.png");
export const iconGalleryLight = require("../assets/gallery2.png");
export const iconRightArrow = require("../assets/right-arrow.png");
export const iconUserProfile = require("../assets/userProfile.png");
export const iconArrowLeft = require("../assets/arrowLeft.svg");
export const iconArrowRight = require("../assets/arrowRight.svg");
