// Customizable Area Start
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import OrderManagementController from "./OrdermanagementController";

class ReturnOrderSummary extends OrderManagementController {
  render() {
    const { itemDetail } = this.state;

    return (
      <div className="frontContent py-4 px-5">
        <div className="breadcrumbs">
          <Link to="/home" className="breadcrumb-link">
            Home
          </Link>
          &nbsp;&nbsp; &#187; &nbsp;&nbsp;
          <Link to="/orders" className="breadcrumb-link">
            My Order Details
          </Link>
          &nbsp;&nbsp; &#187; &nbsp;&nbsp;
          <span className="breadcrumb-text">Return Summary</span>
        </div>
        <div className="title-box mb-0">
          <h4 className="title">Return Summary</h4>
        </div>
        <div className="title-box">
          <h6 className="m-0">
            Order On{" "}
            {moment(itemDetail?.attributes?.order_date).format("DD MMMM YYYY")}{" "}
            &nbsp;&nbsp;&nbsp; Order #{itemDetail?.attributes?.order_number}
          </h6>
          <Link
            to={"/invoice/" + itemDetail?.id}
            className="blueLinks mb-0 text-decoration"
          >
            Invoice
          </Link>
        </div>
        <div className="order-flex-box mb-3">
          <div className="order-flex-column2 w-100">
            <div className="flex-box">
              <div className="column">
                <h6 className="m-0">SHIPPING ADDRESS</h6>
                <p className="m-0">
                  {itemDetail?.attributes?.delivery_address}
                </p>
              </div>
              <div className="column">
                <h6 className="m-0">REFUND METHOD</h6>
                <p className="m-0">
                  {itemDetail?.attributes?.payment_type === "cash_on_delivery"
                    ? "Cash On Delivery"
                    : "Online Payment"}
                </p>
              </div>
              <div className="column">
                <h6 className="m-0">RETURN SUMMARY</h6>
                <table>
                  <tbody>
                    <tr>
                      <td className="column">Item(s) Subtotal: </td>
                      <td>AED {itemDetail?.attributes?.sub_total}</td>
                    </tr>
                    <tr>
                      <td className="column">Shipping: </td>
                      <td>AED 0.00</td>
                    </tr>
                    <tr>
                      <td className="column">Total: </td>
                      <td>AED {itemDetail?.attributes?.total}</td>
                    </tr>
                    <tr>
                      <th className="column" style={{ color: "var(--navy)" }}>
                        Grand Total :
                      </th>
                      <td style={{ color: "var(--navy) !important" }}>
                        <b>AED {itemDetail?.attributes?.total}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {itemDetail?.attributes?.order_items.length > 0 &&
          itemDetail?.attributes?.order_items?.map((result: any, i: number) => (
            <div
              className="order-flex-box mb-3"
              key={"product-list-" + result.id}
            >
              <div className="order-flex-column1">
                <img
                  className="w-100 h-100"
                  src={
                    result?.attributes?.catalogue?.attributes?.images?.length >
                    0
                      ? result?.attributes?.catalogue?.attributes?.images[0]
                          ?.url
                      : "/frontend/product1.png"
                  }
                  alt="product"
                />
              </div>
              <div className="order-flex-column2">
                <div className="flex-box">
                  <div className="column">
                    <h6 className="m-0">Pickup Thursday</h6>
                    <p className="m-0">
                      {result?.attributes?.catalogue?.attributes?.name}
                    </p>
                    <p className="m-0">
                      Sold By : {itemDetail?.attributes?.sold_company_name}
                    </p>
                    <p className="m-0">
                      Total : AED {result?.attributes?.total_price}
                    </p>
                    {result?.attributes?.catalogue?.attributes?.discount && (<p className='m-0'>Discount : {result?.attributes?.catalogue?.attributes?.discount}</p>)}
                    {result?.attributes?.catalogue?.attributes?.taxes && (<p className='m-0'>Taxes : {result?.attributes?.catalogue?.attributes?.taxes}</p>)}
                    <p className='m-0'>Customer Name : {itemDetail?.attributes?.account?.name}</p>
                    <p className='m-0' style={{ textTransform: 'capitalize'}}>Order Status : {result?.attributes?.status}</p>
                  </div>
                  <div className="column"></div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

export default ReturnOrderSummary;
// Customizable Area End
