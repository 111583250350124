// Customizable Area Start

import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import GetApiMessage from "../../utilities/src/GetApiMessage";
import ApiResponseMiddleware from "../../vendor/src/ApiResponseMiddleware";

export const configJSON = require("./config");

export enum SCREEN_STEPS {
  chooseItemsStep,
  confirmReturnStep,
  successReturnStep,
}

export interface Props {
  navigation: any;
  id: string;
}

interface IOrderDetails {
  order_items: Array<any>;
}

interface ISelectedProducts {
  id: number;
  reason: number | any;
  error: string;
}

interface S {
  selectedAddress: { id: number; address: string };
  changeAddressModal: boolean;
  makeItRight: string;
  mailYourReturn: string;
  mailYourReturnError: string;
  selectedProducts: Array<ISelectedProducts>;
  returnReasonList: Array<any>;
  orderDetailsData: IOrderDetails;
  orderReturnSteps: number;
  finalConfirmedModalFlag: boolean;

  // Messages Ids
  ordersRefundMessageId: string;
  orderDetailsMessageId: string;
  returnReasonListMessageId: string;
}

interface SS {}

export default class ReturnOrderController extends BlockComponent<
  Props,
  S,
  SS
> {
  state = {
    selectedAddress: {
      id: -1,
      address: "",
    },
    changeAddressModal: false,
    makeItRight: "refund",
    mailYourReturnError: "",
    mailYourReturn: "",
    selectedProducts: [],

    orderReturnSteps: SCREEN_STEPS.chooseItemsStep,
    returnReasonList: [],
    orderDetailsData: { order_items: [] },
    finalConfirmedModalFlag: false,

    // Message Ids
    ordersRefundMessageId: "",
    orderDetailsMessageId: "",
    returnReasonListMessageId: "",
  };

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  receive(from: string, message: Message): void {
    if (!ApiResponseMiddleware(message)) return;

    const {
      orderDetailsMessageId,
      ordersRefundMessageId,
      returnReasonListMessageId,
    } = this.state;

    let requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (response) {
      if (requestId === orderDetailsMessageId) {
        const { data } = response;
        let orderData = {
          id: data.id,
          ...data.attributes,
        };
        this.setState({
          orderDetailsData: orderData,
          selectedAddress: {
            id: orderData.delivery_address_id,
            address: orderData.delivery_address,
          },
        });
      } else if (requestId === returnReasonListMessageId) {
        const { data } = response;
        this.setState({
          returnReasonList:
            data.map((item: any) => {
              return { id: item.id, ...item.attributes };
            }) || [],
        });
      } else if (requestId === ordersRefundMessageId) {
        this.setState({ orderReturnSteps: SCREEN_STEPS.successReturnStep });
      }
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getReturnReasonList();
    this.getOrderDetails();
  }

  getOrderDetails() {
    let id = this.props.navigation.getParam("id");
    let requestMessage = GetApiMessage({
      endpoint: "/" + configJSON.orderdetailsAPIURL + `/${id}`,
    });
    this.setState({ orderDetailsMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getReturnReasonList() {
    let requestMessage = GetApiMessage({
      endpoint: "/" + configJSON.reasonListAPi,
    });
    this.setState({ returnReasonListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onChangeStep(step: number) {
    this.setState({ orderReturnSteps: step });
  }

  isSelectedItem(id: number) {
    const { selectedProducts } = this.state;
    let isSelected =
      selectedProducts.filter((item: ISelectedProducts) => item.id === id)
        .length > 0;
    return isSelected;
  }

  onChangeReason(id: number, reasonId: number) {
    let newArray = this.state.selectedProducts.map(
      (item: ISelectedProducts) => {
        if (id === item.id) item.reason = reasonId;
        return item;
      }
    );
    this.setState({ selectedProducts: newArray });
  }

  onSelectProduct(id: number) {
    const { selectedProducts } = this.state;

    let isSelected = this.isSelectedItem(id);

    if (!isSelected) {
      let newArray = selectedProducts;
      // @ts-ignore
      newArray.push({ id, reason: -1, error: "" });
      this.setState({ selectedProducts: newArray });
    } else {
      let removedItem = selectedProducts.filter(
        (item: ISelectedProducts) => id !== item.id
      );
      this.setState({ selectedProducts: removedItem });
    }
  }

  isValidReturn() {
    let flag = true;
    let newArray = this.state.selectedProducts.map(
      (item: ISelectedProducts) => {
        if (item.reason === -1) {
          item.error = "Please select reason!";
          flag = false;
        } else item.error = "";
        return item;
      }
    );

    this.setState({ selectedProducts: newArray });
    return flag;
  }

  isValid() {
    const { mailYourReturn } = this.state;
    if (mailYourReturn.length === 0)
      this.setState({ mailYourReturnError: "* Please choose an option!" });
    else this.setState({ mailYourReturnError: "" });

    return mailYourReturn.length > 0;
  }

  isOrderFullOrderReturn() {
    const orderItems = this.state.orderDetailsData.order_items.map(
      (item: any) => ({
        id: item.id,
        ...item.attributes,
      })
    );
    return orderItems.length === this.state.selectedProducts.length;
  }

  onSubmit() {
    if (!this.isValid()) return;
    const isFullReturn = this.isOrderFullOrderReturn();
    const orderId = this.props.navigation.getParam("id");
    const { selectedProducts, makeItRight, mailYourReturn, selectedAddress } =
      this.state;
    let form = new FormData();

    if (isFullReturn) {
      // @ts-ignore
      form.set("issue_id", selectedProducts[0].reason || "");
      form.set("order_id", String(orderId));
    } else {
      let ids = selectedProducts
        .map((item: ISelectedProducts) => Number(item.reason))
        .join(",");

      form.append("issue_id", `[${ids}]`);

      ids = selectedProducts
        .map((item: ISelectedProducts) => item.id)
        .join(",");
      form.set("order_item_id", `[${ids}]`);
    }

    form.set("make_it_right", makeItRight);
    form.set("mail_your_return", mailYourReturn);
    form.set("delivery_address_id", String(selectedAddress.id));

    let requestMessage = GetApiMessage({
      method: configJSON.httpPostMethod,
      endpoint: isFullReturn
        ? configJSON.refundOrderApi
        : configJSON.refundPartialOrderApi,
      data: form,
    });

    this.setState({
      ordersRefundMessageId: requestMessage.messageId,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setChangeAddressModal(flag: boolean) {
    this.setState({ changeAddressModal: flag });
  }
}

// Customizable Area End
