// Customizable Area Start

import React from "react";
import { styled, Grid, Box } from "@material-ui/core";

interface Props {
  children?: any;
  title?: any | String;
  onClose?: any | Function;
}
interface State {}

class ModalBox extends React.Component<Props, State> {
  render() {
    const { title, onClose, children } = this.props;
    return (
      <OverlapContainer>
        <ModalContainer>
          <ModalHeader>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>{title}</Grid>
              <Grid item>
                <span data-test-id="btn-close" onClick={onClose}>
                  &times;
                </span>
              </Grid>
            </Grid>
          </ModalHeader>
          <ModalBody>
            <div>{children}</div>
          </ModalBody>
        </ModalContainer>
      </OverlapContainer>
    );
  }
}

const ModalBody = styled(Box)({
  fontSize: 16,
  minHeight: 150,
  paddingBottom: 16,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ModalHeader = styled(Box)({
  fontSize: 20,
  fontWeight: "bold",
});

const ModalContainer = styled(Box)({
  top: "50%",
  left: "50%",
  padding: "0px 16px",
  minWidth: 250,
  minHeight: 150,
  position: "fixed",
  borderRadius: 4,
  boxShadow: "0px 0px 10px var(--boxShadow)",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  "& span": {
    fontSize: 32,
  },
});

const OverlapContainer = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1000,
  backgroundColor: "rgba(0,0,0,0.5)",
});

export default ModalBox;

// Customizable Area End
