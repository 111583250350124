Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  editCatalogue: "/bx_block_catalogue/catalogues/",
  viewProfile: "/accounts/show_profile",
  categoryWiseList: "/bx_block_catalogue/catalogues/search_own_by_categories",
  categoryList: "/bx_block_filter_items/filters/categories",
  searchCatalogue: "/bx_block_catalogue/catalogues/search_own",
  listInventory: "/bx_block_catalogue/catalogues/list_own_catalogues",
  deleteCatalogue: "/bx_block_catalogue/catalogues/destroy",
  downloadInventory:
    "/bx_block_inventorymanagement25/inventory_management/download_inventory",
};

exports.Strings = {
  header: {
    title: "JHC | Inventory",
  },
  labels: {
    alert: "Alert!",
    search: "Search",
    category: "Category",
    stockable: "Stockable",
    refundable: "Refundable",
    heading: "Inventory Management",
  },
  message: {
    verifyBusiness:
      "You are required to register and verify your business account.",
    removeSelectedProduct: "Remove the Selected Product",
    removeSuccessfully: "Removed Successfully",
  },
  actions: {
    no: "No",
    yes: "Yes",
    goBack: "Go Back",
    addItem: "Add Item",
    removeItem: "Remove Item",
  },
  table: {
    edit: "edit",
    active: "active",
    inStock: "in stock",
    batchNo: "batch no",
    brandName: "brand name",
    productId: "product id",
    expDate: "expiring date",
    noData: "Data Not found",
    salesPrice: "sales price",
    productName: "product name",
    retailPrice: "retail price",
    mfgDate: "manufacturing date",
  },
};
