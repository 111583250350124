// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { iconMenu } from "../../assets";

const configJs = require("./config");
const Strings = configJs.Strings;

interface Props {
  activeScreen: any;
  td_statistics: any;
  otd_statistics: any;
  onChangeScreen: Function;
}
interface SS {}
interface S {}

class OrderHeader extends BlockComponent<Props, S, SS> {
  render() {
    const { in_progress, failed_order, pending_order, completed_task } = this
      .props.activeScreen
      ? this.props.td_statistics
      : this.props.otd_statistics;

    return (
      <div className="head">
        <div className="buttonBox justify-content-start">
          <button
            data-test-id="btn-orderDelivery"
            onClick={() => this.props.onChangeScreen(false)}
            className={
              !this.props.activeScreen ? "buttons filled" : "buttons blank"
            }
            type="button"
          >
            {Strings.labels.orderToDelivery}
          </button>
          <button
            data-test-id="btn-orderTrack"
            onClick={() => this.props.onChangeScreen(true)}
            className={
              this.props.activeScreen ? "buttons filled" : "buttons blank"
            }
            type="button"
          >
            {Strings.labels.trackOrder}
          </button>
        </div>
        <div className="counter">
          <div className="contentBox">
            <h5 className="count">{completed_task}</h5>
            <div>
              <h6>{Strings.labels.completed}</h6>
              <h6>{Strings.labels.order}</h6>
            </div>
          </div>
          <div className="contentBox">
            <h5 className="count">{in_progress}</h5>
            <div>
              <h6>{Strings.labels.in}</h6>
              <h6>{Strings.labels.progress}</h6>
            </div>
          </div>
          <div className="contentBox">
            <h5 className="count">{pending_order}</h5>
            <div>
              <h6>{Strings.labels.pending}</h6>
              <h6>{Strings.labels.order}</h6>
            </div>
          </div>
          <div className="contentBox">
            <h5 className="count">{failed_order}</h5>
            <div>
              <h6>{Strings.labels.failed}</h6>
              <h6>{Strings.labels.order}</h6>
            </div>
          </div>
        </div>
        <div className="menu">
          <img src={iconMenu} alt="menu" />
        </div>
      </div>
    );
  }
}

export default OrderHeader;

// Customizable Area End
