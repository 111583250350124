import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";

import GetApiMessage from "../GetApiMessage";
import ISuccessResponse from "../ISuccessResponse";
import ApiResponseMiddleware from "../ApiResponseMiddleware";
import GetErrorMessages from "../GetApiErrorsWithKeys";
import BlockHelpers from "../../../utilities/src/BlockHelpers";

const configJs = require("./config");
const Strings = configJs.Strings;
const ApiUrls = configJs.ApiUrls;

interface Item {
  name: string;
  photo: File | string | any;
}

interface Props {
  onSet: Function;
  onClose: Function;
  onRefetch: Function;
  brandList: Array<any>;
  selectedCategoryId: any;
}
interface State {
  isLoading: boolean;
  messageId: string;
  item: Item;
  validation: Item;
}
interface SS {}

class NewBrandModalController extends BlockComponent<Props, State, SS> {
  state = {
    item: {
      name: "",
      photo: null,
    },
    validation: {
      name: "",
      photo: "",
    },
    messageId: "",
    isLoading: false,
  };

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.receive = this.receive.bind(this);
  }

  receive(from: string, message: Message): void {
    if (!ApiResponseMiddleware(message)) return;

    let requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (requestId === this.state.messageId) {
      if (successResponse) {
        let error = GetErrorMessages(successResponse, {
          photo: "photo",
          name: "name",
        });
        if (!error) {
          BlockHelpers.showAlert(
            Strings.messages.success,
            Strings.messages.brandedAdded
          );
          this.props.onRefetch();
          this.props.onClose();
          this.props.onSet(successResponse?.data?.id || "");
        } else {
          // @ts-ignore
          this.setState({ validation: error });
          error.extraMessages &&
            BlockHelpers.showAlert(Strings.labels.alert, error.extraMessages);
        }
        this.setState({ isLoading: false });
      }
    }
  }

  isValid() {
    let errors = {};
    const { name, photo } = this.state.item;
    const { brandList } = this.props;

    const saveError = (key: any, value: string) => {
      // @ts-ignore
      errors[key] = value;
    };

    if (!name) saveError("name", Strings.validations.brandName);
    if (!photo) saveError("photo", Strings.validations.uploadPhoto);

    let result = brandList.filter(
      (item: any) =>
        String(item.name)
          .toLowerCase()
          .localeCompare(String(name).toLowerCase()) === 0
    );

    if (result.length > 0)
      saveError("name", Strings.validations.brandNameExist);

    // @ts-ignore
    this.setState({ validation: errors });

    return Object.keys(errors).length === 0;
  }

  onSubmit() {
    const { name, photo } = this.state.item;

    if (!this.isValid()) return;

    let form = new FormData();
    form.set("category_ids[]", String(this.props.selectedCategoryId));
    form.set("account_id", localStorage.userId);
    form.set("name", name);
    // @ts-ignore
    form.set("photo", photo);

    let requestMessage = GetApiMessage({
      data: form,
      method: "post",
      endpoint: ApiUrls.createBrand,
    });

    this.setState({ isLoading: true, messageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onChangeItem(key: string, value: any) {
    this.setState({
      item: {
        ...this.state.item,
        [key]: value,
      },
    });
  }
}

export default NewBrandModalController;
