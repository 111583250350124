Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.categoryApiURL = "/bx_block_filter_items/filters/categories";
exports.newArrivalsApiURL = "/bx_block_catalogue/catalogues/new_arrivals";
exports.specialProductsApiURL = "/bx_block_catalogue/catalogues/specials";
exports.bestSellersApiURL = "/bx_block_catalogue/catalogues/best_sellers";
exports.homeDataApiURL = "/bx_block_catalogue/catalogues/slider";
exports.videoApiURL = "/bx_block_catalogue/catalogues/recomnded_video";
exports.todayDealsApiURL = "/bx_block_catalogue/catalogues/today_deal";
exports.beautyPersonalApiURL = "/bx_block_catalogue/catalogues/frequnatly_repurchased_in_beauty_and_personal_care";
exports.browsingHistoryApiURL = "/bx_block_catalogue/catalogues/get_user_browsing_history";
exports.buyItAgainApiURL = "/bx_block_catalogue/catalogues/buy_it_again?page=1&per_page=4";
// Customizable Area End
