// Customizable Area Start
import moment from "moment";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";

import GetApiMessage from "../GetApiMessage";
import ApiResponseMiddleware from "../ApiResponseMiddleware";
import { toast } from "react-toastify";

const configJs = require("./config");
const ApiUrls = configJs.ApiUrls;
const Strings = configJs.Strings;

export type FilterKey = "orderCount" | "cyLy" | "inventoryReport" | "salesByDistributions";


export interface ISalesByDistributionRow {
  month: string;
  card_payment: number;
  sadad_payment: number;
  cash_on_delivery: number;
}

export interface IOrderCountRow {
  period: {
    [key: string]:string|number
  };
  month: string;
  average: number;
  net_sales: number;
  percentage: number;
  item_count: number;
  transaction_count: number;
}

export interface ICyLyRow {
  period: {
    [key: string]:string|number
  };
  last_year: number;
  current_year: number;
}

interface IOrderTotals {
  total_average: number;
  total_net_sales: number;
  total_item_count: number;
  total_percentage: number;
  total_transaction_count: number;
}

interface Props {}
interface State {
  cyLyList: Array<ICyLyRow>;
  inventoryList: Array<any>;
  orderCountList: Array<IOrderCountRow>;
  salesByDistributionList: Array<ISalesByDistributionRow>;
  orderCountTotals: IOrderTotals;
  dateRanges:{
    orderCount:any[ ];
    cyLy:any[];
    inventoryReport:any[];
    salesByDistributions:any[];
  };
  filter:{
    orderCount:string;
    cyLy:string;
    inventoryReport:string;
    salesByDistributions:string;
  };
  loading:{
    orderCount:boolean;
    cyLy:boolean;
    inventoryReport:boolean;
    salesByDistributions:boolean;
  };
  // download links
  cyLyDownloadLink: string;
  inventoryDownloadLink: string;
  orderCountDownloadLink: string;
  salesByDistributionDownloadLink: string;

  // ApiIds
  salesByDistributionMessageId: string;
  inventoryReportMessageId: string;
  orderCountMessageId: string;
  cyLyMessageId: string;

  // current Month
  currentMonthIndex: number;
  monthList: Array<string>;
}
interface SS {}

class AdHocReportingController extends BlockComponent<Props, State, SS> {
  state = {
    cyLyList: [],
    inventoryList: [],
    orderCountList: [],
    salesByDistributionList: [],
    orderCountTotals: {
      total_average: 0,
      total_net_sales: 0,
      total_item_count: 0,
      total_percentage: 0,
      total_transaction_count: 0,
    },
    dateRanges:{
      orderCount:[ ],
      cyLy:[],
      inventoryReport:[],
      salesByDistributions:[],
    },
    filter:{
      orderCount:"month",
      cyLy:"month",
      inventoryReport:"month",
      salesByDistributions:"month"
    },
    loading:{
      orderCount:false,
      cyLy:false,
      inventoryReport:false,
      salesByDistributions:false
    },
    // download links
    cyLyDownloadLink: "",
    inventoryDownloadLink: "",
    orderCountDownloadLink: "",
    salesByDistributionDownloadLink: "",

    // ApiIds
    salesByDistributionMessageId: "",
    inventoryReportMessageId: "",
    orderCountMessageId: "",
    cyLyMessageId: "",

    // change month logic
    currentMonthIndex: -1,
    monthList: [],
  };

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  receive(from: string, message: Message): void {
    if (!ApiResponseMiddleware(message)) return;

    const {
      cyLyMessageId,
      orderCountMessageId,
      inventoryReportMessageId,
      salesByDistributionMessageId,
    } = this.state;

    let requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (successResponse) {
      switch (requestId) {
        case orderCountMessageId:
          this.setState(prev=>({
            orderCountTotals: successResponse.total,
            orderCountList: successResponse.data ?? [],
            orderCountDownloadLink: successResponse.download_data,
            loading:{
              ...prev.loading,
              orderCount:false
            }
          }));
          break;

        case cyLyMessageId:
          this.setState(prev=>({
            cyLyList: successResponse.data ?? [],
            cyLyDownloadLink: successResponse.download_data,
            loading:{
              ...prev.loading,
              cyLy:false
            }
          }));
          break;

        case salesByDistributionMessageId:
          this.setState(prev=>({
            salesByDistributionList: successResponse.data ?? [],
            salesByDistributionDownloadLink: successResponse.download_data,
            loading:{
              ...prev.loading,
              salesByDistributions:false
            }
          }));
          break;

        case inventoryReportMessageId:
          this.setState(prev=>({
            inventoryList: successResponse.data ?? [],
            inventoryDownloadLink: successResponse.download_data,
            loading:{
              ...prev.loading,
              inventoryReport:false
            }
          }));
          break;
      }
    }
  }

  componentDidMount(): any {
    super.componentDidMount();

    let months = [];
    for (let i = 0; i <= 11; i++)
      months.push(moment(new Date(2023, i, 1)).format("MMMM").toLowerCase());

    let currentMonth = moment().format("MMMM").toLowerCase();
    const initialRange=this.getInitialDateRange();
    this.setState({
      monthList: months,
      currentMonthIndex: months.indexOf(currentMonth),
      dateRanges:{
        cyLy:initialRange,
        inventoryReport:initialRange,
        orderCount:initialRange,
        salesByDistributions:initialRange
      }
    },()=>{
       this.prepareQuery("cyLy");
       this.prepareQuery("inventoryReport");
       this.prepareQuery("orderCount");
       this.prepareQuery("salesByDistributions");
    });
  }

  getInitialDateRange = () => {
    let today = new Date();
    today.setDate(today.getDate()+1)
    let thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    return [thirtyDaysAgo.toLocaleDateString("en-CA"), today.toLocaleDateString("en-CA")];
  };

  getSalesByDistribution(query:string="") {
    let requestMessage = GetApiMessage({
      endpoint: `${ApiUrls.salesByDistributionApi}?${query}`,
    });
    this.setState(prev=>({ salesByDistributionMessageId: requestMessage.messageId ,loading:{...prev.loading,salesByDistributions:true}}));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getOrderCountData(query:string="") {
    let requestMessage = GetApiMessage({ endpoint: `${ApiUrls.getOrderCountApi}?${query}` });
    this.setState(prev=>({ orderCountMessageId: requestMessage.messageId ,loading:{...prev.loading,orderCount:true}}));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCyLy(query:string="") {
    let requestMessage = GetApiMessage({ endpoint: `${ApiUrls.cyLyApi}?${query}` });
    this.setState(prev=>({ cyLyMessageId: requestMessage.messageId,loading:{...prev.loading,cyLy:true} }));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInventoryReport(query:string="") {
    let requestMessage = GetApiMessage({
      endpoint: `${ApiUrls.inventoryReportApi}?${query}`,
    });
    this.setState(prev=>({ inventoryReportMessageId: requestMessage.messageId,loading:{...prev.loading,inventoryReport:true} }));
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCyLyChartData() {
    const { cyLyList } = this.state;
    if (cyLyList.length > 0) {
      const data: Array<any> = cyLyList.map(
        ({ period, current_year, last_year }: ICyLyRow) =>{
          const propertyName = Object.keys(period)[0];
          const propertyValue = period[propertyName];
          return [
            propertyValue,
            current_year,
            last_year,
          ]
        } 
      );

      data.unshift([Strings.chart.month, Strings.chart.cy, Strings.chart.ly]);
      return data;
    } else
      return [
        [Strings.chart.month, Strings.chart.cy, Strings.chart.ly],
        [Strings.chart.noData, 0, 0],
      ];
  }

  getInventoryChartData() {
    const { inventoryList } = this.state;
    let data =
      inventoryList?.map((item: any) => [item.category, item.data]) ?? [];

    data.unshift([Strings.chart.category, Strings.chart.amount]);
    return data;
  }

  getSalesByDistributionChartData() {
    const { salesByDistributionList } =
      this.state;
    if (salesByDistributionList.length > 0) {
        const {
          card_payment,
          sadad_payment,
          cash_on_delivery,
        }: ISalesByDistributionRow = salesByDistributionList[0];

        return [
          [Strings.chart.type, Strings.chart.count],
          [Strings.chart.saddadPayment, sadad_payment],
          [Strings.chart.cardPayment, card_payment],
          [Strings.chart.cod, cash_on_delivery],
        ];
    } else return [[Strings.chart.type, Strings.chart.count]];
  }

  onChangeDateRange=(key:string,range:any)=>{
    const keyName=key as FilterKey;
    this.setState((prev)=>({
      dateRanges:{
        ...prev.dateRanges,
        [keyName]:range
      }
    }),()=>this.prepareQuery(key))
  }
  onFilterChange=(key:string,value:string)=>{
    const keyName=key as FilterKey;
      this.setState(prev=>({
        filter:{
          ...prev.filter,
          [key]:value
        }
      }),()=>{
        const dateRanges=this.state.dateRanges[keyName]
        if(dateRanges?.length!==2){
          toast.warn("Please choose a date range also to apply the filter")
        }
        
        this.prepareQuery(key)
      })
  }

  convertDateIntoRequiredFormate=(dateStr:string)=>{
    const date = new Date(dateStr);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  prepareQuery=(key:string)=>{
    const keyName=key as FilterKey;
     const by=this.state.filter[keyName] || "";
     const dateRanges=this.state.dateRanges[keyName]
     if(dateRanges?.length==2){
      const startDate=this.convertDateIntoRequiredFormate(dateRanges[0]);
      const endDate=this.convertDateIntoRequiredFormate(dateRanges[1]);

      const query=`key=${by}&start_date=${startDate}&end_date=${endDate}`
      if(key==="orderCount"){
        this.getOrderCountData(query)
      }else if(key==="cyLy"){
        this.getCyLy(query);

      }else if(key==="inventoryReport"){
        this.getInventoryReport(query)
      }else if(key==="salesByDistributions"){
        this.getSalesByDistribution(query)
      }
     }

  }


  
  onChangeMonth(flag: boolean) {
    let { currentMonthIndex, monthList } = this.state;
    let condition1 = currentMonthIndex >= monthList.length - 1 && flag;
    let condition2 = currentMonthIndex <= 0 && !flag;

    if (condition1 || condition2) return;

    this.setState({
      currentMonthIndex: flag ? currentMonthIndex + 1 : currentMonthIndex - 1,
    });
  }
}

export default AdHocReportingController;

// Customizable Area End
