// Customizable Area Start

import React from "react";
import { Input, Box, styled } from "@material-ui/core";

import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { iconSearch, iconGo } from "../assets";

const configJs = require("./config");
const Strings = configJs.Strings;

interface Props {
  onSearch?: Function;
}
interface State {
  text: string;
}
interface SS {}

class SearchBar extends BlockComponent<Props, State, SS> {
  state = {
    text: "",
  };

  handleSearch() {
    const { text } = this.state;
    const { onSearch } = this.props;

    if (onSearch) onSearch(text);
  }

  render() {
    return (
      <Box
        pl={1}
        pr={1}
        border={2}
        borderRadius={4}
        borderColor={"var(--navy)"}
      >
        <img src={iconSearch} width={20} />
        &nbsp;
        <StyledInput
          data-test-id="field-search"
          onChange={(e) => this.setState({ text: e.target.value })}
          onKeyDown={(e) => e.key == "Enter" && this.handleSearch()}
          placeholder={Strings.search}
        />
        <img
          data-test-id="btn-ok"
          width={15}
          src={iconGo}
          onClick={() => this.handleSearch()}
        />
      </Box>
    );
  }
}

const StyledInput = styled(Input)({
  lineHeight: "unset",
  height: "unset",
  border: "none !important",
  "&::before": {
    border: "none !important",
  },
  "&::after": {
    border: "none !important",
  },
  "& .MuiInputBase-input": {
    height: "unset",
  },
});

export default SearchBar;

// Customizable Area End
