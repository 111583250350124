// Customizable Area Start
import React from 'react'
import CommonCarousel from '../../FrontLayout/src/CommonCarousel.web'
import Offers from '../../FrontLayout/src/Offers.web'
import BrowsingHistory from '../../FrontLayout/src/BrowsingHistory.web'
import ShopCategory from '../../FrontLayout/src/ShopCategory.web'
import LandingPageController, { Props } from './LandingPageController'

export default class Category extends LandingPageController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const {
            homeData,
            homeCategoryList,
            specialList,
            newArrivalList,
            bestProductsList,
        } = this.state

        const offerProps = { isProtected: false, history: { push: () => null } }
        offerProps.history.push()

        return (
            <div className="HomePage">
                <div className="frontContent">
                    {/* hero */}
                    <div
                        id="hero"
                        className="carousel slide"
                        data-bs-ride="carousel"
                    >
                        <div className="carousel-indicators">
                            {homeData?.length > 0 &&
                                Array.from(
                                    Array(homeData?.length),
                                    (e: any, i: number) => {
                                        return (
                                            <button
                                                type="button"
                                                key={'sliderNumber' + i}
                                                data-bs-target="#hero"
                                                data-bs-slide-to={i}
                                                className={
                                                    i == 0 ? 'active' : ''
                                                }
                                            ></button>
                                        )
                                    }
                                )}
                        </div>
                        <div className="carousel-inner">
                            {homeData?.length > 0 &&
                                homeData?.map((result: any, i: number) => (
                                    <div
                                        className={
                                            i == 0
                                                ? 'carousel-item active'
                                                : 'carousel-item'
                                        }
                                        key={
                                            'category-slider-item-' + result.id
                                        }
                                    >
                                        <img
                                            src={
                                                result?.attributes?.images
                                                    ? result?.attributes?.images
                                                    : '/frontend/banner.png'
                                            }
                                            alt="banner" className={'w-100'}
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                    {/* content */}
                    <div className="flexBox">
                        <div className="product-type">
                            {specialList?.length > 0 && (
                                <CommonCarousel
                                    sectionSlug="specials"
                                    name="Specials"
                                    items={specialList}
                                    bestSeller={''}
                                    resultDeal={''}
                                />
                            )}
                            {newArrivalList?.length > 0 && (
                                <CommonCarousel
                                    name="New Arrivals"
                                    sectionSlug="new-arrivals"
                                    items={newArrivalList}
                                    bestSeller={''}
                                    resultDeal={''}
                                />
                            )}
                            {bestProductsList?.length > 0 && (
                                <CommonCarousel
                                    name="Best Sellers"
                                    sectionSlug="best-sellers"
                                    items={bestProductsList}
                                    bestSeller={''}
                                    resultDeal={''}
                                />
                            )}
                        </div>
                    </div>
                    <Offers {...offerProps} />
                    {homeCategoryList?.length > 0 && (
                        <ShopCategory items={homeCategoryList} type='Category' />
                    )}
                    <BrowsingHistory />
                </div>
            </div>
        )
    }
}

// Customizable Area End
