// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";

interface Props {}
interface State {}
interface SS {}

class CareersController extends BlockComponent<Props, State, SS> {
  componentDidMount(): any {
    setTimeout(() => window.scrollTo(0, 0), 500);
  }
}

export default CareersController;

// Customizable Area End
