// Customizable Area Start

import React from "react";
import { Button, styled, Grid, Typography } from "@material-ui/core";

import { iconLeftArrow, iconRightArrow } from "../assets";
import { BlockComponent } from "../../../../framework/src/BlockComponent";

const configJs = require("./config");
const Strings = configJs.Strings;

interface Props {
  currentPage: number;
  lastPage: number;
  onChangePage?: any;
}
interface S {}
interface SS {}

class Pagination extends BlockComponent<Props, S, SS> {
  onChange(flag: boolean) {
    const { currentPage, lastPage, onChangePage } = this.props;
    let page: number = currentPage;

    if (flag) {
      if (currentPage < lastPage) page++;
    } else {
      if (currentPage > 1) page--;
    }

    if (currentPage !== page) onChangePage(page);
  }

  render() {
    const { currentPage, lastPage } = this.props;

    return (
      <Grid container spacing={1}>
        <Grid item>
          <StyledButton
            data-test-id="btn-left"
            disabled={currentPage === 1}
            onClick={() => this.onChange(false)}
            variant={"outlined"}
          >
            <img src={iconLeftArrow} width={15} />
          </StyledButton>
        </Grid>
        <Grid item>
          <StyledText>
            {Strings.page} {currentPage} / {lastPage}
          </StyledText>
        </Grid>
        <Grid item>
          <StyledButton
            data-test-id="btn-right"
            disabled={currentPage >= lastPage}
            onClick={() => this.onChange(true)}
            variant={"outlined"}
          >
            <img src={iconRightArrow} width={15} />
          </StyledButton>
        </Grid>
      </Grid>
    );
  }
}

const StyledText = styled(Typography)({
  fontSize: 14,
  fontWeight: 500,
  borderRadius: 4,
  padding: "7px 12px",
  color: "var(--navy)",
  border: "1px solid var(--navy)",
});

const StyledButton = styled(Button)({
  width: 37,
  height: 36,
  padding: 11,
  borderRadius: 4,
  minWidth: "unset",
  boxShadow: "none",
  border: "1px solid var(--navy) !important",
  "&.Mui-disabled": {
    opacity: 0.55,
    backgroundColor: "#ddd",
  },
});

export default Pagination;

// Customizable Area End
