// Customizable Area Start
import React from "react";
import {Link} from "react-router-dom";

import Moment from "moment";
import SubscriptionController, {
  Props,
} from "./SubscriptionController";

export default class Subscription extends SubscriptionController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { subscriptions, subscriptionsPagination } = this.state;
    return (
        <> 
        <div className='frontContent py-4 px-5'>           
            <div className='title-box mb-0'>
                <h4 className='title'>My Subscriptions</h4>                               
            </div>
            <div className={"tab-pane active"} id="subscription">
                      {subscriptions?.data?.length === 0 ? (
                          <div className='no-pending-orders'>
                              <h6>No Subscription found.</h6>
                          </div>
                      ) : (
                          <div>
                              {subscriptions?.data?.length > 0 && subscriptions.data.map((result: any, i: number) => (
                                  <div className='order-flex-box' key={'all-orders-items-' + i}>
                                      <div className='order-flex-column1'>
                                          <Link to={'/product/' + result?.attributes?.catalogue?.data?.id}><img className='w-100 h-100' src={result?.attributes?.catalogue?.data?.attributes?.images?.length > 0 ? result.attributes.catalogue?.data?.attributes?.images[0].url : '/frontend/product1.png'} alt='product' /></Link>
                                      </div>
                                      <div className='order-flex-column2'>
                                          <div className='flex-box'>
                                              <div className='column'>
                                                  <h6 className='m-0'>{result?.attributes?.catalogue?.data?.attributes?.name}</h6>
                                              </div>
                                              <div className='column'>
                                              </div>
                                              {result?.attributes?.status === 'on_going' ? (<div className='column green-text w-200'>Ongoing Subscription</div>) : ''}
                                              {result?.attributes?.status === 'cancelled' ? (<div className='column maroon-text w-200'>Subscription Cancelled</div>) : ''}
                                              {result?.attributes?.status === 'expired' ? (<div className='column maroon-text w-200'>Past Subscription</div>) : ''}
                                              {result?.attributes?.status === 'pending' ? (<div className='column maroon-text w-200'>Subscription Pending</div>) : ''}
                                          </div>
                                          <div className='flex-box'>
                                              <div className='column'>
                                                <p className='m-0'>Subscription Started:  {Moment(result?.attributes?.start_date).format('DD MMMM, YYYY')}</p>
                                                  <p className='m-0'>Subscription ends: {Moment(result?.attributes?.end_date).format('DD MMMM, YYYY')}</p>
                                                  <p className='m-0'>Total Duration: {result?.attributes?.duration_type} Month(s)</p>
                                                  <p className='m-0'>Total : AED {result?.attributes?.price}</p>
                                              </div>
                                              <div className='column'>
                                              </div>
                                              <div className='column'>
                                                  {(result?.attributes?.status !== 'cancelled') && (
                                                  <div className='blueLinks text-decoration mb-0' style={{ cursor: 'pointer' }} data-test-id="subscriptionCancel" onClick={() => { this.cancelSubscription(result?.id) }}>Cancel Subscription</div>)}
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              ))}
                          </div>
                      )}
                      {subscriptions?.meta?.last_page > 1 && (
                          <div className='text-end mt-3'>
                              {subscriptionsPagination > 1 && (<button type='button' data-test-id="paginationPrevious" className='border-btn me-2' onClick={() => { this.showPaginationData('subscription', 'previous') }}>Previous</button>)}
                              {subscriptions.meta.last_page > subscriptionsPagination && (<button type='button' className='filled-btn' data-test-id="paginationNext" onClick={() => { this.showPaginationData('subscription', 'next') }}>Next</button>)}
                          </div>
                      )}
                  </div>
        </div>
        
        </>
    );
  }
}
// Customizable Area End
