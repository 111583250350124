// Customizable Area Start
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum'
import { Message } from '../../../framework/src/Message'
import { runEngine } from '../../../framework/src/RunEngine'
import { BlockComponent } from '../../../framework/src/BlockComponent'
import ApiUrls from './config'
import GetApiMessage from '../../../blocks/utilities/src/GetApiMessage'
import { IBlock } from "../../../framework/src/IBlock";

interface Props { }
interface State { }
interface SS { }

class OffersController extends BlockComponent<Props, State, SS> {

  state = {
    offerMessageId: '',
    offers: []
  }

  constructor(props: Props) {
    super(props)

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
    ])
  }

  componentDidMount(): any {
    this.offerList();
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const { offerMessageId} = this.state
    let requestID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    let successResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if (offerMessageId === requestID) {
      if (successResponse) {
        this.setState({ offers: successResponse.data ?? [] })
      }
    }
  }
  async offerList() {
    const requestMessage = GetApiMessage({ method: 'get', endpoint: ApiUrls.offerApiURL });
    this.setState({ offerMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

export default OffersController;
// Customizable Area End
