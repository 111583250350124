import React from "react";

// Customizable Area Start
import CommonCarousel from "../../FrontLayout/src/CommonCarousel.web";
import Offers from "../../FrontLayout/src/Offers.web";
import BrowsingHistory from "../../FrontLayout/src/BrowsingHistory.web";
import {Link} from "react-router-dom";
import {
  StyleSheet,
  Text,
  SafeAreaView,
  Button,
  View
} from "react-native";
// Customizable Area End

import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {homeCategoryList, homeData, video, todayDeals, beautyAndPersonalCare, browsingHistoryList, buyItAgain} = this.state;
    // Customizable Area Start
    const topcategories = homeCategoryList.slice(0,3);
    const offerProps = { isProtected: false, history: { push: () => null }}
    offerProps.history.push()
    // Customizable Area End
    return (
      <>
        <div className='HomePage second'>
            <div className='frontContent'>
                {/* hero */}
                <div id="hero" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                      {homeData.length > 0 && Array.from(Array(homeData.length ), (e: any, i: number) => {
                        return (<button type="button" key={'sliderNumber' + i} data-bs-target="#hero" data-bs-slide-to={i} className={(i == 0) ? "active" : ''}></button>)
                      })
                      }
                        
                    </div>                    
                    <div className="carousel-inner">
                    {homeData.length > 0 && homeData.map((result:any, i:number) =>
                          (
                            <div className={(i == 0) ? "carousel-item active" : 'carousel-item'} key={'landing-page-slider' + result.id}>
                            <a href={result.attributes.url}><img src={result.attributes.images ? result.attributes.images : "/frontend/banner.png"} alt="banner" className={'w-100'}/></a>
                        </div>
                  ))}
                    </div>
                </div>
                {/* content */}
                <div className='row m-0 mt-3 px-3'>
                {topcategories.length > 0 && topcategories.map((result:any, i:number) =>
                          (
                            <div key={'top-categories-'+result.id} className='col-md-4 col-sm-6 mb-3'>
                            <Link to={'/d/category/' + result.id}>
                              <div className='col-card'>
                                <img className='w-100' src={result.attributes?.images ? result.attributes.images.url :'/frontend/product1.png'} alt="banner"/>
                                <div className='col-body'>
                                    <h6 className='offer'>Upto 25% off in</h6>
                                    <h4 className='title'>{result.attributes?.name}</h4>
                                </div>
                              </div>
                            </Link>
                        </div>
                  ))}                   
                </div>
                <div className='row m-0 mt-3 px-3'>
                {video[0]?.attributes?.videos?.length > 0 && (<>
                  <div className={buyItAgain.length === 0 ?  'col-lg-12 mb-3 mb-lg-3' : 'col-lg-8 mb-3 mb-lg-0'}>
                        <video className='home-video' controls autoPlay={true} muted={true}>
                              <source src={video[0]?.attributes?.videos} type="video/mp4"/>
                        </video>
                        <h4 className='mt-1 mb-0'>Recommended a video for you</h4>
                    </div>
                </>)}
                   
                    {buyItAgain.length > 0 && (
                      <div className='col-lg-4 mb-3 mb-md-0'>
                          <div className='col-card'>
                            <div className="row m-0 py-2 px-0">
                            {buyItAgain.length > 0 && buyItAgain.map((result:any, i:number) =>
                              (
                                <div className="col-sm-6" key={'buyItAgain' + i}> <Link key={'buy-it-agin-item-'+result.id} to={'/product/'+result.id}><img className='w-100' src={result.attributes.images.length > 0 ? result.attributes.images[0].url :'/frontend/product1.png'} alt={result.attributes.name}/></Link></div>
                              )
                            )}
                            </div>
                          </div>
                          <div className='d-flex justify-content-between mt-1 align-items-center'>
                              <h4 className='mb-0'>Buy again</h4>
                              <h6 className='mb-0'><Link to="/orders/">See More</Link></h6>
                          </div>
                      </div>
                    )}
                </div>
                <div className='p-4'>
                  {todayDeals.length > 0 && (<CommonCarousel name="Today's deals" items={todayDeals} sectionSlug={'todays-deal'} bestSeller={''} resultDeal={''}/>)}
                    
                  {beautyAndPersonalCare.length > 0 && ( <CommonCarousel name='Frequently repurchased in Beauty & Personal Care' sectionSlug={'frequently-repurchased-in-beauty-and-personal-care'} items={beautyAndPersonalCare} bestSeller={''} resultDeal={''}/>)}
                </div>
                <Offers {...offerProps} />
                <div className='p-4'>
                {browsingHistoryList.length > 0 && ( <CommonCarousel name="Pick up where you left off" sectionSlug={'pick-up-where-you-left-off'} items={browsingHistoryList} bestSeller={''} resultDeal={''}/>)}
                </div>
                <BrowsingHistory/>
            </div>
        </div>
      </>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  landingPageView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  landingPageText: {
    fontSize: 42,
    letterSpacing: 2,
    fontWeight: "bold",
    color: "#323441",
    marginTop: 15
  },
  mainContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff"
  }
});
// Customizable Area End
