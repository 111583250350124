// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import GetApiMessage  from "../../utilities/src/GetApiMessage";

import ApiUrls from './config'


export interface Props {
  navigation: any;
  id: string;
  
}

interface S {
  placeOrder: any;
  offersPageList: any;
  offersPageMessageId: any;
  homeDataMessageId: any;
  homeData: any;
}

interface SS {
  id: any;
}

export default class OffersPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      placeOrder: false,
      offersPageList: [],
      offersPageMessageId: '',
      homeDataMessageId: '',
      homeData: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    const { offersPageMessageId, homeDataMessageId} = this.state
    let requestID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    let successResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if (offersPageMessageId === requestID) {
      if (successResponse) {
        this.setState({ offersPageList: successResponse.data ?? [] })
      }
    } else if (homeDataMessageId === requestID) {
      if (successResponse) {
        this.setState({ homeData: successResponse.data ?? [] })
      }
    }
  }

  componentDidMount(): any {
    this.offersList();
    this.getSlider();
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  showPlaceOrder() {
    this.setState({
      placeOrder: true
    });
  }
  closeModal(){
    this.setState({
      placeOrder: false
    });
  }
  
  
  async offersList() {
    let requestMessage = GetApiMessage({ method: 'get', endpoint: ApiUrls.offerPageApiURL });
    this.setState({ offersPageMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async getSlider() {
    let requestMessage = GetApiMessage({ method: 'get', endpoint: ApiUrls.homeDataApiURL });
    this.setState({ homeDataMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
// Customizable Area End