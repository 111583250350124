// Customizable Area Start
import React from "react";
import {  StyleSheet, View} from "react-native";
import Loader from "../../../components/src/Loader";
import SocialMediaAccountWebController, {
  Props,
} from "../../social-media-account/src/SocialMediaLoginControlle.web";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
class SocialMediaAccountLoginScreen extends SocialMediaAccountWebController {
  static SocialMediaAccountLoginScreen: SocialMediaAccountLoginScreen;

  constructor(props: Props) {
    super(props);
    this.state = {
      // Customizable Area Start
       isDocSkip: false,
       isLogin: true,
       passwordShown: false,
       loginPasswordShown: false,
       confirmPasswordShown: false,
       signup1: false,
       signup2: false,
       signup3: false,
       OTP: false,
       loginInputValues: {
         loginEmail: '',
         loginPassword: ''
       },
       signupInputValues: {
         signupEmail : '',
         signupFname : '',
         signupLname : '',
         signupPassword : '',
         signupConfirmPassword : '',
         signupAddress: '',
         signupCity : '',
         signupState : '',
         signupCountry : '',
         signupBusinessName : '',
         signupPhoneNumber : '',
         signupBusinessCountry : '',
         signupTeamMember : ''
       },
       commercial: {name: ''},
       tax:{name: ''},
       supporting:{name: ''},
       pharmacy:{name: ''},
       companyLogo:{name: ''},
       OTPInputValues: {otp: ''},
       loginValidation: {
         loginEmail: '',
         loginPassword: ''
       },
       signupValidation:{
         signupEmail : '',
         signupFname : '',
         signupLname : '',
         signupPassword : '',
         signupConfirmPassword : '',
         signupAddress: '',
         signupCity : '',
         signupState : '',
         signupCountry : '',
         signupBusinessName : '',
         signupPhoneNumber : '',
         signupBusinessCountry : ''
       },
       otpValidation:{otp: ''},
      mobileLoginNumber: { phoneNumber: "" },
      mobileLoginValidation: { phoneNumber: "" },
      vendorLoginError: "",
      vendorMobileLoginError: "",
      vendorSignupError: "",
      otpTimer: 60,
      showResendTimer: false,
      otpPhoneNumber: '',
      verifyOtpError: '',
      countries: {},
      states: {},
      cities: {},
      showActivatePopup: false,
       // Customizable Area End
      loading: false,
      isRegistration: false
    };
  }

  render() {
    const { loading } = this.state;
    // Customizable Area Start
    const { isLogin, loginInputValues, signup1, signupInputValues, passwordShown, loginPasswordShown, confirmPasswordShown, signup2, commercial, tax, supporting, pharmacy, signup3, OTP, OTPInputValues, loginValidation, signupValidation, mobileLoginNumber, vendorLoginError, mobileLoginValidation, vendorMobileLoginError, vendorSignupError, otpTimer, showResendTimer, otpPhoneNumber, verifyOtpError, otpValidation, countries, states, cities, companyLogo, showActivatePopup} = this.state;
    // Customizable Area End
    return (
      <View style={styles.container}>
        <Loader loading={loading} />
     {/* Customizable Area Start */}
      <section className='user'>
      {/* login */}
      {isLogin ? (<>
      <div className='popup' id="login">
          <img className="logo" src="/images/logo.png" alt="logo" />
          <div className='options'>
            <button className='links' onClick={() => this.showSignup1()}>Sign Up</button> <span>|</span> <button className='links focus' onClick={() => this.showLogin()}>Login</button>
          </div>
          <div className='scroll-container'>
          <p className='formErrors'>{vendorLoginError ? vendorLoginError : ''}</p>
          <div style={{overflow:'auto', paddingTop: '5px'}}>
          <div className='inputGroups'>
            <label className='icons'><img src="/images/email.png" alt="email" /></label>
            <input className='inputs' name="loginEmail" type="text" placeholder='Enter Email Address' value={loginInputValues.loginEmail} onChange={(e) => this.handleLoginChange(e)}/>
          </div>
          {loginValidation.loginEmail && (<p className='formErrors'>{loginValidation.loginEmail}</p>)}
          <div className='inputGroups'>
            <label className='icons'><img src="/images/lock.png" alt="password" /></label>
            <input className='inputs' name="loginPassword" type={loginPasswordShown ? "text" : "password"} placeholder='Password' value={loginInputValues.loginPassword} onChange={(e) => this.handleLoginChange(e)}/>
            <img className='passwordIcon' src={loginPasswordShown ? "/images/showPassword.png" : "/images/hidePassword.png"} alt="eye" onClick={() => {this.toggleLoginPassword()}}/>
          </div>
          {loginValidation.loginPassword && (<p className='formErrors'>{loginValidation.loginPassword}</p>)}
          <div className="text-end"><span className='blueLinks forgot d-inline' onClick={()=>{this.openForgotPassword()}}>Forgot Password?</span></div>
          <div className='buttons'><button className='btnSubmit' onClick={(e) => {this.logIn(e)}}>Login</button></div>
          <h6 className='textLine'><span>or</span></h6>
          <form>
            {vendorMobileLoginError && (<p className='formErrors'>{vendorMobileLoginError}</p>)}
            <div className='inputGroups'>
              <label className='icons'><img src="/images/phone.png" alt="phone" /></label>
              <PhoneInput
                inputProps={{
                  onKeyDown: (e: any) => {
                    e.key == "Enter" && this.mobileLogin()
                  },
                }}  
                inputStyle={{
                  height:"100%",
                  width:"calc(100% - 45px)",
                  marginLeft:"43px"
                }}
                inputClass="inputs"
                placeholder="Enter phone number"
                value={mobileLoginNumber.phoneNumber}
                onChange={value=>this.handlePhoneNumberChange(value)}
                />
             </div>
            {mobileLoginValidation.phoneNumber && (<p className='formErrors'>{mobileLoginValidation.phoneNumber}</p>)}
          </form>
          <p className='notes'>Don't Have an Account. <button className='blueLinks' onClick={() => {this.showSignup1()}}>Click Here</button> to create an Account</p>
          </div>
          </div>
      </div>
      </>)
      : ('')}
      {/* signup */}
      {signup1 ? (<>
      <div className='popup' id="signup1">
          <img className="logo" src="/images/logo.png" alt="logo" />
          <div className='options'>
            <button className='links focus' onClick={() => {this.showSignup1()}}>Sign Up</button> <span>|</span> <button className='links' onClick={() => {this.showLogin()}}>Login</button>
          </div>
          <div className='scroll-container'>
          {vendorSignupError && (<p className="formErrors">{vendorSignupError}</p>)}
          <h4 className='steps'>Step 1</h4>
          <div className='inputGroups'>
            <label className='icons'><img src="/images/email.png" alt="email" /></label>
            <input className='inputs' name="signupEmail" type="text" placeholder='Enter Email Address' value={signupInputValues.signupEmail} onChange={(e) => this.handleSignupChange(e)}/>
          </div>
          {signupValidation.signupEmail && (<p className='formErrors'>{signupValidation.signupEmail}</p>)}
          <div className='inputGroups'>
            <label className='icons'><img src="/images/user.png" alt="user" /></label>
            <input className='inputs' name="signupFname" type="text" placeholder='First Name' value={signupInputValues.signupFname} onChange={(e) => this.handleSignupChange(e)}/>
          </div>
          {signupValidation.signupFname && (<p className='formErrors'>{signupValidation.signupFname}</p>)}
          <div className='inputGroups'>
            <label className='icons'><img src="/images/user.png" alt="user" /></label>
            <input className='inputs' name="signupLname" type="text" placeholder='Last Name' value={signupInputValues.signupLname} onChange={(e) => this.handleSignupChange(e)}/>
          </div>
          {signupValidation.signupLname && (<p className='formErrors'>{signupValidation.signupLname}</p>)}
        <div className='inputGroups'>
          <label className='icons'><img src="/images/phone.png" alt="phone" /></label>
              <PhoneInput
                inputStyle={{
                  height:"100%",
                  width:"calc(100% - 45px)",
                  marginLeft:"43px"
                }}
                inputClass="inputs"
                placeholder="Phone number"
                value={signupInputValues.signupFullPhoneNumber}
                onChange={value=>this.handleSignupPhoneNumberChange("signupFullPhoneNumber",value)}
                />
        </div>
        {signupValidation.signupFullPhoneNumber && (<p className='formErrors'>{signupValidation.signupFullPhoneNumber}</p>)}
          <div className='inputGroups'>
            <label className='icons'><img src="/images/lock.png" alt="password" /></label>
            <input className='inputs' type={passwordShown ? "text" : "password"} name="signupPassword" placeholder='Password' value={signupInputValues.signupPassword} onChange={(e) => this.handleSignupChange(e)}/>
            <img className='passwordIcon' src={passwordShown ? "/images/showPassword.png" : "/images/hidePassword.png"} alt="eye" onClick={() => {this.togglePassword()}}/>
          </div>
          {signupValidation.signupPassword && (<p className='formErrors'>{signupValidation.signupPassword}</p>)}
          <div className='inputGroups'>
            <label className='icons'><img src="/images/lock.png" alt="password" /></label>
            <input className='inputs' type={confirmPasswordShown ? "text" : "password"}  name="signupConfirmPassword" placeholder='Confirm Password' value={signupInputValues.signupConfirmPassword} onChange={(e) => this.handleSignupChange(e)}/>
            <img className='passwordIcon' src={confirmPasswordShown ? "/images/showPassword.png" : "/images/hidePassword.png"} alt="eye" onClick={() => {this.toggleConfirmPassword()}}/>
          </div>
          {signupValidation.signupConfirmPassword && (<p className='formErrors'>{signupValidation.signupConfirmPassword}</p>)}
          <div className='buttons'><button className='btnSubmit' onClick={() => {this.showSignup2()}}>Go to next step</button> </div>
      </div>
      </div>
      </>)
      : ('')}
      {/* signup 2 */}
      {signup2 ? (<>
      <div className='popup' id="signup2">
          <img className="logo" src="/images/logo.png" alt="logo" />
          <div className='options'>
            <button className='links focus' onClick={() => {this.showSignup1()}}>Sign Up</button> <span>|</span> <button className='links' onClick={() => {this.showLogin()}}>Login</button>
          </div>
          <div className='scroll-container'>
          <h4 className='steps'>Step 2</h4>
          <h6 className='label'>Commercial Registration Document <span>*</span></h6>
          <div className='filesBox' onChange={(e) => this.uploadCommercial(e)}>
            <input type="file" className='file' id='commercial' name="commercial" accept="application/pdf" />
            <label className='fileLabel'><img className='upload' src='/images/upload.png' alt='upload' /> {commercial.name ? commercial.name : 'Upload Documents'} </label>
          </div>
        {signupValidation.signupCommercial && (<p className='formErrors'>{signupValidation.signupCommercial}</p>)}
          <h6 className='label'>Tax Registration Document <span>*</span></h6>
          <div className='filesBox' onChange={(e) => this.uploadTax(e)}>
            <input type="file" className='file' id='tax' name='tax' accept="application/pdf" />
            <label className='fileLabel'><img className='upload' src='/images/upload.png' alt='upload' /> {tax.name ? tax.name : 'Upload Documents'} </label>
          </div>
        {signupValidation.signupTax && (<p className='formErrors'>{signupValidation.signupTax}</p>)}
          <h6 className='label'>Supporting Document <span>*</span></h6>
          <div className='filesBox' onChange={(e) => {this.uploadSupporting(e)}}>
            <input type="file" className='file' id='supporting' name="supporting" accept="application/pdf" />
            <label className='fileLabel'><img className='upload' src='/images/upload.png' alt='upload' /> {supporting.name ? supporting.name : 'Upload Documents'} </label>
          </div>
        {signupValidation.signupSupporting && (<p className='formErrors'>{signupValidation.signupSupporting}</p>)}
          <h6 className='label'>Pharmacy Registration Document <span>*</span></h6>
          <div className='filesBox' onChange={(e) => this.uploadPharmacy(e)}>
            <input type="file" className='file' id='pharmacy' name='pharmacy' accept="application/pdf" />
            <label className='fileLabel'><img className='upload' src='/images/upload.png' alt='upload' /> {pharmacy.name ? pharmacy.name : 'Upload Documents'} </label>
          </div>
          {signupValidation.signupPharmacy && (<p className='formErrors'>{signupValidation.signupPharmacy}</p>)}
          <h6 className='label'>Company Logo <span>(Optional)</span></h6>
          <div className='filesBox' onChange={(e) => this.uploadCompanyLogo(e)}>
            <input type="file" className='file' id='companyLogo' name='companyLogo' accept="image/*" />
            <label className='fileLabel'><img className='upload' src='/images/upload.png' alt='upload' /> {companyLogo.name ? companyLogo.name : 'Upload Logo'} </label>
          </div>
          <div className='buttons'><button className='btnSubmit' onClick={() => {this.showSignup1()}}>Back</button> &nbsp;&nbsp;&nbsp;&nbsp;
          <button className='btnSubmit' onClick={() => {this.showSignup3()}}>Next</button> &nbsp;&nbsp;&nbsp;&nbsp; 
          {/* <button className='btnSubmit' onClick={() => {this.showSignup3WithSkip()}}>Skip</button> */}
          </div>
      </div>
      </div>
      </>)
      : ('')}
      {/* signup 3 */}
      {signup3 ? (<>
      <div className='popup' id="signup3">
          <img className="logo" src="/images/logo.png" alt="logo" />
          <div className='options'>
            <button className='links focus' onClick={() => {this.showSignup1()}}>Sign Up</button> <span>|</span> <button className='links' onClick={() => {this.showLogin()}}>Login</button>
          </div>
          <div className='scroll-container'>
          <h4 className='steps'>Step 3</h4>
        {vendorSignupError && (<p className='formErrors'>{vendorSignupError}</p>)}
          <h6 className='label'>Business Address <span>*</span></h6>
          <div className='inputGroups'>
            <select className='inputs' name='signupCountry' value={signupInputValues.signupCountry} onChange={(e) => this.handleSignupChange(e, 'country')}>
                <option value={''}>Country/Region</option>
                {countries && Object.keys(countries).map(function(key, i) { 
                    return (<option value={key} key={'country' + i}>{countries[key]}</option>)
                  })}
            </select>
          </div>
          {signupValidation.signupCountry && (<p className='formErrors'>{signupValidation.signupCountry}</p>)}
          <div className='inputGroups'>
            <select className='inputs' name='signupState' value={signupInputValues.signupState} onChange={(e) => this.handleSignupChange(e, 'state')}>
                <option value={''}>State</option>
                {states && Object.keys(states).map(function(key, i) {
                    return (<option value={key} key={'states' + i}>{states[key]}</option>)
                })}
            </select>
          </div>
          {signupValidation.signupState && (<p className='formErrors'>{signupValidation.signupState}</p>)}
          <div className='inputGroups'>
            <input className='inputs' name="signupCity" type="text" placeholder='City'  value={signupInputValues.signupCity} onChange={(e) => this.handleSignupChange(e)}/>
          </div>
          {signupValidation.signupCity && (<p className='formErrors'>{signupValidation.signupCity}</p>)}
          <div className='inputGroups'>
            <input className='inputs' name="signupAddress" type="text" placeholder='Address'  value={signupInputValues.signupAddress} onChange={(e) => this.handleSignupChange(e)}/>
          </div>
          {signupValidation.signupAddress && (<p className='formErrors'>{signupValidation.signupAddress}</p>)}
          <h6 className='label'>Legal Business Name <span>*</span></h6>
          <div className='inputGroups'>
            <input className='inputs' name="signupBusinessName" type="text" placeholder='Business Name'  value={signupInputValues.signupBusinessName} onChange={(e) => this.handleSignupChange(e)}/>
          </div>
          {signupValidation.signupBusinessName && (<p className='formErrors'>{signupValidation.signupBusinessName}</p>)}
          <div className='inputGroups'>
              <PhoneInput
                inputStyle={{
                  height:"100%",
                  width:"calc(95% - 47px)",
                  marginLeft:"40px"
                }}
                inputClass="inputs"
                placeholder="Phone number"
                value={signupInputValues.signupPhoneNumber}
                onChange={value=>this.handleSignupPhoneNumberChange("signupPhoneNumber",value)}
                />
        </div>
          {signupValidation.signupPhoneNumber && (<p className='formErrors'>{signupValidation.signupPhoneNumber}</p>)}
          <div className='inputGroups'>
          <select className='inputs' name='signupBusinessCountry' value={signupInputValues.signupBusinessCountry} onChange={(e) => this.handleSignupChange(e)}>
                <option value={''}>Country/Region</option>
                {countries && Object.keys(countries).map(function(key, i) { 
                    return (<option value={key} key={'country' + i}>{countries[key]}</option>)
                  })}
            </select>
          </div>
          {signupValidation.signupBusinessCountry && (<p className='formErrors'>{signupValidation.signupBusinessCountry}</p>)}
          <h6 className='label'>Add Team Members (Optional)</h6>
          <div className='inputGroups'>
            <input className='inputs' name="signupTeamMember" type="text" placeholder='email ID,email ID,etc '  value={signupInputValues.signupTeamMember} onChange={(e) => this.handleSignupChange(e)}/>
          </div>
          <div className='buttons'><button className='btnSubmit' onClick={() => {this.signup2Back()}}>Back</button> &nbsp;&nbsp;&nbsp;&nbsp; <button className='btnSubmit' onClick={() => {this.showOTP()}}>Sign up</button></div>
      </div>
      </div>
      </>)
      : ('')}
      {/* otp */}
      {OTP ? (<>
      <div className='popup' id="otp">
          <img className="logo" src="/images/logo.png" alt="logo" />
          <h6 className='otpSent'>OTP send to your mobile number <span className='blueLinks'>{otpPhoneNumber}</span></h6>
          {verifyOtpError && (<p className='formErrors'>{verifyOtpError}</p>)}
          <div className='inputGroups'>
            <label className='icons'><img src="/images/key.png" alt="key" /></label>
            <input className='inputs loose' name="otp" type="text" placeholder='Enter OTP'  value={OTPInputValues.otp} onChange={(e) => this.handleOTPChange(e)}/>
          </div>
        {otpValidation.otp && (<p className='formErrors'>{otpValidation.otp}</p>) }
          <div className='buttons'><button className='btnSubmit' onClick={(e) => this.OtpValidation(e)}>Verify</button></div>
        {showResendTimer ? (<p className='notes'>Resend <span className='blueLinks'>OTP</span> in <span className='blueLinks'>{otpTimer} sec(s)</span></p>) : (<p className='notes'><span className='blueLinks' onClick={() => this.vendorMobileLogin(otpPhoneNumber, '')}>Resend OTP</span></p>) }
      </div>
      </>)
      : ('')}
      {showActivatePopup ? <div className="modal show d-block" id="makePayment">
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirmation</h5>
            </div>
            <div className="modal-body">
              <div className='message'>
                    <h5 className='text-center mb-4'>You want to activate your  account?</h5>
                    <div className='buttonBox'>
                      <button className='buttons filled' onClick={()=> {this.activateAccount('yes')}}>Yes</button>
                      <button className='buttons blank' onClick={()=> {this.activateAccount('no')}}>No</button>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div> : ''}
    </section>
    {/* Customizable Area Start */}
    </View>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#F5FCFF"
  },
  logInButtonContainer: {
    overflow: "hidden",
    display: undefined,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignContent: "flex-start",
    alignItems: "center",
    shadowColor: "black",
    shadowOpacity: 0.3,
    shadowRadius: 8,
    height: 40,
    width: 205,
    marginTop: 16,
    elevation: 6,
    backgroundColor: "#ffffff"
  },
  facebookStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "black",
    shadowOpacity: 0.3,
    elevation: 6,
    shadowRadius: 8,
    borderWidth: 0,
    borderRadius: 0,
    backgroundColor: "#ffffff",
    padding: "11px"
  },
  facebookImageStyle: {
    marginRight: 10,
    width: 20,
    height: 20
  },
  facebookTextStyle: {
    color: "#2553b4",
    fontFamily: "Helvetica-Bold, sans-serif",
    paddingLeft: 7
  },
  googleStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "black",
    shadowOpacity: 0.3,
    elevation: 6,
    shadowRadius: 8,
    borderWidth: 0,
    borderRadius: 0,
    backgroundColor: "#ffffff",
    padding: "11px",
    marginTop: 32
  },
  googleButtonImageStyle: {
    marginRight: 15,
    width: 20,
    height: 20
  },
  googleButtonTextStyle: {
    paddingLeft: 6,
    paddingRight: 3,
    fontSize: 14,
    color: "#2553b4",
    fontFamily: "Helvetica-Bold, sans-serif",
    marginLeft: 8
  },
  orTextStyle: {
    color: "#00000",
    fontWeight: "bold",
    alignSelf: "center",
    margin: 20
  },
  signUpButtonStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "black",
    shadowOpacity: 0.3,
    elevation: 6,
    shadowRadius: 8,
    borderWidth: 0,
    borderRadius: 0,
    backgroundColor: "#ffffff",
    padding: "11px",
    height: "100%",
    width: "100%"
  },
  signUpButtonTextStyle: {
    color: "#2553b4",
    fontSize: 11,
    fontFamily: "Helvetica-Bold, sans-serif"
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  labelTitle: {
    marginTop: 24,
    marginBottom: 32,
    fontSize: 32,
    textAlign: "left",
    marginVertical: 8,
    color: "#6200EE"
  }
});
// Customizable Area End

export default SocialMediaAccountLoginScreen;

// Customizable Area End