// Customizable Area Start

import React from "react";
import { Helmet } from "react-helmet";
import AddNotificationController from "./AddNotificationController";

import {
  styled,
  Box,
  Grid,
  Chip,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";

import { iconEdit, iconDelete, iconAttachment } from "../assets";

import StyledHr from "../Shared/StyledHR";
import TitleHeading from "../Shared/TitleHeading";
import RoundedButton from "../Shared/RoundedButton";

const configJs = require("./config");
const Strings = configJs.Strings;

class AddNotification extends AddNotificationController {
  render() {
    const {
      heading,
      content,
      recipientList,
      recipients,
      attachmentList,
      validation,
    } = this.state;
    const { isEdit } = this.props;

    return (
      <>
        <Helmet>
          <title>
            {!isEdit ? Strings.header.titleAdd : Strings.header.titleEdit}
          </title>
        </Helmet>
        <TitleHeading
          text={!isEdit ? Strings.header.titleAdd : Strings.header.titleEdit}
        />
        <StyledHr />

        <Grid data-test-id="notification-add-screen" container spacing={1}>
          <Grid item xs={12}>
            <Headings>{Strings.labels.title}</Headings>
            <StyledInput
              data-test-id="field-title"
              size="small"
              fullWidth
              variant="outlined"
              value={heading}
              onChange={(e: any) =>
                // @ts-ignore
                this.setState({ heading: e.target.value })
              }
            />
            {validation?.heading && <ErrorText>{validation.heading}</ErrorText>}
          </Grid>
          <Grid item xs={12}>
            <Headings>{Strings.labels.recipients}</Headings>
            <StyledSelect
              data-test-id="field-recipients"
              fullWidth
              multiple
              disabled={isEdit}
              value={recipients}
              onChange={(e: any) => {
                this.setState({ recipients: e.target.value });
              }}
              renderValue={(selected: any) => (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    // @ts-ignore
                    gap: 6,
                  }}
                >
                  {selected.map((id: any) => {
                    let item: any =
                      recipientList.filter((item: any) => item.id === id)[0] ??
                      {};
                    return (
                      <Chip
                        key={"recipient-drop-down-" + id}
                        label={item.email}
                      />
                    );
                  })}
                </Box>
              )}
            >
              {recipientList.map((item: any) => {
                return (
                  <MenuItem value={item.id} key={item.id}>
                    {item.email}
                  </MenuItem>
                );
              })}
            </StyledSelect>
            {validation?.recipients && (
              <ErrorText>{validation.recipients}</ErrorText>
            )}
          </Grid>
          <Grid item xs={12}>
            <Headings>{Strings.labels.description}</Headings>
            <Container p={2}>
              <FloatingRightIcons>
                <img src={iconEdit} width={20} />
                <img src={iconDelete} width={20} />
              </FloatingRightIcons>
              <StyledMultilineTextBox
                data-test-id="field-description"
                minRows={8}
                fullWidth
                multiline
                value={content}
                InputProps={{
                  disableUnderline: true,
                }}
                onChange={(e: any) =>
                  this.setState({ content: e.target.value })
                }
              />

              <div>
                <label htmlFor="attachments">
                  <FlexContainer>
                    <img src={iconAttachment} width={18} />
                    <div>{Strings.labels.attachFiles}</div>
                    <input
                      data-test-id="field-attachFile"
                      type="file"
                      accept={".jpeg, .jpg, .png"}
                      id="attachments"
                      onChange={(e: any) =>
                        this.setState({
                          attachmentList: Array.from(e.target.files),
                        })
                      }
                    />
                  </FlexContainer>
                </label>
              </div>
            </Container>
            {validation?.content && <ErrorText>{validation.content}</ErrorText>}
          </Grid>

          <Grid item xs={12}>
            {attachmentList.length > 0 && (
              <>
                <Headings>{Strings.labels.attachments}</Headings>
                <div>
                  {attachmentList.map((item: any, index: any) => {
                    const isView = item?.image?.url;

                    if (!isView)
                      return (
                        <AttachmentText key={"attachments-" + index}>
                          <span>
                            {index + 1}.{"  "}
                            {item?.name}
                          </span>
                          <span>
                            <img
                              style={{
                                cursor: "pointer",
                              }}
                              data-test-id={"attachment-" + index}
                              onClick={() => this.onDeleteAttachment(index)}
                              src={iconDelete}
                              width={15}
                            />
                          </span>
                        </AttachmentText>
                      );
                    else
                      return (
                        <a
                          href={item?.image?.url}
                          key={"attachments-links-" + index}
                          target="_blank"
                        >
                          <AttachmentText>
                            <span>
                              {index + 1}.{"  "}
                              {item?.name}
                            </span>
                          </AttachmentText>
                        </a>
                      );
                  })}
                </div>
              </>
            )}
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item>
            <RoundedButton
              data-test-id="btn-submit"
              onClick={() => this.onSubmit()}
              text={Strings.actions.send}
              isActive={true}
            />
          </Grid>
          <Grid>
            <RoundedButton
              data-test-id="btn-cancel"
              onClick={() =>
                this.props?.navigation?.navigate("Vendor", {
                  page: "notifications-menu",
                })
              }
              text={Strings.actions.cancel}
              isActive={false}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

const ErrorText = styled(Typography)({
  fontSize: 14,
  color: "red",
  paddingTop: 6,
});

const AttachmentText: any = styled(Typography)({
  gap: 12,
  fontSize: 14,
  display: "flex",
  color: "var(--navy)",
  alignItems: "center",
});

const StyledSelect: any = styled(Select)({
  padding: 8,
  lineHeight: "none",
  boxShadow: "0px 0px 10px var(--boxShadow)",
  "&::before": {
    border: "none !important",
  },
});

const StyledInput: any = styled(TextField)({
  "& MuiOutlinedInput-input": {
    padding: "8px !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
    borderColor: "white",
    top: "0px !important",
    boxShadow: "1px 1px 10px var(--boxShadow)",
  },
});

const Headings: any = styled(Typography)({
  fontSize: 14,
  fontWeight: 500,
  paddingBottom: 8,
});

const FlexContainer: any = styled(Box)({
  gap: 4,
  fontSize: 15,
  color: "#aaa",
  display: "flex",
  alignItems: "center",
  "& input": {
    display: "none",
  },
});

const FloatingRightIcons: any = styled(Box)({
  position: "absolute",
  top: 0,
  gap: 8,
  right: 0,
  display: "flex",
  paddingTop: 16,
  paddingRight: 16,
});

const StyledMultilineTextBox: any = styled(TextField)({});

const Container: any = styled(Box)({
  boxShadow: "1px 1px 10px var(--boxShadow)",
  position: "relative",
});

export default AddNotification;

// Customizable Area End
