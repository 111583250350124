// Customizable Area Start
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import CommoncardController, { Props } from "./CommonCardController";


const CartActions=({isStockOut,inCart,wishlist,clickHandler}:{isStockOut:boolean,inCart:boolean,wishlist:boolean,clickHandler:()=>void})=>{
    if(isStockOut){
      return(<button className="filled-btn">Out of Stock</button>)
    }else if(!inCart){
      return(
      <button
        className="filled-btn"
        onClick={clickHandler}
      >
        {wishlist ? "Move to Cart":"Add to Cart"}
      </button>
      )
    }else if(inCart){
      return(
        <button className="filled-btn">Added</button>
      )
    }else{
      return null;
    }
}

export class CommonCard extends CommoncardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  isApproved(isApproved: string, slug: any) {
    if (isApproved != "pending") {
      this.addToCartFromList(slug);
    } else {
      this.props.openApproved(true);
    }
  }
  render() {
    const {
      product,
      description,
      price,
      deal,
      bestSeller,
      resultDeal,
      slug,
      image,
      accountIsApproved,
      wishlist,
    } = this.props;
    const {itemData} = this.state;
    const isStockOut=itemData?.attributes?.stock_qty===0;
    return (
      <>
      {(wishlist && !itemData.attributes?.is_wishlisted) ? '' : (<div className={wishlist ? 'result-columns position-relative' : ''}>
        {wishlist ? (<>
          <button className="close-product" onClick={()=>{this.DeleteFromWishlist(itemData?.id)}}>&times;</button>
          </>) : ""}
        <div className="commonCarousel">
          <Link to={"/product/" + slug}>
            <img className="commonImages" src={image} alt={product} />
          </Link>
          <div className="contentBody">
            {product ? (
              <Link to={"/product/" + slug}>
                <h6 className="commonName">{product}</h6>
              </Link>
            ) : (
              ""
            )}
            {price ? (
              <div className="flex">
                <span className="price">AED {price}</span>
                {itemData?.attributes?.account_id?.toString() !==
                localStorage.getItem("userId") ? (
                    <CartActions 
                        isStockOut={isStockOut}
                        inCart={itemData?.attributes?.is_in_cart}
                        wishlist={wishlist}
                        clickHandler={() => {
                          this.isApproved(accountIsApproved, slug);
                     }} />
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="share-icons">
            {!wishlist &&
            itemData?.attributes?.account_id?.toString() !==
              localStorage.getItem("userId") ? (
              <>
                {itemData?.attributes?.is_wishlisted ? (
                  <button className="icons" onClick={()=>{this.DeleteFromWishlist(itemData?.id)}}>
                    <img className="wishlist" src="/frontend/wish-black.png" />
                  </button>
                ) : (
                  <button className="icons" onClick={()=>{this.addToWishlist(itemData?.id)}}>
                    <img className="wishlist" src="/frontend/wish.png" />
                  </button>
                )}
              </>
            ) : (
              ""
            )}
            <button className="icons">
              <img className="share" src="/frontend/share.png" />
            </button>
            <Link to={"/product/" + slug}>
              <button className="icons">
                <img className="view" src="/frontend/view.png" />
              </button>
            </Link>
          </div>
          {deal ? <div className="deal banner">Deal of the day</div> : ""}
          {bestSeller ? (
            <div className="bestSeller banner">Best Seller</div>
          ) : (
            ""
          )}
          {resultDeal ? (
            <div className="resultDeal banner">Deal of the day</div>
          ) : (
            ""
          )}
        </div>
      </div>)}
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({ reduxState: state });
const mapDispatchToProps = (dispatch: any) => {
  return {
    reduxDispatch: { dispatch },
  };
};

// @ts-ignore
const ReduxWrapper: any = connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(CommonCard);
// @ts-ignore
export default ReduxWrapper;
// Customizable Area End
