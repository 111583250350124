// Customizable Area Start
import { ReactNode } from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";

interface Props {
  dateRangeKey:string;
  mainTitle: string;
  downloadLink: string;
  onChangeDate?: (key:string,range:any)=>void;
  onChangeFilter: (key:string,range:any)=>void;
  rangeValue?:any;
  children: any | ReactNode;
  visibleMonthSelector: boolean;
  currentFilter: string;
  loading:boolean;
}
interface State {
  toggleFilter: boolean;
  
}
interface SS {}

class ReportWrapperController extends BlockComponent<Props, State, SS> {
  state = { toggleFilter: false };
}

export default ReportWrapperController;

// Customizable Area End
