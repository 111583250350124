Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  createBrand: "/bx_block_catalogue/brands",
  showCatalogue: "/bx_block_catalogue/catalogues/",
  listSubCategory: "/bx_block_categories/sub_categories",
  catalogueListByBrand: "/bx_block_catalogue/catalogues/search_by_brand/",
  listBrands: "/bx_block_filter_items/filters/brands",
  listCategory: "/bx_block_filter_items/filters/categories",
  addCatalogue: "/bx_block_catalogue/catalogues",
  editCatalogue: "/bx_block_catalogue/catalogues/",
  listInventory: "/bx_block_catalogue/catalogues/list_own_catalogues",
  removeBulkVariants: "/bx_block_catalogue/catalogues/remove_catalogue_variant",
};

exports.Strings = {
  actions: {
    upload: "Upload",
    removeItem: "Remove Field",
    addItem: "+ Add Field",
    edit: "Edit",
    add: "Add",
    item: "Item",
    ok: "Ok",
    saveChanges: "Save Changes",
    cancel: "Cancel",
    submit: "Submit",
  },
  header: {
    titleAdd: "JHC | Add Item",
    titleEdit: "JHC | Edit Item",
    heading: "Add Brand",
  },
  labels: {
    alert: "Alert!",
    brandImage: "Brand Image",
    subCategory: "Sub Category",
    enterValues: "Enter values",
    pleaseEnterValue: "Please enter value",
    extraFields: "Add Extra Product Details",
    fieldName: "Field Name",
    fieldValue: "Field Value",
    brandName: "Brand Name",
    brandNamePlaceholder: "Enter Brand Name",
    category: "Category",
    brand: "Brand",
    productName: "Product Name",
    productCode: "Product Code",
    batchNo: "Batch No.",
    weight: "Weight",
    mfgDate: "Manufacturing Date",
    expDate: "Expiry Date",
    retailPrice: "Retail Price",
    salesPrice: "Sales Price",
    quantity: "Quantity",
    minStockQuantity: "Minimum Stock Quantity",
    benefits: "Benefits",
    description: "Description",
    productImages: "Product Images",
    mainView: "Main View",
    viewFile: "View file",
    uploadImage: "Upload Image",
    frontView: "Front View",
    backView: "Back View",
    leftView: "Left View",
    rightView: "Right View",
    refundable: "Refundable",
    stockable: "Stockable",
    taxes: "Taxes",
  },
  messages: {
    brandedAdded: "Brand is added successfully!",
    productUpdated: "Product is updated successfully!",
    pleaseSelectCategory: "Please select category",
    productAdded: "Product is added successfully!",
    createNewBrand: "+ Create New Brand",
    success: "Success!",
    error: "Error!",
    addBrand: "Add Brand",
  },
  validations: {
    uploadPhoto: "Please upload photo",
    subCategory: "Please enter sub category",
    description: "Click here to write description",
    brandNameExist: "Brand already exist.",
    brandName: "Enter brand name",
    weight: "Weight must be greater than zero",
    name: "Please enter product name",
    batchNo: "Please enter batch number",
    quantity: "Please enter quantity",
    minStockQuantity: "Please enter minimum stock quantity",
    minStockQuantityGreater0:
      "Minimum stock quantity should be greater or equal than zero",
    minStockQuantityGreaterQuantity:
      "Minimum stock quantity should be less than quantity",
    expDate: "Please enter expiry date",
    salesPrice: "Please enter sales price",
    mfgDate: "Please enter manufacturing date",
    enterRetailPrice: "Please enter retail price",
    greaterRetailPrice:
      "Retail price should be greater or equal than sales price",
    productCode: "Please enter product code",
    category: "Please select category",
    brand: "Please select brand",
    mainImage: "Please select main image",
    validRetailPrice: "Please enter valid numeric value",
    validSalesPrice: "Please enter valid numeric value",
    validQuantity: "Please enter valid numeric value",
    validMinStockQuantity: "Please enter valid numeric value",
    selectTaxes: "Please select tax",
    afterMfgDate: "The expiry date should be greater than the manufacture date",
  },
};

exports.MappedErrorsKeys = {
  name: "name",
  taxes: "taxes",
  weight: "weight",
  price: "salesPrice",
  benefits: "benefits",
  stock_qty: "quantity",
  use_before: "expDate",
  model_number: "batchNo",
  category_id: "categoryId",
  brands: "selectedBrandId",
  description: "description",
  product_code: "productCode",
  manufacture_date: "mfgDate",
  retails_price: "retailPrice",
  sub_categories: "subCategory",
  min_stock_qty: "min_stock_qty",
};
