import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import GetApiMessage from "../../utilities/src/GetApiMessage";
import ISuccessResponse from "../../vendor/src/ISuccessResponse";
import ApiResponseMiddleware from "../../vendor/src/ApiResponseMiddleware";

const configJSON = require("./config");
const ApiUrls = configJSON.ApiUrls;
const Strings = configJSON.Strings;

interface Props {
  navigation: {
    navigate: Function;
    getParam: Function;
  };
}
interface State {
  isLoading: boolean;
  pageMessageId: string;
  heading: string;
  content: string;
}
interface SS {}

class FooterPagesController extends BlockComponent<Props, State, SS> {
  state = {
    isLoading: true,
    pageMessageId: "",
    content: "",
    heading: "",
  };

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  receive(from: string, message: Message): void {
    if (!ApiResponseMiddleware(message)) return;

    let requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const { pageMessageId } = this.state;

    if (requestId === pageMessageId) {
      if (successResponse) {
        // @ts-ignore
        if (successResponse.message === "No data found") {
          this.setState({
            content: Strings.noData,
            heading: Strings.notFound,
          });
        } else {
          const { title, description } = successResponse.data;
          this.setState({ heading: title, content: description });
        }

        window.scrollTo(0, 0);
      }

      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    let isPropsChange =
      prevProps.navigation.getParam("page") !==
      this.props.navigation.getParam("page");

    if (isPropsChange) {
      this.setState({ isLoading: true });
      this.componentDidMount();
    }
  }

  componentDidMount(): any {
    super.componentDidMount();
    this.getPageData();
  }

  getPageEndpoint(): string {
    let page: string = this.props.navigation.getParam("page");

    const endpoints: any = {
      help: "help",
      faqs: "faqs",
      "about-us": "about_us",
      "terms-of-use": "terms_of_use",
      "privacy-policy": "privacy_policy",
      "returns-and-refunds": "returns_and_refunds",
      "payments-and-shippings": "payments_and_shippings",
      "copyrights-and-trademarks": "copyrights_and_trademarks",
    };

    return endpoints[page] || "no-content-found";
  }

  getPageData() {
    let requestMessage: any = GetApiMessage({
      endpoint: ApiUrls.footerContentApi + this.getPageEndpoint(),
    });
    this.setState({ pageMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

export default FooterPagesController;
