import React from "react";

import { Grid, TextField } from "@material-ui/core";

import {
  AddNewText,
  SubmitButton,
  StyledYesNoButton,
  StyledInstructionsBox,
} from "./SelectAddress.web";

import { configJs } from "./SelectAddressController";

const Strings = configJs.Strings;

interface Props {
  setState: Function;
  onCancelForm: Function;
  onSubmitInstructions: Function;
  instructionsError: string;
  instructions: string;
  isSaturday: boolean;
  isSunday: boolean;
  isEdit: boolean;
}

const InstructionForm = (props: Props) => {
  const {
    isEdit,
    setState,
    isSunday,
    isSaturday,
    onCancelForm,
    instructions,
    instructionsError,
    onSubmitInstructions,
  } = props;
  return (
    <div>
      <AddNewText>
        {isEdit
          ? Strings.labels.editAnInstruction
          : Strings.labels.addAnInstruction}
      </AddNewText>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={6}>
          <TextField
            fullWidth
            multiline
            minRows={4}
            inputProps={{ "data-test-id": "instructions-text" }}
            variant="outlined"
            value={instructions}
            helperText={instructionsError}
            error={instructionsError ? true : false}
            label={Strings.labels.writeYourInstructions}
            onChange={(e) => setState("instructions", e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledInstructionsBox>
            <Grid container spacing={2} alignItems="center">
              {[
                {
                  id: 1,
                  flag: isSaturday,
                  label: Strings.labels.saturday,
                  onChange: () => setState("isSaturday", !isSaturday),
                },
                {
                  id: 2,
                  flag: isSunday,
                  label: Strings.labels.sunday,
                  onChange: () => setState("isSunday", !isSunday),
                },
              ].map((item: any) => (
                <React.Fragment key={"radio-type-options-" + item.id}>
                  <Grid item xs={4}>
                    {item.label}
                  </Grid>
                  <Grid item xs={8}>
                    <StyledYesNoButton
                      data-test-id={"btn-instruction-yes-" + item.id}
                      color="primary"
                      onClick={() => item.onChange()}
                      variant={item.flag ? "contained" : "outlined"}
                    >
                      {Strings.actions.yes}
                    </StyledYesNoButton>
                    &nbsp;&nbsp;
                    <StyledYesNoButton
                      data-test-id={"btn-instruction-no-" + item.id}
                      color="primary"
                      onClick={() => item.onChange()}
                      variant={!item.flag ? "contained" : "outlined"}
                    >
                      {Strings.actions.no}
                    </StyledYesNoButton>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </StyledInstructionsBox>
        </Grid>
        <Grid item xs={12}>
          <br />
          <SubmitButton
            data-test-id="btn-submit-instructions"
            onClick={() => onSubmitInstructions()}
          >
            {isEdit
              ? Strings.actions.saveChanges
              : Strings.actions.saveInstructions}
          </SubmitButton>
          &nbsp;&nbsp;&nbsp;
          <SubmitButton onClick={() => onCancelForm()}>
            {Strings.actions.cancel}
          </SubmitButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default InstructionForm;
