Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  notificationList: "/bx_block_notifications/notifications",
  viewProfile: "/accounts/show_profile",
  logout: "/accounts/logout",
};

exports.Strings = {
  header: {
    title: "JHC | Vendor",
  },
  labels: {
    logo: "Logo",
    myOrders: "My Orders",
    logout: "Logout",
    search: "Search",
    notification: "Notification",
    dataNotFound: "Data not found",
    viewAll: "View All",
  },
  sidebar: {
    home: "Home",
    menu: "Menu",
    inventoryManagement: "Inventory Management",
    allOrders: "All Orders",
    discounts: "Discounts",
    payments: "Payments",
    settings: "Settings",
    profileSettings: "Profile Setting",
    vendorForm: "Vendor Form",
    notification: "Notification",
    contactUs: "Contact Us",
    adHocReporting: "Ad Hoc Reporting",
  },
};
