// Customizable Area Start
import React from "react";
import { styled } from "@material-ui/core";

const NoDataTdDefault = (props: any) => {
  return <NoDataTd {...props} />;
};

const NoDataTd = styled("td")({
  height: 80,
  textAlign: "center",
  verticalAlign: "middle",
});

export default NoDataTdDefault;

// Customizable Area End
