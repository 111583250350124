import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import GetApiMessage  from "../../utilities/src/GetApiMessage";
import phone from "phone";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  // Customizable Area Start
  type: string;
  navigation: {getParam: Function, navigate: Function};
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  addressListMessageId: string;
  checkoutInputValues: any;
  checkoutValidation: any;
  addressItems: any;
  checkoutItemsMessageId: string;
  checkoutItemsData: any;
  addAddressMessageId: string;
  addAddressMessage: any;
  showDivs:any;
  defaultInstructionAddressId: any;
  defaultInstructionMessage: any;
  addInstructionMessageId: any;
  instruction: any;
  editInstructionMessageId: any;
  defaultInstructionValidation: any;
  displayAddress: any;
  useThisAddressButton: boolean;
  isPaymentButtonDisabled: boolean;
  makePaymentMessageId: any;
  editAddressMessageId: any;
  editAddressMessage: any;
  instructionCount: number;
  showAddAddressPopup: boolean;
  countryMessageId:  any;
  StateMessageId: any;
  CityMessageId: any;
  countries: any;
  states: any;
  cities: any;
  isEditAddress: boolean;
  showInstructionPopup: boolean;
  disableButton: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CheckoutController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      addressListMessageId: '',
      addressItems: [],
      checkoutItemsMessageId: '',
      checkoutItemsData: '',
      checkoutInputValues: {
        checkoutCountry : "",
        checkoutFname : "",
        checkoutMobile : "",
        checkoutZipcode : "",
        checkoutBuilding : "",
        checkoutStreet : "",
        checkoutLandmark : "",
        checkoutTown : "",
        checkoutState : "",
        checkoutAddressType : "",
        isDefault: 'no'
      },
      checkoutValidation:{
        checkoutCountry : "",
        checkoutFname : "",
        checkoutMobile : "",
        checkoutZipcode : "",
        checkoutBuilding : "",
        checkoutStreet : "",
        checkoutLandmark : "",
        checkoutTown : "",
        checkoutState : "",
        checkoutAddressType : ""
      },
      addAddressMessageId: '',
      addAddressMessage: '',
      showDivs: {
        first: true,
        second: false,
        third: false
      },
      defaultInstructionAddressId: '',
      defaultInstructionMessage: '',
      addInstructionMessageId: '',
      instruction: {
        message: '',
        isSaturday: false,
        isSunday: false
      },
      editInstructionMessageId: '',
      defaultInstructionValidation: {
        message: '',
        isSaturday: false,
        isSunday: false
      },
      displayAddress: [],
      useThisAddressButton: true,
      isPaymentButtonDisabled: true,
      makePaymentMessageId: '',
      editAddressMessageId: '',
      editAddressMessage: '',
      instructionCount: 0,
      showAddAddressPopup: false,
      countryMessageId:  '',
      StateMessageId: '',
      CityMessageId: '',
      countries: {},
      states: {},
      cities: {},
      isEditAddress: false,
      showInstructionPopup: false,
      disableButton: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);
    
    const { addressListMessageId, checkoutItemsMessageId, addAddressMessageId, addInstructionMessageId, makePaymentMessageId, countryMessageId, StateMessageId, CityMessageId, editAddressMessageId} = this.state
    let requestID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    let successResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    this.setState({disableButton: false});
    if (addressListMessageId === requestID) {
      if (successResponse) {
        let disableUseAddressButton = true;
        if(successResponse?.data?.length > 0){
          for(let i=0;i<successResponse?.data?.length;i++){
            if(successResponse?.data[i]?.attributes?.is_default){
              disableUseAddressButton = false;
              this.setState({defaultInstructionAddressId : successResponse?.data[i]?.id, useThisAddressButton: disableUseAddressButton});
            }
          }
        }
        this.setState({ addressItems: successResponse?.data ?? []});
      }
    } else if (checkoutItemsMessageId === requestID) {
      if (successResponse && successResponse.data) {
        const type = this.props.navigation.getParam('type');
        const data = successResponse.data;
        let filteredData;
        switch(type) {
          case "buy-now":
            filteredData = data.filter((item: any) => item.attributes.buy_now === true);
            break;
          case "subscribe":
            filteredData = data.filter((item: any) => item.attributes.is_subscribed === true)
            break;
          default:
            filteredData = data.filter((item: any) => item.attributes.is_subscribed === false && item.attributes.buy_now === false)
        }
        this.setState({ checkoutItemsData: filteredData ?? [] })
      }
    } else if (addAddressMessageId === requestID || editAddressMessageId === requestID) {
      if (successResponse?.data) {
        this.setState({ addAddressMessage: 'Successfully added.' ?? '', showAddAddressPopup: false })
        this.addressList();
      } else {
        this.setState({ addAddressMessage: successResponse.messageId ?? '' })
      }
    } else if (addInstructionMessageId === requestID) {
      if (successResponse) {
        const instructionData = {
          message: '',
          isSaturday: false,
          isSunday: false
        }
        this.setState({ defaultInstructionMessage: 'Successfully added.' ?? '', instruction: instructionData, showInstructionPopup: false})
        this.addressList();
      } else {
        this.setState({ defaultInstructionMessage: successResponse.messageId ?? '' })
      }
    }  else if (countryMessageId === requestID && successResponse) {
        this.setState({countries: successResponse ? successResponse?.countries : {}})
    } else if (StateMessageId === requestID  && successResponse) {
        this.setState({states: successResponse ? successResponse?.states : {}})
    } else if (CityMessageId === requestID && successResponse) {
        this.setState({cities: successResponse ? successResponse?.cities : {}})
    }
    else if (makePaymentMessageId === requestID) {
      if(successResponse && successResponse?.data){
        localStorage.setItem('order', JSON.stringify(successResponse?.data));
        window.location.href= '/success';
      }else{
        const type = this.props.navigation.getParam('type');
        this.props?.navigation?.navigate('Failed', {type: type});
      }
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): any {
    this.addressList();
    this.checkoutItems();
    this.getCountries();
  }

  handleCheckoutChange(e:any, type= ''){
    if(type === 'country'){
      this.setState({states: {}, cities: {}});
      this.getStates(e.target.value);
    }
    if(type === 'state'){
      this.setState({cities: {}});
      this.getCities(this.state.checkoutInputValues.checkoutCountry, e.target.value);
    }
    this.setState({
      checkoutInputValues: {
        ...this.state.checkoutInputValues,
        [e.target.name]: e.target.value
      }
    });
  }
  handlePhoneInputChange=(value:string)=>{
    this.setState({
      checkoutInputValues: {
        ...this.state.checkoutInputValues,
        ["checkoutMobile"]:value
      }
    });
  }

  checkCheckoutValidation(){
    const {checkoutValidation, checkoutInputValues} = this.state;
    let errors = JSON.parse(JSON.stringify(checkoutValidation));

    //full name validation
    const alphabetCond = /^[a-zA-Z ]*$/;
    if (!checkoutInputValues.checkoutFname) {
      errors.checkoutFname = "Full name is required";
    } else if (!checkoutInputValues.checkoutFname.match(alphabetCond)) {
      errors.checkoutFname = "Please enter valid Full name";
    } else {
      errors.checkoutFname = "";
    }
    //number validation
    if(!checkoutInputValues.checkoutMobile?.trim()){
      errors.checkoutMobile = "Please enter phone";
    }else{
      errors.checkoutMobile = "";
    }
    //AddressType validation
    if (!checkoutInputValues.checkoutAddressType) {
      errors.checkoutAddressType = "Address is required";
    }else {
      errors.checkoutAddressType = "";
    }
    //country validation
    if (!checkoutInputValues.checkoutCountry) {
      errors.checkoutCountry = "Country is required";
    }else {
      errors.checkoutCountry = "";
    }
    //building validation
    if (!checkoutInputValues.checkoutBuilding) {
      errors.checkoutBuilding = "Flat/Apartment/building is required";
    }else {
      errors.checkoutBuilding = "";
    }
    //street validation
    if (!checkoutInputValues.checkoutStreet) {
      errors.checkoutStreet = "Street/Area/Sector is required";
    }else {
      errors.checkoutStreet = "";
    }
    //landmark validation
    if (!checkoutInputValues.checkoutLandmark) {
      errors.checkoutLandmark = "Landmark is required";
    }else {
      errors.checkoutLandmark = "";
    }
    //state validation
    if (!checkoutInputValues.checkoutState) {
      errors.checkoutState = "State is required";
    }else {
      errors.checkoutState = "";
    }
    //checkoutTown validation
    if (!checkoutInputValues.checkoutTown) {
      errors.checkoutTown = "Town is required";
    }else {
      errors.checkoutTown = "";
    }
    //zip validation
    const zipCodeValidation = /^\d{5,10}$/;
    if (!checkoutInputValues.checkoutZipcode) {
      errors.checkoutZipcode = "Zip is required";
    } else if (!checkoutInputValues.checkoutZipcode.match(zipCodeValidation)) {
      errors.checkoutZipcode = "Zip code length should be at least 5 and at most 10.";
    } else {
      errors.checkoutZipcode = "";
    }
    this.setState({
      checkoutValidation: errors
    });
    if(errors.checkoutFname === '' && errors.checkoutZipcode === '' && errors.checkoutTown === '' && errors.checkoutState === '' && errors.checkoutCountry === '' && errors.checkoutAddressType === '' && errors.checkoutMobile === ''){
      return true;
    }else{
      return false;
    }
  }
  saveAddress(e:any){
    e.preventDefault();
    const isSuccess = this.checkCheckoutValidation();
    if(isSuccess) {
      if(this.state.isEditAddress){
        this.editAddress(e, this.state.defaultInstructionAddressId);
      }else{
        this.addAddress();
      }
    }
  }

  async addressList() {
    const requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.addressListAPIURL});
    this.setState({ addressListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  showAddress(id:any, i: number) {
    this.getStates('AE');
    const getStateShortName = (typeof this.state.addressItems[i]?.attributes?.state === 'object') ? Object.keys(this.state.addressItems[i]?.attributes?.state)[0] : 'AZ';
    this.getCities('AE', getStateShortName);
    const data =  {
      checkoutCountry : this.state.addressItems[i]?.attributes?.country ? Object.keys(this.state.addressItems[i].attributes.country) : '',
      checkoutFname : this.state.addressItems[i]?.attributes?.name,
      checkoutMobile : this.state.addressItems[i]?.attributes?.phone_number,
      checkoutZipcode : this.state.addressItems[i]?.attributes?.zip_code,
      checkoutBuilding : this.state.addressItems[i]?.attributes?.flat_no,
      checkoutStreet : this.state.addressItems[i]?.attributes?.address,
      checkoutLandmark : this.state.addressItems[i]?.attributes?.address_line_2,
      checkoutTown : this.state.addressItems[i]?.attributes?.city,
      checkoutState : this.state.addressItems[i]?.attributes?.state ? Object.keys(this.state.addressItems[i].attributes.state) : '',
      checkoutAddressType : this.state.addressItems[i]?.attributes?.address_type,
      isDefault: this.state.addressItems[i]?.attributes?.is_default === true ? 'yes' : 'no'
    }
    this.setState({checkoutInputValues: data, showAddAddressPopup: true, isEditAddress: true, defaultInstructionAddressId: id, addAddressMessage: ''});
  }

  async checkoutItems() {
    const requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.checkoutSummaryAPIURL});
    this.setState({ checkoutItemsMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async addAddress() {
    this.setState({disableButton: true});
    const {checkoutInputValues} = this.state;
    const data = {
      "address": {
        name: checkoutInputValues.checkoutFname,
        phone_number: checkoutInputValues.checkoutMobile,
        address_type: checkoutInputValues.checkoutAddressType,
        flat_no: checkoutInputValues.checkoutBuilding,
        address: checkoutInputValues.checkoutStreet,
        address_line_2: checkoutInputValues.checkoutLandmark,
        landmark: checkoutInputValues.checkoutLandmark,
        city: checkoutInputValues.checkoutTown,
        zip_code: checkoutInputValues.checkoutZipcode,
        state: checkoutInputValues.checkoutState,
        country: checkoutInputValues.checkoutCountry,
        is_default: checkoutInputValues.isDefault === 'yes' ? true : false
      }
    }
    const headers = {
      "Content-Type" : "application/json"
    }
    const addAddressData = JSON.stringify(data);
    const requestMessage = GetApiMessage({ method: 'post', endpoint: configJSON.addAddressAPIURL, data: addAddressData, extraHeaders: headers});
    this.setState({ addAddressMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async editAddress(e:any, id:any) {
    this.setState({disableButton: true});
    e.preventDefault();
    const isSuccess = this.checkCheckoutValidation();
    if(isSuccess) {
      const {checkoutInputValues} = this.state;
      const data = {
        "address": {
          name: checkoutInputValues.checkoutFname,
          phone_number: checkoutInputValues.checkoutMobile,
          address_type: checkoutInputValues.checkoutAddressType,
          flat_no: checkoutInputValues.checkoutBuilding,
          address: checkoutInputValues.checkoutStreet,
          address_line_2: checkoutInputValues.checkoutLandmark,
          landmark: checkoutInputValues.checkoutLandmark,
          city: checkoutInputValues.checkoutTown,
          zip_code: checkoutInputValues.checkoutZipcode,
          state: checkoutInputValues.checkoutState,
          country: checkoutInputValues.checkoutCountry,
          is_default: checkoutInputValues.isDefault === 'yes' ? true : false
        }
      }
      const headers = {
        "Content-Type" : "application/json"
      }
      const addAddressData = JSON.stringify(data);
      const requestMessage = GetApiMessage({ method: 'put', endpoint: configJSON.addAddressAPIURL + '/' + id, data: addAddressData, extraHeaders: headers});
      this.setState({ editAddressMessageId: requestMessage.messageId });
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  showPaymentBox(){
    this.setState({ showDivs: {first: false, second: true, third: false}});
  }
  showAddressBox(){
    this.setState({ showDivs: {first: true, second: false, third: false}});
  }

  openAddInstructionPopUP(addressId:any, index:number) {
    const data = {
      message : this.state.addressItems[index]?.attributes?.instructions ? this.state.addressItems[index]?.attributes?.instructions : '',
      isSaturday: this.state.addressItems[index]?.attributes?.on_saturday ? this.state.addressItems[index]?.attributes?.on_saturday : false,
      isSunday: this.state.addressItems[index]?.attributes?.on_sunday ? this.state.addressItems[index]?.attributes?.on_sunday : false
    }
    this.setState({defaultInstructionAddressId: addressId, instruction: data, displayAddress:this.state.addressItems[index], defaultInstructionMessage: '', addAddressMessage: '', showInstructionPopup: true});
  }

  instructionDetails(type: string, value: boolean){
    const {instruction} = this.state;
    let data = JSON.parse(JSON.stringify(instruction));
    if(type === 'saturday') {
      data.isSaturday = value;
    } else {
      data.isSunday = value;
    }
    this.setState({instruction: data});
  }
  saveInstructions(){
    const {defaultInstructionValidation, instruction} = this.state;
    let errors = JSON.parse(JSON.stringify(defaultInstructionValidation));

    if(!instruction.message){
      errors.message = "Please add instruction";
    }else if(instruction.message.trim() === ''){
      errors.message = "Please add some instructions";
    }else {
      errors.message = "";
    }

    this.setState({
      defaultInstructionValidation: errors
    });
    if(errors.message === ''){
      this.addInstructionsAPI();
    }else{
      return false;
    }
  }

  async addInstructionsAPI() {
    this.setState({disableButton: true});
    const data = {
      add_instruction: {
        instructions: this.state.instruction.message,
        on_saturday: this.state.instruction.isSaturday,
        on_sunday: this.state.instruction.isSunday
      }
    }
    const headers = {
      "Content-Type" : "application/json"
    }
    const instructionData = JSON.stringify(data);
    const requestMessage = GetApiMessage({ method: 'put', endpoint: configJSON.addInstructionsAPIURL + this.state.defaultInstructionAddressId, data: instructionData, extraHeaders: headers});
    this.setState({ addInstructionMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleInstructionChange = (e: any) => {
    this.setState({
      instruction: {
        ...this.state.instruction,
        [e.target.name]: e.target.value
      }
    });
  };

  selectDefaultAddress(i: number){
    let data = [...this.state.addressItems];
    data[i].attributes.is_default = true;
    this.setState({addressItems: data, useThisAddressButton: false, defaultInstructionAddressId: data[i]?.id});
  }

  changePaymentMethod(){
    this.setState({isPaymentButtonDisabled : false});
  }

  async makePayment(){
    const type = (typeof this.props.navigation.getParam === 'function') ? this.props.navigation.getParam('type') : '';
    const paymentType = (type === 'subscribe') ? 'online_payment' : 'cash_on_delivery';
    const data = new FormData()
    data.set('payment_type', paymentType);
    data.set('delivery_address_id', this.state.defaultInstructionAddressId);
    data.set('order_id', this.state.checkoutItemsData[0].attributes.id);
    const requestMessage = GetApiMessage({ method: 'put', endpoint: configJSON.makePaymentAPIURL, data: data});
    this.setState({ makePaymentMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  openAddAddressPopup(){
    const data =  {
      checkoutCountry : '',
      checkoutFname : '',
      checkoutMobile : '',
      checkoutZipcode : '',
      checkoutBuilding : '',
      checkoutStreet : '',
      checkoutLandmark : '',
      checkoutTown : '',
      checkoutState : '',
      checkoutAddressType : '',
      isDefault: 'yes'
    }
    this.setState({checkoutInputValues: data, showAddAddressPopup: true, isEditAddress: false, addAddressMessage: ''});
  }
  handleCheckboxChange(e: any){
    if(e.target.checked){
      this.setState({
        checkoutInputValues: {
          ...this.state.checkoutInputValues,
          [e.target.name]: "yes"
        }
      });
    }else{
      this.setState({
        checkoutInputValues: {
          ...this.state.checkoutInputValues,
          [e.target.name]: "no"
        }
      });
    }
  }
  getCountries(){
    const requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.countryAPIURL});
    this.setState({ countryMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getStates(country:string){
    const requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.stateAPIURL + country});
    this.setState({ StateMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCities(country:string, state:string){
    let url = configJSON.cityAPIURL;
    url = url.replace('{country}', country);
    url = url.replace('{state}', state);
    const requestMessage = GetApiMessage({ method: 'get', endpoint: url});
    this.setState({ CityMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  hideInstructionPopup(){
    this.setState({showInstructionPopup : false})
  }
  hideAddressPopup(){
    this.setState({showAddAddressPopup: false})
  }
  // Customizable Area End
}
