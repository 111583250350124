Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.addressListAPIURL = "/bx_block_order_management/addresses";
exports.checkoutSummaryAPIURL = "/bx_block_order_management/orders/list_cart";
exports.addAddressAPIURL = "/bx_block_order_management/addresses";
exports.addInstructionsAPIURL = "/bx_block_order_management/addresses/";
exports.makePaymentAPIURL = "/bx_block_order_management/orders/confirm_order";
exports.countryAPIURL = "/bx_block_state_cities/state_cities/countries";
exports.stateAPIURL = "/bx_block_state_cities/state_cities/states?country=";
exports.cityAPIURL = "/bx_block_state_cities/state_cities/cities?state={state}&country={country}";
// Customizable Area End
