import React, { createRef } from "react";
import { Checkbox, MenuItem, TextField } from "@material-ui/core";

import PhoneInput from "react-phone-input-2";
import {
  AddNewText,
  StyledFrom,
  SubmitButton,
  GridContainer,
  MakeThisText,
} from "./SelectAddress.web";

import { configJs } from "./SelectAddressController";

const Strings = configJs.Strings;

interface Props {
  item: any;
  isEdit: any;
  validation: any;

  cityList: Array<any>;
  stateList: Array<any>;
  countryList: Array<any>;

  onSubmit: Function;
  getCityList: Function;
  onCancelForm: Function;
  getStateList: Function;
  onChangeItem: Function;
  onMakeDefaultAddress: Function;
}

const AddressForm = (props: Props) => {
  const {
    item,
    isEdit,
    onSubmit,
    stateList,
    validation,
    countryList,
    getCityList,
    getStateList,
    onChangeItem,
    onCancelForm,
    onMakeDefaultAddress,
  } = props;

  const phoneLabelRef=createRef<HTMLDivElement>();


  const handleOnFocous = (focouse: boolean) => {
    if (phoneLabelRef.current) {
      if (focouse) {
        phoneLabelRef.current.classList.add("active");
      } else if (!focouse && !item.phone_number) {
        phoneLabelRef.current.classList.remove("active");
      }
    }
  };


  return (
    <div data-test-id="address-form">
      <AddNewText>
        {isEdit ? Strings.labels.editAnAddress : Strings.labels.addANewAddress}
      </AddNewText>
      <StyledFrom>
        <TextField
          select
          fullWidth
          variant="outlined"
          value={item.country}
          helperText={validation.country}
          label={Strings.labels.countryRegion}
          error={validation.country ? true : false}
          inputProps={{
            "data-test-id": "field-country",
          }}
          onChange={(e: any) => {
            onChangeItem("country", e.target.value);
            getStateList(e.target.value);
          }}
        >
          {countryList.map((item: any) => {
            return (
              <MenuItem
                key={"country-list-item-" + item.value}
                value={item.value}
              >
                {item.label}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          fullWidth
          variant="outlined"
          value={item.name}
          helperText={validation.name}
          label={Strings.labels.fullName}
          error={validation.name ? true : false}
          onChange={(e: any) => onChangeItem("name", e.target.value)}
          inputProps={{
            "data-test-id": "field-name",
          }}
        />
        <div>
         <div className="phone_entryarea">
            <PhoneInput
              inputClass={validation.phone_number ? "error":""}
              containerClass={validation.phone_number ? "error":""}
              onBlur={() => handleOnFocous(false)}
              onFocus={() => handleOnFocous(true)}
              placeholder=""
              value={item.phone_number}
              onChange={(value) => onChangeItem("phone_number", value)}
              inputProps={{ required: true,"data-test-id": "field-phone", }}
            />
            <div
              ref={phoneLabelRef}
              className={`phone_labelline ${item.phone_number ? "active" : ""}`}
            >
              Mobile Number
            </div>
          </div>
          {validation?.phone_number &&
            (<div style={{marginTop:"5px"}}>
              <p style={{color:"red",fontSize:"12px"}}>{validation.phone_number}</p>
             </div>
             )
            }
        </div>
        <TextField
          fullWidth
          variant="outlined"
          value={item.zip_code}
          label={Strings.labels.zipCode}
          helperText={validation.zip_code}
          error={validation.zip_code ? true : false}
          onChange={(e: any) => onChangeItem("zip_code", e.target.value)}
          inputProps={{
            "data-test-id": "field-zipCode",
          }}
        />
        <TextField
          fullWidth
          variant="outlined"
          value={item.flat_no}
          helperText={validation.flat_no}
          label={Strings.labels.flatHouseNo}
          error={validation.flat_no ? true : false}
          onChange={(e: any) => onChangeItem("flat_no", e.target.value)}
          inputProps={{
            "data-test-id": "field-flatNo",
          }}
        />
        <TextField
          fullWidth
          variant="outlined"
          value={item.address_line_2}
          label={Strings.labels.areaStreet}
          helperText={validation.address_line_2}
          error={validation.address_line_2 ? true : false}
          onChange={(e: any) => onChangeItem("address_line_2", e.target.value)}
          inputProps={{
            "data-test-id": "field-addressLine2",
          }}
        />
        <TextField
          fullWidth
          variant="outlined"
          value={item.landmark}
          label={Strings.labels.landmark}
          helperText={validation.landmark}
          error={validation.landmark ? true : false}
          onChange={(e: any) => onChangeItem("landmark", e.target.value)}
          inputProps={{
            "data-test-id": "field-landmark",
          }}
        />

        <TextField
          select
          fullWidth
          value={item.state}
          variant="outlined"
          label={Strings.labels.state}
          helperText={validation.state}
          error={validation.state ? true : false}
          onChange={(e: any) => {
            onChangeItem("state", e.target.value);
            getCityList(e.target.value);
          }}
          inputProps={{
            "data-test-id": "field-state",
          }}
        >
          {stateList.map((item: any) => {
            return (
              <MenuItem
                key={"state-list-item-" + item.value}
                value={item.value}
              >
                {item.label}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          fullWidth
          variant="outlined"
          value={item.city}
          placeholder="City"
          helperText={validation.city}
          label={Strings.labels.townCity}
          error={validation.city ? true : false}
          onChange={(e: any) => onChangeItem("city", e.target.value)}
          inputProps={{
            "data-test-id": "field-city",
          }}
        />
      </StyledFrom>
      <GridContainer>
        <Checkbox
          data-test-id="btn-makeDefault"
          checked={item.is_default}
          onClick={() => onMakeDefaultAddress()}
        />
        <MakeThisText>{Strings.labels.makeThisMyDefaultAddress}</MakeThisText>
      </GridContainer>
      <br />
      <SubmitButton data-test-id="btn-saveAddress" onClick={() => onSubmit()}>
        {isEdit ? Strings.actions.saveChanges : Strings.actions.addAddress}
      </SubmitButton>
      &nbsp;&nbsp;
      <SubmitButton onClick={() => onCancelForm()}>
        {Strings.actions.cancel}
      </SubmitButton>
    </div>
  );
};

export default AddressForm;
