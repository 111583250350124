// Customizable Area Start
import React from "react";

import { Grid } from "@material-ui/core";
import ModalBox from "../Shared/ModalBox";
import RoundedButton from "../Shared/RoundedButton";

const configJs = require("./config");
const Strings = configJs.Strings;

interface Props {
  onClose: Function;
  onOk?: Function;
}
interface State {}

class ConfirmDeleteModal extends React.Component<Props, State> {
  render() {
    const { onClose, onOk } = this.props;
    return (
      <ModalBox
        data-test-id="confirm-delete-modal"
        title={Strings.message.pleaseConfirm}
        onClose={onClose}
      >
        <div>{Strings.message.areYouSure}</div>
        <br />
        <br />

        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <RoundedButton
              text={Strings.actions.cancel}
              onClick={onClose}
              isActive={false}
            />
          </Grid>
          <Grid item>
            <RoundedButton
              text={Strings.actions.yes}
              onClick={onOk}
              isActive={true}
            />
          </Grid>
        </Grid>
      </ModalBox>
    );
  }
}

export default ConfirmDeleteModal;
// Customizable Area End
