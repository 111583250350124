// Customizable Area Start
import React from "react";
import { Link } from "react-router-dom";
import { styled } from "@material-ui/core";
import AddMembersWebController, { configJs } from "./AddMembersWebController";

const Strings = configJs.Strings;

class AddMembers extends AddMembersWebController {
  render() {
    const {
      item,
      validation,
      showPassword,
      showMemberSignUp,
      memberErrorMessage,
      showConfirmPassword,
    } = this.state;

    const labels = Strings.labels;

    return (
      <section className="user">
        {showMemberSignUp && (
          <div className="popup" style={{ maxHeight: "unset" }}>
            <img className="logo" src="/images/logo.png" alt="logo" />
            {memberErrorMessage && (
              <p className="formErrors text-center">{memberErrorMessage}</p>
            )}
            <h4 className="steps">{labels.step1}</h4>
            <div className="inputGroups">
              <label className="icons">
                <img src="/images/email.png" alt="email" />
              </label>
              <input
                readOnly
                disabled
                value={item.email}
                className="inputs"
                data-test-id="email"
              />
            </div>

            <div className="inputGroups">
              <label className="icons">
                <img src="/images/user.png" alt="user" />
              </label>
              <input
                className="inputs"
                placeholder={labels.enterFirstName}
                value={item.first_name}
                onChange={(e: any) =>
                  this.onChangeValues("first_name", e.target.value)
                }
                data-test-id="first-name"
              />
            </div>
            {validation.first_name && (
              <p className="formErrors">{validation.first_name}</p>
            )}
            <div className="inputGroups">
              <label className="icons">
                <img src="/images/user.png" alt="user" />
              </label>
              <input
                className="inputs"
                placeholder={labels.enterLastName}
                value={item.last_name}
                onChange={(e: any) =>
                  this.onChangeValues("last_name", e.target.value)
                }
                data-test-id="last-name"
              />
            </div>
            {validation.last_name && (
              <p className="formErrors">{validation.last_name}</p>
            )}
            <div className="inputGroups">
              <label className="icons">
                <img src="/images/lock.png" alt="password" />
              </label>
              <input
                className="inputs"
                placeholder={labels.enterPassword}
                type={showPassword ? "text" : "password"}
                onChange={(e: any) =>
                  this.onChangeValues("password", e.target.value)
                }
                data-test-id="password"
              />
              <img
                className="passwordIcon"
                src={
                  showPassword
                    ? "/images/showPassword.png"
                    : "/images/hidePassword.png"
                }
                data-test-id="togglePassword"
                alt="eye"
                onClick={() => this.togglePassword()}
              />
            </div>
            {validation.password && (
              <p className="formErrors">{validation.password}</p>
            )}
            <div className="inputGroups">
              <label className="icons">
                <img src="/images/lock.png" alt="password" />
              </label>
              <input
                className="inputs"
                placeholder={labels.enterConfirmPassword}
                type={showConfirmPassword ? "text" : "password"}
                onChange={(e) =>
                  this.onChangeValues("confirmPassword", e.target.value)
                }
                data-test-id="confirmPassword"
              />
              <img
                className="passwordIcon"
                src={
                  showConfirmPassword
                    ? "/images/showPassword.png"
                    : "/images/hidePassword.png"
                }
                alt="eye"
                data-test-id="toggleConfirmPassword"
                onClick={() => this.toggleConfirmPassword()}
              />
            </div>
            {validation.confirmPassword && (
              <p className="formErrors">{validation.confirmPassword}</p>
            )}
            <div className="buttons">
              <button
                className="btnSubmit"
                data-test-id="btn-submit"
                onClick={() => this.onSubmit()}
              >
                {labels.btnSignUp}
              </button>
            </div>
          </div>
        )}

        {!showMemberSignUp && (
          <StyleRemoverWrapper>
            <section className="thanks">
              <div className="popup">
                <h4 className="logo">
                  Company
                  <br /> Logo
                </h4>
                <br />
                <br />
                <div>
                  <div className="thanks-icon">
                    <img src="/frontend/thanks.png" alt="thanks" />
                  </div>
                  <h4 className="heading">{labels.thankYou}</h4>
                  <p className="description">{labels.accountUnderReview}</p>
                </div>
                <br />
                <br />

                <Link data-test-id="btn-continue" className="white-btn" to="/">
                  {labels.btnContinue}
                </Link>
              </div>
            </section>
          </StyleRemoverWrapper>
        )}
      </section>
    );
  }
}

const StyleRemoverWrapper = styled("div")({
  "& .popup": {
    padding: 64,
    maxHeight: "unset",
    overflow: "unset",
    minHeight: "unset",
    display: "block",
    "& .white-btn": {
      width: 200,
    },
  },
});

export default AddMembers;
// Customizable Area End
