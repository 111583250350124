// App.js - WEB
import React, { Component } from "react";
import firebase from "firebase";
import { BrowserRouter, Switch } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";

import { MuiThemeProvider, createTheme } from "@material-ui/core";

// redux setup start
import { Provider } from "react-redux";
import ReduxStore from "./redux/index";
// redux setup end

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import InfoPage from "../../blocks/info-page/src/InfoPageBlock";
import AlertBlock from "../../blocks/alert/src/AlertBlock.web";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import CfWhatsapp15 from "../../blocks/CfWhatsapp15/src/CfWhatsapp15";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import Details from "../../blocks/ordermanagement/src/Details.web";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaLoginScreen.web";
import SplitPayments from "../../blocks/SplitPayments/src/SplitPayments";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrdersWrapper.web";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import PaymentAdmin from "../../blocks/PaymentAdmin/src/PaymentAdmin";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import StripeIntegration from "../../blocks/stripepayments/src/StripeIntegration";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Notifications from "../../blocks/notifications/src/Notifications";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import RefundManagement from "../../blocks/RefundManagement/src/RefundManagement";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import AutomaticRenewals from "../../blocks/automaticrenewals/src/AutomaticRenewals";
import ProductDescription from "../../blocks/ProductDescription/src/ProductDescriptionWithWrapper.web";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Cf3rdPartyDeliveryIntegration from "../../blocks/Cf3rdPartyDeliveryIntegration/src/Cf3rdPartyDeliveryIntegration";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Sorting from "../../blocks/sorting/src/Sorting";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import InventoryManagement3 from "../../blocks/InventoryManagement3/src/InventoryManagement3";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import CustomisedOrderStatus from "../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus";
import SalesReporting from "../../blocks/SalesReporting/src/SalesReporting";
import Vendor from "../../blocks/vendor/src/Vendor";
import Checkout from "../../blocks/Checkout/src/Checkout";
import Category from "../../blocks/landingpage/src/Category.web";
import AddMembers from "../../blocks/social-media-account-login/src/AddMembers/AddMembers.web";
import SignupThanks from "../../blocks/social-media-account-login/src/SignupThanks.web";
import FooterPages from "../../blocks/FooterPages/src/FooterPages";
import ReturnOrder from "../../blocks/ordermanagement/src/ReturnOrder";
import ReturnOrderSummary from "../../blocks/ordermanagement/src/ReturnOrderSummary";
import DefaultPageLayout from "../../blocks/FrontLayout/src/LandingPageLayout";
import UserLoginModal from '../../blocks/FrontLayout/src/UserLoginModal.web'
import ContactUs from "../../blocks/FrontLayout/src/ContactUs.web";
import CareersPage from "../../blocks/FrontLayout/src/Careers.web";
import OffersPage from "../../blocks/FrontLayout/src/OffersPage.web";
import Brands from "../../blocks/FrontLayout/src/Brands.web";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Wishlist from "../../blocks/wishlist/src/Wishlist.web";
import Success from "../../blocks/Checkout/src/Success.web";
import Failed from "../../blocks/Checkout/src/Failed.web";
import Subscription from "../../blocks/ordermanagement/src/Subscription.web";

const reusableLayouts = [
  {
    id: "default",
    component: DefaultPageLayout,
  },
  {
    id: "no-layout",
    component: ({ children }) => <div>{children}</div>,
  },
];

const protectedProps = (layoutId = "default") => ({
  protected: true,
  layoutId,
});

const openRouteProps = (layoutId = "no-layout") => ({
  protected: false,
  layoutId,
});

const routeMap = {
  Footer: {
    component: FooterPages,
    path: "/footer/:page",
  },
  Vendor: {
    component: Vendor,
    path: "/Vendor/:page",
    exact: true,
    ...openRouteProps(),
  },
  VendorSub: {
    component: Vendor,
    path: "/Vendor/:page/:id",
    exact: true,
    ...openRouteProps(),
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics",
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: "/CustomisableUserProfiles",
  },
  CfWhatsapp15: {
    component: CfWhatsapp15,
    path: "/CfWhatsapp15",
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: "/orders",
    ...protectedProps(),
  },
  Subscription: {
    component: Subscription,
    path: "/my-subscriptions",
    ...protectedProps(),
  },
  Details: {
    component: Details,
    path: "/order-details/:id",
    ...protectedProps(),
  },
  CarouselDisplay: {
    component: CarouselDisplay,
    path: "/CarouselDisplay",
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen",
  },
  SplitPayments: {
    component: SplitPayments,
    path: "/SplitPayments",
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock",
  },
  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: "/cart",
    ...protectedProps(),
  },
  AddShoppingCartOrderItem: {
    component: AddShoppingCartOrderItem,
    path: "/AddShoppingCartOrderItem",
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth",
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: "/invoice/:id",
    ...protectedProps("no-layout"),
  },
  PaymentAdmin: {
    component: PaymentAdmin,
    path: "/PaymentAdmin",
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications",
  },
  StripeIntegration: {
    component: StripeIntegration,
    path: "/StripeIntegration",
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/forgot-password",
    exact: true,
    ...openRouteProps(),
  },
  NewPassword: {
    component: NewPassword,
    path: "/reset-password",
    exact: true,
    ...openRouteProps(),
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications",
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: "/Customisableusersubscriptions",
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: "/SubscriptionDetails",
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems",
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions",
  },
  RefundManagement: {
    component: RefundManagement,
    path: "/RefundManagement",
  },
  Settings5: {
    component: Settings5,
    path: "/Settings5",
  },
  AutomaticRenewals: {
    component: AutomaticRenewals,
    path: "/AutomaticRenewals",
  },
  ProductDescription: {
    component: ProductDescription,
    path: "/product/:id",
    ...protectedProps(),
    protected:false
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories",
  },
  Cf3rdPartyDeliveryIntegration: {
    component: Cf3rdPartyDeliveryIntegration,
    path: "/Cf3rdPartyDeliveryIntegration",
  },
  ContentManagement: {
    component: ContentManagement,
    path: "/ContentManagement",
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector",
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: "/ReviewAndApproval",
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: "/UploadMedia2",
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput",
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm",
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: "/RolesPermissions",
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen",
    layoutId: "no-layout",
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting",
  },
  AdminConsole: {
    component: AdminConsole,
    path: "/AdminConsole",
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration",
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus",
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus",
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue",
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration",
  },
  EmailNotifications2: {
    component: EmailNotifications2,
    path: "/EmailNotifications2",
  },
  ElasticSearch: {
    component: ElasticSearch,
    path: "/d/:type?/:category?",
    ...protectedProps(),
    protected:false
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock",
  },
  InventoryManagement3: {
    component: InventoryManagement3,
    path: "/InventoryManagement3",
  },
  Home: {
    component: LandingPage,
    path: "/",
    exact: true,
    ...protectedProps(),
    protected:false
  },
  home: {
    component: LandingPage,
    path: "/home",
    exact: true,
    ...protectedProps(),
    protected:false
  },
  CustomisedOrderStatus: {
    component: CustomisedOrderStatus,
    path: "/CustomisedOrderStatus",
  },
  SalesReporting: {
    component: SalesReporting,
    path: "/SalesReporting",
  },
  login: {
    component: SocialMediaAccountLoginScreen,
    path: "/login",
    exact: true,
    ...openRouteProps(),
  },
  InfoPage: {
    component: InfoPage,
    path: "/InfoPage",
  },
  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true,
  },
  Checkout: {
    component: Checkout,
    path: "/checkout/:type",
    ...protectedProps(),
  },
  OffersPage: {
    component: OffersPage,
    path: "/offers",
    ...protectedProps(),
    protected:false
  },
  Brands: {
    component: Brands,
    path: "/brands",
    ...protectedProps(),
    protected:false
  },
  Category: {
    component: Category,
    path: "/landing-page",
    ...protectedProps(),
  },
  Wishlist: {
    component: Wishlist,
    path: "/wishlist",
    ...protectedProps(),
  },
  AddMembers: {
    component: AddMembers,
    path: "/members",
    layoutId: "no-layout",
  },
  SignupThanks: {
    component: SignupThanks,
    path: "/thanks",
    exact: true,
    ...openRouteProps(),
  },
  Success: {
    component: Success,
    path: "/success",
    ...protectedProps()
  },
  Failed: {
    component: Failed,
    path: "/failed/:type",
    ...protectedProps()
  },
  ReturnOrder: {
    component: ReturnOrder,
    path: "/return-order/:id",
    ...protectedProps(),
  },
  ReturnOrderSummary: {
    component: ReturnOrderSummary,
    path: "/return-order-details/:id",
    ...protectedProps(),
  },
  ContactUs: {
    component: ContactUs,
    path: "/contact-us",
    ...openRouteProps("default"),
  },
  CareersPage: {
    component: CareersPage,
    path: "/career",
    ...openRouteProps("default"),
  },
};

export const firebaseAPI = firebase.initializeApp({
  databaseURL: "https://jeddah-house-of-commerce-default-rtdb.firebaseio.com",
  secret: "yVU1R3q1lRwQjJXm5IPXwjCj3YwMUmiHxVeO4gNX",
  projectId: "jeddah-house-of-commerce",
  senderId: "554285959365",

  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  // databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  // projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3",
});

let CurrentLayout = DefaultPageLayout;

class App extends Component {
  state = {
    layoutId: "default",
    isProtected: false,
    isChange: false,
  };

  constructor() {
    super();

    this.onChangeRoute = this.onChangeRoute.bind(this);
  }

  onChangeRoute(
    layoutId = this.state.layoutId,
    isProtected = this.state.isProtected
  ) {
    this.setState({ isChange: !this.state.isChange });

    if (this.state.layoutId !== layoutId) {
      let layoutToBeStore = DefaultPageLayout;
      const layouts = reusableLayouts.filter(({ id }) => id === layoutId);
      if (layouts.length > 0) layoutToBeStore = layouts[0].component;

      CurrentLayout = layoutToBeStore;
      this.setState({ layoutId });
    }

    if (this.state.isProtected != isProtected) {
      this.setState({ isProtected });
    }
  }

  componentDidMount() {
    this.removeIFrames();

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent("APP_Loaded");
  }

  removeIFrames() {
    if (process.env.REACT_APP_MODE !== "development") return;

    setTimeout(() => {
      let element = document.querySelectorAll("iframe");
      for (let i = 0; i < element.length; i++) {
        // @ts-ignore
        document.body.removeChild(document.querySelector("iframe"));
      }
    }, 3000);
  }

  render() {
    const { isProtected } = this.state;

    return (
      <MuiThemeProvider theme={GlobalThemeWrapper}>
        <Provider store={ReduxStore}>
          <BrowserRouter>
            <Switch>
              <CurrentLayout
                isRouteChange={this.state.isChange}
                isProtected={isProtected}
              >
                {WebRoutesGenerator({
                  routeMap,
                  onChangeRoute: this.onChangeRoute.bind(this),
                })}
                <ModalContainer />
              </CurrentLayout>
            </Switch>
          </BrowserRouter>
          <UserLoginModal/>
        </Provider>
         <ToastContainer theme="dark" />
      </MuiThemeProvider>
    );
  }
}

const GlobalThemeWrapper = createTheme({
  typography: {
    fontFamily: [
      "Amazon Ember",
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
  },
});

export default App;
