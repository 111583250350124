import Actions from "../actions";

const { cart: cartActions } = Actions;

export interface iReturnType {
  count: number;
  refetch: boolean;
}

const initialState = { count: 0, refetch: false };

interface iAction {
  type: string;
  payload: any;
}

const cartReducer = (state = initialState, action: iAction): iReturnType => {
  switch (action.type) {
    case cartActions.SAVE_COUNT:
      return {
        ...state,
        count: action.payload || 0,
      };
    case cartActions.RESET:
      return {
        ...state,
        count: 0,
      };
    case cartActions.REFETCH:
      return {
        ...state,
        refetch: !state.refetch,
      };
    default:
      return state;
  }
};

export default cartReducer;
