Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  trackOrders:
    "/bx_block_inventorymanagement25/inventory_management/track_order",
};

exports.Strings = {
  table: {
    pickupAddress: "Pickup address",
    deliveryAddress: "delivery address",
    liveStatus: "live status",
    picture: "picture",
    address: "Address",
    pickupTime: "Pickup Time",
    phoneNo: "Phone No",
    deliveryTime: "Delivery Time",
    viewDetails: "View Details",
  },
  message: {
    noData: "Data not found",
  },
};
