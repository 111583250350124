// Customizable Area Start

import React from "react";
import { Typography, styled } from "@material-ui/core";
import { BlockComponent } from "../../../../framework/src/BlockComponent";

interface Props {
  text: string;
}
interface State {}
interface SS {}

class TitleHeading extends BlockComponent<Props, State, SS> {
  render() {
    return <Heading variant="h4">{this.props.text ?? ""}</Heading>;
  }
}

const Heading = styled(Typography)({
  color: "black",
  margin: 0,
  fontSize: 24,
  fontWeight: "bold",
});

export default TitleHeading;

// Customizable Area End
