// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";

import GetApiMessage from "../GetApiMessage";
import ISuccessResponse from "../ISuccessResponse";
import ApiResponseMiddleware from "../ApiResponseMiddleware";

const configJs = require("./config");
const ApiUrls = configJs.ApiUrls;

interface Props {
  listType: any;
  onChangeList: Function | any;
}
interface State {
  listType: any;

  // logical
  currentPage: number;
  lastPage: number;

  // lists
  codPaymentList: Array<any>;
  onlinePaymentList: Array<any>;

  // message ids
  codListApiMessageId: any;
  onlineApiMessageId: string;
}
interface SS {}

class PaymentsController extends BlockComponent<Props, State, SS> {
  state = {
    listType: "Online Payment",

    // logical
    lastPage: 1,
    currentPage: 1,

    // lists
    codPaymentList: [],
    onlinePaymentList: [],

    // message ids
    codListApiMessageId: [],
    onlineApiMessageId: "",
  };

  constructor(props: Props) {
    super(props);

    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
    ]);

    this.receive = this.receive.bind(this);
  }

  receive(from: string, message: Message): void {
    if (!ApiResponseMiddleware(message)) return;

    const { codListApiMessageId, onlineApiMessageId } = this.state;

    let requestID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (requestID === codListApiMessageId) {
      if (successResponse) {
        let newArray = [];
        let { data, meta } = successResponse;
        if (data) {
          // @ts-ignore
          newArray = data?.map((item: any) => {
            return { id: item.id, ...item.attributes };
          });
        }
        this.setState({
          // @ts-ignore
          codPaymentList: newArray ?? [],
          currentPage: 1,
          lastPage: meta?.last_page || 1,
        });
      }
    }

    if (requestID === onlineApiMessageId) {
      if (successResponse) {
        let newArray = [];
        let { data, meta } = successResponse;
        if (data) {
          // @ts-ignore
          newArray = data?.map((item: any) => {
            return { id: item.id, ...item.attributes };
          });
        }
        this.setState({
          // @ts-ignore
          onlinePaymentList: newArray ?? [],
          currentPage: 1,
          lastPage: meta?.last_page || 1,
        });
      }
    }
  }

  componentDidMount(): any {
    this.getPaymentListCod();
    this.getPaymentListOnline();
  }

  getPaymentListOnline() {
    let requestMessage = GetApiMessage({ endpoint: ApiUrls.listOnlinePayment });
    this.setState({ onlineApiMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPaymentListCod() {
    let requestMessage = GetApiMessage({ endpoint: ApiUrls.listCOD });
    this.setState({ codListApiMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onChangeList(type: String) {
    this.setState({ listType: type });
  }
}

export default PaymentsController;

// Customizable Area End
