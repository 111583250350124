Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  getOrderCountApi:
    "/bx_block_inventorymanagement25/inventory_management/order_count_report",
  salesByDistributionApi:
    "/bx_block_inventorymanagement25/inventory_management/sales_by_distibution",
  inventoryReportApi:
    "/bx_block_inventorymanagement25/inventory_management/inventory_report",
  cyLyApi: "/bx_block_inventorymanagement25/inventory_management/cy_vs_ly",
};

exports.Strings = {
  header: {
    title: "JHC | AdHoc Reporting",
  },
  labels: {
    cyLy: "CY v/s LY",
    orderCount: "Order Count",
    inventoryReport: "Inventory Report",
    noData: "No Data Found",
    month: "Month",
    transactionCount: "Transaction Count",
    itemCount: "Item Count",
    average: "Average",
    netSales: "Net Sales",
    monthWise: "Month wise",
    salesByDistribution: "Sales By Distributions",
  },
  chart: {
    cy: "CY",
    ly: "LY",
    type: "Type",
    month: "Month",
    count: "Count",
    amount: "Amount",
    noData: "No data",
    category: "Category",
    cod: "Cash On Delivery",
    cardPayment: "Card Payment",
    saddadPayment: "SADAD Payment",
  },
};
