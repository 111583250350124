import Actions from "../actions";

const { dialogues: dialoguesActions } = Actions;

export interface iReturnType {
  isApproved: boolean;
}

const initialState = { isApproved: false };

interface iAction {
  type: string;
  payload: any;
}

const dialoguesReducer = (
  state = initialState,
  action: iAction
): iReturnType => {
  switch (action.type) {
    case dialoguesActions.accountApproved:
      return {
        ...state,
        isApproved: action.payload || false,
      };
    default:
      return state;
  }
};

export default dialoguesReducer;
