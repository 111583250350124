// Customizable Area Start
import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  styled,
  Box,
  Grid,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@material-ui/core";

import NoDataTd from "../Shared/NoDataTd";
import Pagination from "../Shared/Pagination";
import InventoryController from "./InventoryController";
import BlockHelpers from "../../../utilities/src/BlockHelpers";

import {
  iconGo,
  iconAdd,
  iconEdit,
  iconCloud,
  iconSearch,
  iconDelete,
} from "../assets";

const configJs = require("./config");
const Strings = configJs.Strings;

class Inventory extends InventoryController {
  render() {
    return (
      <>
        <Helmet>
          <title>{Strings.header.title}</title>
        </Helmet>
        <div data-test-id="inventory-screen" className="inventory">
          <div className="head">
            <h4 className="title">{Strings.labels.heading}</h4>
            <div className="right-container">
              <button
                data-test-id="btn-download"
                className="cloud"
                onClick={() => this.onDownloadInventory()}
              >
                <img src={iconCloud} alt="icon" />
              </button>
              {this.state.selectedProductIds.length > 0 && (
                <button
                  data-test-id="btn-remove"
                  onClick={() =>
                    this.setState({
                      removeItemFlag: "confirm",
                    })
                  }
                  className="addItem"
                  style={{ marginRight: 10 }}
                >
                  <img src={iconDelete} alt="icon" />{" "}
                  {Strings.actions.removeItem}
                </button>
              )}
              <Link
                data-test-id="btn-addInventory"
                onClick={() => {
                  if (!this.state.isApproved)
                    BlockHelpers.showAlert(
                      Strings.labels.alert,
                      Strings.message.verifyBusiness
                    );
                }}
                to={this.state.isApproved ? "/vendor/add-inventory-item" : "#"}
              >
                <button className="addItem">
                  <img src={iconAdd} alt="icon" /> {Strings.actions.addItem}
                </button>
              </Link>
            </div>
          </div>
          <div className="filter">
            <div className="selectbox">
              <StyledSelect
                data-test-id="field-category"
                multiple
                displayEmpty
                MenuProps={{
                  PaperProps: {
                    className: "Mui-Vendor-Inventory-Select",
                  },
                }}
                input={<OutlinedInput />}
                value={this.state.selectedCategoryId}
                disabled={this.state.querySearch ? true : false}
                renderValue={(values) => this.getSelectRenderValue(values)}
                onChange={(e) => this.onSelectCategoryChange(e.target.value)}
              >
                <StyledMenuItem value={""} disabled>
                  {Strings.labels.category}
                </StyledMenuItem>
                {this.state.categoryList.map((item: any) => {
                  const { name, id } = item?.attributes;
                  return (
                    <StyledMenuItem key={"inventory-category-" + id} value={id}>
                      <ListItemText primary={name} />
                      <Checkbox
                        checked={this.state.selectedCategoryId.includes(id)}
                      />
                    </StyledMenuItem>
                  );
                })}
              </StyledSelect>
            </div>
            <div className="right-container">
              <FilterCheckBoxText>
                <Checkbox
                  onClick={() => {
                    this.setState({
                      isRefundable: !this.state.isRefundable,
                    });
                    setTimeout(() => this.getDataBySearchQueryApi(), 500);
                  }}
                  id="item-refundable"
                  checked={this.state.isRefundable}
                />
                <label htmlFor="item-refundable">
                  {Strings.labels.refundable}
                </label>
              </FilterCheckBoxText>
              <FilterCheckBoxText>
                <Checkbox
                  onClick={() => {
                    this.setState({
                      isStocakble: !this.state.isStocakble,
                    });
                    setTimeout(() => this.getDataBySearchQueryApi(), 500);
                  }}
                  id="item-stockable"
                  checked={this.state.isStocakble}
                />
                <label htmlFor="item-stockable">
                  {Strings.labels.stockable}
                </label>
              </FilterCheckBoxText>
              <div style={{ width: 12 }}></div>

              <div className="searchBox">
                <img className="searchIcon" src={iconSearch} alt="search" />
                <input
                  data-test-id="field-search"
                  type="search"
                  className="search"
                  placeholder={Strings.labels.search}
                  onKeyDown={(e) =>
                    e.key === "Enter" && this.getDataBySearchQueryApi()
                  }
                  onChange={(e) => this.onChangeQueryInput(e.target.value)}
                />
                <button type="button" className="searchBtn">
                  <img
                    data-test-id="btn-search"
                    src={iconGo}
                    alt="go"
                    onClick={() => this.getDataBySearchQueryApi()}
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="inventory-table">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <HeadingCheckbox
                      data-test-id="btn-selectAll"
                      onClick={() => this.onSelectAllChange()}
                      defaultChecked={this.state.selectAllItemFlag}
                    />
                  </th>
                  <th>{Strings.table.productName}</th>
                  <th>{Strings.table.productId}</th>
                  <th>{Strings.table.brandName}</th>
                  <th>{Strings.table.batchNo}</th>
                  <th>{Strings.table.mfgDate}</th>
                  <th>{Strings.table.expDate}</th>
                  <th>{Strings.table.inStock}</th>
                  <th>{Strings.table.retailPrice}</th>
                  <th>{Strings.table.salesPrice}</th>
                  <th>{Strings.table.active}</th>
                  <th>{Strings.table.edit}</th>
                </tr>
              </thead>
              <tbody>
                {this.getInventoryListByCondition().length === 0 && (
                  <tr key={"inventory-item-not-found"}>
                    <NoDataTd colSpan={12}>{Strings.table.noData}</NoDataTd>
                  </tr>
                )}
                {this.getInventoryListByCondition().map((item) => {
                  return (
                    <tr key={"inventory-item-" + item.id}>
                      <td>
                        <label className="checkbox-container">
                          <input
                            data-test-id={"btn-checkbox-" + item.id}
                            type="checkbox"
                            onClick={() =>
                              this.onChangeProductSelection(item.id)
                            }
                            checked={this.state.selectedProductIds.includes(
                              item.id
                            )}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </td>
                      <td>{item.name}</td>
                      <td>{item.product_code}</td>
                      <td>{item.brand.name}</td>
                      <td>{item.model_number}</td>
                      <td>
                        {moment(item.manufacture_date_timestamp).format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                      <td>
                        {moment(item.use_before_timestamp).format("DD/MM/YYYY")}
                      </td>
                      <td>
                        {item.min_stock_qty >= item.stock_qty ? (
                          <LowStockText>{item.stock_qty}</LowStockText>
                        ) : (
                          item.stock_qty
                        )}
                      </td>
                      <td>{item.retails_price}</td>
                      <td>{item.price}</td>
                      <td>
                        <label className="checkbox-container">
                          <input
                            data-test-id={"btn-status-" + item.id}
                            onClick={() => this.onUpdateActiveStatus(item)}
                            type="checkbox"
                            checked={item.status === "active" ? true : false}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </td>
                      <td>
                        <Link
                          onClick={() =>
                            this.state.isApproved
                              ? this.props.navigation.navigate("VendorSub", {
                                  page: "edit-inventory-item",
                                  id: item.id,
                                })
                              : BlockHelpers.showAlert(
                                  Strings.labels.alert,
                                  Strings.message.verifyBusiness
                                )
                          }
                          to={"#"}
                        >
                          <img src={iconEdit} />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Pagination
                data-test-id={"btn-pagination"}
                lastPage={this.state.lastPage}
                onChangePage={(page: number) => this.onChangePage(page)}
                currentPage={this.state.currentPage}
              />
            </Grid>
          </Grid>
        </div>
        <br />
        <br />

        {this.state.removeItemFlag === "confirm" && (
          <>
            <div className="modalBox">
              <div className="modalContent">
                <button
                  data-test-id={"btn-close"}
                  type="button"
                  className="btnClose"
                  onClick={() => this.setState({ removeItemFlag: null })}
                >
                  &times;
                </button>
                <div className="modalBody">
                  <div className="message">
                    <h4>{Strings.message.removeSelectedProduct}</h4>
                    <div className="buttonBox">
                      <button
                        data-test-id={"btn-yes"}
                        className="buttons filled"
                        type="button"
                        onClick={() => this.onDeleteSelectedItems()}
                      >
                        {Strings.actions.yes}
                      </button>
                      <button
                        data-test-id={"btn-no"}
                        className="buttons blank"
                        type="button"
                        onClick={() =>
                          this.setState({
                            removeItemFlag: false,
                          })
                        }
                      >
                        {Strings.actions.no}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {this.state.removeItemFlag === "deleted" && (
          <>
            <div className="modalBox">
              <div className="modalContent">
                <button
                  data-test-id={"btn-close-deleted"}
                  type="button"
                  className="btnClose"
                  onClick={() =>
                    this.setState({
                      removeItemFlag: false,
                      selectedProductIds: [],
                    })
                  }
                >
                  &times;
                </button>
                <div className="modalBody">
                  <div className="message">
                    <h4>{Strings.message.removeSuccessfully}</h4>
                    <div className="buttonBox">
                      <button
                        data-test-id={"btn-goBack"}
                        className="buttons filled"
                        type="button"
                        onClick={() =>
                          this.setState({
                            removeItemFlag: false,
                            selectedProductIds: [],
                          })
                        }
                      >
                        {Strings.actions.goBack}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

const LowStockText: any = styled("span")({
  color: "red",
});

const HeadingCheckbox = styled(Checkbox)({
  "& .MuiSvgIcon-root": {
    fontSize: 22,
  },
  "&.MuiCheckbox-root": {
    padding: 0,
    color: "white",
  },
  "&.MuiCheckbox-colorSecondary.Mui-checked": {
    color: "white",
  },
});

const FilterCheckBoxText = styled(Box)({
  padding: "0px 8px",
  display: "inline-flex",
  alignItems: "center",
  gap: 4,
  "& label": {
    fontSize: 16,
    fontWeight: 500,
    color: "var(--content)",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
  "& .MuiCheckbox-root": {
    padding: 0,
  },
  "& .MuiCheckbox-colorSecondary.Mui-checked": {
    color: "var(--navy)",
  },
});

const StyledMenuItem = styled(MenuItem)({
  "&.MuiListItem-root": {
    padding: 0,
    paddingLeft: 17,
  },
  "& .MuiSvgIcon-root": {
    fill: "var(--gray)",
    width: 20,
    height: 20,
  },
  "& .MuiTypography-root": {
    fontSize: 14,
    fontWeight: 500,
    color: "var(--gray)",
    whiteSpace: "break-spaces",
  },
});

const StyledSelect = styled(Select)({
  padding: 4,
  maxWidth: 200,
  minWidth: 150,
  border: "none",
  fontWeight: 600,
  "& .MuiOutlinedInput-notchedOutline": {
    boxShadow: "1px 1px 10px var(--boxShadow)",
    border: 0,
  },
  "& .MuiOutlinedInput-input": {
    padding: "0px 8px",
    paddingRight: 24,
    paddingBottom: 4,
  },
});

export default Inventory;

// Customizable Area End
