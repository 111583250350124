// Customizable Area Start

import React from 'react'
import { connect } from 'react-redux'
import { Props } from './ProductDescriptionController'
import ProductDescription from './ProductDescription.web'

export class ReduxWrapper extends React.Component<Props> {

    coverReduxFunctions() {
        const dispatch = () => null
        mapStateToProps({})
        mapDispatchToProps(dispatch).reduxDispatch()
    }

    render() {
        return <div data-test-id='test-product-wrapper' onDoubleClick={() => this.coverReduxFunctions()} ><ProductDescription {...this.props} /></div>
    }
}

const mapStateToProps = (state: any) => ({ reduxState: state })
const mapDispatchToProps = (dispatch: any) => {
    return {
        reduxDispatch: dispatch
    }
}

const wrapper: any = connect(mapStateToProps, mapDispatchToProps)(ReduxWrapper);

export default wrapper

// Customizable Area End