Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  updateStoreMember: "/account_block/store_members/",
  listStoreMembers: "/account_block/store_members",
  activateStatus: "/account_block/store_members/",
  addStoreMember: "/account_block/store_members",
  deleteStoreMember: "/account_block/store_members/delete_store_member?ids=",
};

exports.Strings = {
  header: {
    heading: "Store Members",
    addMember: "Add Member",
    editMember: "Edit Member",
  },
  actions: {
    yes: "Yes",
    addMember: "Add Member",
    cancel: "Cancel",
    save: "Save",
    saveChanges: "Save Changes",
  },
  table: {
    sr: "Sr",
    storeMember: "Store Member",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone",
    createdAt: "Created At",
    active: "Active",
    action: "Action",
    loading: "Loading...",
    noData: "No data found",
  },
  labels: {
    pleaseConfirm: "Please confirm!",
    role: "Role",
    alert: "Alert!",
    error: "Error!",
    success: "Success!",
    lastName: "Last Name",
    firstName: "First Name",
    phoneNumber: "Phone Number",
    storeMember: "Store Members",
    emailAddress: "Email Address",
    selectRole: "Select Role",
    supervisor: "Supervisor",
  },
  placeholders: {
    role: "Enter Role",
    lastName: "Enter Last Name",
    firstName: "Enter First Name",
    phoneNumber: "Enter Phone Number",
    storeMember: "Enter Store Members",
    emailAddress: "Enter Email Address",
  },
  messages: {
    confirmDeleteText: "Are you sure you want to delete this store member?",
    storeMemberDeleted: "Store member is deleted successfully!",
    storeMemberAdded: "Store member is added successfully!",
    storeMemberUpdated: "Store member is updated successfully!",
  },
  validation: {
    storeMember: "Please enter store member",
    firstName: "Please enter first name",
    lastName: "Please enter last name",
    role: "Please select role",
    email: "Please enter email id",
    validEmail: "Please enter valid email id",
    phone: "Please enter phone",
    validPhone: "Please enter valid phone",
  },
};

exports.MappedStoreMembersKey = {
  store_member: "store_member",
  full_phone_number: "phone",
  first_name: "first_name",
  last_name: "last_name",
  email: "email",
  role: "role",
};
