Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  notificationList: "/bx_block_notifications/notifications",
  readNotifications: "/bx_block_notifications/notifications/mark_unread?ids=",
  deleteNotifications: "/bx_block_notifications/notifications/destroy?ids=",
};

exports.Strings = {
  actions: {
    read: "Mark as Read",
    unread: "Mark as Unread",
    delete: "Delete Selected",
    select: "Select",
    goBack: "Go Back",
    clearAll: "Clear All",
  },
  labels: {
    title: "Notifications",
  },
  header: {
    title: "JHC | Notifications",
  },
  message: {
    deleteSuccessfully: "Delete Successfully",
    noData: "Data not found",
  },
};
