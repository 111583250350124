// Customizable Area Start

import React from "react";
import {
  styled,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TableContainer,
} from "@material-ui/core";

import ReportWrapper from "./ReportWrapper";
import AdHocReportingController, {
  IOrderCountRow,
} from "./AdHocReportingController";

const configJs = require("./config");
const Strings = configJs.Strings;

class OrderCountTable extends AdHocReportingController {
  render() {
    const { orderCountList, orderCountTotals, orderCountDownloadLink ,filter,loading,dateRanges} =
      this.state;
    return (
      <ReportWrapper
        visibleMonthSelector={true}
        mainTitle={Strings.labels.orderCount}
        downloadLink={orderCountDownloadLink}
        dateRangeKey="orderCount"
        onChangeDate={this.onChangeDateRange}
        currentFilter={filter["orderCount"]}
        onChangeFilter={this.onFilterChange}
        loading={loading["orderCount"]}
        rangeValue={dateRanges["orderCount"]}
      >
        <TableContainer>
          <Table stickyHeader size="small">
            <StyledTableHead>
              <TableRow>
                {[
                  Strings.labels.month,
                  Strings.labels.transactionCount,
                  Strings.labels.itemCount,
                  Strings.labels.average,
                  Strings.labels.netSales,
                  "%",
                ].map((label: string) => (
                  <TableCell key={"table-heading-" + label}>{label}</TableCell>
                ))}
              </TableRow>
            </StyledTableHead>

            <StyledTableBody>
              {orderCountList.length === 0 && (
                <TableRow key={"order-count-table-no-data"}>
                  <TableCell colSpan={7}>{Strings.labels.noData}</TableCell>
                </TableRow>
              )}
              {orderCountList.map((item: IOrderCountRow, index) => {
                return (
                  <TableRow
                    data-testid={"table-data-row"}
                    key={"order-count-table-" + index}
                  >
                    <TableCell>{item.period[Object.keys(item.period)[0]]}</TableCell>
                    <TableCell>{item.transaction_count}</TableCell>
                    <TableCell>{item.item_count}</TableCell>
                    <TableCell>{item.average}</TableCell>
                    <TableCell>{item.net_sales}</TableCell>
                    <TableCell>{item.percentage}</TableCell>
                  </TableRow>
                );
              })}
            </StyledTableBody>

            {orderCountList.length !== 0 && (
              <StyledTableFooter>
                <TableRow>
                  <TableCell>Total</TableCell>
                  <TableCell>
                    {orderCountTotals.total_transaction_count}
                  </TableCell>
                  <TableCell>{orderCountTotals.total_item_count}</TableCell>
                  <TableCell>{orderCountTotals.total_average}</TableCell>
                  <TableCell>{orderCountTotals.total_net_sales}</TableCell>
                  <TableCell>-</TableCell>
                </TableRow>
              </StyledTableFooter>
            )}
          </Table>
        </TableContainer>
      </ReportWrapper>
    );
  }
}

const StyledTableBody = styled(TableBody)({
  "& .MuiTableCell-body": {
    color: "black !important",
    borderBottom: "1px solid white",
    backgroundColor: "rgb(246,246,256)",
  },
});

const StyledTableFooter = styled(TableFooter)({
  "& .MuiTableCell-footer": {
    color: "white !important",
    backgroundColor: "gray",
  },
});

const StyledTableHead = styled(TableHead)({
  "& .MuiTableCell-head": {
    color: "white",
    fontSize: 14,
    backgroundColor: "var(--navy)",
  },
});

export default OrderCountTable;

// Customizable Area End
