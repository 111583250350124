Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  deleteNotification:
    "/bx_block_notifications/notifications/delete_sent_notification/",
  notificationList:
    "/bx_block_notifications/notifications/list_sent_notifications",
};

exports.Strings = {
  header: {
    title: "JHC | Notification Menu",
    heading: "Notifications",
  },
  message: {
    pleaseConfirm: "Please Confirm!",
    areYouSure: "Are you sure you want to delete the notification?",
  },
  label: {
    notificationAdd: "Add Notification",
  },
  actions: {
    cancel: "Cancel",
    yes: "Yes",
  },
  table: {
    notificationType: "Notification Type",
    lastModified: "Last Modified",
    recipients: "Recipients",
    actions: "Actions",
    status: "Status",
    noData: "Data not found",
    sent: "Sent",
  },
};
