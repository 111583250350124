// Customizable Area Start

import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Grid, styled } from "@material-ui/core";

import NoDataTd from "../../Shared/NoDataTd";
import Pagination from "../../Shared/Pagination";
import OrderToDeliveryController from "./OrderToDeliveryController";

const configJs = require("./config");
const Strings = configJs.Strings;

class OrderToDelivery extends OrderToDeliveryController {
  render() {
    const { orderList, rejectItemFlag } = this.state;
    return (
      <>
        <div className="inventory-table mh-100">
          <table className="table">
            <thead>
              <tr>
                <th>{Strings.table.orderDetails}</th>
                <th>{Strings.table.productDetails}</th>
                <th>{Strings.table.customerDetails}</th>
                <th>{Strings.table.paymentMode}</th>
                <th>{Strings.table.orderStatus}</th>
                <th>{Strings.table.actions}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {orderList?.length === 0 && (
                  <NoDataTd colSpan={6}>{Strings.table.noData}</NoDataTd>
                )}
              </tr>
              {orderList?.map((item: any) => {
                const orderItemsNames = item?.order_items
                  ?.map((item: any) => item?.attributes?.name)
                  .filter((item: any) => item)
                  .join(", ");
                return (
                  <StyledTr key={item?.id}>
                    <td>
                      <h6 className="blueTitle">
                        {Strings.table.orderNumber}{" "}
                        <span>: #{item?.order_number || ""}</span>
                      </h6>
                      <h6 className="blueTitle">
                        {Strings.table.time}{" "}
                        <span>
                          : {moment(item?.order_date).format("hh:mm A")}
                        </span>
                      </h6>
                    </td>
                    <td>
                      <h6 className="blueTitle">
                        {Strings.table.itemName}{" "}
                        <span>: {orderItemsNames}</span>
                      </h6>
                      <h6 className="blueTitle">
                        {Strings.table.noOfPackage}{" "}
                        <span>: {item?.order_items?.length}</span>
                      </h6>
                      <h6 className="blueTitle">
                        {Strings.table.price}{" "}
                        <span>
                          : AED {item?.total || "0"} <small>inc tax</small>
                        </span>
                      </h6>
                    </td>
                    <td>
                      <h6 className="blueTitle">
                        {Strings.table.customerName}{" "}
                        <span>: {item?.customer_details}</span>
                      </h6>
                      <h6 className="blueTitle">
                        {Strings.table.address}{" "}
                        <span>: {item?.delivery_address}</span>
                      </h6>
                      <h6 className="blueTitle">
                        {Strings.table.phoneNumber}{" "}
                        <span>: {item?.phone_number}</span>
                      </h6>
                    </td>
                    <td>
                      <h6 className="blueTitle">
                        {item?.payment_mode === "cash_on_delivery"
                          ? Strings.table.cod
                          : Strings.table.onlinePayment}
                      </h6>
                    </td>
                    <td>
                      <StatusButton className="tableButtons">
                        {item?.order_status}
                      </StatusButton>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item?.order_status === "placed" ? (
                        <>
                          <button
                            data-test-id="btn-accept"
                            type="button"
                            onClick={() => this.onAcceptOrderClick(item?.id)}
                            className="tableButtons"
                          >
                            {Strings.actions.accept}
                          </button>
                          <div style={{ height: 10 }} />
                          <button
                            data-test-id="btn-reject"
                            type="button"
                            onClick={() => this.onRejectOrderClick(item?.id)}
                            className="tableButtons"
                          >
                            {Strings.actions.reject}
                          </button>
                        </>
                      ) : (
                        <>
                          <div style={{ marginTop: 4, textAlign: "center" }}>
                            <Link
                              to={"/vendor/order-details/" + item?.id}
                              className="tableButtons"
                            >
                              {Strings.actions.view}
                            </Link>
                          </div>
                          {item?.order_status === "confirmed" && (
                            <div>
                              <div style={{ height: 10 }} />
                              <button
                                data-test-id="btn-delivered"
                                type="button"
                                onClick={() => this.onDeliverOrder(item?.id)}
                                className="tableButtons"
                              >
                                {Strings.actions.delivered}
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </td>
                  </StyledTr>
                );
              })}
            </tbody>
          </table>
        </div>

        {rejectItemFlag && (
          <div className="modalBox">
            <div className="modalContent">
              <button
                data-test-id="btn-close"
                type="button"
                className="btnClose"
                onClick={() => this.setState({ rejectItemFlag: false })}
              >
                &times;
              </button>
              <div className="modalBody">
                <h6 className="title">Reason for Rejection</h6>
                <select
                  data-test-id="field-reason"
                  className="reason"
                  name="reason"
                  value={this.state.rejectReasonMessage}
                  onChange={(e) =>
                    this.setState({
                      rejectReasonMessage: e.target.value,
                    })
                  }
                >
                  <option value="" disabled>Select reason</option>
                  {this.state?.reasonList?.map((item: any) => {
                    return (
                      <option key={"reason-list-" + item.id} value={item.id}>
                        {item.reasons}
                      </option>
                    );
                  })}
                </select>
                <div className="buttonBox">
                  <button
                    data-test-id="btn-submit"
                    className="buttons filled"
                    type="button"
                    onClick={() => this.onRejectOrder()}
                  >
                    {Strings.actions.submit}
                  </button>
                  <button
                    data-test-id="btn-cancel"
                    className="buttons blank"
                    type="button"
                    onClick={() =>
                      this.setState({
                        rejectItemFlag: false,
                      })
                    }
                  >
                    {Strings.actions.cancel}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <br />
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Pagination
              data-test-id="pagination"
              lastPage={this.state.lastPage}
              onChangePage={this.onChangePage}
              currentPage={this.state.currentPage}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default OrderToDelivery;

const StyledTr = styled("tr")({
  "& > td": {
    position: "relative",
  },
  "& > td::before": {
    top: 8,
    right: 0,
    bottom: 8,
    content: "''",
    position: "absolute",
    borderRight: "1px solid var(--lightgray)",
  },
  "& > td:last-child::before": {
    borderRight: "none",
  },
});

const StatusButton = styled("div")({
  display: "inline-block",
  textAlign: "center",
  textTransform: "capitalize",
});

// Customizable Area End
