// Customizable Area Start
import React from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
import OrderDetailsController from "./OrderDetailsController";

const configJs = require("./config");
const Strings = configJs.Strings;

class OrderDetail extends OrderDetailsController {
  render() {
    const data = this.state.orderData || {};
    return (
      <div data-test-id='order-details-screen' data-testid="edit-order-screen" className="order">
        <h6 className="heading">{Strings.header.heading}</h6>
        <Helmet>
          <title>
            {Strings.header.title}
            {data?.order_number}
          </title>
        </Helmet>
        <div className="order-detail">
          <h6 className="blueTitle">
            {Strings.labels.orderNumber}{" "}
            <span>: #{data?.order_number || ""}</span>
          </h6>
          <h6 className="blueTitle">
            {Strings.labels.time}{" "}
            <span>: {moment(data?.order_date || "").format("hh:mm A")}</span>
          </h6>
          <h6 className="blueTitle">
            {Strings.labels.address} <span>: {data?.pickup_address || ""}</span>
          </h6>
          <h6 className="blueTitle">
            {Strings.labels.itemName} <span>: {data?.itemNames || ""}</span>
          </h6>
          <h6 className="blueTitle">
            {Strings.labels.noOfPackage}{" "}
            <span>: {data?.order_items.length}</span>
          </h6>
          <h6 className="blueTitle">
            {Strings.labels.price}{" "}
            <span>
              : AED {data?.total || ""} <small>Inc Tax</small>
            </span>
          </h6>
          <h6 className="blueTitle">
            {Strings.labels.customerName}{" "}
            <span>: {data?.customer_details || ""}</span>
          </h6>
          <h6 className="blueTitle">
            {Strings.labels.address}{" "}
            <span>: {data?.delivery_address || ""}</span>
          </h6>
          <h6 className="blueTitle">
            {Strings.labels.phone} <span>: {data?.phone_number || ""}</span>
          </h6>
          <h6 className="blueTitle">
            {Strings.labels.paymentMode}{" "}
            <span>: {data?.payment_mode || ""}</span>
          </h6>

          <div className="row p-0 m-0">
            <div className="col-sm-4 p-0">
              <h6 className="blueTitle">
                {Strings.labels.orderStatus}{" "}
                <span>: {data?.order_status || ""}</span>
              </h6>
            </div>
            {data?.order_status === "rejected" && (
              <div className="col-sm-8 p-0">
                <h6 className="blueTitle">
                  {Strings.labels.reasonFor} <span>: {data?.reason_for}</span>
                </h6>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default OrderDetail;

// Customizable Area End
