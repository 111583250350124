// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import {toast} from 'react-toastify';
import GetApiMessage from "../../utilities/src/GetApiMessage";

import ApiUrls from "./config";

export interface Props {
  navigation: any;
  id: string;
  product: string,
  description: string,
  price: any,
  deal: any,
  bestSeller: any,
  resultDeal: any,
  slug: string,
  image: string,
  accountIsApproved: string,
  itemData: any;
  openApproved: any;
  reduxState: { actions: { cart: { REFETCH: string } } }
  reduxDispatch: {dispatch : Function},
  wishlist: boolean
}

interface S {
    addToCartMessageId: any;
    cartTotal: any;
    addToWishlistMessageId: any;
    removeFromWishlistMessageId: any;
    itemData: any;
}

interface SS {
  id: any;
}

export default class CommoncardController extends BlockComponent<
  Props,
  S,
  SS
> {
    constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
        addToCartMessageId: '',
        cartTotal: 0,
        addToWishlistMessageId: '',
        removeFromWishlistMessageId: '',
        itemData: this.props.itemData
      }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
        let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    }
    const { addToCartMessageId, addToWishlistMessageId, removeFromWishlistMessageId, itemData } = this.state
    let data = JSON.parse(JSON.stringify(itemData));
    let requestID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    let successResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
   if (addToCartMessageId === requestID) {
      let data = JSON.parse(JSON.stringify(itemData));
      if(successResponse?.errors?.length>0 && successResponse?.errors[0]?.token){
        //guest user browsing application need to open login modal
        this.sendLoginAlertMessage();
        return;
      }
      if(successResponse?.errors){
        toast.error(successResponse?.errors);
      }
      if (successResponse && successResponse?.data) {
        data.attributes.is_in_cart = true;
        data.attributes.is_wishlisted = false;
        this.setState({itemData: data})
        this.onRefetchCart()
      }
    } else if (addToWishlistMessageId === requestID) {
      if(successResponse?.errors?.length>0 && successResponse?.errors[0]?.token){
        //guest user browsing application need to open login modal
        this.sendLoginAlertMessage();
        return;
      }
      if (successResponse && successResponse?.success) {
        data.attributes.is_wishlisted = true;
        this.setState({itemData: data})
        toast.success('Successfully added in wishlist')
      }else{
        toast.error('Something went wrong.')
      }
    } else if (removeFromWishlistMessageId === requestID) {
      if (successResponse && successResponse?.data) {
        data.attributes.is_wishlisted = false;
        this.setState({itemData: data})
        toast.success('Successfully removed in wishlist')
      }else{
        toast.error('Something went wrong.')
      }
    }
  }
 sendLoginAlertMessage() {
    const msg: Message = new Message(getName(MessageEnum.UserLoginAlertMessage));
    msg.addData(getName(MessageEnum.UserLoginAlertBodyText),true)
    this.send(msg)
   }
  onRefetchCart() {
    const { dispatch } = this.props.reduxDispatch
    dispatch({ type: this.props.reduxState.actions.cart.REFETCH })
  }

  addToCartFromList(catalogueId: any) {
    const addtoCartData = new FormData();
    addtoCartData.set('catalogue_id', catalogueId);
    addtoCartData.set('quantity', '1');
    addtoCartData.set('order_action', 'add');
    const requestMessage = GetApiMessage({ method: 'post', endpoint: ApiUrls.addToCartAPIURL, data: addtoCartData });
    this.setState({ addToCartMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  addToWishlist(catalogueId: any) {
    const product=this.props.itemData;
    if(product?.attributes?.guest_login){
      this.sendLoginAlertMessage();
      return;
    }
    const wishlistData = new FormData();
    wishlistData.set('catalogue_id', catalogueId);
    const requestMessage = GetApiMessage({ method: 'post', endpoint: ApiUrls.addToWishlistApiURL, data: wishlistData });
    this.setState({ addToWishlistMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  DeleteFromWishlist(catalogueId: any) {
    const wishlistData = new FormData();
    wishlistData.set('catalogue_id', catalogueId)
    const requestMessage = GetApiMessage({ method: 'DELETE', endpoint: ApiUrls.removeFromWishlistApiURL, data: wishlistData });
    this.setState({ removeFromWishlistMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
  // Customizable Area End