// Customizable Area Start

import React from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
import { styled } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";

import { iconEdit } from "../assets";
import EditProfileController from "./EditProfileController";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
const configJs = require("./config");
const Strings = configJs.Strings;

class EditProfile extends EditProfileController {
  render() {
    return (
      <div data-test-id="edit-profile-screen" className="inventory">
        <Helmet>
          <title>{Strings.header.title}</title>
        </Helmet>
        <div className="head">
          <h4 className="title">{Strings.labels.title}</h4>
          <div className="right-container">
            <button className="edit">
              <img src={iconEdit} alt="icon" />
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3">
            <label className="vender-label">
              {Strings.labels.firstName}&nbsp;<RedLabel>*</RedLabel>
            </label>
            <input
              data-test-id="field-firstName"
              value={this.state.firstName}
              type="text"
              className="vender-inputs"
              onChange={(e) => this.onChangeValue("firstName", e.target.value)}
            />
            {this.state.validation.firstName && (
              <p className="formErrors">{this.state.validation.firstName}</p>
            )}
          </div>
          <div className="col-sm-3">
            <label className="vender-label">
              {Strings.labels.lastName}&nbsp;<RedLabel>*</RedLabel>
            </label>
            <input
              data-test-id="field-lastName"
              value={this.state.lastName}
              type="text"
              className="vender-inputs"
              onChange={(e) => this.onChangeValue("lastName", e.target.value)}
            />
            {this.state.validation.lastName && (
              <p className="formErrors">{this.state.validation.lastName}</p>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <label className="vender-label">
              {Strings.labels.email}&nbsp;<RedLabel>*</RedLabel>
            </label>
            <input
              data-test-id="field-email"
              readOnly
              value={this.state.email}
              type="text"
              className="vender-inputs"
              onChange={(e) => this.onChangeValue("email", e.target.value)}
            />
            {this.state.validation.email && (
              <p className="formErrors">{this.state.validation.email}</p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <label className="vender-label">{Strings.labels.password}</label>
            <input
              data-test-id="field-password"
              value={this.state.password}
              type="text"
              className="vender-inputs"
              onChange={(e) => this.onChangeValue("password", e.target.value)}
            />
            {this.state.validation.password && (
              <p className="formErrors">{this.state.validation.password}</p>
            )}
          </div>
          <div className="col-sm-6">
            <label className="vender-label">
              {Strings.labels.changePassword}
            </label>
            <input
              data-test-id="field-confirmPassword"
              value={this.state.confirmPassword}
              type="text"
              className="vender-inputs"
              onChange={(e) =>
                this.onChangeValue("confirmPassword", e.target.value)
              }
            />
            {this.state.validation.confirmPassword && (
              <p className="formErrors">
                {this.state.validation.confirmPassword}
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <label className="vender-label">
              {Strings.labels.phoneNumber}&nbsp;<RedLabel>*</RedLabel>
            </label>
              <PhoneInput
                  data-test-id="field-phone"
                  inputStyle={{
                    width:"100%",
                    border:"0",
                    borderBottom:"1px solid var(--lightgray)",
                    borderRadius:"0px"
                  }}
                  inputClass="vender-inputs"
                  containerClass="vendor-phone-input"
                  value={this.state.phone || ""}
                  onChange={value=>this.onChangeValue("phone", value)}
              />
            {this.state.validation.phone && (
              <p className="formErrors">{this.state.validation.phone}</p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <label className="vender-label">{Strings.labels.dateOfBirth}</label>
            <StyledDatePicker
              data-test-id="field-dob"
              fullWidth
              format={"DD/MM/YYYY"}
              value={this.state.dob}
              onChange={(e: any) =>
                this.onChangeValue("dob", moment(e).format("YYYY-MM-DD"))
              }
            />
            {this.state.validation.dob && (
              <p className="formErrors">{this.state.validation.dob}</p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <label className="vender-label">{Strings.labels.state}</label>
            <input
              data-test-id="field-state"
              value={this.state.state}
              type="text"
              className="vender-inputs"
              onChange={(e) => this.onChangeValue("state", e.target.value)}
            />
            {this.state.validation.state && (
              <p className="formErrors">{this.state.validation.state}</p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <label className="vender-label">{Strings.labels.address}</label>
            <input
              data-test-id="field-address"
              value={this.state.address}
              type="text"
              className="vender-inputs"
              onChange={(e) => this.onChangeValue("address", e.target.value)}
            />
            {this.state.validation.address && (
              <p className="formErrors">{this.state.validation.address}</p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <label className="vender-label">{Strings.labels.city}</label>
            <input
              data-test-id="field-city"
              value={this.state.city}
              type="text"
              className="vender-inputs"
              onChange={(e) => this.onChangeValue("city", e.target.value)}
            />
            {this.state.validation.city && (
              <p className="formErrors">{this.state.validation.city}</p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <label className="vender-label">{Strings.labels.pinCode}</label>
            <input
              data-test-id="field-pinCode"
              value={this.state.pinCode}
              type="text"
              className="vender-inputs"
              maxLength={12}
              onChange={(e) => this.onChangeValue("pinCode", e.target.value)}
            />
            {this.state.validation.pinCode && (
              <p className="formErrors">{this.state.validation.pinCode}</p>
            )}
          </div>
        </div>
        <div className="buttonBox justify-content-start mt-3">
          <button
            data-test-id="btn-submit"
            onClick={() => this.onSubmit()}
            className="buttons filled"
            type="button"
          >
            {this.state.buttonLoading
              ? Strings.actions.saving
              : Strings.actions.save}
          </button>
          <button
            data-test-id="btn-cancel"
            className="buttons blank"
            type="button"
            onClick={() =>
              this.props.navigation.navigate("Vendor", {
                page: "profile",
              })
            }
          >
            {Strings.actions.cancel}
          </button>
        </div>
      </div>
    );
  }
}

const RedLabel = styled("span")({
  color: "red",
});

const StyledDatePicker: any = styled(DatePicker)({
  marginBottom: 10,
  "& .MuiInput-input": {
    height: 25,
    padding: 0,
    fontSize: 16,
    fontWeight: 600,
    color: "var(--black)",
  },
  "& .MuiInput-underline::before": {
    borderBottom: "1px solid var(--lightgray) !important",
  },
});

export default EditProfile;

// Customizable Area End
