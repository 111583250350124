import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import GetApiMessage from "../../utilities/src/GetApiMessage";
import { isAnyOf } from "@reduxjs/toolkit";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  reduxState?: {
    actions: any;
    profile: any;
  };
  reduxDispatch?: {dispatch: Function};
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  searchMessageId: any;
  searchList: any;
  brandsMessageId: any;
  brandsList: any;
  slug: any;
  priceList: any;
  showSearch: any;
  currentPage: any;
  totalPages: any;
  titleName: string;
  lastPage: number;
  excludeOutOfStock: boolean;
  categoryName: string;
  locationMessageId: any;
  locations: any;
  sort: any;
  isApproved: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ElasticSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      searchMessageId: "",
      searchList: [],
      brandsMessageId: "",
      brandsList: [],
      slug: "",
      priceList: [],
      showSearch: 3,
      currentPage: 1,
      totalPages: [],
      titleName: "",
      lastPage: 1,
      excludeOutOfStock: false,
      categoryName: '',
      locationMessageId: '',
      locations: [],
      sort: 'all',
      isApproved: "approved"
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  componentDidUpdate(prevProps: Props): void {
    let type = this.props.navigation.getParam("type");
    let newType = prevProps.navigation.getParam("type");
    let categoryId = this.props.navigation.getParam("category");
    let newCategoryId = prevProps.navigation.getParam("category");

    let { slug } = this.state;
    let params = new URLSearchParams(window.location.search);
    let newSlug = params.get("s");

    if (type !== newType) {
      this.componentDidMount();
    } else if ([type, newType].includes("category")) {
      if (categoryId !== newCategoryId) this.componentDidMount();
    } else if ([type, newType].includes("search")) {
      if (slug && newSlug) if (slug !== newSlug) this.componentDidMount();
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const { searchMessageId, brandsMessageId, locationMessageId } = this.state;
    let requestID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (searchMessageId === requestID && successResponse) {
      this.setState({
        searchList: (this.props.navigation.getParam("type") === 'brand' || this.props.navigation.getParam("type") === 'offer') ? successResponse.data.attributes.catalogues : successResponse,
        lastPage: successResponse.meta?.last_page,
        categoryName: (this.props.navigation.getParam("type") === 'category' && successResponse.data?.length > 0) ? successResponse.data[0].attributes.category.name : '',
        isApproved: successResponse?.account_is_approved
      });
    } else if (brandsMessageId === requestID && successResponse) {
      const priceArray = [
        { min: 1, max: 50, isChecked: false },
        { min: 51, max: 100, isChecked: false },
        { min: 101, max: 200, isChecked: false },
        { min: 201, max: 300, isChecked: false },
        { min: 301, max: 400, isChecked: false },
        { min: 401, max: 500, isChecked: false },
        { min: 501, max: 600, isChecked: false },
        { min: 601, max: 1000000, isChecked: false },
      ];
      this.setState({ priceList: priceArray });
      if (successResponse.data?.length > 0) {
        let addCheckedInBrand = [...successResponse.data];
        addCheckedInBrand.forEach((brand: any) => {
          brand.attributes.isChecked = false;
        });
        this.setState({ brandsList: addCheckedInBrand });
      }
    } else if (locationMessageId === requestID && successResponse) {
      let modifyLocations: Array<Object> = [];
      {successResponse.data && Object.keys(successResponse.data)?.map((key: any, i: number) => (
        modifyLocations.push({name: key, value: successResponse.data[key], isChecked: false})
      ))}
      this.setState({
        locations: modifyLocations
      });
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  componentDidMount(): any {
    this.getSearchResult(1);
    this.getBrands();
    this.getLocations();
  }

  getSearchResult(page: any = 1, excludeOutOfStock: boolean = false, sort:any = 'all') {
    let url = "";
    const params = new URLSearchParams(window.location.search);
    const type = this.props.navigation.getParam("type");
    const categoryId = this.props.navigation.getParam("category");
    const offerId = this.props.navigation.getParam("category");
    const brandId = this.props.navigation.getParam("category");
    const limit = "15";
    switch (type) {
      case "search":
        url += configJSON.searchAPIURL;
        const slug = params.get("s");
        if (slug) {
          this.setState({ slug: decodeURI(slug) });
          url += "&key=" + slug;
        }
        if(excludeOutOfStock){
          url += "&exclude_out_of_stock=true";
        } 
        this.setState({ showSearch: 2 });
        break;
      case "category":
        url += configJSON.searchAPIURL;
        url += "&category_id=" + categoryId;
        if(excludeOutOfStock){
          url += "&exclude_out_of_stock=true";
        } 
        this.setState({ showSearch: 1 });
        break;
      case "offer":
          url += configJSON.offerApiURL;
          url = url.replace("{offer_id}", offerId);
          this.setState({ showSearch: 3, titleName: "Offer" });
          break;
      case "brand":
        url += configJSON.brandPageApiURL;
        url = url.replace("{brand_id}", brandId);
        this.setState({ showSearch: 3, titleName: "Brand" });
        break;
      case "todays-deal":
        url += configJSON.todayDealsApiURL;
        this.setState({ showSearch: 3, titleName: "Today's deals" });
        break;
      case "frequently-repurchased-in-beauty-and-personal-care":
        url += configJSON.beautyPersonalApiURL;
        this.setState({
          showSearch: 3,
          titleName: "Frequently Repurchased In Beauty And Personal care",
        });
        break;
      case "pick-up-where-you-left-off":
        url += configJSON.searchAPIURL;
        this.setState({
          showSearch: 3,
          titleName: "Pick Up Where You Left Off",
        });
        break;
      case "specials":
        url += configJSON.specialProductsApiURL;
        this.setState({ showSearch: 3, titleName: "Specials" });
        break;
      case "new-arrivals":
        url += configJSON.newArrivalsApiURL;
        this.setState({ showSearch: 3, titleName: "New Arrivals" });
        break;
      case "best-sellers":
        url += configJSON.bestSellersApiURL;
        this.setState({ showSearch: 3, titleName: "Best Sellers" });
        break;
      case "buy-it-again":
        url += configJSON.buyItAgainApiURL;
        this.setState({ showSearch: 3, titleName: "Buy It Again" });
        break;
      default:
        this.setState({ showSearch: 3 });
    }
    const { brandsList, priceList, locations } = this.state;
    if (priceList.length > 0) {
      let priceFrom: Array<any> = [];
      let priceTo: Array<any> = [];
      priceList
        .filter((item: any) => item.isChecked === true)
        .forEach((item: any) => {
          priceFrom.push(item.min);
          priceTo.push(item.max);
        });
      url +=
        priceFrom.length > 0 && priceTo.length > 0
          ? "&price_from=[" +
            priceFrom.join(",") +
            "]&price_to=[" +
            priceTo.join(",") +
            "]"
          : "";
    }
    if (brandsList.length > 0) {
      let selectedBrands = brandsList
        .filter((item: any) => item.attributes.isChecked === true)
        .map((item: any) => item.attributes.id);
      url +=
        selectedBrands.length > 0 ? `&brand_ids=[${selectedBrands}]` : "";
    }
    if (locations.length > 0) {
      let selectedLocations = locations
        .filter((item: any) => item.isChecked === true)
        .map((item: any) => item.name);
      url +=
      selectedLocations.length > 0 ? `&location=[${selectedLocations}]` : "";
    }

    switch (sort) {
      case "a-z":
        url += `&is_alphabet=1`;
      break;
      case "z-a":
        url += `&is_alphabet=2`;
      break;
      case "lowToHigh":
        url += `&min=true`;
      break;
      case "highToLow":
        url += `&max=true`;
      break;
      default:
        url += ``;
    }


    url = url.replace("{page}", page);
    url = url.replace("{per_page}", limit);

    this.setState({ currentPage: page });
    const requestMessage = GetApiMessage({ method: "get", endpoint: url });
    this.setState({ searchMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBrands() {
    const requestMessage = GetApiMessage({
      method: "get",
      endpoint: configJSON.brandAPIURL,
    });
    this.setState({ brandsMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFilters(e: any, i: number, type: string) {
    if (type === "brands") {
      let data = [...this.state.brandsList];
      data[i].attributes.isChecked = e.target.checked;
      this.setState({ brandsList: data });
    } else if (type === "locations") {
      let data = [...this.state.locations];
      data[i].isChecked = e.target.checked;
      this.setState({ locations: data });
    } else{
      let data = [...this.state.priceList];
      data[i].isChecked = e.target.checked;
      this.setState({ priceList: data });
    }
    this.getSearchResult(1, this.state.excludeOutOfStock, this.state.sort);
  }

  handleStockFilter(e: any){
    this.getSearchResult(1, !e.target.checked, this.state.sort);
    this.setState({excludeOutOfStock : !e.target.checked});
  }

  ShowPaginationNumbers(pages: any) {
    let pageNumbers = [];
    for (let i = 0; i < pages; i++) {
      pageNumbers.push(i);
    }
    let paginationNumbers = [];
    if (pageNumbers) {
      let showMax = 3;
      let endPage;
      let startPage;
      if (pageNumbers.length <= showMax) {
        startPage = 1;
        endPage = pageNumbers.length;
      } else {
        startPage = this.state.currentPage;
        if (
          startPage != pageNumbers.length &&
          startPage + 1 != pageNumbers.length
        ) {
          endPage = this.state.currentPage + showMax - 1;
        } else {
          endPage = pageNumbers.length;
        }
      }
      for (let i = startPage; i <= endPage; i++) {
        paginationNumbers.push(i);
      }
      this.setState({ totalPages: paginationNumbers });
    }
  }

  clearButton(){
    const {priceList, brandsList} = this.state;
    let data = [...priceList];
    data.forEach((item: any) => {
      item.isChecked = false;
    });
    let addCheckedInBrand = [...brandsList];
    addCheckedInBrand.forEach((brand: any) => {
      brand.attributes.isChecked = false;
    });
    this.getSearchResult(1, true, this.state.sort);
    this.setState({ brandsList: addCheckedInBrand, priceList: data, excludeOutOfStock: true});
  }
  getLocations(){
    const requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.locationApiURL});
    this.setState({ locationMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSort(e: any){
    this.setState({sort: e.target.value});
    this.getSearchResult(1, this.state.excludeOutOfStock, e.target.value);
  }
  openApproved(flag: boolean) {
    const data = {
      type: this.props.reduxState?.actions.dialogues.accountApproved,
      payload: flag,
    };
    this.props.reduxDispatch?.dispatch(data);
  }
  // Customizable Area End
}
