import React from 'react'
import { connect } from 'react-redux'
import { Props } from './ShoppingCartOrdersController'
import ShoppingCartOrders from './ShoppingCartOrders.web'

class ReduxWrapper extends React.Component<Props> {
  // used for only test coverage
  testCoverage() {
    const dispatch = () => null
    mapStateToProps({ value: 'check for redux test cases' })
    mapDispatchToProps(dispatch).reduxDispatch()
  }

  render() {
    return <div data-test-id="test-shopping-wrapper" onDoubleClick={() => this.testCoverage()}><ShoppingCartOrders {...this.props} /></div>
  }
}

const mapStateToProps = (state: any) => ({ reduxState: state })
const mapDispatchToProps = (dispatch: any) => {
  return {
    reduxDispatch: dispatch
  }
}

const wrapper: any = connect(mapStateToProps, mapDispatchToProps)(ReduxWrapper)

export default wrapper;