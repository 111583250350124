Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  saveAddress: "/bx_block_order_management/addresses",
  editAddress: "/bx_block_order_management/addresses/",
  getAddressList: "/bx_block_order_management/addresses",
  cityList: "/bx_block_state_cities/state_cities/cities?",
  countryList: "/bx_block_state_cities/state_cities/countries",
  stateList: "/bx_block_state_cities/state_cities/states?country=",
};

exports.Strings = {
  actions: {
    no: "No",
    yes: "Yes",
    edit: "Edit",
    cancel: "Cancel",
    addAddress: "Add Address",
    saveChanges: "Save Changes",
    addInstructions: "Add Instruction",
    useThisAddress: "Use this address",
    saveInstructions: "Save Instruction",
    editInstructions: "Edit Instruction",
  },
  labels: {
    alert: "Alert!",
    countryRegion: "Country/Region",
    state: "State",
    sunday: "Sunday: ",
    zipCode: "Zipcode",
    landmark: "Landmark",
    fullName: "Full Name",
    townCity: "Town/City",
    saturday: "Saturday: ",
    mobileNo: "Mobile Number",
    recentlyUsed: "Recently Used",
    pickupAddress: "Pickup Address",
    editAnAddress: "Edit an address",
    addANewAddress: "Add a new address",
    editAnInstruction: "Edit an Instruction",
    addAnInstruction: "Add a new Instructions",
    areaStreet: "Area, Street, Sector, Village",
    writeYourInstructions: "Write your instruction!",
    makeThisMyDefaultAddress: "Make this my default address",
    flatHouseNo: "Flat, House No, Building, Company, Apartment",
  },
  validation: {
    name: "Please enter name",
    city: "Please select city",
    phone: "Please enter phone",
    state: "Please select state",
    flatNo: "Please enter flat no",
    country: "Please select country",
    zipCode: "Please enter zip code",
    landmark: "Please enter landmark",
    validPhone: "Please enter valid phone",
    instructionError: "Please enter instructions",
    addressLine2: "Please enter area, street, sector, villages",
    address: "Please select address",
  },
};

exports.MappedErrorKeys = {
  name: "name",
  phone_number: "phone_number",
  address_type: "address_type",
  flat_no: "flat_no",
  address: "address",
  address_line_2: "address_line_2",
  landmark: "landmark",
  city: "city",
  zip_code: "zip_code",
  state: "state",
  country: "country",
  is_default: "is_default",
};
