Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.Strings = {
  table: {
    no: "No.",
    cod: "COD",
    date: "Date",
    status: "Status",
    received: "Received",
    noData: "Data not found",
    billAmount: "Bill Amount",
    notReceived: "Not Received",
    paymentMode: "Payment Mode",
    orderNumber: "Order Number",
    customerName: "Customer Name",
    receiverName: "Receiver Name",
    sku: "SKU",
    penalty_fee:"Penalty Fee"
  },
};
