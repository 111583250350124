Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  listCOD:
    "/bx_block_inventorymanagement25/inventory_management/payment_details?payment_type=cash_on_delivery",
  listOnlinePayment:
    "/bx_block_inventorymanagement25/inventory_management/payment_details?payment_type=online_payment",
};

exports.Strings = {
  header: {
    title: "JHC | Payments",
  },
};
