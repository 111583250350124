// Customizable Area Start
import React from "react";
import { connect } from "react-redux";
import Carousel from "react-multi-carousel";
import CommonCard from "./CommonCard.web";
import Arrows from "./Arrows.web";
import { Link } from "react-router-dom";
import { BlockComponent } from "../../../framework/src/BlockComponent";
interface Props {
  name: any;
  items: any;
  bestSeller: any;
  resultDeal: any;
  sectionSlug: string;
  reduxState: {
    actions: any;
    profile: any;
  };
  reduxDispatch: {dispatch: Function};
}
interface State {}
interface SS {}

export class CommonCarousel extends BlockComponent<Props, State, SS> {
  openApproved(flag: boolean) {
    const data = {
      type: this.props.reduxState.actions.dialogues.accountApproved,
      payload: flag,
    };
    this.props.reduxDispatch.dispatch(data);
  }

  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 7
      },
      largeDesktop: {
          breakpoint: { max: 3000, min: 1921 },
          items: 6
      },
      desktop: {
          breakpoint: { max: 1920, min: 1400 },
          items: 5
      },
      miniDesktop: {
          breakpoint: { max: 1399, min: 1200 },
          items: 4
      },
      tablet: {
          breakpoint: { max: 1199, min: 768 },
          items: 3
      },
      miniTablet: {
          breakpoint: { max: 767, min: 575 },
          items: 2
      },
      mobile: {
          breakpoint: { max: 575, min: 0 },
          items: 1
      }
    };
    const { name, items, bestSeller, resultDeal, sectionSlug } = this.props;
    return (
      <div className="commonCarouselBox">
        <div className="head">
          <h6 className="title">{name}</h6>
          {sectionSlug !== "" && (
            <Link to={"/d/" + sectionSlug}>
              <button className="filled-btn">View All</button>
            </Link>
          )}
        </div>
        <div className="carousel-body">
          <Carousel
            responsive={responsive}
            showDots={false}
            autoPlaySpeed={1000}
            infinite
            arrows={false}
            customButtonGroup={<Arrows next={""} previous={""} />}
            renderButtonGroupOutside
          >
            {items?.length > 0 &&
              items?.map((result: any, i: number) => (
                <CommonCard
                  price={result?.attributes?.price}
                  image={
                    result?.attributes?.images?.length > 0
                      ? result?.attributes?.images[0]?.url
                      : "/frontend/product1.png"
                  }
                  product={result?.attributes?.name}
                  description={result?.attributes?.description}
                  deal={""}
                  bestSeller={bestSeller}
                  resultDeal={resultDeal}
                  key={i}
                  slug={result?.id}
                  accountIsApproved={
                    this.props.reduxState?.profile?.data?.status
                  }
                  itemData={result}
                  openApproved={this.openApproved.bind(this)}
                  wishlist={false}
                />
              ))}
          </Carousel>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({ reduxState: state });
const mapDispatchToProps = (dispatch: any) => {
  return {
    reduxDispatch: { dispatch },
  };
};
const reduxWrapper: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommonCarousel);

export default reduxWrapper;
// Customizable Area End
