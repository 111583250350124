// Customizable Area Start
import React from "react";
import {
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import StoreMemberFormController from "./StoreMemberFormController";

const configJs = require("./config");
const Strings = configJs.Strings;

class StoreMemberForm extends StoreMemberFormController {
  render() {
    const { validation, data, isEdit } = this.state;

    return (
      <Dialog
        open
        data-test-id="dialogModal"
        onClose={() => this.props.onClose()}
      >
        <DialogTitle>
          {!isEdit ? Strings.header.addMember : Strings.header.editMember}
        </DialogTitle>
        <StyledDialogContent dividers>
          <div className="row gy-3 gx-4">
            <div className="col-sm-6">
              <label className="vender-label">
                {Strings.labels.storeMember}
              </label>
              <input
                data-test-id="field-storeMember"
                type="text"
                className="vender-inputs"
                placeholder={Strings.placeholders.storeMember}
                defaultValue={data.store_member}
                onChange={(e: any) =>
                  this.onChangeValues("store_member", e.target.value)
                }
                onKeyDown={(e) => {
                  if (["Backspace", "Delete"].includes(e.key)) return;
                  let regex = /^[A-Za-z\s]+$/i;
                  if (!regex.test(e.key)) e.preventDefault();
                }}
              />
              {validation.store_member && (
                <ErrorLabel>{validation.store_member}</ErrorLabel>
              )}
            </div>
            <div className="col-sm-6">
              <label className="vender-label">{Strings.labels.role}</label>
              <StyledSelect
                data-test-id="field-role"
                name="role"
                className="vender-inputs"
                placeholder={Strings.placeholders.role}
                defaultValue={data.role}
                onChange={(e: any) =>
                  this.onChangeValues("role", e.target.value)
                }
              >
                <option value="">{Strings.labels.selectRole}</option>
                <option value="supervisor">{Strings.labels.supervisor}</option>
              </StyledSelect>
              {validation.role && <ErrorLabel>{validation.role}</ErrorLabel>}
            </div>
            <div className="col-sm-6">
              <label className="vender-label">{Strings.labels.firstName}</label>
              <input
                data-test-id="field-firstName"
                type="text"
                value={data.first_name}
                className="vender-inputs"
                placeholder={Strings.placeholders.firstName}
                onChange={(e: any) =>
                  this.onChangeValues("first_name", e.target.value)
                }
                onKeyDown={(e) => {
                  if (["Backspace", "Delete"].includes(e.key)) return;
                  let regex = /^[A-Za-z]+$/i;
                  if (!regex.test(e.key)) e.preventDefault();
                }}
              />
              {validation.first_name && (
                <ErrorLabel>{validation.first_name}</ErrorLabel>
              )}
            </div>
            <div className="col-sm-6">
              <label className="vender-label">{Strings.labels.lastName}</label>
              <input
                data-test-id="field-lastName"
                type="text"
                value={data.last_name}
                className="vender-inputs"
                placeholder={Strings.placeholders.lastName}
                onChange={(e: any) =>
                  this.onChangeValues("last_name", e.target.value)
                }
                onKeyDown={(e) => {
                  if (["Backspace", "Delete"].includes(e.key)) return;
                  let regex = /^[A-Za-z]+$/i;
                  if (!regex.test(e.key)) e.preventDefault();
                }}
              />
              {validation.last_name && (
                <ErrorLabel>{validation.last_name}</ErrorLabel>
              )}
            </div>
            <div className="col-sm-6">
              <label className="vender-label">
                {Strings.labels.emailAddress}
              </label>
              <input
                data-test-id="field-email"
                type="text"
                value={data.email}
                className="vender-inputs"
                placeholder={Strings.placeholders.emailAddress}
                onChange={(e: any) =>
                  this.onChangeValues("email", e.target.value)
                }
              />
              {validation.email && <ErrorLabel>{validation.email}</ErrorLabel>}
            </div>
            <div className="col-sm-6">
              <label className="vender-label">
                {Strings.labels.phoneNumber}
              </label>
              <PhoneInput
                  inputStyle={{
                    width:"100%",
                    border:"0",
                    borderBottom:"1px solid var(--lightgray)",
                    borderRadius:"0px"
                  }}
                  inputClass="vender-inputs"
                  containerClass="vendor-phone-input"
                  placeholder="Enter phone"
                  value={data.phone || ""}
                  onChange={value=>this.onChangeValues("phone", value)}
                  />
              {validation.phone && <ErrorLabel>{validation.phone}</ErrorLabel>}
            </div>
          </div>
          <br />
        </StyledDialogContent>
        <DialogActions>
          <div className="buttonBox justify-content-start">
            <button
              data-test-id="btn-cancel"
              onClick={() => this.props.onClose()}
              className="buttons blank"
              type="button"
            >
              {Strings.actions.cancel}
            </button>
            <button
              data-test-id="btn-submit"
              onClick={() => this.onSubmit()}
              className="buttons filled"
              type="button"
            >
              {isEdit ? Strings.actions.saveChanges : Strings.actions.save}
            </button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

const StyledDialogContent = styled(DialogContent)({
  "& .vender-inputs": {
    marginBottom: 0,
    marginTop: 4,
  },
});

const StyledSelect = styled("select")({
  background: "white",
});

const ErrorLabel = styled("div")({
  fontSize: 13,
  color: "#f44336",
  marginTop: 5,
});

export default StoreMemberForm;

// Customizable Area End
