// Customizable Area Start

import React from "react";
import { styled, Grid } from "@material-ui/core";
import RoundedButton from "../../Shared/RoundedButton";

import PaymentsController from "../PaymentsController";

import { iconGo, iconSearch } from "../../assets";

const configJs = require("./config");
const Strings = configJs.Strings;

class PaymentHeaders extends PaymentsController {
  render() {
    const listType = this.props.listType;
    return (
      <>
        <div className="head">
          <h4 className="title">{Strings.header.title}</h4>
        </div>

        <div className="d-flex">
          <div>
            <Grid container spacing={1}>
              <Grid item>
                <RoundedButton
                  data-test-id="btn-online-payments"
                  onClick={() => this.props?.onChangeList("Online Payment")}
                  text={Strings.labels.onlinePayment}
                  isActive={listType !== "COD"}
                />
              </Grid>
              <Grid item>
                <RoundedButton
                  data-test-id="btn-cash-on-delivery"
                  onClick={() => this.props?.onChangeList("COD")}
                  text={Strings.labels.cod}
                  isActive={listType === "COD"}
                />
              </Grid>
            </Grid>
          </div>
          <div style={{ flexGrow: 1 }}></div>
          <div className="filter w-auto mb-0">
            <div className="right-container">
              <div className="searchBox">
                <StyledSelect>
                  {[
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ].map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </StyledSelect>
                &nbsp;&nbsp;
                <StyledSelect>
                  {[2000, 2001, 2022].map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </StyledSelect>
              </div>

              <div>&nbsp;&nbsp;</div>

              <div className="searchBox">
                <img className="searchIcon" src={iconSearch} alt="search" />
                <input
                  placeholder={Strings.labels.selectDate}
                  type="text"
                  className="search"
                />
                <button type="button" className="searchBtn">
                  <img src={iconGo} alt="go" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const StyledSelect = styled("select")({
  border: "none",
  backgroundColor: "white",
});

export default PaymentHeaders;

// Customizable Area End
