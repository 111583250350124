// Customizable Area Start

import React from "react";
import { Helmet } from "react-helmet";
import { BlockComponent } from "../../../../framework/src/BlockComponent";

import OrderHeader from "./OrderHeader/OrderHeader.web";
import TrackOrders from "./TrackOrders/TrackOrders.web";
import OrderToDelivery from "./OrderToDelivery/OrderToDelivery.web";

const configJs = require("./config");
const Strings = configJs.Strings;

interface Props {}
interface SS {}
interface S {
  count: number;
  trackOrder: boolean;
  otd_statistics: {
    in_progress: number | any;
    failed_order: number | any;
    pending_order: number | any;
    completed_task: number | any;
  };
  td_statistics: {
    in_progress: number | any;
    failed_order: number | any;
    pending_order: number | any;
    completed_task: number | any;
  };
}

class OrdersView extends BlockComponent<Props, S, SS> {
  state = {
    count: 0,
    trackOrder: false,
    otd_statistics: {
      in_progress: 0,
      failed_order: 0,
      pending_order: 0,
      completed_task: 0,
    },
    td_statistics: {
      in_progress: 0,
      failed_order: 0,
      pending_order: 0,
      completed_task: 0,
    },
  };

  onSetStatistics(data: any, type: string) {
    if (type === "order-to-delivery") {
      this.setState({
        otd_statistics: {
          in_progress: data.in_progress ?? 0,
          failed_order: data.failed_order ?? 0,
          pending_order: data.pending_order ?? 0,
          completed_task: data.completed_task ?? 0,
        },
      });
    } else {
      this.setState({
        td_statistics: {
          in_progress: data.in_progress ?? 0,
          failed_order: data.failed_order ?? 0,
          pending_order: data.pending_order ?? 0,
          completed_task: data.completed_task ?? 0,
        },
      });
    }
    this.setState((prev: S) => ({ count: prev.count + 1 }));
  }

  render() {
    return (
      <div data-test-id="orders-screen" className="order">
        <Helmet>
          <title>{Strings.header.title}</title>
        </Helmet>
        <OrderHeader
          data-test-id="order-header"
          otd_statistics={this.state.otd_statistics}
          td_statistics={this.state.td_statistics}
          activeScreen={this.state.trackOrder}
          onChangeScreen={(flag: any) => this.setState({ trackOrder: flag })}
        />
        {this.state.trackOrder ? (
          <TrackOrders
            data-test-id={"track-order"}
            onSetStatistics={this.onSetStatistics.bind(this)}
          />
        ) : (
          <OrderToDelivery
            data-test-id={"order-to-delivery"}
            onSetStatistics={this.onSetStatistics.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default OrdersView;

// Customizable Area End
