// Customizable Area Start

import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";

import NoDataTd from "../../Shared/NoDataTd";
import Pagination from "../../Shared/Pagination";
import TrackOrdersController from "./TrackOrdersController";

import { iconLive } from "../../assets";

const configJs = require("./config");
const Strings = configJs.Strings;

class TrackOrders extends TrackOrdersController {
  render() {
    const { orderList } = this.state;
    return (
      <>
        <div className="inventory-table mh-100">
          <table className="table">
            <thead>
              <tr>
                <th>{Strings.table.pickupAddress}</th>
                <th>{Strings.table.deliveryAddress}</th>
                <th>{Strings.table.liveStatus}</th>
                <th>{Strings.table.picture}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {orderList?.length === 0 && (
                  <NoDataTd colSpan={6}>{Strings.message.noData}</NoDataTd>
                )}
              </tr>
              {orderList?.map((item: any) => {
                const length = item?.images?.length || 0;
                let image: any = { url: "" };
                try {
                  image = item?.images[length - 1];
                } catch (e) {}
                image = image?.url || "";
                return (
                  <tr key={item?.id}>
                    <td>
                      <h6 className="blueTitle">
                        {Strings.table.address}{" "}
                        <span>: {item?.pickup_address || ""}</span>
                      </h6>
                      <h6 className="blueTitle">
                        {Strings.table.pickupTime}{" "}
                        <span>
                          : {moment(item?.pickup_time).format("hh:mm A")}
                        </span>
                      </h6>
                    </td>
                    <td>
                      <h6 className="blueTitle">
                        {Strings.table.address}{" "}
                        <span>: {item?.delivery_address || ""}</span>
                      </h6>
                      <h6 className="blueTitle">
                        {Strings.table.phoneNo}{" "}
                        <span>: {item?.phone_number}</span>
                      </h6>
                      <h6 className="blueTitle">
                        {Strings.table.deliveryTime}{" "}
                        <span>
                          : {moment(item?.delivered_at).format("hh:mm A")}
                        </span>
                      </h6>
                    </td>
                    <td>
                      <img src={iconLive} alt="live" />
                    </td>
                    <td>
                      <a href={image} target="_blank">
                        <img src={image} width={100} />
                      </a>
                    </td>
                    <td>
                      <Link
                        style={{ whiteSpace: "nowrap" }}
                        to={"/vendor/order-details/" + item?.id}
                        type="button"
                        className="tableButtons"
                      >
                        {Strings.table.viewDetails}
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <br />
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Pagination
              data-test-id="pagination"
              onChangePage={this.onChangePage}
              currentPage={this.state.currentPage}
              lastPage={this.state.lastPage}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default TrackOrders;

// Customizable Area End
