// Customizable Area Start

import React from "react";
import moment from "moment";

import PaymentsController from "../PaymentsController";
import NoDataTdDefault from "../../Shared/NoDataTd";

const configJs = require("./config");
const Strings = configJs.Strings;

interface IPenaltyDeduction{
  id: number;
  penalty_fee: number;
}

interface IOnlinePaymentItem {
  order_number: string;
  payment_confirmed_at: string | null;
  status: boolean;
  payment_mode: string;
  bill_amount: string;
  penalty_deduction:IPenaltyDeduction | null;
    customer_name: string;
    receiver_name: string;
    delivery_time: string;
}


class OnlinePaymentList extends PaymentsController {
  render() {
    const { onlinePaymentList } = this.state;
    return (
      <>
        <div className="inventory-table mt-2">
          <table className="table">
            <thead>
              <tr>
                <th>{Strings.table.no}</th>
                <th>{Strings.table.date}</th>
                <th>{Strings.table.orderNumber}</th>
                <th>{Strings.table.penalty_fee}</th>
                <th>{Strings.table.customerName}</th>
                <th>{Strings.table.paymentMode}</th>
                <th>{Strings.table.billAmount}</th>
                <th>{Strings.table.sku}</th>
                <th>{Strings.table.status}</th>
              </tr>
            </thead>
            <tbody>
              {onlinePaymentList.length === 0 && (
                <tr>
                  <NoDataTdDefault colSpan={8}>
                    {Strings.table.noData}
                  </NoDataTdDefault>
                </tr>
              )}
              {onlinePaymentList.map((item:IOnlinePaymentItem, index) => {
                const penaltyFee= item?.penalty_deduction? item.penalty_deduction?.penalty_fee:0
                return (
                  <tr key={"online-payment-list-item" + index}>
                    <td>{index + 1}</td>
                    <td> {moment(item.payment_confirmed_at).format("DD/MM/YYYY")}</td>
                    <td>{item.order_number}</td>
                    <th>{penaltyFee}</th>
                    <td>{item.customer_name}</td>
                    <td>{item.payment_mode}</td>
                    <td>{item.bill_amount}</td>
                    <td></td>
                    <td>{item.status
                        ? Strings.table.received
                        : Strings.table.notReceived}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default OnlinePaymentList;

// Customizable Area End
