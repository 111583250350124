Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  notificationById: "/bx_block_notifications/notifications/",
  editNotification: "/bx_block_notifications/notifications/",
  addNotification: "/bx_block_notifications/notifications",
  notificationList:
    "/bx_block_notifications/notifications/list_sent_notifications",
  listRecipients: "/bx_block_notifications/notifications/list_recipient",
};

exports.Strings = {
  header: {
    titleAdd: "JHC | Add Notification",
    titleEdit: "JHC | Edit Notification",
  },
  actions: {
    send: "Send",
    cancel: "Cancel",
  },
  labels: {
    title: "Title",
    alert: "Alert!",
    success: "Success!",
    recipients: "Recipients",
    description: "Description",
    attachFiles: "Attach file",
    attachments: "Attachment(s)",
    clickToView: "Click to view image",
  },
  messages: {
    notificationAdd: "Notification Added Successfully",
    notificationEdit: "Notification Updated Successfully",
  },
  validation: {
    title: "* Please enter heading",
    recipients: "* Please select recipients",
    description: "* Please enter description",
    attachFiles: "Attach file",
  },
};
