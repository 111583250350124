// Customizable Area Start

import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { IBlock } from "../../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";

import GetApiMessage from "../../GetApiMessage";
import ISuccessResponse from "../../ISuccessResponse";
import ApiResponseMiddleware from "../../ApiResponseMiddleware";

const configJs = require("./config");
const ApiUrls = configJs.ApiUrls;

interface Props {
  onSetStatistics: Function;
}
interface SS {}
interface S {
  currentPage: number;
  lastPage: number;
  orderListMessageId: any;
  orderList: Array<any>;
}

class TrackOrdersController extends BlockComponent<Props, S, SS> {
  state = {
    orderListMessageId: null,
    orderList: [],
    currentPage: 1,
    lastPage: 1,
  };

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
    ]);
  }

  receive(from: string, message: Message): void {
    if (!ApiResponseMiddleware(message)) return;

    const { orderListMessageId } = this.state;

    let requestID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (requestID === orderListMessageId) {
      if (successResponse) {
        const {
          meta,
          in_progress,
          failed_order,
          pending_order,
          completed_task,
        } = successResponse;

        if (meta) {
          this.setState({ lastPage: meta.last_page });
        }

        let data = successResponse?.data;
        if (data)
          // @ts-ignore
          data = data?.map((data: any) => ({
            id: data.id,
            ...data.attributes,
          }));
        // @ts-ignore
        this.setState({ orderList: data || [] });
        this.props.onSetStatistics({
          in_progress,
          failed_order,
          pending_order,
          completed_task,
        });
      }
    }
  }

  componentDidMount(): any {
    this.getOrderData();
  }

  getOrderData(page: number = this.state.currentPage) {
    let requestMessage = GetApiMessage({
      method: "get",
      endpoint: ApiUrls.trackOrders + "?per_page=10&page=" + page,
    });
    this.setState({ orderListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onChangePage(page: number) {
    this.setState({ currentPage: page });
    this.getOrderData(page);
  }
}

export default TrackOrdersController;

// Customizable Area End
