// Customizable Area Start

import React from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
import {
  styled,
  Grid,
  Table,
  Checkbox,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
} from "@material-ui/core";

import { iconAdd, iconEdit } from "../assets";

import StyledHR from "../Shared/StyledHR";
import SearchBar from "../Shared/SearchBar";
import TitleHeading from "../Shared/TitleHeading";
import RoundedButton from "../Shared/RoundedButton";
import RoundedButtonWithIcon from "../Shared/RoundedButtonWithIcon";
import SubscriptionListController from "./SubscriptionController";

import { ISubscriptionListRow } from "./SubscriptionController";
import Pagination from "../Shared/Pagination";

const configJs = require("./config");
const Strings = configJs.Strings;

class SubscriptionPlanList extends SubscriptionListController {
  render() {
    const { subscriptionList,selectedSubscription,lastPage,currentPage } = this.state;
    return (
      <>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Helmet>
              <title>{Strings.header.title}</title>
            </Helmet>
            <TitleHeading text={Strings.header.heading} />
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <RoundedButtonWithIcon
                  data-test-id="btn-editPlan"
                  icon={iconEdit}
                  text={Strings.labels.editPlan}
                  onClick={this.navigateToEditSubscription}
                />
              </Grid>
              <Grid item>
                <RoundedButtonWithIcon
                  data-test-id="btn-addNew"
                  icon={iconAdd}
                  text={Strings.labels.addPlan}
                  onClick={() =>
                    this.props.navigation.navigate("Vendor", {
                      page: "add-subscription-plan",
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <StyledHR />

        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <RoundedButton
                  data-test-id="btn-discounts"
                  text={Strings.labels.discountOffers}
                  isActive={false}
                  onClick={() =>
                    this.props.navigation.navigate("Vendor", {
                      page: "discounts",
                    })
                  }
                />
              </Grid>
              <Grid item>
                <RoundedButton
                  text={Strings.labels.subscriptionPlan}
                  isActive={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <SearchBar data-testid="search-input" onSearch={this.handleOnSearch}/>
          </Grid>
        </Grid>

        <br />
        <StyledTableContainer>
          <Table size="small">
            <StyledTableHead>
              <TableRow>
                <StyledTableHeadCell style={{paddingLeft:"30px"}}>
                  #
                </StyledTableHeadCell>
                <StyledTableHeadCell>{Strings.table.productName}</StyledTableHeadCell>
                <StyledTableHeadCell>{Strings.table.timeDuration}</StyledTableHeadCell>
                <StyledTableHeadCell>{Strings.table.price}</StyledTableHeadCell>
                <StyledTableHeadCell>{Strings.table.date}</StyledTableHeadCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {subscriptionList.length === 0 && (
                <StyledTableRow data-testid="no-sub-found" key={"subscription-item-no-data"}>
                  <TableCell
                    colSpan={5}
                    align="center"
                    valign="middle"
                    style={{ height: 80 }}
                  >
                    No Data Found
                  </TableCell>
                </StyledTableRow>
              )}

              {subscriptionList.map((item: ISubscriptionListRow) => {
                return (
                  <StyledTableRow onClick={()=>this.handleItemClick(item.id)} data-testid="subscription-item" key={"subscription-item-list" + item}>
                    <TableCell>
                      <Checkbox
                         data-testid="sub-checkbox"
                         size="small"
                         checked={selectedSubscription===item.id?true:false}
                         onChange={(e)=>this.handleSelection(e,item.id)}
                         />
                    </TableCell>
                    <TableCell style={{maxWidth:"200px"}}>{item.catalogues?.name}</TableCell>
                    <TableCell>
                      {new Date(item.valid_from.toString()).toLocaleDateString()} to {new Date(item.valid_up_to.toString()).toLocaleDateString()}
                    </TableCell>
                    <TableCell>{item.price}</TableCell>
                    <TableCell>
                      Last Modified {new Date(item?.updated_at?.toString()).toLocaleDateString()}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </StyledTableContainer>
        {subscriptionList.length !== 0 && (
          <Grid container justifyContent="flex-end">
            <Grid item>
              <br />
              <Pagination
                data-test-id="pagination"
                lastPage={lastPage}
                currentPage={currentPage}
                onChangePage={(page: number) => this.onChangePage(page)}
              />
              
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

const StyledTableHead = styled(TableHead)({
  color: "var(--white)",
  textTransform: "uppercase",
  background: "var(--gradient2)",
  "& .MuiCheckbox-root": {
    color: "white",
  },
});

const StyledTableHeadCell = styled(TableCell)({
  fontWeight: 400,
  fontSize: 15,
  color: "white",
});

const StyledTableRow = styled(TableRow)({
  boxShadow: "0 0 5px var(--boxShadow)",
});

const StyledTableContainer = styled(TableContainer)({
  boxShadow: "0px 0px 10px var(--boxShadow)",
});

export default SubscriptionPlanList;

// Customizable Area End
