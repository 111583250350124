// Customizable Area Start
import React from "react";
import moment from "moment";
import {
  styled,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";

import StoreMemberForm from "./StoreMemberForm.web";
import StoreMemberListController from "./StoreMemberListController";

import { iconAdd, iconEdit, iconDelete } from "../../assets";

const configJs = require("./config");
const Strings = configJs.Strings;

class StoreMembers extends StoreMemberListController {
  render() {
    let { addVendor, storeMemberList, isLoading, selectedStoreMemberItemId } =
      this.state;
    const noData = storeMemberList.length === 0;
    const isStoremember=this.props.isStoreMember;
    return (
      <>
        <br />

        <Dialog
          open={selectedStoreMemberItemId ? true : false}
          onClose={() => this.setState({ selectedStoreMemberItemId: false })}
        >
          <DialogContent>
            <h5>{Strings.labels.pleaseConfirm}</h5>
            {Strings.messages.confirmDeleteText}
            <br />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState({ selectedStoreMemberItemId: false })
              }
            >
              {Strings.actions.cancel}
            </Button>
            <Button
              color="secondary"
              onClick={() => this.onDeleteStoreMember()}
            >
              {Strings.actions.yes}
            </Button>
          </DialogActions>
        </Dialog>

        <div className="head">
          <h4 className="title">{Strings.header.heading}</h4>
          <div className="right-container">
            {
              !isStoremember && (
                <button
                    data-test-id="btn-addItem"
                    className="addItem"
                    onClick={() => this.setState({ addVendor: true })}
                  >
                    <img src={iconAdd} alt="icon" /> {Strings.actions.addMember}
                  </button>
              )
            }
            
          </div>
        </div>

        {addVendor && (
          <StoreMemberForm
            {...this.props}
            data-test-id="storeMemberForm"
            refetch={() => this.getMembersList()}
            updateData={this.state.data}
            onClose={() => this.setState({ data: null, addVendor: false })}
          />
        )}

        <div className="inventory-table">
          <table className="table">
            <thead>
              <tr key={"store-member-cols"}>
                <th>{Strings.table.sr}</th>
                <th>{Strings.table.storeMember}</th>
                <th>{Strings.table.firstName}</th>
                <th>{Strings.table.lastName}</th>
                <th>{Strings.table.email}</th>
                <th>{Strings.table.phone}</th>
                <th>{Strings.table.createdAt}</th> 
                {
                  !isStoremember && (
                    <>
                     <th>{Strings.table.active}</th>
                     <th>{Strings.table.action}</th>
                    </>
                  )
                }
               
              </tr>
            </thead>
            {noData && (
              <tbody>
                <tr key={"store-member-no-data"}>
                  <StyledNoDataTd colSpan={9}>
                    {isLoading ? Strings.table.loading : Strings.table.noData}
                  </StyledNoDataTd>
                </tr>
              </tbody>
            )}
            {!noData && (
              <tbody>
                {storeMemberList.map((item: any, index: number) => {
                  const data: any = { id: item?.id, ...item?.attributes };

                  return (
                    <tr key={"store-member-item-" + item.id}>
                      <td>{index + 1}</td>
                      <td>{data?.store_name}</td>
                      <td>{data?.first_name}</td>
                      <td>{data?.last_name}</td>
                      <td>{data?.email}</td>
                      <td>{data?.full_phone_number}</td>
                      <td>
                        {moment(data?.created_at).format("YYYY-MM-DD hh:mm A")}
                      </td>
                      {
                        !isStoremember && (
                          <>
                              <td>
                              <label className="checkbox-container">
                                <input
                                  data-test-id={"btn-active-" + data.id}
                                  onClick={() =>
                                    this.onChangeStatus(
                                      data.id,
                                      data.status === "active"
                                        ? "deactivate"
                                        : "active"
                                    )
                                  }
                                  type="checkbox"
                                  checked={data.status === "active"}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </td>
                            <td>
                              <Grid container spacing={1}>
                                <Grid item>
                                  <img
                                    data-test-id={"btn-edit-" + data.id}
                                    onClick={() =>
                                      this.setState({ data, addVendor: true })
                                    }
                                    src={iconEdit}
                                    style={{ cursor: "pointer" }}
                                  />
                                </Grid>
                                <Grid item>
                                  <img
                                    data-test-id={"btn-delete-" + data.id}
                                    onClick={() =>
                                      this.setState({
                                        selectedStoreMemberItemId: data.id,
                                      })
                                    }
                                    src={iconDelete}
                                    style={{ cursor: "pointer", width: 23 }}
                                  />
                                </Grid>
                              </Grid>
                            </td>
                          </>
                        )
                      }
                    
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </>
    );
  }
}

const StyledNoDataTd = styled("td")({
  height: 80,
  textAlign: "center",
  verticalAlign: "middle",
});

export default StoreMembers;

// Customizable Area End
