// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";

import ApiResponseMiddleware from "../ApiResponseMiddleware";
import ISuccessResponse from "../ISuccessResponse";
import GetApiMessage from "../GetApiMessage";

const configJs = require("./config");
const ApiUrls = configJs.ApiUrls;

interface Props {}
interface State {
  // logical
  selectAction: any;
  deleteModalFlag: any;

  // pagination
  currentPage: any;
  lastPage: any;

  // lists
  notificationList: Array<any>;
  selectedNotifications: Array<any>;

  // message id
  notificationListMessageId: any;
  readNotificationMessageId: any;
  deleteNotificationMessageId: any;
}
interface SS {}

class NotificationsController extends BlockComponent<Props, State, SS> {
  state = {
    // logical
    selectAction: "",
    deleteModalFlag: false,

    // pagination
    currentPage: 1,
    lastPage: 1,

    // lists
    selectedNotifications: [],
    notificationList: [],

    // message ids
    notificationListMessageId: null,
    readNotificationMessageId: null,
    deleteNotificationMessageId: null,
  };

  constructor(props: Props) {
    super(props);

    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
    ]);

    this.receive = this.receive.bind(this);
  }

  componentDidMount(): any {
    this.getNotification();
  }

  getNotification(page: number = this.state.currentPage) {
    let requestMessage = GetApiMessage({
      endpoint: ApiUrls.notificationList + "?per_page=10&page=" + page,
    });
    this.setState({ notificationListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  receive(from: string, message: Message): void {
    if (!ApiResponseMiddleware(message)) return;

    const {
      notificationListMessageId,
      readNotificationMessageId,
      deleteNotificationMessageId,
    } = this.state;

    let requestID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (requestID === notificationListMessageId) {
      if (successResponse) {
        const { data, meta } = successResponse;
        let newArray: any;
        if (data) {
          newArray = data?.map((item: any) => {
            return { id: item.id, ...item.attributes };
          });
        }

        this.setState({
          notificationList: newArray || [],
          lastPage: meta?.last_page || 1,
        });
      }
    }

    if (
      [readNotificationMessageId, deleteNotificationMessageId].includes(
        requestID
      )
    ) {
      if (successResponse){
        this.setState({selectedNotifications:[],selectAction:""})
        this.getNotification();
      }
    }
  }

  onSelectNotification(id: any) {
    let newArray: Array<number> = this.state.selectedNotifications;

    if (newArray.indexOf(id) === -1) newArray.push(id);
    else newArray = newArray.filter((no) => no !== id);

    this.setState({ selectedNotifications: newArray, selectAction: "" });
  }

  onChangeAction(action: any) {
    this.setState({
      selectAction: action,
    });

    if (action === "read") this.onMarkReadNotifications(true);
    if (action === "unread") this.onMarkReadNotifications(false);
    else if (action === "delete") this.onMarkDeleteNotifications();
  }

  onMarkReadNotifications(isRead: boolean) {
    const { selectedNotifications } = this.state;

    let form = new FormData();
    // @ts-ignore
    form.set("is_read", isRead);

    let requestMessage = GetApiMessage({
      method: "put",
      data: form,
      endpoint:
        ApiUrls.readNotifications + `[${selectedNotifications.join(",")}]`,
    });
    this.setState({ readNotificationMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onMarkDeleteNotifications(ids: any = this.state.selectedNotifications) {
    let requestMessage = GetApiMessage({
      method: "delete",
      endpoint: ApiUrls.deleteNotifications + `[${ids.join(",")}]`,
    });
    this.setState({ readNotificationMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onChangePage(page: number) {
    this.setState({
      currentPage: page,
    });
    this.getNotification(page);
  }

  onClearAll() {
    const { notificationList } = this.state;
    let ids = notificationList.map((item: any) => item.id);
    this.setState({ selectedNotifications: ids });
    this.onMarkDeleteNotifications(ids);
  }
}

export default NotificationsController;

// Customizable Area End
