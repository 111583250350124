import { configureStore, CombinedState } from "@reduxjs/toolkit";
import rootReducer, { IRootReducer } from "./reducers";

const store: CombinedState<any> = configureStore({
  reducer: rootReducer,
});

export interface IReduxState extends IRootReducer {}

export default store;
