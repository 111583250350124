// Customizable Area Start

import React from "react";
import moment from "moment";

import PaymentController from "../PaymentsController";
import NoDataTdDefault from "../../Shared/NoDataTd";

const configJs = require("./config");
const Strings = configJs.Strings;

class CashOnDeliveryList extends PaymentController {
  render() {
    const { codPaymentList } = this.state;
    return (
      <>
        <div className="inventory-table mt-2">
          <table className="table">
            <thead>
              <tr>
                <th>{Strings.table.no}</th>
                <th>{Strings.table.date}</th>
                <th>{Strings.table.orderNumber}</th>
                <th>{Strings.table.customerName}</th>
                <th>{Strings.table.receiverName}</th>
                <th>{Strings.table.cod}</th>
                <th>{Strings.table.billAmount}</th>
                <th>{Strings.table.status}</th>
              </tr>
            </thead>
            <tbody>
              {codPaymentList.length === 0 && (
                <tr>
                  <NoDataTdDefault colSpan={8}>
                    {Strings.table.noData}
                  </NoDataTdDefault>
                </tr>
              )}

              {codPaymentList.map((item: any, index) => {
                const { currentPage } = this.state;
                return (
                  <tr key={item?.id}>
                    <td>{index + (currentPage - 1) * 10}</td>
                    <td>
                      {moment(item?.payment_confirmed_at).format("DD/MM/YYYY")}
                    </td>
                    <td>{item?.order_number}</td>
                    <td>{item?.customer_name}</td>
                    <td>{item?.receiver_name}</td>
                    <td>
                      <div>{item?.payment_mode}</div>
                      <small>(Received At 1:00 PM)</small>
                    </td>
                    <td>{item?.bill_amount}</td>
                    <td>
                      {item?.status
                        ? Strings.table.received
                        : Strings.table.notReceived}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default CashOnDeliveryList;

// Customizable Area End
