Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  editProfile: "/accounts/edit_profile",
  viewProfile: "/accounts/show_profile",
  removePhoto: "/accounts/remove_photo",
};

exports.Strings = {
  header: {
    title: "JHC | Profile",
    heading: "Profile Setting",
    deactivateAccountTitle: "Deactivate Account!",
  },
  labels: {
    pleaseConfirm: "Please Confirm!",
    alert: "Alert!",
    deactivateAccount: "Deactivate Account",
    loading: "Loading...",
    firstName: "First Name",
    lastName: "Last Name",
    emailAddress: "Email Address",
    userApproval: "User Approval",
    password: "Password",
    phone: "Phone Number",
    dob: "Date of Birth",
    address: "Address",
    city: "City",
    state: "State",
    pinCode: "Pin code",
    photoFromGallery: "Photo from Gallery",
    removePhoto: "Remove Photo",
  },
  actions: {
    save: "Save",
    edit: "Edit",
  },
  message: {
    deactivateConfirmText: "Are you sure you want to deactivate your account?",
    deactivateAccountText:
      "Your account is deactivated successfully and you are redirecting to login screen!",
  },
};
