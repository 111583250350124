Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProductDescription";
exports.labelBodyText = "ProductDescription Body";

exports.btnExampleTitle = "CLICK ME";
exports.productDetailAPIURL = "/bx_block_catalogue/catalogues/";
exports.addToCartAPIURL = "/bx_block_order_management/orders";
exports.featuredItemsAPIURL = "/bx_block_catalogue/catalogues/featured?page=1&per_page=10";
exports.customerReviwedAPIURL = "/bx_block_catalogue/catalogues/other_customer_viewd_item?catalogue_id=";
exports.relatedProductsAPIURL = "/bx_block_catalogue/catalogues/same_product?catalogue_id=";
exports.buyItAgainAPIURL = "/bx_block_catalogue/catalogues/buy_it_again?page=1&per_page=10";
exports.frequantlyRepurchasedAPIURL = "/bx_block_catalogue/catalogues/deals_on_frequently_purchased_item?page=1&per_page=10";
// Customizable Area End
