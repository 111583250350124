import getActionString from "./GetActionString";

const prefix = "cart";

export interface iActions {
  SAVE_COUNT: string;
  REFETCH: string;
  RESET: string;
}

const actions: iActions = {
  SAVE_COUNT: getActionString(prefix, "save_count"),
  REFETCH: getActionString(prefix, "refetch"),
  RESET: getActionString(prefix, "reset"),
};

export default actions;
