// Customizable Area Start

import React from "react";
import { Box, Typography, styled, Grid } from "@material-ui/core";

import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";

import Button from "../Shared/RoundedButton";

const configJs = require("./config");
const Strings = configJs.Strings;

interface Props {}
interface State {
  isOpen: boolean;
  title: string;
  description: string;
}
interface SS {}

class AlertMessage extends BlockComponent<Props, State, SS> {
  state = {
    title: "",
    description: "",
    isOpen: false,
  };

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.AlertBodyMessage),
      getName(MessageEnum.AlertTitleMessage),
    ]);
  }

  receive(from: string, message: Message): void {
    if (message.id === getName(MessageEnum.AlertMessage)) {
      let title: string = message.getData(
        getName(MessageEnum.AlertTitleMessage)
      );
      let description: string = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.setState({ title, description });
      this.onOpen();

      setTimeout(() => {
        this.onClose();
      }, 8000);
    }
  }

  onOpen() {
    this.setState({ isOpen: true });
  }

  onClose() {
    this.setState({ isOpen: false, title: "", description: "" });
  }

  render() {
    const { title, description } = this.state;

    if (!this.state.isOpen) return <></>;

    return (
      <FullScreenWrapper>
        <AlertBox>
          <Grid container justifyContent="space-between">
            <Grid item>
              <AlertTitle data-test-id="alertHeading">{title}</AlertTitle>
            </Grid>
            <Grid item>
              <AlertCloseText
                data-test-id="btn-close"
                onClick={() => this.onClose()}
              >
                &times;
              </AlertCloseText>
            </Grid>
          </Grid>

          <AlertBodyText data-test-id="alertContent">
            {description}
          </AlertBodyText>
          <br />
          <br />
          <Grid container spacing={1} justifyContent="center">
            <Grid item>
              <Button
                data-test-id="btn-ok"
                text={Strings.ok}
                isActive={true}
                onClick={() => this.onClose()}
              />
            </Grid>
          </Grid>
        </AlertBox>
      </FullScreenWrapper>
    );
  }
}

const AlertBox = styled(Box)({
  position: "fixed",
  top: "50%",
  left: "50%",
  padding: 16,
  minWidth: 300,
  maxWidth: 600,
  borderRadius: 9,
  backgroundColor: "white",
  transform: "translate(-50%,-50%)",
  boxShadow: "0px 0px 10px var(--boxShadow)",
});

const FullScreenWrapper = styled(Box)({
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 2000,
  position: "fixed",
  backgroundColor: "rgba(0,0,0,0.3)",
});

const AlertTitle = styled(Typography)({
  fontSize: 20,
  fontWeight: 700,
  color: "var(--navy)",
  textTransform: "capitalize",
});

const AlertCloseText = styled(Typography)({
  fontSize: 26,
  marginTop: -7,
  cursor: "pointer",
  color: "var(--navy)",
});

const AlertBodyText = styled(Typography)({
  fontSize: 16,
});

export default AlertMessage;

// Customizable Area End
