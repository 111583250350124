// Customizable Area Start
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { IBlock } from "../../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";

import phoneValidator from "phone";
import GetApiMessage from "../../GetApiMessage";
import ISuccessResponse from "../../ISuccessResponse";
import ApiResponseMiddleware from "../../ApiResponseMiddleware";
import GetErrorMessages from "../../GetErrorMessageFromResponse";
import GetApiErrorsWithKeys from "../../GetApiErrorsWithKeys";
import BlockHelpers from "../../../../utilities/src/BlockHelpers";

const configJs = require("./config");
const Strings = configJs.Strings;
const ApiUrls = configJs.ApiUrls;
const MappedStoreMembersKey = configJs.MappedStoreMembersKey;

interface Props {
  onClose: Function;
  navigation: {
    navigate: Function;
  };
  refetch: Function;
  updateData: any;
}
interface SS {}
interface S {
  count: number;
  isEdit: boolean;
  saveMessageId: string;
  updateMessageId: string;
  data: {
    id: number | null;
    store_member: string;
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    role: string;
  };
  validation: {
    store_member: string;
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    role: string;
  };
}

class StoreMemberFormController extends BlockComponent<Props, S, SS> {
  state = {
    count: 0,
    isEdit: false,
    saveMessageId: "",
    updateMessageId: "",
    data: {
      id: null,
      store_member: "",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      role: "",
    },
    validation: {
      store_member: "",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      role: "",
    },
  };

  constructor(props: Props) {
    super(props);

    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
    ]);

    this.receive = this.receive.bind(this);
  }

  receive(from: string, message: Message): void {
    if (!ApiResponseMiddleware(message)) return;

    const { saveMessageId, updateMessageId } = this.state;

    let requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (successResponse) {
      if (requestId === saveMessageId) {
        let errorObjects = GetApiErrorsWithKeys(
          successResponse,
          MappedStoreMembersKey
        );
        if(successResponse?.message && successResponse?.message?.length>0){
          const message=successResponse?.message[0]?.error;
          BlockHelpers.showAlert(
            Strings.labels.error,
            message
          );
        }
        if (errorObjects) {
          this.setState({ validation: errorObjects });
        } else {
          BlockHelpers.showAlert(
            Strings.labels.success,
            Strings.messages.storeMemberAdded
          );
          this.onReset();
        }
      }

      if (requestId === updateMessageId) {
        let errorObjects = GetApiErrorsWithKeys(
          successResponse,
          MappedStoreMembersKey
        );
        if(successResponse?.message && successResponse?.message?.length>0){
          const message=successResponse?.message[0]?.error;
          BlockHelpers.showAlert(
            Strings.labels.error,
            message
          );
        }
        if (errorObjects) {
          this.setState({ validation: errorObjects });
         
        } else {
          BlockHelpers.showAlert(
            Strings.labels.success,
            Strings.messages.storeMemberUpdated
          );
          this.props.refetch();
          this.props.onClose();
        }
      }
    } else if (errorResponse) {
      if (requestId === saveMessageId) {
        let messages: string = GetErrorMessages(errorResponse);
        BlockHelpers.showAlert(Strings.labels.error, messages);
      }
    }
  }

  componentDidMount(): any {
    let isEdit = this.props.updateData !== null;
    let newData = this.props.updateData;

    this.setState({ isEdit });

    if (isEdit) {
      this.setState({
        data: {
          ...newData,
          role: "supervisor",
          store_member: newData.store_name,
          phone: newData.full_phone_number,
        },
      });

      this.setState((prev: S) => ({ count: prev.count + 1 }));
    }
  }

  onReset() {
    this.setState({
      data: {
        id: null,
        store_member: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        role: "",
      },
      validation: {
        store_member: "",
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        role: "",
      },
    });
    this.props.onClose();
    // this.props.navigation.navigate("Home");
  }

  onChangeValues(key: string, value: string) {
    this.setState({
      data: {
        ...this.state.data,
        [key]: value,
      },
    });
    this.setState((prev: S) => ({ count: prev.count + 1 }));
  }

  isValid() {
    let errors = {};
    // @ts-ignore
    const saveErrors = (key: string, value: string) => (errors[key] = value);

    const { store_member, first_name, last_name, email, role, phone } =
      this.state.data;

    if (!store_member)
      saveErrors("store_member", Strings.validation.storeMember);
    if (!first_name) saveErrors("first_name", Strings.validation.firstName);
    if (!last_name) saveErrors("last_name", Strings.validation.lastName);
    if (!role) saveErrors("role", Strings.validation.role);

    if (!email) saveErrors("email", Strings.validation.email);
    else {
      let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i;
      if (!regex.test(email))
        saveErrors("email", Strings.validation.validEmail);
    }

    if (!phone) saveErrors("phone", Strings.validation.phone);
    // @ts-ignore
    this.setState({ validation: errors });

    return !(Object.keys(errors).length > 0);
  }

  onSubmit() {
    if (!this.isValid()) return;

    const isEdit = this.state.isEdit;

    const { id, store_member, first_name, last_name, email, phone, role } =
      this.state.data;

    let form = new FormData();
    form.set("role", role);
    form.set("email", email);
    form.set("full_phone_number", phone);
    form.set("status", "active");
    form.set("last_name", last_name);
    form.set("first_name", first_name);
    form.set("store_name", store_member);

    let requestMessage: any = null;

    if (!isEdit) {
      requestMessage = GetApiMessage({
        endpoint: ApiUrls.addStoreMember,
        method: "post",
        data: form,
      });

      this.setState({ saveMessageId: requestMessage.messageId });
    } else {
      requestMessage = GetApiMessage({
        endpoint: ApiUrls.updateStoreMember + id,
        method: "put",
        data: form,
      });

      this.setState({ updateMessageId: requestMessage.messageId });
    }

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

export default StoreMemberFormController;

// Customizable Area End
