import React from "react";
import { Skeleton } from "@material-ui/lab";
import { styled, Typography, Grid } from "@material-ui/core";

import FooterPagesController from "./FooterPagesController";

export const configJSON = require("./config");

class FooterPages extends FooterPagesController {
  render() {
    const { isLoading, heading, content } = this.state;

    return (
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12}>
          <Heading data-test-id="heading">
            {isLoading ? (
              <StyledSkeleton animation="wave" variant="text" />
            ) : (
              heading
            )}
          </Heading>
        </Grid>
        <Grid item xs={10}>
          <Container>
            {isLoading ? (
              <>
                <Skeleton animation="wave" variant="text" />
                <br />
                <Skeleton animation="wave" variant="text" />
                <br />
                <Skeleton animation="wave" variant="text" />
                <br />
                <Skeleton animation="wave" variant="text" />
              </>
            ) : (
              <>
                <SecondHeading>{heading}</SecondHeading>
                <div
                  data-test-id="content"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </>
            )}
            <br />
            <br />
            <br />
          </Container>
        </Grid>
      </Grid>
    );
  }
}

const StyledSkeleton = styled(Skeleton)({
  width: "35%",
  display: "inline-block",
});

const Container = styled("div")({
  minHeight: 400,
  color: "rgb(148,148,148)",
});

const Heading: any = styled(Typography)({
  fontSize: 42,
  color: "white",
  marginBottom: 32,
  fontWeight: "bold",
  padding: "42px 0px",
  textAlign: "center",
  textTransform: "uppercase",
  background:
    "linear-gradient(189deg, rgba(0,110,184,1) 0%, rgba(2,101,167,1) 100%)",
});

const SecondHeading = styled(Typography)({
  fontSize: 32,
  marginBottom: 16,
  fontWeight: "bold",
  color: "rgb(60,60,60)",
});

export default FooterPages;
