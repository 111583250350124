Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  showMenu: "/bx_block_filter_items/filters/categories",
};

exports.Strings = {
  header: {
    title: "JHC | Menu",
    heading: "Menu",
  },
  table: {
    category: "category",
    productId: "product id",
    noData: "Data not found",
    subCategory: "Sub Category",
    productName: "product name",
  },
};
