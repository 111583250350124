Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  checkLinkExpiration: "/account_block/store_members/check_expiration_time",
  storeMemberSignUp: "/account_block/store_members/",
};

exports.Strings = {
  labels: {
    step1: "Step 1",
    btnSignUp: "Sign Up",
    thankYou: "Thank You",
    btnContinue: "Continue",
    enterPassword: "Enter Password",
    enterLastName: "Enter Last Name",
    enterFirstName: "Enter First Name",
    enterConfirmPassword: "Enter Confirm Password",
    accountUnderReview:
      "You account is under review please allow 24 to 48 hours to receive an email for confirmation",
  },
  validation: {
    tokenError: "Token is expired!",
    firstNameRequire: "First name is required",
    firstNameValid: "Please enter valid first name",
    lastNameRequire: "Last name is required",
    lastNameValid: "Please enter valid last name",
    passwordRequire: "Password is required",
    passwordValid:
      "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.",
    confirmPasswordRequire: "Confirm Password is required",
    confirmPasswordMatch: "Both password should match",
  },
};
