// Customizable Area Start

import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  styled,
  Dialog,
  Button,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import ProfileSettingsController from "./ProfileSettingsController";

import { iconUserProfile, iconEdit, iconGalleryDark } from "../assets";

const configJs = require("./config");
const Strings = configJs.Strings;

class ProfileSetting extends ProfileSettingsController {
  render() {
    const {
      isApproved,
      firstName,
      lastName,
      address,
      city,
      dob,
      email,
      password,
      phone,
      pinCode,
      state,
      isStoreMember
    } = this.state.profileData;

    return (
      <div data-test-id="profile-screen" className="inventory">
        <Helmet>
          <title>{Strings.header.title}</title>
        </Helmet>

        <Dialog
          open={this.state.confirmDelete}
          title={Strings.header.deactivateAccountTitle}
          onClose={() => this.setState({ confirmDelete: false })}
        >
          <DialogContent>
            <h5>{Strings.labels.pleaseConfirm}</h5>
            {Strings.message.deactivateConfirmText}
            <br />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ confirmDelete: false })}>
              Cancel
            </Button>
            <Button
              color="secondary"
              onClick={() => this.onDeactivateAccount()}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <div className="head">
          <h4 className="title">{Strings.header.heading}</h4>
        </div>

        {this.state.isLoading ? (
          <h4>{Strings.labels.loading}</h4>
        ) : (
          <>
            <div className="userInfo">
              <div className="imageBox">
                <img
                  className="userProfile"
                  src={this.state.profileImage ?? iconUserProfile}
                  alt="user Profile"
                />
                {
                  !isStoreMember && (
                    <EditIconStyled
                      data-test-id="btn-edit"
                      className="edit-profile"
                      onClick={() => this.editImage()}
                      src={iconEdit}
                      alt="edit Profile"
                    />
                  )
                }
                
              </div>
              <div className="w-75">
                <div className="row">
                  <div className="col-sm-6">
                    <label className="vender-label">
                      {Strings.labels.firstName} <RedLabel>*</RedLabel>
                    </label>
                    <input
                      readOnly
                      value={firstName}
                      type="text"
                      className="vender-inputs"
                    />
                  </div>
                  <div className="col-sm-6">
                    <label className="vender-label">
                      {Strings.labels.lastName} <RedLabel>*</RedLabel>
                    </label>
                    <input
                      readOnly
                      value={lastName}
                      type="text"
                      className="vender-inputs"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <label className="vender-label">
                      {Strings.labels.emailAddress} <RedLabel>*</RedLabel>
                    </label>
                    <input
                      readOnly
                      type="text"
                      value={email}
                      className="vender-inputs"
                    />
                  </div>
                  <div className="col-sm-6">
                    <label className="vender-label">
                      {Strings.labels.userApproval}
                    </label>
                    <input
                      readOnly
                      value={isApproved}
                      type="text"
                      className="vender-inputs"
                    />
                  </div>
                </div>

                <label className="vender-label">
                  {Strings.labels.password}
                </label>
                <input
                  readOnly
                  value={password}
                  type="text"
                  className="vender-inputs mb-0"
                  placeholder="******"
                />
              </div>
            </div>
            <div className="row mt-3 mb-4">
              <div className="col-sm-6">
                <label className="vender-label">
                  {Strings.labels.phone} <RedLabel>*</RedLabel>
                </label>
                <input
                  readOnly
                  value={phone}
                  type="text"
                  className="vender-inputs"
                />
              </div>
              <div className="col-sm-6">
                <label className="vender-label">{Strings.labels.dob}</label>
                <input
                  readOnly
                  value={dob}
                  type="date"
                  className="vender-inputs"
                />
              </div>
              <div className="col-sm-6">
                <label className="vender-label">{Strings.labels.address}</label>
                <input
                  readOnly
                  value={address}
                  type="text"
                  className="vender-inputs"
                />
              </div>
              <div className="col-sm-6">
                <label className="vender-label">{Strings.labels.city}</label>
                <input
                  readOnly
                  value={city}
                  type="text"
                  className="vender-inputs"
                />
              </div>
              <div className="col-sm-6">
                <label className="vender-label">{Strings.labels.state}</label>
                <input
                  readOnly
                  value={state}
                  type="text"
                  className="vender-inputs"
                />
              </div>
              <div className="col-sm-6">
                <label className="vender-label">{Strings.labels.pinCode}</label>
                <input
                  readOnly
                  value={pinCode}
                  type="text"
                  className="vender-inputs"
                />
              </div>
            </div>
            {!isStoreMember && (<div className="buttonBox justify-content-start">
              <Link
                to="/vendor/edit-profile"
                className="buttons blank"
                type="button"
              >
                {Strings.actions.edit}
              </Link>
              <button
                onClick={() => this.setState({ confirmDelete: true })}
                className="buttons blank"
                type="button"
              >
                {Strings.labels.deactivateAccount}
              </button>
            </div>)}
            {this.state.profileEdit && (
              <div className="editProfileImage">
                <div className="icon-box">
                  <label htmlFor="uploadProfilePhoto" className="title">
                    <img className="icon" src={iconGalleryDark} alt="gallery" />
                    <div>{Strings.labels.photoFromGallery}</div>
                  </label>
                  <input
                    data-test-id="field-profilePhoto"
                    onChange={(e) => this.onUploadImage(e.target.files)}
                    hidden
                    type="file"
                    accept=".jpeg, .jpg, .png"
                    id="uploadProfilePhoto"
                  />
                </div>
                <div
                  data-test-id="btn-removePhoto"
                  onClick={() => this.onRemoveProfileImageApi()}
                  className="icon-box"
                >
                  <img className="icon" src={iconGalleryDark} alt="gallery" />
                  <h6 className="title">{Strings.labels.removePhoto}</h6>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

const RedLabel = styled("span")({
  color: "red",
});

const EditIconStyled = styled("img")({
  boxShadow: "0px 0px 5px var(--boxShadow)",
});

export default ProfileSetting;

// Customizable Area End
