// Customizable Area Start
import React  from 'react';
import { Link } from 'react-router-dom';
import { BlockComponent } from '../../../framework/src/BlockComponent';
interface Props {
  type: string;
  navigation: {getParam: Function};
}
interface SS { }
interface S { }
class Success extends BlockComponent<Props, S, SS>{
  constructor(props: Props) {
    super(props);
  }
  render() {
    const type = this.props.navigation.getParam('type');
    return (
        <div className='container py-4'>
            <div className='order-status failed'>                      
                {/* for failed */}
                <div className='success'><img src='/frontend/remove.png' /></div>
                <h4 className='heading'>Failed!</h4>
                <h6 className='fw-normal'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h6>
                <hr className='my-4'/>
                <h6 className='fw-normal space-height'>Lorem ipsum dolor sit amet,</h6>
                <div className='d-flex justify-content-between'>
                    <Link to={'/checkout/'+ type} className="filled-btn">Try Again</Link>
                    <Link to='/cart' className="border-btn">Back to Cart</Link>
                </div>
            </div>
            <p className='mt-5 text-center'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
    )
  }
}


export default Success;
// Customizable Area End
