import React from "react";
// Customizable Area Start
import {Link} from "react-router-dom";
// Customizable Area End

import Moment from "moment";
// import {WebView} from "react-native-webview"
import OrdermanagementController, {
  Props,
  //   configJSON
} from "./OrdermanagementController";
// //@ts-ignore
// import StarRating from "react-native-star-rating";

export default class Details extends OrdermanagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { itemDetail } = this.state;
    //Customizable Area End
    return (
        <>
         
        <div className='frontContent py-4 px-5'>
            <div className='breadcrumbs'>
                <Link to='/home' className='breadcrumb-link'>Home </Link>&nbsp;&nbsp; &#187; &nbsp;&nbsp; <Link to='/orders' className='breadcrumb-link'>My Order Details </Link> &nbsp;&nbsp; &#187; &nbsp;&nbsp;<span className='breadcrumb-text'> Order Details</span>
            </div>            
            <div className='title-box mb-0'>
                <h4 className='title'>Order Details</h4>                               
            </div>
            <div className='title-box'>
                <h6 className='m-0'>Order On {Moment(itemDetail?.attributes?.order_date).format('DD MMMM YYYY')} &nbsp;&nbsp;&nbsp; Order #{itemDetail?.attributes?.order_number}</h6>  
                <Link to={'/invoice/' + itemDetail?.id} className='blueLinks mb-0 text-decoration'>Invoice</Link>                             
            </div>
            <div className='order-flex-box mb-3'>                
                <div className='order-flex-column2 w-100'>
                    <div className='flex-box'>
                        <div className='column'>
                            <h6 className='m-0'>SHIPPING ADDRESS</h6>
                            <p className='m-0'>{itemDetail?.attributes?.delivery_address}</p>
                        </div>
                        <div className='column'>
                            <h6 className='m-0'>PAYMENT METHOD</h6>
                            <p className='m-0'>{itemDetail?.attributes?.payment_type === 'cash_on_delivery' ? 'Cash On Delivery' : 'Online Payment'}</p>
                        </div>
                        <div className='column'>
                            <h6 className='m-0'>ORDER SUMMARY</h6>
                            <table>
                                <tbody>
                                    <tr>
                                        <td  className='column'>Item(s) Subtotal: </td>
                                        <td>AED {itemDetail?.attributes?.sub_total}</td>
                                    </tr>
                                    <tr>
                                        <td  className='column'>Shipping: </td>
                                        <td>AED 0.00</td>
                                    </tr>
                                    <tr>
                                        <td  className='column'>Total: </td>
                                        <td>AED {itemDetail?.attributes?.total}</td>
                                    </tr>
                                    <tr>
                                        <th  className='column'>Grand Total : </th>
                                        <td>AED {itemDetail?.attributes?.total}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {itemDetail?.attributes?.order_items.length > 0 && itemDetail?.attributes?.order_items?.map((result: any, i: number) => (
                <div className='order-flex-box mb-3'>
                    <div className='order-flex-column1'>
                        <img className='w-100 h-100' src={result?.attributes?.catalogue?.attributes?.images?.length > 0 ? result?.attributes?.catalogue?.attributes?.images[0]?.url : '/frontend/product1.png'} alt='product' />
                    </div>
                    <div className='order-flex-column2'>
                        <div className='flex-box'>
                            <div className='column'>
                                <h6 className='m-0'>Delivered Thursday</h6>
                                <p className='m-0'>{result?.attributes?.catalogue?.attributes?.name}</p>
                                <p className='m-0'>Sold By : {itemDetail?.attributes?.sold_company_name}</p>
                                <p className='m-0'>Total : AED {result?.attributes?.total_price}</p>
                                {result?.attributes?.catalogue?.attributes?.discount && (<p className='m-0'>Discount : {result?.attributes?.catalogue?.attributes?.discount}</p>)}
                                {result?.attributes?.catalogue?.attributes?.taxes && (<p className='m-0'>Taxes : {result?.attributes?.catalogue?.attributes?.taxes}</p>)}
                                <p className='m-0'>Customer Name : {itemDetail?.attributes?.account?.name}</p>
                                <p className='m-0' style={{ textTransform: 'capitalize'}}>Product Status : {result?.attributes?.status}</p>
                            </div>
                            <div className='column'>
                                <Link to='/order_details/order_detail' className='blueLinks mb-0 text-decoration'>Write a product review</Link>
                                <Link to='/track' className='blueLinks mb-0 text-decoration'>Track Package</Link>
                            </div>
                        </div>
                        <div className='flex-box'>
                            <div className='column'></div>
                            <div className='column'>
                                <Link to='/' className='blueLinks text-decoration mb-0'>Active Order</Link>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
        
        </>
    );
  }
}
// Customizable Area End
