// Customizable Area Start
import React, { Component, ChangeEvent } from "react";
import { styled } from "@material-ui/core";
import {
  Pagination,
  PaginationItem,
  PaginationRenderItemParams,
} from "@material-ui/lab";

interface Props {
  count: number;
  onChange: Function;
}
interface State {
  totalPage: number | null | undefined;
  currentPage: number;
}
interface SS {}

interface LinkProps extends PaginationRenderItemParams {
  right: number;
  left: number;
  text: string;
}

class PaginationWeb extends Component<Props, State, SS> {
  state = {
    totalPage: 1,
    currentPage: 1,
  };

  componentDidUpdate(): void {
    const { count } = this.props;
    const { totalPage } = this.state;
    if (totalPage !== count) this.setState({ totalPage: this.props.count });
  }

  componentDidMount(): void {
    this.componentDidUpdate();
  }

  render() {
    const { totalPage } = this.state;
    if (!totalPage || totalPage <= 1) return <></>;
    return (
      <StyledPagination
        data-test-id="pagination"
        size="small"
        color="primary"
        count={totalPage}
        renderItem={(item: PaginationRenderItemParams) => {
          const { type } = item;
          if (type === "previous")
            return (
              <StyledArrow {...item} left={0} right={10} text={"Previous"} />
            );
          else if (type === "next")
            return <StyledArrow {...item} left={10} right={0} text={"Next"} />;
          else return <PaginationItem {...item} />;
        }}
        onChange={(e: ChangeEvent<any>, page: number) =>
          this.props?.onChange(page)
        }
      />
    );
  }
}

const StyledArrow = (props: LinkProps) => (
  <StyledLink {...props}>{props.text}</StyledLink>
);

const StyledLink: any = styled("div")((props: LinkProps) => ({
  cursor: "pointer",
  userSelect: "none",
  fontSize: "0.875rem",
  marginLeft: props.left,
  marginRight: props.right,
  pointerEvents: props.disabled ? "none" : "auto",
  color: props.disabled ? "unset" : "var(--navy)",
}));

const StyledPagination: any = styled(Pagination)({
  "& .MuiPaginationItem-textPrimary.Mui-selected": {
    backgroundColor: "var(--navy) !important",
  },
});

export default PaginationWeb;

// Customizable Area End
