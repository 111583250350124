// Customizable Area Start
interface iResponse {
  status?: number;
  message?: any;
  data?: {
    type?: string;
    attributes?: {
      errors?: any;
    };
  };
  error?: any;
}

const GetErrorMessages = (
  response: any | iResponse,
  no422Check: boolean = false
): string | any => {
  const { status, message, data, error } = response;
  if (status === 500) {
    return "Internal Server Error";
  }
  if (status === 422) {
    if (Array.isArray(message)) return message.join(", ");
  } else if (!no422Check && data?.type === "error") {
    // @ts-ignore
    let errors = data?.attributes?.errors;
    let messages: Array<any> = [];
    Object.keys(errors).map((key: any) => {
      let item = errors[key];
      if (Array.isArray(item)) {
        item.map((msg) => messages.push(msg));
      } else {
        messages.push(item);
      }
    });
    return messages.join(", ");
  } else if (error) {
    let messages: Array<any> = [];
    Object.keys(error).forEach((key: string) => {
      if (Array.isArray(error[key])) {
        error[key].forEach((msg: string) => {
          messages.push(msg);
        });
      } else messages.push(error[key]);
    });
    return messages.join(", ");
  } else {
    return "";
  }
};

export default GetErrorMessages;
// Customizable Area End
