// Customizable Area Start
import React from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import { BlockComponent } from "../../../framework/src/BlockComponent";
interface Props {
}
interface SS {}
interface S {
  order: any;
}
class Success extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    const getOrder = localStorage.getItem('order');
    this.state = {
      order: getOrder ? JSON.parse(getOrder) : {}
    }
  }
  render() {
    const {order} = this.state;
    if(!order?.attributes?.order_number) {return  false;}
    return (
      <div className="container py-4">
        <div className="order-status">
          <div className="row mb-0 p-0 align-items-center">
            <div className="col-md-7 mb-3 mb-md-0">
              <div className="column-1">
                {/* for success */}
                <div className="success">
                <img src='/frontend/check.png' />
                </div>
                <h4 className="heading">Success!</h4>
                <p className='order-msg'>
                  Your order is successfully placed. You will be able to see the status of you order from "Track Order"
                </p>
                <hr />
                <div className="d-flex justify-content-between mb-5 mt-4">
                  <div className="text-start w-60">
                    <h6 className="fw-normal mb-0">Order ID</h6>
                    <h6 className="fw-normal mb-0">#{order.attributes.order_number}</h6>
                  </div>
                  <div className="text-end">
                    <h6 className="fw-normal mb-0">Date</h6>
                    <h6 className="fw-normal mb-0">{Moment(order.attributes.order_date).format('DD MMMM, YYYY')}</h6>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-5">
                  <div className="text-start w-60">
                    <h6 className="fw-normal mb-0">Shipping Address</h6>
                    <h6 className="fw-normal mb-0">
                      {order.attributes.delivery_address}
                    </h6>
                  </div>
                  <div className="text-end">
                    <h6 className="fw-normal mb-0">Payment Method</h6>
                    <h6 className="fw-normal mb-0">{order.attributes.payment_type === 'cash_on_delivery' ? 'Cash On Delivery' : 'Online Payment'}</h6>
                  </div>
                </div>
                {/* for success  */}
                <div className="d-flex justify-content-between">
                  <Link to="/home" className="border-btn">
                    Back to Shopping
                  </Link>
                  <Link to="#" className="filled-btn">
                    Track Order
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-5 column-2">
              <h4 className="heading">{order.attributes.order_items.length} Item(s) Ordered</h4>
              <div className="row m-0 p-0">
              {order.attributes?.order_items.length > 0 && order.attributes?.order_items?.map((result: any, i: number) => (
                <div className="col-sm-6 mb-3" key={'successItem' + i}>
                  <div className="card rounded-0">
                    <img src={result?.attributes?.catalogue?.attributes?.images?.length > 0 ? result?.attributes?.catalogue?.attributes?.images[0]?.url : '/frontend/product1.png'} alt={result?.attributes?.catalogue?.attributes?.name}/>
                    <div className="ordered-products p-2 d-flex justify-content-between flex-wrap">
                      <h6 className="mb-0 blue">{result?.attributes?.catalogue?.attributes?.name}</h6>
                      <h6 className="redText mb-0">AED {result?.attributes?.total_price}</h6>
                    </div>
                  </div>
                </div>
                 ))}
              </div>
            </div>
          </div>
        </div>
        <p className="mt-5 text-center">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
      </div>
    );
  }
}

export default Success;
// Customizable Area End
