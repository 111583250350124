// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import ApiResponseMiddleware from "../ApiResponseMiddleware";
import ISuccessResponse from "../ISuccessResponse";
import GetApiMessage from "../GetApiMessage";

const configJs = require("./config");
const Strings = configJs.Strings;
const ApiUrls = configJs.ApiUrls;

interface Props {}
interface State {
  // logical
  lastPage: any;
  currentPage: any;
  deleteModalFlag: any;
  selectedId: any;

  // lists
  notificationList: Array<any>;

  // message ids
  deleteMessageId: any;
  notificationListMessageId: any;
}
interface SS {}

export const Cols = [
  Strings.table.notificationType,
  Strings.table.recipients,
  Strings.table.lastModified,
  Strings.table.actions,
  Strings.table.status,
];

class NotificationMenuController extends BlockComponent<Props, State, SS> {
  state = {
    // logical
    lastPage: 1,
    currentPage: 1,
    selectedId: null,
    deleteModalFlag: false,

    // lists
    notificationList: [],

    // message ids
    deleteMessageId: null,
    notificationListMessageId: null,
  };

  constructor(props: Props) {
    super(props);

    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
    ]);

    this.receive = this.receive.bind(this);
  }

  componentDidMount(): any {
    this.getNotificationsByApi();
  }

  receive(from: string, message: Message): void {
    if (!ApiResponseMiddleware(message)) return;

    const { notificationListMessageId, deleteMessageId } = this.state;

    let requestID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (requestID === notificationListMessageId) {
      if (successResponse) {
        const { data, meta } = successResponse;
        let newArray: any;
        if (data) {
          newArray = data?.map((item: any) => {
            return { id: item.id, ...item.attributes };
          });
        }

        this.setState({
          notificationList: newArray || [],
          lastPage: meta?.last_page || 1,
        });
      }
    }

    if (requestID === deleteMessageId) {
      if (successResponse) {
        this.getNotificationsByApi();
        this.setState({ deleteModalFlag: false });
      }
    }
  }

  getNotificationsByApi(page: number = this.state.currentPage) {
    this.setState({ currentPage: page });
    let requestMessage = GetApiMessage({
      endpoint: ApiUrls.notificationList + "?per_page=10&page=" + page,
    });
    this.setState({ notificationListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onClickDelete() {
    const { selectedId } = this.state;

    let requestMessage: any = GetApiMessage({
      method: "delete",
      endpoint: ApiUrls.deleteNotification + selectedId,
    });
    this.setState({ deleteMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

export default NotificationMenuController;

// Customizable Area End
