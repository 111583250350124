// Customizable Area Start

import CreateRestApiMessage from "../../../../components/src/CreateRestApiMessage";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { ICatalogue, ISingleSubscriptionPlan } from "./subscriptionplan.types";
import {toast} from 'react-toastify'
import * as Yup from 'yup';
const configJSON=require("./config.js")
interface Props {
  isEdit: boolean;
  navigation?: {
    getParam: Function;
    navigate: Function;
  };
}
interface State {
   catagloList:ICatalogue[];
   getCatelogLoading:boolean;
   inputs:{
    selectedCatelog:string;
    description:string;
    price:string;
    from:string;
    to:string;
   };
   submitLoading:boolean;
   errors:any;
}
interface SS {}

class SubscriptionPlanFormController extends BlockComponent<Props, State, SS> {
  getAllCatelogApiCallId:string="";
  editSubscriptionApiCallId:string="";
  createCatelogApiCallId:string="";
  getSingleSubscriptionCallId:string="";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    this.state = {
      catagloList:[],
      getCatelogLoading:false,
      inputs:{
        selectedCatelog:"",
        description:"",
        from:"",
        to:"",
        price:""
      },
      errors:{},
      submitLoading:false,
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

async receive(from: string, message: Message) {
  if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson?.errors?.length > 0 && responseJson?.errors[0]?.token) {
       this.navigateToLogin();
       return;
    }
    this.checkApiRequestPayload(apiRequestCallId, responseJson);

  }
  

  }
  checkApiRequestPayload=(apiRequestCallId: string, responseJson: any)=>{
      if(apiRequestCallId===this.getAllCatelogApiCallId){
        this.setState({
          getCatelogLoading:false,
          catagloList:responseJson?.data
        })
      }
      if(apiRequestCallId===this.createCatelogApiCallId){
        this.setState({
          submitLoading:false
        })
        console.log(responseJson)
        if(responseJson?.data?.id){
          toast.success("Subscription has been created successfully.")
          this.clearInputs();
          this.navigateToSubscriptionListPage();
        }else{
          toast.error("Subscription create failed.");
          if(responseJson?.data?.type==="error" && responseJson?.data?.attributes?.errors){
            this.handleSubmitErrors(responseJson);
          }
        }
      } 
      if(apiRequestCallId===this.getSingleSubscriptionCallId){
        this.handleSingleSubscriptionPayload(responseJson)
      }
       if(apiRequestCallId===this.editSubscriptionApiCallId){
        this.setState({
          submitLoading:false
        })
        if(responseJson?.data?.id && responseJson?.data?.type!=="error"){
          toast.success("Subscription has been updated successfully.");
          this.navigateToSubscriptionListPage();
        }else{
          toast.error("Subscription update failed.")
          if(responseJson?.data?.type==="error" && responseJson?.data?.attributes?.errors){
            this.handleSubmitErrors(responseJson)
         }
        }
      }
  }

  handleSubmitErrors=(responseJson:any)=>{
    const errors=responseJson.data.attributes.errors;
    let newErrors:any={};
    Object.keys(errors).forEach((error)=>{
        if(error==="valid_up_to"){
          newErrors.to="End date should be greater than start date"
        }
        if(error==="price"){
         newErrors.price=errors[error][0];
        }
    })
    this.setState({
      errors:newErrors
    })
  }
  handleSingleSubscriptionPayload=(responseJson:ISingleSubscriptionPlan)=>{
    if(responseJson?.data?.id){
      const subscription=responseJson.data.attributes;
      const fromDate=subscription?.valid_from;
      const parsedDate = new Date(fromDate);
      const year = parsedDate.getFullYear().toString();
      const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
      const date = parsedDate.getDate().toString().padStart(2, "0");
      const fromParsedDate = `${year}-${month}-${date}`;
      this.setState({
        inputs:{
          selectedCatelog:subscription?.catalogues?.id.toString(),
          description:subscription.description,
          from:fromParsedDate,
          to:subscription.valid_up_to,
          price:subscription.price
        }
      })
    }else{
      toast.error("Subscription not found")
    }

  }

  async componentDidMount() {
      this.getAllCatelogList();
      if(this.props?.isEdit){
         this.getEditSubscription();
      }
  }


  getEditSubscription=()=>{
     const id=this.props.navigation?.getParam("id");
     const header = {
      "Content-Type": configJSON.applicationJsonMethodType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.createSubscriptionApiCallId}/${id}`,
      method: configJSON.getMethodType,
      body: null,
    });
    this.getSingleSubscriptionCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);


     
  }


  getAllCatelogList=()=>{
      const header = {
        "Content-Type": configJSON.applicationJsonMethodType,
        token: localStorage.getItem("token"),
      };
      const requestMessage = CreateRestApiMessage({
        header,
        apiUrl: configJSON.getCatelogApiUrl,
        method: configJSON.getMethodType,
        body: null,
      });
      this.getAllCatelogApiCallId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

  handleSubmit=()=>{
    const subscriptionScheme = Yup.object({
      product: Yup.string().required("Please select product"),
      description: Yup.string().required("Please enter description"),
      from: Yup.string().required("Please select starting duration"),
      to: Yup.string().required("Please select end duration"),
      price: Yup.string().required("Please enter price")
    });
    const {inputs}=this.state;
    subscriptionScheme
    .validate(
      {
        product:inputs.selectedCatelog,
       description:inputs.description,
       from:inputs.from,
       to:inputs.to,
       price:inputs.price
      },
      { abortEarly: false }
    )
    .then((value) => { 
        this.submitInfo();
    })
    .catch((err) => {
      this.catchInputErrors(err);
    });
  }
  submitInfo=()=>{
    this.setState({
      submitLoading:true,
      errors:{}
    })
    const id=this.props.navigation?.getParam("id");
     const userId=localStorage.getItem("userId");
     const isEdit=this.props.isEdit;
    const {inputs}=this.state;
     const formData = new FormData();
     formData.append("account_id", userId as string);
     formData.append("catalogue_id", inputs.selectedCatelog);
     formData.append("description",inputs.description );
     formData.append("valid_from",inputs.from );
     formData.append("valid_up_to",inputs.to );
     formData.append("price",inputs.price );

     const header = {
      token: localStorage.getItem("token"),
    };

    const hitUrl=isEdit?`${configJSON.createSubscriptionApiCallId}/${id}`:`${configJSON.createSubscriptionApiCallId}`;
    const method=isEdit?`${configJSON.putApiMethodType}`:configJSON.postApiMethodType;
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: hitUrl,
      body: formData,
      method: method,
    });
    if(isEdit){
      this.editSubscriptionApiCallId=requestMessage.messageId;
    }else{
      this.createCatelogApiCallId = requestMessage.messageId;
    }
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  catchInputErrors=(err:any)=>{
    let newError: any = {};
    err.inner?.forEach((item: any) => {
      newError[item.path] = item.message;
    });
    this.setState({
      errors:newError
    })
  }

  handleCatelogChange=(e:any)=>{
    const catelogId=e.target.value;
    this.setState((prev)=>({
       inputs:{
        ...prev.inputs,
        selectedCatelog:catelogId
       }
    }))
  }
  navigateToSubscriptionListPage=()=>{
    this.props.navigation?.navigate("Vendor", {
      page: "subscriptions",
    })
  }
  handleInputChange=(e:any)=>{
    const keyName=e.target.name;
    const keyValue=e.target.value;
    this.setState(prev=>({
      inputs:{
        ...prev.inputs,
        [keyName]:keyValue
      }
    }))

  }
 clearInputs=()=>{
  this.setState({
    inputs:{
      description:"",
      from:"",
      price:"",
      selectedCatelog:"",
      to:""
    }
  })

 }
  navigateToLogin=()=>{
      this.props.navigation?.navigate("Home")
  }

}


export default SubscriptionPlanFormController;

// Customizable Area End
