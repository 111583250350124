// Customizable Area Start
import React from "react";
import { Grid, styled, Box, Typography, Radio } from "@material-ui/core";
import { iconFilter, iconDownload } from "../assets";

import ReportWrapperController from "./ReportWrapperController";
import DatePicker from "react-multi-date-picker";
import CircularProgress from "@material-ui/core/CircularProgress";
const configJs = require("./config");
const Strings = configJs.Strings;

const filterItems=[
  {
    id:1,
    name:"Daily",
    value:"day"
  },
  {
    id:2,
    name:"Weekly",
    value:"week"
  },
  {
    id:3,
    name:"Monthly",
    value:"month"
  },
  {
    id:4,
    name:"Quarterly",
    value:"quarter"
  },
  {
    id:5,
    name:"Yearly",
    value:"year"
  },
]
class ReportWrapper extends ReportWrapperController {
  render() {
    const { toggleFilter } = this.state;
    const {
      mainTitle,
      downloadLink,
      rangeValue,
      onChangeDate,
      visibleMonthSelector,
      dateRangeKey,
      currentFilter,
      onChangeFilter,
      loading
    } = this.props;
    return (
      <Container>
        {toggleFilter && (
          <OverlayContainer
            data-test-id="overlay-container"
            onClick={() => this.setState({ toggleFilter: false })}
          />
        )}
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Heading>{mainTitle}</Heading>
          </Grid>
          <Grid item xs="auto">
            {visibleMonthSelector && (
              <DatePicker
                data-testid="range-date-picker"
                placeholder="start date - end date"
                value={rangeValue}
                onChange={(values:any)=>{
                  onChangeDate && onChangeDate(dateRangeKey,values)
                }}
                range
                numberOfMonths={2}
                // @ts-ignore 
                dateSeparator=" to "
                rangeHover
              />
            )}
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <div
                  style={{
                    position: "relative",
                    zIndex: toggleFilter ? 4 : "unset",
                  }}
                >
                  <StyledButton
                    data-test-id="btn-filter"
                    onClick={() =>
                      this.setState({ toggleFilter: !toggleFilter })
                    }
                  >
                    <img src={iconFilter} width={30} />
                  </StyledButton>
                  <DropDownMenu
                    style={{
                      visibility: toggleFilter ? "visible" : "hidden",
                    }}
                  >
                    {filterItems.map(
                      (item: any) => {
                        return (
                          <div
                            data-testid="filterRadioButton"
                            data-test-id="btn-filter-radio"
                            key={item?.id}
                            onClick={() =>{
                              onChangeFilter(dateRangeKey,item?.value)
                              this.setState({toggleFilter:false})
                            }}
                          >
                            <Radio checked={item?.value === currentFilter} />
                            <div>{item?.name}</div>
                          </div>
                        );
                      }
                    )}
                  </DropDownMenu>
                </div>
              </Grid>
              <Grid item>
                <StyledButton
                  data-test-id="btn-download"
                  onClick={() => window.open(downloadLink)}
                >
                  <img src={iconDownload} width={30} />
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: 8 }} />
          </Grid>
          <Grid item xs={12}>
            {
              loading?(<div style={{display:"flex",height:"100%",justifyContent:"center",alignItems:"center"}}> 
                  <CircularProgress
                    data-testid="submitLoading"
                    // style={{ color: "white" }}
                    size={24}
                  />
                </div>):this.props.children
            }
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const OverlayContainer = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 3,
  backgroundColor: "rgba(0,0,0,0.3)",
});

const DropDownMenu = styled("div")({
  top: 42,
  right: 0,
  zIndex: 3,
  width: 140,
  borderRadius: 4,
  padding: "4px 8px",
  position: "absolute",
  backgroundColor: "white",
  border: "1px solid var(--navy)",
  "& > div": {
    gap: 8,
    fontSize: 14,
    display: "flex",
    fontWeight: 500,
    cursor: "pointer",
    padding: "8px 0px",
    color: "var(--navy)",
    alignItems: "center",
    borderBottom: "2px solid lightgray",
    "& > .MuiRadio-root": {
      padding: 0,
    },
  },
  "& > div:last-child": {
    borderBottom: "unset",
  },
  "& .MuiRadio-colorSecondary.Mui-checked": {
    color: "var(--navy)",
  },
});

const ReportTitle = styled(Typography)({
  fontSize: 14,
  fontWeight: 500,
  color: "var(--navy)",
});

const StyledButton = styled(Box)({
  border: "1px solid #ccc",
  cursor: "pointer",
  height: "100%",
  padding: 0,
  "&:hover": {
    borderColor: "var(--navy)",
  },
});

const Heading = styled(Typography)({
  fontSize: 20,
  fontWeight: 600,
  color: "var(--navy)",
});

const Container = styled(Box)({
  border: "1px solid rgba(0,0,0,0.3)",
  position: "relative",
  height: "100%",
  padding: 8,
});

export default ReportWrapper;

// Customizable Area End
