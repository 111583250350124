// Customizable Area Start

import moment from "moment";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";

import GetApiMessage from "../GetApiMessage";
import ISuccessResponse from "../ISuccessResponse";
import ApiResponseMiddleware from "../ApiResponseMiddleware";
import BlockHelpers from "../../../utilities/src/BlockHelpers";
import GetApiErrorsWithKeys from "../GetApiErrorsWithKeys";

const configJs = require("./config");
const Strings = configJs.Strings;
const ApiUrls = configJs.ApiUrls;
const MappedErrorsKeys = configJs.MappedErrorsKeys;

interface Props {
  inventoryId: any;
  navigation:
    | {
        navigate: Function;
      }
    | any;
}
interface SS {}
interface S {
  brandListMessageId: any;
  categoryListMessageId: any;
  showCatalogueMessageId: any;
  inventoryListMessageId: any;
  saveCatalogueMessageId: any;
  editCatalogueMessageId: any;
  subCategoryMessageId: any;

  // session ids
  userId: any;
  inventoryId: any;

  // logical
  isLoading: boolean;
  brandName: string;
  selectedBrandId: any;
  isBrandModal: boolean;
  selectedCategoryId: any;
  isProductEditable: boolean;
  isExtraFieldsOpen: boolean;

  // Catalogue
  catalogueName: string;
  catalogueValue: string;
  catalogueNameValidation: string;
  catalogueValueValidation: string;

  // inventory list
  inventoryProductList: Array<any>;
  categoryList: Array<any>;
  brandList: Array<any>;
  subCategoryList: Array<any>;

  // products
  product: {
    name: any;
    id: any;
    subCategory: any;
    productCode: any;
    modalNo: any;
    mfgDate: any;
    expDate: any;
    retailPrice: any;
    salesPrice: any;
    taxes: any;
    weight: any;
    quantity: any;
    benefits: any;
    description: any;
    isAddToInventory: any;
    frontViewImageLink: any;
    backViewImageLink: any;
    leftViewImageLink: any;
    rightViewImageLink: any;
    mainViewImageLink: any;
    mainViewImage: { name: any } | any;
    frontViewImage: { name: any } | any;
    backViewImage: { name: any } | any;
    leftViewImage: { name: any } | any;
    rightViewImage: { name: any } | any;
    isRefundable: any;
    isStockable: boolean;
    min_stock_qty: any;
    catalogueVariants: Array<any>;
  };
  validation: {
    name: any;
    id: any;
    subCategory: any;
    batchNo: any;
    mfgDate: any;
    expDate: any;
    retailPrice: any;
    salesPrice: any;
    taxes: any;
    weight: any;
    quantity: any;
    benefits: any;
    description: any;
    categoryId: any;
    productCode: any;
    selectedBrandId: any;
    isAddToInventory: boolean;
    mainViewImage: any;
    frontViewImage: any;
    backViewImage: any;
    leftViewImage: any;
    rightViewImage: any;
    isRefundable: any;
    isStockable: any;
    min_stock_qty: any;

    catalogueVariants: Array<any>;
  };
}

class AddInventoryItemController extends BlockComponent<Props, S, SS> {
  state = {
    // api ids
    brandListMessageId: null,
    categoryListMessageId: null,
    showCatalogueMessageId: null,
    inventoryListMessageId: null,
    saveCatalogueMessageId: null,
    editCatalogueMessageId: null,
    subCategoryMessageId: null,

    // session ids
    userId: "",
    inventoryId: "",

    // logical
    brandName: "",
    isLoading: true,
    isBrandModal: false,
    selectedBrandId: "",
    selectedCategoryId: "",
    isProductEditable: true,
    catalogueName: "",
    catalogueValue: "",
    catalogueNameValidation: "",
    catalogueValueValidation: "",
    isExtraFieldsOpen: false,

    // inventory list
    inventoryProductList: [],
    subCategoryList: [],
    categoryList: [],
    brandList: [],

    // products
    product: {
      name: "",
      id: "",
      subCategory: "",
      productCode: "",
      modalNo: "",
      mfgDate: null,
      expDate: null,
      retailPrice: "",
      salesPrice: "",
      taxes: "",
      quantity: "",
      min_stock_qty: "",
      weight: "",
      benefits: "",
      description: "",
      isAddToInventory: true,

      mainViewImageLink: "",
      frontViewImageLink: "",
      backViewImageLink: "",
      leftViewImageLink: "",
      rightViewImageLink: "",

      mainViewImage: null,
      frontViewImage: null,
      backViewImage: null,
      leftViewImage: null,
      rightViewImage: null,
      isRefundable: false,
      isStockable: false,

      catalogueVariants: [],
    },
    validation: {
      name: "",
      id: "",
      subCategory: "",
      batchNo: "",
      mfgDate: "",
      expDate: "",
      retailPrice: "",
      salesPrice: "",
      weight: "",
      taxes: "",
      quantity: "",
      min_stock_qty: "",
      benefits: "",
      description: "",
      categoryId: "",
      productCode: "",
      selectedBrandId: "",
      isAddToInventory: true,
      mainViewImage: null,
      frontViewImage: null,
      backViewImage: null,
      leftViewImage: null,
      rightViewImage: null,
      isRefundable: false,
      isStockable: false,

      catalogueVariants: [],
    },
  };

  constructor(props: Props) {
    super(props);

    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ]);

    this.receive = this.receive.bind(this);
  }

  componentDidMount(): any {
    this.getInventoryDataApi();
    this.getCategoriesFromApi();
    this.getSubCategoryList();
    this.getCatalogueById();
  }

  receive(from: string, message: Message): void {
    if (!ApiResponseMiddleware(message)) return;

    let requestID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const getErrorMessages = (response: any) => {
      if (response.data?.type === "error") {
        // @ts-ignore
        let errors = successResponse?.data?.attributes?.errors;
        let messages: Array<any> = [];
        Object.keys(errors).map((key: any) => {
          let item = errors[key];
          if (Array.isArray(item)) {
            item.map((msg) => messages.push(msg));
          } else {
            let message = item[key];
            messages.push(message);
          }
        });
        return messages.join(", ");
      } else return "";
    };

    const {
      brandListMessageId,
      subCategoryMessageId,
      categoryListMessageId,
      editCatalogueMessageId,
      showCatalogueMessageId,
      saveCatalogueMessageId,
      inventoryListMessageId,
    } = this.state;

    if (from === "SessionSaveMessage") {
      const { inventoryId } = message?.properties;
      this.setState({ userId: localStorage.userId, inventoryId });
    }

    if (inventoryListMessageId === requestID) {
      if (successResponse) {
        let data: any = successResponse.data;
        data = data.map((item: { id: any; attributes: any }) => {
          return { id: item.id, ...item.attributes };
        });
        this.setState({ inventoryProductList: data });
      }
    } else if (categoryListMessageId === requestID) {
      if (successResponse) {
        // @ts-ignore
        let list = successResponse?.data?.map((item: { attributes: any }) => {
          return item.attributes;
        });
        this.setState({ categoryList: list });
      }
    } else if (brandListMessageId === requestID) {
      if (successResponse) {
        // @ts-ignore
        let list = successResponse.data?.map((item: { attributes: any }) => {
          return item.attributes;
        });
        this.setState({ brandList: list });
      }
    } else if (saveCatalogueMessageId === requestID) {
      if (successResponse) {
        let errorObjects = GetApiErrorsWithKeys(
          successResponse,
          MappedErrorsKeys
        );
        if (errorObjects) {
          // @ts-ignore
          this.setState({ validation: errorObjects });
          errorObjects.extraMessages &&
            BlockHelpers.showAlert(
              Strings.labels.error,
              errorObjects.extraMessages
            );
          window.scrollTo(0, 0);
        } else {
          this.onRemoveAllVariants();
          BlockHelpers.showAlert(
            Strings.messages.success,
            Strings.messages.productAdded
          );
          setTimeout(
            () =>
              this.props.navigation.navigate("Vendor", {
                page: "inventory",
              }),
            3000
          );
        }
      }
    } else if (showCatalogueMessageId === requestID) {
      if (successResponse) {
        // @ts-ignore
        let { id, attributes } = successResponse.data;
        let data = { id, ...attributes };
        let variants =
          data?.catalogue_variants?.map((item: any) => item?.attributes) || [];

        let p = {
          ...this.state.product,
          isRefundable: Boolean(data.refundable),
          isStockable: Boolean(data.stockable),
          subCategory: data?.sub_category?.id,
          min_stock_qty: data.min_stock_qty || 0,
          mainViewImageLink: data?.images[0]?.url ?? null,
          frontViewImageLink: data?.front_face,
          backViewImageLink: data?.back_face,
          leftViewImageLink: data?.top_face,
          rightViewImageLink: data?.bottom_face,
          benefits: data.benefits,
          description: data.description,
          name: data.name,
          modalNo: data.model_number,
          salesPrice: data.price,
          quantity: data.stock_qty,
          productCode: data.product_code,
          retailPrice: data.retails_price,
          weight: data.weight,
          expDate: moment(data.use_before_timestamp).format("YYYY-MM-DD"),
          mfgDate: moment(data.manufacture_date_timestamp).format("YYYY-MM-DD"),
          taxes: data.taxes,
          catalogueVariants: variants,
        };

        this.setState({
          product: p,
          isLoading: false,
          selectedBrandId: data.brand.id,
          selectedCategoryId: data.category.id,
        });

        this.getBrandsFromApi(data.category.id);
      }
    } else if (editCatalogueMessageId === requestID) {
      let errorObjects = GetApiErrorsWithKeys(
        successResponse,
        MappedErrorsKeys
      );
      if (errorObjects) {
        // @ts-ignore
        this.setState({ validation: errorObjects });
        errorObjects.extraMessages &&
          BlockHelpers.showAlert(
            Strings.labels.error,
            errorObjects.extraMessages
          );
        window.scrollTo(0, 0);
      } else {
        this.onRemoveAllVariants();
        BlockHelpers.showAlert(
          Strings.messages.success,
          Strings.messages.productUpdated
        );
        setTimeout(
          () =>
            this.props.navigation.navigate("Vendor", {
              page: "inventory",
            }),
          3000
        );
      }
    } else if (subCategoryMessageId === requestID) {
      if (successResponse) {
        let data: any = successResponse.data;
        data = data.map((item: { id: any; attributes: any }) => {
          return { id: item.id, ...item.attributes };
        });
        this.setState({ subCategoryList: data });
      }
    }
  }

  getCatalogueById() {
    let inventoryId = this.props.inventoryId;
    if (!inventoryId) return;

    let requestMessage = GetApiMessage({
      endpoint: ApiUrls.showCatalogue + inventoryId,
    });
    this.setState({ showCatalogueMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInventoryByBrand(id: any) {
    if (id === "create-new-brand") {
      this.setState({ isBrandModal: true, selectedBrandId: "" });
      return;
    }

    let requestMessage = GetApiMessage({
      method: "get",
      endpoint: ApiUrls.catalogueListByBrand + id,
    });
    this.setState({
      inventoryListMessageId: requestMessage.messageId,
      selectedBrandId: id,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBrandsFromApi(id: any) {
    let requestMessage = GetApiMessage({
      method: "GET",
      endpoint: ApiUrls.listBrands + "?category=" + id+"&is_new_inventory=true",
    });
    this.setState({
      brandListMessageId: requestMessage.messageId,
      selectedCategoryId: id,
      brandList: [],
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCategoriesFromApi() {
    let requestMessage = GetApiMessage({
      method: "GET",
      endpoint: ApiUrls.listCategory,
    });
    this.setState({ categoryListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onChangeProduct(key: string, value: any) {
    let product = this.state.product;
    product = {
      ...product,
      [key]: value,
    };
    this.setState({ product });
  }

  isValid() {
    const isEdit = this.props.inventoryId !== null;
    let errorMessages = {};

    const updateErrorMessage = (key: string, value: any) => {
      errorMessages = {
        ...errorMessages,
        [key]: value,
      };
    };

    const {
      name,
      productCode,
      modalNo,
      mfgDate,
      expDate,
      retailPrice,
      salesPrice,
      weight,
      quantity,
      mainViewImage,
      min_stock_qty,
      taxes,
      subCategory,
    } = this.state.product;

    if (!taxes) updateErrorMessage("taxes", Strings.validations.selectTaxes);
    if (!weight) updateErrorMessage("weight", Strings.validations.weight);
    if (!name) updateErrorMessage("name", Strings.validations.name);
    if (!modalNo) updateErrorMessage("batchNo", Strings.validations.batchNo);
    if (!quantity) updateErrorMessage("quantity", Strings.validations.quantity);
    if (!min_stock_qty && Number(min_stock_qty) !== 0)
      updateErrorMessage("min_stock_qty", Strings.validations.minStockQuantity);
    else if (Number(min_stock_qty) < 0) {
      updateErrorMessage(
        "min_stock_qty",
        Strings.validations.minStockQuantityGreater0
      );
    } else if (quantity && Number(quantity) < Number(min_stock_qty)) {
      updateErrorMessage(
        "min_stock_qty",
        Strings.validations.minStockQuantityGreaterQuantity
      );
    }
    if (!expDate) updateErrorMessage("expDate", Strings.validations.expDate);
    if (!salesPrice)
      updateErrorMessage("salesPrice", Strings.validations.salesPrice);
    else if (Number(salesPrice) === 0)
      updateErrorMessage("salesPrice", Strings.validations.salesPrice);

    if (!mfgDate) updateErrorMessage("mfgDate", Strings.validations.mfgDate);

    if (!retailPrice)
      updateErrorMessage("retailPrice", Strings.validations.enterRetailPrice);
    else if (Number(retailPrice) === 0)
      updateErrorMessage("retailPrice", Strings.validations.enterRetailPrice);
    else if (salesPrice && Number(retailPrice) < Number(salesPrice))
      updateErrorMessage("retailPrice", Strings.validations.greaterRetailPrice);

    if (!productCode)
      updateErrorMessage("productCode", Strings.validations.productCode);

    if (!this.state.selectedCategoryId)
      updateErrorMessage("categoryId", Strings.validations.category);
    if (!this.state.selectedBrandId)
      updateErrorMessage("selectedBrandId", Strings.validations.brand);

    if (!subCategory)
      updateErrorMessage("subCategory", Strings.validations.subCategory);

    if (!isEdit) {
      if (!mainViewImage)
        updateErrorMessage("mainViewImage", Strings.validations.mainImage);
    }

    if (expDate && mfgDate)
      if (!moment(expDate).isAfter(mfgDate))
        updateErrorMessage("expDate", Strings.validations.afterMfgDate);

    if (retailPrice)
      if (this.onInputNumberValidation(retailPrice))
        updateErrorMessage("retailPrice", Strings.validations.validRetailPrice);

    if (salesPrice)
      if (this.onInputNumberValidation(salesPrice))
        updateErrorMessage("salesPrice", Strings.validations.validSalesPrice);

    if (quantity)
      if (this.onInputNumberValidation(quantity))
        updateErrorMessage("quantity", Strings.validations.validQuantity);

    if (min_stock_qty)
      if (this.onInputNumberValidation(min_stock_qty))
        updateErrorMessage(
          "min_stock_qty",
          Strings.validations.validMinStockQuantity
        );

    // extra fields
    if (!this.isValidAllExtraFields()) return false;

    // @ts-ignore
    this.setState({ validation: errorMessages });

    return !(Object.keys(errorMessages).length > 0);
  }

  onSubmit() {
    const isEdit = this.props.inventoryId !== null;
    if (!this.isValid()) return;

    const {
      modalNo,
      weight,
      frontViewImage,
      backViewImage,
      leftViewImage,
      rightViewImage,
      name,
      benefits,
      description,
      productCode,
      mfgDate,
      quantity,
      expDate,
      salesPrice,
      retailPrice,
      mainViewImage,
      min_stock_qty,
      isRefundable,
      isStockable,
      taxes,
      subCategory,
      catalogueVariants,
    } = this.state.product;

    const { isProductEditable, selectedCategoryId, selectedBrandId } =
      this.state;

    let form = new FormData();
    form.set("name", name);
    form.set("category_id", selectedCategoryId);
    form.set("sub_category_id", subCategory);
    form.set("brand_id", selectedBrandId);
    form.set("description", description);
    form.set("manufacture_date", String(mfgDate));
    form.set("date_first_available", String(mfgDate));
    form.set("use_before", String(expDate));
    // @ts-ignore
    form.set("stock_qty", quantity);
    // @ts-ignore
    form.set("min_stock_qty", min_stock_qty);
    form.set("weight", weight);
    // @ts-ignore
    form.set("price", salesPrice);
    form.set("taxes", taxes);
    form.set("stockable", String(isStockable));
    form.set("refundable", String(isRefundable));
    // @ts-ignore
    form.set("retails_price", retailPrice);
    form.set("benefits", benefits);
    form.set("country_of_origin", "UAE");
    form.set("product_code", productCode);
    form.set("model_number", modalNo);
    form.set("account_id", localStorage.userId || "");

    if (mainViewImage)
      // @ts-ignore
      form.set("images", mainViewImage);

    if (frontViewImage)
      // @ts-ignore
      form.append("front_face", frontViewImage);
    if (backViewImage)
      // @ts-ignore
      form.append("back_face", backViewImage);
    if (leftViewImage)
      // @ts-ignore
      form.append("top_face", leftViewImage);
    if (rightViewImage)
      // @ts-ignore
      form.append("bottom_face", rightViewImage);

    if (!isEdit)
      // @ts-ignore
      form.append("is_new_item", !isProductEditable);
    else if (!isProductEditable)
      // @ts-ignore
      form.append("is_new_item", !isProductEditable);

    catalogueVariants
      .filter((item: any) => item.isRemoved !== true)
      .forEach(({ variant_name, variant_description }, index) => {
        form.set(
          `catalogue_variants_attributes[${index}][variant_name]`,
          variant_name
        );
        form.set(
          `catalogue_variants_attributes[${index}][variant_description]`,
          variant_description
        );
      });

    let requestMessage: any = { id: "", messageId: "" };

    if (!isEdit) {
      requestMessage = GetApiMessage({
        method: "post",
        endpoint: ApiUrls.addCatalogue,
        data: form,
      });
      this.setState({ saveCatalogueMessageId: requestMessage.messageId });
    } else {
      requestMessage = GetApiMessage({
        method: "put",
        endpoint: ApiUrls.editCatalogue + this.props.inventoryId,
        data: form,
      });
      this.setState({ editCatalogueMessageId: requestMessage.messageId });
    }
    // @ts-ignore
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInventoryDataApi() {
    let requestMessage = GetApiMessage({
      method: "get",
      endpoint: ApiUrls.listInventory,
    });
    this.setState({ inventoryListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSubCategoryList() {
    let requestMessage = GetApiMessage({
      method: "get",
      endpoint: ApiUrls.listSubCategory,
    });
    this.setState({ subCategoryMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onSelectProduct(productName: any) {
    let foundProduct: any = this.state.inventoryProductList.filter(
      ({ name }) => name === productName
    );
    if (foundProduct.length > 0) {
      foundProduct = foundProduct[0];
      let p = {
        ...this.state.product,
        id: foundProduct.id,
        name: productName,
        productCode: foundProduct.product_code,
      };
      this.setState({ product: p });
      this.setState({ isProductEditable: true });
    } else {
      this.setState({
        product: {
          ...this.state.product,
          name: productName,
          productCode: "",
        },
      });
      this.setState({ isProductEditable: false });
    }
  }

  onInputNumberValidation(value: any) {
    let no = Number(value);
    return isNaN(no);
  }

  onGetExtraFieldList() {
    return this.state.product.catalogueVariants.filter(
      (item: any) => !(item.isRemoved === true)
    );
  }

  onChangeExtraFieldValues(id: number, value: string) {
    let variants: Array<any> = this.state.product.catalogueVariants.map(
      (item: any) => {
        if (item.id === id) item.variant_description = value;
        return item;
      }
    );

    this.setState({
      product: {
        ...this.state.product,
        catalogueVariants: variants,
      },
    });
  }

  onRemoveExtraField(id: number) {
    let variants: Array<any> = this.state.product.catalogueVariants.map(
      (item: any) => {
        if (id === item.id) item.isRemoved = true;
        return item;
      }
    );

    this.setState({
      product: {
        ...this.state.product,
        catalogueVariants: variants,
      },
    });
  }

  isValidExtraField() {
    const { catalogueName } = this.state;

    this.setState({
      catalogueNameValidation: !catalogueName ? "Field name is required!" : "",
    });

    return catalogueName ? true : false;
  }

  isValidAllExtraFields() {
    const { catalogueVariants } = this.state.product;
    let variants = catalogueVariants.map((item: any) => {
      if (!item.variant_description) item.error = true;
      else item.error = false;
      return item;
    });

    this.setState({
      catalogueNameValidation: "",
      product: {
        ...this.state.product,
        catalogueVariants: variants,
      },
    });

    return !(variants.filter((item) => item.error).length > 0);
  }

  onSaveExtraField() {
    if (!this.state.isExtraFieldsOpen) {
      this.setState({ isExtraFieldsOpen: true });
      return;
    }

    if (!this.isValidExtraField()) return;

    let variants: Array<any> = this.state.product.catalogueVariants;
    let newItem = {
      id: new Date().getTime(),
      error: false,
      variant_name: this.state.catalogueName,
      variant_description: this.state.catalogueValue,
    };
    variants.push(newItem);
    this.setState({
      product: {
        ...this.state.product,
        catalogueVariants: variants,
      },
    });
    this.setState({
      catalogueName: "",
      catalogueValue: "",
    });
  }

  onRemoveAllVariants() {
    let ids = this.state.product.catalogueVariants
      .map((item: any) => item.id)
      .filter((item) => item);

    if (ids.length === 0) return;

    let requestMessage = GetApiMessage({
      method: "DELETE",
      endpoint: ApiUrls.removeBulkVariants + `?ids=[${ids.join(",")}]`,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

export default AddInventoryItemController;

// Customizable Area End
