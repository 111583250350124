// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import GetApiMessage  from "../../utilities/src/GetApiMessage";

import ApiUrls from './config'

export interface Props {
  navigation: any;
  id: string;
  
}

interface S {
  placeOrder: any;
  brandsPageList: any;
  brandsPageMessageId: any;
}

interface SS {
  id: any;
}

export default class BrandsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      placeOrder: false,
      brandsPageList: [],
      brandsPageMessageId: ''
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    const { brandsPageMessageId} = this.state
    let requestID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    let successResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if (brandsPageMessageId === requestID) {
      if (successResponse) {
        this.setState({ brandsPageList: successResponse.data ?? [] })
      }
    }
  }

  componentDidMount(): any {
    this.getAllBrands();
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  showPlaceOrder() {
    this.setState({
      placeOrder: true
    });
  }
  closeModal(){
    this.setState({
      placeOrder: false
    });
  }
  
  
  async getAllBrands() {
    let requestMessage = GetApiMessage({ method: 'get', endpoint: ApiUrls.brandsPageApiURL });
    this.setState({ brandsPageMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
// Customizable Area End