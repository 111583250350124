// Customizable Area Start

import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Button, styled } from "@material-ui/core";

interface Props {
  text: string;
  icon: any;
  onClick?: any;
}
interface State {}
interface SS {}

class RoundedButton extends BlockComponent<Props, State, SS> {
  render() {
    const { text, icon, onClick } = this.props;
    return (
      <StyledButton
        data-test-id="btn-rounded"
        onClick={onClick ?? null}
        variant={"outlined"}
        startIcon={<img width={20} height={20} src={icon} />}
      >
        {text || ""}
      </StyledButton>
    );
  }
}

const StyledButton = styled(Button)({
  fontSize: 16,
  minWidth: 100,
  fontWeight: 500,
  borderRadius: 4,
  boxShadow: "none",
  padding: "5px 20px",
  color: "var(--navy)",
  lineHeight: "unset",
  textTransform: "unset",
  border: "2px solid var(--navy)",
});

export default RoundedButton;

// Customizable Area End
