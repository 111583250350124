// Customizable Area Start

import React from "react";
import moment from "moment";
import { Link, withRouter } from "react-router-dom";
import { Typography, Box, styled } from "@material-ui/core";

import HeaderController from "./HeaderController";
import NotificationDropDown from "./NotificationDropDown";

import { iconNotification, iconProfile, iconSearch } from "./assets";

const configJs = require("./config");
const Strings = configJs.Strings;

export class Header extends HeaderController {
  render() {
    const { notificationCount } = this.state;
    let userName = "";
    let companyLogo: any = "";
    let userProfileImage = iconProfile;
    let profile = this.props.profile;
    if (profile) {
      const { first_name, last_name, profile_photo } = profile;
      userName = first_name + " " + last_name;
      userProfileImage = profile_photo ?? iconProfile;
      companyLogo = profile?.company_logo;
    }

    return (
      <>
      <div className="header fixed-top">
        <Link to="/vendor/menu" className="logo">
          {companyLogo ? (
            <LogoWrapper>
              <img className="fit-image" src={companyLogo} />
            </LogoWrapper>
          ) : (
            Strings.labels.logo
          )}
        </Link>
        <div className="userPanel">
          <div className="search-box">
            <input
              data-test-id="input-search"
              type="search"
              className="search"
              placeholder={Strings.labels.search}
              onChange={(e) => this.setState({ searchQuery: e.target.value })}
              onKeyDown={(e) => e.key === "Enter" && this.onClickSearch()}
            />
            <button
              data-test-id="btn-search"
              type="button"
              className="searchButton"
              onClick={() => this.onClickSearch()}
            >
              <img src={iconSearch} alt="icon" />
            </button>
          </div>
          <div className="notification">
            <MenuWrapper>
              <Link to={"/vendor/notifications"}>
                <img
                  className="notice-icon"
                  src={iconNotification}
                  alt="icon"
                />
                {notificationCount > 0 && (
                  <NotificationCount className="count">
                    {notificationCount}
                  </NotificationCount>
                )}
              </Link>
              <NotificationDropDown list={this.state.notificationList || []} />
            </MenuWrapper>
          </div>
          <div className="notification">
            <img className="notice-icon" src={userProfileImage} alt="icon" />
          </div>
          <div className="userName">
            <button className="dropbtn">{userName}</button>
            <div className="dropdown-content">
              <a href="/orders">{Strings.labels.myOrders}</a>
              <a
                href="#"
                data-test-id="btn-logout"
                onClick={() => this.onClickLogout()}
              >
                {Strings.labels.logout}
              </a>
            </div>
          </div>
          <div className="dateBox">
            <TimeText>
              {moment().format("MMM DD")} <br />
              {moment().format("YYYY")}
            </TimeText>
          </div>
        </div>
      </div>
      <div className={'vendor-header-height'}></div>
      </>
    );
  }
}

// @ts-ignore
export default withRouter(Header);

const LogoWrapper = styled("div")({
  width: 75,
  height: 75,
  overflow: "hidden",
  borderRadius: "50%",
  backgroundColor: "white",
  "& > img": {
    objectFit: "cover",
  },
});

const NotificationCount = styled("span")({
  top: "-6px !important",
  right: "-6px !important",
});

const MenuWrapper = styled(Box)({
  position: "relative",
  "&:hover .vendor-header-menu-dropdown": {
    display: "block !important",
  },
});

const TimeText = styled(Typography)({
  fontSize: 14,
  color: "white",
  textAlign: "center",
});

// Customizable Area End
