// Customizable Area Start

import React from "react";
import { Helmet } from "react-helmet";
import { Grid } from "@material-ui/core";

import CyLyGraph from "./CyLyGraph";
import OrderCountTable from "./OrderCountTable";
import InventoryReport from "./InventoryReport";
import SalesByDistribution from "./SalesByDistributions";
import AdHocReportingController from "./AdHocReportingController";

const configJs = require("./config");
const Strings = configJs.Strings;

class AdHocReporting extends AdHocReportingController {
  render() {
    if (!this.isLoaded) return <></>;

    return (
      <>
        <Helmet>
          <title>{Strings.header.title}</title>
        </Helmet>

        <Grid container spacing={1} alignItems="stretch">
          <Grid item xs={6}>
            <OrderCountTable />
          </Grid>
          <Grid item xs={6}>
            <CyLyGraph />
          </Grid>
          <Grid item xs={6}>
            <InventoryReport />
          </Grid>
          <Grid item xs={6}>
            <SalesByDistribution />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default AdHocReporting;

// Customizable Area End
