import React from "react";
import {
  styled,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import { StyledInput } from "./AddInventory.web";
import RoundedButton from "../Shared/RoundedButton";
import NewBrandModalController from "./NewBrandModalController";

const configJs = require("./config");
const Strings = configJs.Strings;

class NewBrandModal extends NewBrandModalController {
  render() {
    const { validation } = this.state;
    return (
      <Dialog
        data-test-id="brand-modal"
        open={true}
        onClose={() => this.props.onClose()}
      >
        <DialogTitle>{Strings.header.heading}</DialogTitle>
        <DialogContent dividers style={{ minWidth: 300 }}>
          <StyledInput
            fullWidth
            variant="standard"
            label={Strings.labels.brandName}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={validation.name}
            data-test-id="field-brandName"
            error={validation.name ? true : false}
            placeholder={Strings.labels.brandNamePlaceholder}
            onChange={(e: any) => this.onChangeItem("name", e.target.value)}
            requiredField
          />

          <FieldLabel>
            {Strings.labels.brandImage} <span>*</span>
          </FieldLabel>
          <StyledLabel htmlFor="brandImage">
            {Strings.actions.upload}
          </StyledLabel>
          <input
            type="file"
            id="brandImage"
            style={{ display: "none" }}
            accept=".jpeg, .jpg, .png"
            onChange={(e: any) =>
              this.onChangeItem("photo", e.target.files[0] ?? null)
            }
          />
          {this.state.item.photo && (
            <div>
              {/* @ts-ignore */}
              <small>{this.state.item.photo?.name}</small>
            </div>
          )}
          {validation.photo && <ErrorLabel>{validation.photo}</ErrorLabel>}
          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <RoundedButton
                isActive={false}
                data-test-id="btn-cancel"
                text={Strings.actions.cancel}
                onClick={() => this.props.onClose()}
              />
            </Grid>
            <Grid item>
              <RoundedButton
                isActive={true}
                data-test-id="btn-submit"
                text={Strings.actions.submit}
                onClick={() => this.onSubmit()}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
}

const ErrorLabel = styled("div")({
  marginTop: 4,
  fontSize: 13,
  color: "#f44336",
});

const FieldLabel = styled("div")({
  fontSize: 10,
  marginTop: 12,
  fontWeight: 600,
  color: "var(--content)",
  "& > span": {
    color: "red",
  },
});

const StyledLabel = styled("label")({
  marginTop: 4,
  fontSize: 12,
  borderRadius: 4,
  padding: "2px 8px",
  border: "2px solid var(--content)",
});

export default NewBrandModal;
