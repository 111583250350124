// Customizable Area Start
import * as React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
interface Props{
  previous: any,
  next: any
}
interface State { }
interface SS { }
class Arrows extends BlockComponent<Props, State, SS>{

  render() {
    const {previous, next} = this.props;
    return (
      <div className="arrowBox">
      <button className="carouselArrows left" onClick={previous}><img className="arrows" src='/frontend/arrow-left.png' alt='' /></button>
      <button className="carouselArrows right" onClick={next}><img className="arrows" src='/frontend/arrow-right.png' alt=''/></button>
    </div>
    )

  }
}


export default Arrows;
// Customizable Area End
