// Customizable Area Start

import React from "react";
import { Chart } from "react-google-charts";

import ReportWrapper from "./ReportWrapper";
import AdHocReportingController from "./AdHocReportingController";

const configJs = require("./config");
const Strings = configJs.Strings;

const options = {
  // title: 'My Daily Activities',
  pieHole: 0.5,
  is3D: false,
};

class SalesByDistributionsReport extends AdHocReportingController {
  render() {
    const { salesByDistributionDownloadLink,filter,loading,dateRanges } =
      this.state;
    return (
      <ReportWrapper
        visibleMonthSelector={true}
        mainTitle={Strings.labels.salesByDistribution}
        downloadLink={salesByDistributionDownloadLink}
        onChangeDate={this.onChangeDateRange}
        dateRangeKey={"salesByDistributions"}
        currentFilter={filter["salesByDistributions"]}
        onChangeFilter={this.onFilterChange}
        loading={loading["salesByDistributions"]}
        rangeValue={dateRanges["salesByDistributions"]}
      >
        <Chart
          options={options}
          height={"400px"}
          chartType="PieChart"
          data={this.getSalesByDistributionChartData()}
        />
      </ReportWrapper>
    );
  }
}

export default SalesByDistributionsReport;

// Customizable Area End
