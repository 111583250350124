Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.createAccountURL = "account/accounts";
exports.loginAccountURL = "login/login";
exports.urlHeaderTypeJSON = "application/json";
exports.postHttpRequest = "POST";

// Customizable Area Start
exports.clientID =
  "649592030497-gp3moqh0k2src5r2u5qfaorii1dqkvdc.apps.googleusercontent.com";
exports.facebookButtonText = "Sign up With Facebook";
exports.googleButtonText = "Sign up With Google";
exports.signUpButtonText = "Sign up with email and password";

exports.loginFacebookButtonText = "Log in With Facebook";
exports.loginGoogleButtonText = "Log in With Google";
exports.loginButtonText = "Log in with email and password";

exports.errorTitle = "Error";
exports.errorDescription = "#Error fetching data: ";

exports.signUpTitleText = "Sign up";
exports.signUpBodyText = "Value proposition: why users should sign up.";

exports.logInTitleText = "Log In";
exports.logInBodyText = "The reason why we require login.";
exports.vendorSignupURL = "account/accounts";
exports.mobileLoginURL = exports.vendorSignupURL + "/send_otp";
exports.vendorVerifyOTP = exports.vendorSignupURL + "/sms_confirmation";
exports.signupServerValidation = {
  email: "signupEmail",
  first_name: "signupFname",
  last_name: "signupLname",
  password: "signupPassword",
  password_confirmation: "signupConfirmPassword",
  business_address: "signupAddress",
  business_city: "signupCity",
  business_state: "signupState",
  business_country: "signupCountry",
  business_name: "signupBusinessName",
  business_phone_no: "signupPhoneNumber",
  legal_bussiness_country: "signupBusinessCountry",
  full_phone_number: "signupFullPhoneNumber",
  commercial_registration_doc: "signupCommercial",
  tax_registration_doc: "signupTax",
  supporting_doc: "signupSupporting",
  pharmacy_registration_doc: "signupPharmacy"
};
exports.countryAPIURL = "bx_block_state_cities/state_cities/countries";
exports.stateAPIURL = "bx_block_state_cities/state_cities/states?country=";
exports.cityAPIURL = "bx_block_state_cities/state_cities/cities?state={state}&country={country}";
exports.emailValidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
exports.vendorActivationApiURL = 'accounts/edit_profile';
// Customizable Area End
