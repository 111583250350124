// Customizable Area Start
import React from "react";
import { styled } from "@material-ui/core";
import ChooseReason from "./ChooseReason.web";
import ConfirmReturn from "./ConfirmReturn.web";
import ConfirmReturnOrderDialog from "./ConfirmReturnDIalog";
import ReturnOrderController, { SCREEN_STEPS } from "./ReturnOrderController";
import SelectAddress from "./SelectAddress/SelectAddress.web";

class ReturnOrder extends ReturnOrderController {
  render() {
    const {
      selectedAddress,
      orderReturnSteps,
      returnReasonList,
      selectedProducts,
      orderDetailsData,
      changeAddressModal,
      mailYourReturnError,
      mailYourReturn,
    } = this.state;

    return (
      <>
        {changeAddressModal && (
          <SelectAddress
            selectedAddressId={selectedAddress.id}
            onClose={() => this.setChangeAddressModal(false)}
            onChange={(address: any) =>
              this.setState({ selectedAddress: address })
            }
          />
        )}

        {orderReturnSteps === SCREEN_STEPS.chooseItemsStep && (
          <ChooseReason
            {...this.props}
            // @ts-ignore
            hasUpdates={selectedProducts.length}
            returnReasonList={returnReasonList}
            isSelectedItem={this.isSelectedItem.bind(this)}
            orderDetailsData={orderDetailsData}
            selectedItems={selectedProducts}
            onChange={(id: number, reasonId: any) => {
              if (!reasonId) this.onSelectProduct(id);
              else this.onChangeReason(id, reasonId);
            }}
            onClick={() => {
              if (this.isValidReturn())
                this.setState({
                  orderReturnSteps: SCREEN_STEPS.confirmReturnStep,
                });
            }}
          />
        )}
        {orderReturnSteps === SCREEN_STEPS.confirmReturnStep && (
          <ConfirmReturn
            {...this.props}
            onSubmit={() => this.onSubmit()}
            returnReasonList={returnReasonList || []}
            selectedItems={selectedProducts}
            orderDetailsData={orderDetailsData}
            mailYourReturnError={mailYourReturnError}
            selectedAddress={selectedAddress}
            mailYourReturn={mailYourReturn}
            onChange={(label: string) =>
              this.setState({ mailYourReturn: label })
            }
            setChangeAddressModal={(flag: boolean) =>
              this.setChangeAddressModal(flag)
            }
          />
        )}
        {orderReturnSteps === SCREEN_STEPS.successReturnStep && (
          <DialogWrapper>
            <ConfirmReturnOrderDialog />
          </DialogWrapper>
        )}
      </>
    );
  }
}

export default ReturnOrder;

const DialogWrapper = styled("div")({
  "& .thanks": {
    width: "unset",
    height: "unset",
    "& .popup": {
      gap: 32,
      overflow: "unset",
      minHeight: "unset",
      maxHeight: "unset",
      "& .white-btn": {
        marginTop: 32,
      },
    },
  },
});

// Customizable Area End
