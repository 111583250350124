// Customizable Area Start

import React from "react";
import { Helmet } from "react-helmet";
import { Grid } from "@material-ui/core";

import NoDataTd from "../Shared/NoDataTd";
import Pagination from "../Shared/Pagination";
import ProductsByCategoryController from "./ProductsByCategoryController";

const configJs = require("./config");
const Strings = configJs.Strings;

class ProductsByCategory extends ProductsByCategoryController {
  render() {
    const { currentPage, lastPage, menuList } = this.state;
    return (
      <div data-test-id="menu-screen" className="inventory">
        <Helmet>
          <title>{Strings.header.title}</title>
        </Helmet>

        <div className="head">
          <h4 className="title">{Strings.header.heading}</h4>
        </div>

        <div className="inventory-table mh-100">
          <table className="table">
            <thead>
              <tr>
                <th>{Strings.table.category}</th>
                {/* <th>Sub Category</th> */}
                <th>{Strings.table.productName}</th>
                <th>{Strings.table.productId}</th>
              </tr>
            </thead>
            <tbody>
              {menuList.length === 0 && (
                <tr>
                  <NoDataTd colSpan={3}>{Strings.table.noData}</NoDataTd>
                </tr>
              )}
              {menuList.map((item: any) => {
                return (
                  <tr key={"list-item-" + item.id}>
                    <td>{item.name}</td>
                    {/* <td>NA</td> */}
                    <td>
                      {item?.catalogue?.map(
                        // @ts-ignore
                        ({ name }) => (
                          <div key={"name-" + item.id + "-" + name}>{name}</div>
                        )
                      )}
                    </td>
                    <td>
                      {item?.catalogue?.map(
                        // @ts-ignore
                        ({ product_code }) => (
                          <div
                            key={"product-id-" + item.id + "-" + product_code}
                          >
                            {product_code}
                          </div>
                        )
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <Grid container justifyContent="flex-end">
          <Grid item>
            <Pagination
              data-test-id="pagination"
              currentPage={currentPage}
              lastPage={lastPage}
              onChangePage={(page: number) => this.onChangePage(page)}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ProductsByCategory;

// Customizable Area End
