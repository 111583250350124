// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


import GetApiMessage  from "../../utilities/src/GetApiMessage";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  reduxState?: {
    actions: any;
    profile: any;
  };
  reduxDispatch?: {dispatch: Function};
}

interface S {
  wishListMessageId: string;
  wishlistItems: any;
}

interface SS {
  id: any;
}

export default class WishlistController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      wishListMessageId: '',
      wishlistItems: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const { wishListMessageId, wishlistItems} = this.state
    let requestID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    let successResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if (wishListMessageId === requestID) {
      if (successResponse) {
        this.setState({ wishlistItems: successResponse ?? []});
      }
    }
  }

  componentDidMount(): any {
    this.wishListData();
  }

  async wishListData(page:number| string = 1) {
    let url = configJSON.wishlistApiURL;
    url = url.replace("{page}", page);
    const requestMessage = GetApiMessage({ method: 'get', endpoint: url});
    this.setState({ wishListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  openApproved(flag: boolean) {
    const data = {
      type: this.props.reduxState?.actions.dialogues.accountApproved,
      payload: flag,
    };
    this.props.reduxDispatch?.dispatch(data);
  }
}
// Customizable Area End