// Customizable Area Start

import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import BlockHelpers from "../../../utilities/src/BlockHelpers";
import ApiResponseMiddleware from "../ApiResponseMiddleware";
import ISuccessResponse from "../ISuccessResponse";
import GetApiMessage from "../GetApiMessage";
import GetErrorMessages from "../GetErrorMessageFromResponse";

const configJs = require("./config");
const Strings = configJs.Strings;
const ApiUrls = configJs.ApiUrls;

interface Props {
  navigation?: { getParam?: Function; navigate: any | Function };
  isEdit?: boolean;
}
interface State {
  heading: string;
  content: string;
  recipients: Array<string | number | any>;
  images: Array<any>;

  validation:
    | any
    | {
        heading: string;
        content: string;
        recipients: any;
      };

  attachmentList: Array<any>;
  recipientList: Array<any>;
  recipientsMessageId: string;
  saveMessageId: string;
  editMessageId: string;
  notificationMessageId: string;
}
interface SS {}

class AddNotificationController extends BlockComponent<Props, State, SS> {
  state = {
    heading: "",
    content: "",
    recipients: [],

    validation: {
      heading: "",
      content: "",
      recipients: "",
    },

    images: [],
    attachmentList: [],
    recipientList: [],

    recipientsMessageId: "",
    saveMessageId: "",
    editMessageId: "",
    notificationMessageId: "",
  };

  constructor(props: Props) {
    super(props);

    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
    ]);

    this.receive = this.receive.bind(this);
  }

  receive(from: string, message: Message): void {
    if (!ApiResponseMiddleware(message)) return;

    const {
      notificationMessageId,
      recipientsMessageId,
      saveMessageId,
      editMessageId,
    } = this.state;

    let requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (requestId === recipientsMessageId) {
      if (successResponse) {
        let array = successResponse?.data;
        array = array.map((item: any) => {
          if (Array.isArray(item)) return { id: item[0], email: item[1] };
          else return item;
        });

        this.setState({ recipientList: array || [] });
        // for edit only
        this.getNotificationById();
      }
    }

    if (requestId === saveMessageId) {
      if (successResponse) {
        let errors = GetErrorMessages(successResponse);
        if (errors) {
          BlockHelpers.showAlert(Strings.labels.alert, errors);
          return;
        }

        BlockHelpers.showAlert(
          Strings.labels.success,
          Strings.messages.notificationAdd
        );
        this.props.navigation?.navigate("Vendor", {
          page: "notifications-menu",
        });
      }
    }

    if (requestId === editMessageId) {
      if (successResponse) {
        let errors = GetErrorMessages(successResponse);
        if (errors) {
          BlockHelpers.showAlert(Strings.labels.alert, errors);
          return;
        }
        BlockHelpers.showAlert(
          Strings.labels.success,
          Strings.messages.notificationEdit
        );
        this.props.navigation?.navigate("Vendor", {
          page: "notifications-menu",
        });
      }
    }

    if (requestId === notificationMessageId) {
      if (successResponse) {
        const { headings, contents, recipent, attachment } =
          successResponse?.data?.attributes;

        const recipientId =
          this.state.recipientList.filter(
            ({ email }) => email === recipent
            // @ts-ignore
          )[0]?.id || -1;

        if (attachment)
          this.setState({
            attachmentList: [
              {
                image: {
                  url: attachment,
                },
                name: Strings.labels.clickToView,
                isView: true,
              },
            ],
          });

        this.setState({
          heading: headings,
          content: contents,
          recipients: [recipientId],
        });
      }
    }
  }

  componentDidMount(): any {
    this.getRecipients();
  }

  getNotificationById() {
    // @ts-ignore
    const notificationId = this.props?.navigation?.getParam("id") || "";
    if (!notificationId) return;

    let requestMessage = GetApiMessage({
      endpoint: ApiUrls.notificationById + notificationId,
    });
    this.setState({ notificationMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRecipients() {
    let requestMessage = GetApiMessage({
      endpoint: ApiUrls.listRecipients,
    });
    this.setState({ recipientsMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onDeleteAttachment(index: number) {
    let newArray = this.state.attachmentList.filter((item, i) => index !== i);
    this.setState({ attachmentList: newArray });
  }

  isValid() {
    let errors: any = {};
    const saveErrors = (key: any, value: any) => (errors[key] = value);

    const { heading, content, recipients } = this.state;

    if (!heading) saveErrors("heading", Strings.validation.title);
    if (!content) saveErrors("content", Strings.validation.description);
    if (recipients.length === 0)
      saveErrors("recipients", Strings.validation.recipients);

    this.setState({
      validation: errors,
    });

    return !(Object.keys(errors).length > 0);
  }

  onSubmit() {
    if (!this.isValid()) return;
    // @ts-ignore
    const notificationId = this.props?.navigation?.getParam("id") || "";

    const { heading, content, recipients, attachmentList } = this.state;

    let formattedRecipients = recipients
      .map((id) => {
        let { email } =
          this.state.recipientList.filter((item: any) => item.id === id)[0] ??
          {};
        return email ?? null;
      })
      .filter((item) => item);

    let form = new FormData();
    form.set("headings", heading);
    form.set("contents", content);
    formattedRecipients.map((item: any) => form.append("recipients[]", item));
    attachmentList.map(
      (item: any) => !item?.isView && form.append("image", item)
    );

    let requestMessage: any;

    if (!this.props.isEdit) {
      requestMessage = GetApiMessage({
        data: form,
        method: "post",
        endpoint: ApiUrls.addNotification,
      });
      this.setState({ saveMessageId: requestMessage.messageId });
    } else {
      requestMessage = GetApiMessage({
        data: form,
        method: "put",
        endpoint: ApiUrls.editNotification + notificationId,
      });
      this.setState({ editMessageId: requestMessage.messageId });
    }

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

export default AddNotificationController;

// Customizable Area End