import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import GetApiMessage  from "../../utilities/src/GetApiMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  placeOrder: any;
  categoriesMessageId: any;
  homeCategoryList: any;
  specialList: any;
  newArrivalList: any;
  bestProductsList: any;
  specialMessageId: any;
  newArrivalMessageId: any;
  bestProductsMessageId: any;
  homeDataMessageId: any;
  homeData: any;
  videoMessageId: any;
  video: any;
  todayDealMessageId: any;
  todayDeals: any;
  beautyAndPersonalCareMessageId: any;
  beautyAndPersonalCare: any;
  browsingHistoryMessageId: any;
  browsingHistoryList: any;
  buyItAgain: any;
  buyItAgainMessageId: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      placeOrder: false,
      categoriesMessageId: '',
      homeCategoryList: [],
      specialList: [],
      newArrivalList: [],
      bestProductsList: [],
      specialMessageId: '',
      newArrivalMessageId: '',
      bestProductsMessageId: '',
      homeDataMessageId: '',
      homeData: [],
      videoMessageId: '',
      video: [],
      todayDealMessageId: '',
      todayDeals: [],
      beautyAndPersonalCareMessageId: '',
      beautyAndPersonalCare: [],
      browsingHistoryMessageId: '',
      browsingHistoryList: [],
      buyItAgain: [],
      buyItAgainMessageId: ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const { categoriesMessageId, specialMessageId, newArrivalMessageId, bestProductsMessageId, homeDataMessageId, videoMessageId, todayDealMessageId, beautyAndPersonalCareMessageId, browsingHistoryMessageId, buyItAgainMessageId } = this.state
    let requestID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    let successResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if (categoriesMessageId === requestID) {
      if (successResponse) {
        this.setState({ homeCategoryList: successResponse.data ?? [] })
      }
    } else if (specialMessageId === requestID) {
      if (successResponse) {
        this.setState({ specialList: successResponse.data ?? [] })
      }
    } else if (newArrivalMessageId === requestID) {
      if (successResponse) {
        this.setState({ newArrivalList: successResponse.data ?? [] })
      }
    } else if (bestProductsMessageId === requestID) {
      if (successResponse) {
        this.setState({ bestProductsList: successResponse.data ?? [] })
      }
    } else if (homeDataMessageId === requestID) {
      if (successResponse) {
        this.setState({ homeData: successResponse.data ?? [] })
      }
    } else if (videoMessageId === requestID) {
      if (successResponse) {
        this.setState({ video: successResponse.data ?? [] })
      }
    } else if (todayDealMessageId === requestID) {
      if (successResponse) {
        this.setState({ todayDeals: successResponse.data ?? [] })
      }
    } else if (beautyAndPersonalCareMessageId === requestID) {
      if (successResponse) {
        this.setState({ beautyAndPersonalCare: successResponse.data ?? [] })
      }
    } else if (browsingHistoryMessageId === requestID) {
      if (successResponse) {
        this.setState({ browsingHistoryList: successResponse.data ?? [] })
      }
    } else if (buyItAgainMessageId === requestID) {
      if (successResponse) {
        this.setState({ buyItAgain: successResponse.data ?? [] })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): any {
    this.getCategories();
    this.getBestProducts();
    this.getNewArrivalProducts();
    this.getSpecialProducts();
    this.gethomeData();
    this.getVideoData();
    this.getTodayDealsData();
    this.getBeautyPersoanlCareData();
    this.getBrowsingHistory();
    this.getBuyItAgain();
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  showPlaceOrder() {
    this.setState({
      placeOrder: true
    });
  }
  closeModal(){
    this.setState({
      placeOrder: false
    });
  }
  
  async getCategories() {
    let requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.categoryApiURL });
    this.setState({ categoriesMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async getSpecialProducts() {
    let requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.specialProductsApiURL });
    this.setState({ specialMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async getNewArrivalProducts() {
    let requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.newArrivalsApiURL });
    this.setState({ newArrivalMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async getBestProducts() {
    let requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.bestSellersApiURL });
    this.setState({ bestProductsMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async gethomeData() {
    let requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.homeDataApiURL });
    this.setState({ homeDataMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async getVideoData() {
    let requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.videoApiURL });
    this.setState({ videoMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async getTodayDealsData() {
    let requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.todayDealsApiURL });
    this.setState({ todayDealMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async getBeautyPersoanlCareData() {
    let requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.beautyPersonalApiURL });
    this.setState({ beautyAndPersonalCareMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async getBrowsingHistory() {
    const requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.browsingHistoryApiURL });
    this.setState({ browsingHistoryMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async getBuyItAgain() {
    const requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.buyItAgainApiURL });
    this.setState({ buyItAgainMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
