import React from "react";
import { Link } from "react-router-dom";

interface Props {}
interface State {}

class ConfirmReturnOrderDialog extends React.Component<Props, State> {
  render() {
    return (
      <section className="thanks">
        <div className="popup">
          <h4 className="logo">
            Company
            <br /> Logo
          </h4>
          <div>
            <div className="thanks-icon">
              <img src="/frontend/thanks.png" alt="thanks" />
            </div>
            <h4 className="heading">Return Confirmed</h4>
            <p className="description">
              Your order will be picked up in next 2-3 business days and your
              refund will be processed in 5-6 business days.
            </p>
          </div>
          <br />
          <br />
          <Link className="white-btn" to="/home">
            Continue
          </Link>
        </div>
      </section>
    );
  }
}

export default ConfirmReturnOrderDialog;
