Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  listSubscriptions: "/bx_block_custom_user_subs/subscriptions",
};

exports.Strings = {
  header: {
    title: "JHC | Subscription Plan",
    heading: "Subscription Plans",
  },
  labels: {
    editPlan: "Edit Plan",
    addPlan: "Add New",
    discountOffers: "Discounts & Offers",
    subscriptionPlan: "Subscription Plans",
  },
  table: {
    id: "ID",
    date: "Date",
    title: "Title",
    price: "Price",
    status: "Status",
    timeDuration:"Time Duration",
    description:"Description",
    productName:"Product Name"
  },
};
