import React from "react";
import moment from "moment";
import { styled, Checkbox } from "@material-ui/core";

import RoundedButton from "../../vendor/src/Shared/RoundedButton";
import { infoIcon } from "./assets";

interface Props {
  selectedItems: Array<any>;
  returnReasonList: Array<any>;
  orderDetailsData: any;
  isSelectedItem: Function;
  onClick: Function;
  onChange: Function;
}

class ChooseReturn extends React.Component<Props> {
  render() {
    const {
      onChange,
      onClick,
      selectedItems,
      isSelectedItem,
      returnReasonList,
      orderDetailsData,
    } = this.props;

    const orderItems = orderDetailsData.order_items.map((item: any) => ({
      id: item.id,
      ...item.attributes,
    }));

    const selectedProducts = orderItems.filter((item: any) => {
      let isSelected =
        selectedItems.filter((selected) => selected.id === item.id).length > 0;
      return isSelected;
    });

    return (
      <div className="shoppingCart">
        <div className="title">Choose reason to return</div>
        <div className="flex-box">
          <div className="flex-column1" data-test-id="data-order-items">
            {orderItems.map((item: any, index: number) => {
              const isRefundable =
                Boolean(item?.catalogue?.attributes?.refundable) ?? false;
              const selectedItem =
                selectedItems.filter(
                  (selected) => selected.id === item.id
                )[0] ?? {};

              return (
                <div
                  data-test-id={"catalogue-item"}
                  key={"catalogue-item-" + item.id}
                >
                  <RefundableWrapper
                    borderColor={!isRefundable ? "red" : "unset"}
                    className="mb-3"
                  >
                    <div className="cart-item">
                      <div className="product-img">
                        <img
                          className="w-100 h-100"
                          src={item?.catalogue?.attributes?.images[0]?.url}
                          alt="product"
                        />
                        {isRefundable && (
                          <Checkbox
                            data-test-id={"select-item-" + item.id}
                            color="primary"
                            onClick={() => onChange(item.id)}
                            className="item-checkbox"
                            size="small"
                          />
                        )}
                      </div>
                      <div className="product-detail">
                        <div className="detail-column">
                          <div>
                            <h6 className="productname">
                              {item?.catalogue?.attributes?.name}
                            </h6>
                            <p className="stock mb-0 text-green">In Stock</p>
                            <p className="size mb-3">Size:(Pack of 1)</p>
                          </div>
                        </div>
                        <div className="detail-column">
                          <div>
                            <label> Quantity : </label> &nbsp;
                            <input
                              type="number"
                              value={item?.quantity}
                              min={0}
                              max={item?.quantity}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {isRefundable ? (
                      isSelectedItem(item.id) && (
                        <>
                          <h5 className="mt-3">
                            What is the issue with this item
                          </h5>
                          <select
                            data-test-id={"select-reason-" + item.id}
                            defaultValue={-1}
                            className="return-reason"
                            onChange={(e) =>
                              onChange(item.id, e.target.value || null)
                            }
                          >
                            <option key={"reason-list-no-item"} value={-1}>
                              --- select ---
                            </option>
                            {returnReasonList.map(({ id, issue }: any) => (
                              <option key={"reason-list-item" + id} value={id}>
                                {issue}
                              </option>
                            ))}
                          </select>
                          {selectedItem?.error && (
                            <RedLabel>{selectedItem?.error}</RedLabel>
                          )}
                        </>
                      )
                    ) : (
                      <RedLabel>
                        <img src={infoIcon} />
                        &nbsp; This order cannot be returned as it is a
                        non-refundable item
                      </RedLabel>
                    )}

                    {index < orderItems.length - 1 && <hr />}
                  </RefundableWrapper>
                </div>
              );
            })}
          </div>
          <div className="flex-column2 productReturn pt-0">
            <div className="card rounded-0">
              <div className="card-body pt-4">
                <div className="buttonBox">
                  <RoundedButton
                    data-test-id="btn-continue"
                    text="Continue"
                    isActive={true}
                    onClick={() => onClick()}
                    disabled={selectedItems.length === 0}
                  />
                </div>
                <h6 className="mini-text">
                  Return by {moment().format("DD/MM/YYYY")}
                </h6>

                {selectedProducts.length > 0 && (
                  <>
                    <hr />
                    <h6>
                      <strong>Items you're returning</strong>
                    </h6>
                    <RefundableItemsImagesWrapper>
                      {selectedProducts.map((item: any) => {
                        return (
                          <img
                            key={item.id}
                            className="return-product border"
                            alt={
                              item?.catalogue?.attributes?.name +
                              " is to be return"
                            }
                            src={
                              item?.catalogue?.attributes?.images[0]?.url ||
                              "/frontend/s1.png"
                            }
                          />
                        );
                      })}
                    </RefundableItemsImagesWrapper>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const RefundableItemsImagesWrapper = styled("div")({
  gap: 12,
  display: "flex",
  alignItems: "flex-start",
  "& .return-product": {
    margin: 0,
  },
});

const RefundableWrapper = styled("div")((props: any) => ({
  "& .cart-item": {
    border: props.borderColor == "red" ? "solid 1px red !important" : "",
  },
  "& .item-checkbox": {
    top: 0,
    left: 0,
    position: "absolute",
  },
  "& .return-reason": {
    boxShadow: "none",
    backgroundColor: "white",
    border: "1px solid var(--content)",
  },
}));

const RedLabel = styled("div")({
  gap: 6,
  color: "red",
  display: "flex",
  alignItems: "center",
});

export default ChooseReturn;
