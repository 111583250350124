import React from "react";
// Customizable Area Start
import { Link } from 'react-router-dom';

// Customizable Area End

import ShoppingCartOrdersController, {
  Props,
} from "./ShoppingCartOrdersController";

export default class ShoppingCartOrders extends ShoppingCartOrdersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { orderList, cartMessage, showDeletePopup, showConfirmedPopup, subTotal, cartTotal } = this.state;
    return (
      <div className='shoppingCart'>
        <div className='title'>Shopping Cart</div>
        {cartMessage && (<p className='formErrors' style={{ 'textDecoration': 'center' }}>{cartMessage}</p>)}
        <div className='flex-box'>
          <div className='flex-column1'>
          {orderList[0]?.attributes?.order_items?.length > 0 && (<div className='top-bar justify-content-end'><h6>Price</h6></div>)}
            {(orderList?.length == 0 || orderList[0]?.attributes?.order_items?.length === 0) && (<p>Cart is empty.</p>)}
            {orderList[0]?.attributes?.order_items?.length > 0 && orderList[0]?.attributes?.order_items?.map((result: any, i: number) => (
              <div className={(result?.attributes?.catalogue?.attributes?.available === 'In Stock') ? 'cart-item' : 'cart-item red'} key={'totalOrders' + i}>
                <div className='product-img'>
                  <Link to={'/product/' + result?.attributes?.catalogue?.id} ><img className='w-100 h-100' src={result?.attributes?.catalogue?.attributes?.images.length > 0 ? result?.attributes?.catalogue?.attributes?.images[0]?.url : '/frontend/product1.png'} alt={result?.attributes?.catalogue?.attributes?.name} /></Link>
                </div>
                <div className='product-detail'>
                  <div className='detail-column'>
                    <div className='column'>
                      <h6 className='productname'><Link to={'/product/' + result?.attributes?.catalogue?.id} >{result?.attributes?.catalogue?.attributes?.name}</Link></h6>
                      <p className='stock'>{result?.attributes?.catalogue?.attributes?.available}</p>
                      <p className='size'>Size:(Pack of 1)</p>
                    </div>
                    <div className='column'>
                      <h6 className='productname'>{result?.attributes?.total_price} AED</h6>
                      <p className='blueLinks'>Save 5% more with Subscribe and save</p>
                    </div>
                  </div>
                  <div className='detail-column'>
                  <div className='column'>
                      <label> Quantity : </label> &nbsp;<input type='number' disabled={result?.attributes?.catalogue?.attributes?.available !== 'In Stock'} placeholder="1" onChange={(e) => { this.changeQuantity(e, i, result?.attributes?.catalogue_id) }} value={result?.attributes?.quantity} />
                    </div>
                    <div className='column'>
                      <p className='blueLinks' onClick={() => { this.confirmDeleteOrder(result?.id)}} style={{ cursor: 'pointer' }}>Delete</p>  &nbsp; &nbsp; &nbsp; |  &nbsp; &nbsp; &nbsp;
                      <p className='blueLinks'>See More Like This</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {orderList[0]?.attributes?.order_items?.length > 0 && (<h5 className='text-end mini-head'>Subtotal ({cartTotal} items): {subTotal} AED</h5>)}
          </div>
          {orderList[0]?.attributes?.order_items?.length > 0 && (
            <div className='flex-column2'>
              <div className='orderdetail'>
                <div className='head'>Subtotal ({cartTotal} items): {subTotal} AED</div>
                <div className='body'>
                  <Link to={'/checkout/all'}><button className='filled-btn' type='button'>Proceed to Buy</button></Link>
                </div>
              </div>
            </div>
          )}
        </div>
        {showDeletePopup ? <div className="modal show d-block" id="makePayment">
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirmation</h5>
            </div>
            <div className="modal-body">
              <div className='message'>
                    <h5 className='text-center mb-4'>Are you sure you want to delete this item?</h5>
                    <div className='buttonBox'>
                      <button className='buttons filled' data-test-id="deleteOrderItem" onClick={()=> {this.deleteOrderItem()}}>Yes</button>
                      <button className='buttons blank' data-test-id="hidePopup" onClick={()=> {this.hidePopup()}}>No</button>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div> : ''}
      {showConfirmedPopup ? <div className="modal show d-block" id="makePayment">
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className='message'>
                    <h5 className='text-center mb-4'>Successfully deleted.</h5>
                    <div className='buttonBox'>
                      <button className='buttons blank' data-test-id="hidePopup" onClick={()=> {this.hidePopup()}}>Ok</button>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div> : ''}
      </div>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start


// Customizable Area End
