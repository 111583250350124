// Customizable Area Start

import React from "react";
import { Helmet } from "react-helmet";
import {
  styled,
  Box,
  Grid,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";

import SubscriptionPlanFormController from "./SubscriptionPlanFormController";

import StyledHR from "../Shared/StyledHR";
import TitleHeading from "../Shared/TitleHeading";
import RoundedButton from "../Shared/RoundedButton";

const configJs = require("./config");
const Strings = configJs.Strings;

class SubscriptionPlanForm extends SubscriptionPlanFormController {
  render() {
    const isEdit = this.props.isEdit;
    const {catagloList,inputs,submitLoading,errors}=this.state;
    return (
      <div data-test-id="subscription-form-screen">
        <TitleHeading
          text={
            !isEdit ? Strings.header.headingAdd : Strings.header.headingEdit
          }
        />
        <StyledHR />
        <Helmet>
          <title>
            {!isEdit ? Strings.header.titleAdd : Strings.header.titleEdit}
          </title>
        </Helmet>
        <br />
        <Heading p={1}>{Strings.table.heading}</Heading>
        <Container p={2} pl={3} pr={4}>
          <Grid container spacing={2} alignItems="center">
            <Grid item lg={3}>
              <FormLabel>
                <div>{Strings.table.name}</div>
                <div>:</div>
              </FormLabel>
            </Grid>
            <Grid item lg={9}>
              <StyledSelect data-testid="product-dropdown" value={inputs.selectedCatelog} onChange={(e)=>this.handleCatelogChange(e)} variant="outlined">
                {
                  catagloList?.map((item)=>(
                    <MenuItem data-testid="product-item" key={item.id} value={item.id}>{item?.attributes?.name}</MenuItem>
                  ))
                }
              </StyledSelect>
              {
                errors?.product && <ErrorLabel>{errors?.product}</ErrorLabel>
              }
            </Grid>
            <Grid item lg={3}>
              <FormLabel>
                <div>{Strings.table.description}</div>
                <div>:</div>
              </FormLabel>
            </Grid>
            <Grid item lg={9}>
              <StyledInput 
                data-testid="description-input"
                name="description"
                value={inputs.description}
                onChange={this.handleInputChange}
                variant="outlined" multiline />
                {
                  errors?.description && <ErrorLabel>{errors?.description}</ErrorLabel>
                }
            </Grid>

            <Grid item lg={3}>
              <FormLabel>
                <div>{Strings.table.duration}</div>
                <div>:</div>
              </FormLabel>
            </Grid>
            <Grid item lg={9}>
              <Grid container spacing={1}>
                <Grid item>
                  <StyledInput 
                    data-testid="duration-from"
                    name="from"
                    value={inputs.from}
                    onChange={this.handleInputChange}
                    type="date"
                    variant="outlined" />
                     {
                      errors?.from && <ErrorLabel>{errors?.from}</ErrorLabel>
                    }
                </Grid>
                <Grid item>
                  <StyledInput
                      data-testid="duration-to"
                      name="to"
                      value={inputs.to}
                      onChange={this.handleInputChange}
                     type="date"
                     variant="outlined" />
                    {
                      errors?.to && <ErrorLabel>{errors?.to}</ErrorLabel>
                    }
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={3}>
              <FormLabel>
                <div>{Strings.table.price}</div>
                <div>:</div>
              </FormLabel>
            </Grid>
            <Grid item lg={9}>
              <StyledInput 
                data-testid="price-input"
                name="price"
                value={inputs.price}
                onChange={this.handleInputChange}
                type="number"
                variant="outlined" />
                {
                 errors?.price && <ErrorLabel>{errors?.price}</ErrorLabel>
                }
            </Grid>
            <Grid item lg={3} />
            <Grid item lg={9}>
              <RoundedButton
                data-testid="submit-info"
                isLoading={submitLoading}
                loadingText={isEdit?Strings.actions.updateLoadingText:Strings.actions.createLoadingText}
                text={
                  isEdit ? Strings.actions.saveChanges : Strings.actions.save
                }
                isActive={true}
                onClick={this.handleSubmit}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

const Heading = styled(Box)({
  color: "var(--white)",
  fontWeight: 600,
  background: "var(--gradient2)",
});

const Container = styled(Box)({
  boxShadow: "1px 1px 10px var(--boxShadow)",
});
const ErrorLabel = styled("div")({
  fontSize: 13,
  color: "#f44336",
  marginTop: 5,
});

const FormLabel = styled("div")({
  margin: 0,
  padding: 0,
  fontSize: 16,
  color: "black",
  fontWeight: 500,
  display: "grid",
  gridTemplateColumns: "1fr 5px",
});

const StyledInput = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-input": {
    padding: "6px 12px",
    paddingBottom: 8,
  },
});

const StyledSelect = styled(Select)({
  width: "100%",
  "& .MuiOutlinedInput-input": {
    padding: "6px 12px",
    paddingBottom: 8,
  },
});

export default SubscriptionPlanForm;

// Customizable Area End
