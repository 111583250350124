// Customizable Area Start
import React from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
import {
  styled,
  Grid,
  Table,
  Checkbox,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
} from "@material-ui/core";

import DiscountForm from "./DiscountForm";
import DiscountListController from "./DiscountListController";

import RoundedButtonWithIcon from "../Shared/RoundedButtonWithIcon";
import RoundedButton from "../Shared/RoundedButton";
import TitleHeading from "../Shared/TitleHeading";
import Pagination from "../Shared/Pagination";
import StyledHR from "../Shared/StyledHR";

import { iconAdd, iconEdit } from "../assets";

export const configJs = require("./config");
const Strings = configJs.Strings;

export class DiscountList extends DiscountListController {
  render() {
    const {
      lastPage,
      isLoading,
      currentPage,
      discountList,
      discountModal,
      selectedDiscountId,
    } = this.state;

    return (
      <>
        {discountModal && (
          <DiscountForm
            data-test-id={"discount-form"}
            discountId={selectedDiscountId}
            onRefetch={() => this.getDiscountList()}
            onClose={() =>
              this.setState({ discountModal: false, selectedDiscountId: null })
            }
          />
        )}
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Helmet>
              <title>{Strings.header.title}</title>
            </Helmet>
            <TitleHeading text={Strings.labels.heading} />
          </Grid>
          <Grid item>
            <RoundedButtonWithIcon
              data-test-id="btn-add"
              icon={iconAdd}
              text={Strings.actions.add}
              onClick={() =>
                this.setState({ discountModal: true, selectedDiscountId: null })
              }
            />
          </Grid>
        </Grid>
        <StyledHR />

        <Grid container spacing={1}>
          <Grid item>
            <RoundedButton
              text={Strings.actions.discountAndOffers}
              isActive={true}
            />
          </Grid>
          <Grid item>
            <RoundedButton
              data-test-id="btn-subscription"
              onClick={() =>
                this.props.navigation.navigate("Vendor", {
                  page: "subscriptions",
                })
              }
              text={Strings.actions.subscriptionPlan}
              isActive={false}
            />
          </Grid>
        </Grid>

        <br />

        <StyledTableContainer>
          <Table size="small">
            <StyledTableHead>
              <TableRow>
                {[
                  Strings.table.productName,
                  Strings.table.discountType,
                  Strings.table.value,
                  Strings.table.expiry,
                  Strings.table.freeShipping,
                  Strings.table.createdAt,
                  Strings.table.action,
                ].map((colName: string) => (
                  <StyledTableHeadCell key={"discount-item-col-" + colName}>
                    {colName}
                  </StyledTableHeadCell>
                ))}
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {discountList.length === 0 && (
                <StyledTableRow key={"discount-item-no-data"}>
                  <StyledNoDataTd colSpan={7}>
                    {isLoading ? Strings.table.loading : Strings.table.noData}
                  </StyledNoDataTd>
                </StyledTableRow>
              )}
              {discountList.map((item: any) => {
                const data: any = { id: item?.id, ...item?.attributes };

                return (
                  <StyledTableRow key={"discount-item-list" + item.id}>
                    <TableCell>{data?.product_name}</TableCell>
                    <TableCell>{data?.discount_type}</TableCell>
                    <TableCell>{data?.discount}</TableCell>
                    <TableCell>
                      {moment(data?.expire_at).format("DD/MM/YYYY HH:mm:ss")}
                    </TableCell>
                    <TableCell>
                      <StyledCheckbox
                        color="primary"
                        size="small"
                        checked={data?.allow_free_shipping}
                      />
                    </TableCell>
                    <TableCell>
                      {moment(data?.created_at).format("DD/MM/YYYY HH:mm:ss")}
                    </TableCell>
                    <TableCell>
                      <img
                        data-test-id={"btn-edit-" + item.id}
                        onClick={() =>
                          this.setState({
                            selectedDiscountId: item?.id,
                            discountModal: true,
                          })
                        }
                        src={iconEdit}
                        style={{ cursor: "pointer" }}
                      />
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </StyledTableContainer>
        {discountList.length !== 0 && (
          <Grid container justifyContent="flex-end">
            <Grid item>
              <br />
              <Pagination
                data-test-id="pagination"
                lastPage={lastPage}
                currentPage={currentPage}
                onChangePage={(page: number) => this.onChangePage(page)}
              />
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

const StyledCheckbox = styled(Checkbox)({
  color: "var(--navy) !important",
});

const StyledNoDataTd = styled(TableCell)({
  height: 80,
  textAlign: "center",
  verticalAlign: "middle",
});

const StyledTableHead = styled(TableHead)({
  color: "var(--white)",
  textTransform: "uppercase",
  background: "var(--gradient2)",
  "& .MuiCheckbox-root": {
    color: "white",
  },
});

const StyledTableHeadCell = styled(TableCell)({
  fontWeight: 400,
  fontSize: 15,
  color: "white",
});

const StyledTableRow = styled(TableRow)({
  boxShadow: "0 0 5px var(--boxShadow)",
});

const StyledTableContainer = styled(TableContainer)({
  boxShadow: "0px 0px 10px var(--boxShadow)",
});

export default DiscountList;
// Customizable Area End
