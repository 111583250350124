// Customizable Area Start

import React from "react";
import { Helmet } from "react-helmet";
import { Grid, styled } from "@material-ui/core";

import AppLayoutController from "./AppLayoutController";
import withReduxWrapper from "../withReduxWrapper";
import Sidebar from "./Sidebar.web";
import Header from "./Header.web";

import AlertBox from "../Shared/AlertMessage";

const configJs = require("./config");
const Strings = configJs.Strings;

export class AppLayout extends AppLayoutController {
  render() {
    return (
      <Grid container>
        <Grid item xs={12}>
          {/* @ts-ignore */}
          <Header {...this.props} profile={this.state.profileData} />
        </Grid>
        <Grid item xs={2}>
          <Sidebar {...this.props} profile={this.state.profileData} />
        </Grid>
        <Grid item xs={10}>
          <Helmet>
            <title>{Strings.header.title}</title>
          </Helmet>
          <AlertBox />
          <Container>{this.props.children}</Container>
        </Grid>
      </Grid>
    );
  }
}

export default withReduxWrapper(AppLayout);

const Container = styled("div")({
  padding: 10,
  width: "100%",
  height: "100%",
  display: "block",
  minHeight: "90vh",
  backgroundColor: "var(--white)",
  border: "10px solid var(--contentBorder)",
});

// Customizable Area End
