// Customizable Area Start
import React from "react";
import {
  Box,
  Grid,
  styled,
  TextField,
  Typography,
  InputAdornment,
} from "@material-ui/core";

import CareersController from "./CareersController";
import ReadMoreBlock from "../../../blocks/vendor/src/ReadMoreBlock";

const searchIcon = require("../assets/search.svg");
const locationIcon = require("../assets/location.svg");

class Careers extends CareersController {
  render() {
    const paragraph =
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin mattis odio quis accumsan euismod. Aliquam ut sem sed tortor consectetur hendrerit id et elit. Fusce velit lacus, gravida vitae ultrices sed, aliquam quis felis. Nullam viverra quam id iaculis pharetra. Praesent tortor sem, aliquam ut urna eget, pretium vulputate enim. Suspendisse neque justo, vehicula a aliquet a, aliquet nec nisi. Nam eros neque, placerat sit amet vulputate eu, pharetra id nulla. Cras in nunc pellentesque, ultrices metus accumsan, tempus augue. Aenean posuere sem a ligula semper commodo." +
      "Curabitur maximus ex ac elit dignissim pretium. Morbi faucibus porta purus eu congue. Donec consectetur lectus sem, ut iaculis sapien finibus vel. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec porta porttitor sapien eget mattis. Integer vulputate lorem eget dapibus ultricies. Ut elementum imperdiet ante at auctor. Morbi fermentum enim eget feugiat sodales." +
      "Cras lectus tortor, rhoncus vel interdum quis, condimentum quis eros. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum tincidunt mi ut nisl convallis, quis maximus sem tempor. Maecenas at ipsum ante. Nulla a volutpat enim, id sodales turpis. Cras fringilla felis ac scelerisque pharetra. Proin vitae velit vel felis finibus ultricies et sit amet ligula. Nullam quis pretium magna. Praesent rhoncus turpis tellus. Sed est tortor, convallis sed nulla et, scelerisque tristique augue. Donec ultrices velit quis neque molestie, sit amet egestas erat efficitur.";

    return (
      <>
        <Heading>Career</Heading>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={8}>
              <JobHeading>Find jobs</JobHeading>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={7}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search job by title or keyword"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={locationIcon} />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    variant="outlined"
                    placeholder="Location"
                  />
                </Grid>
                <Grid item xs={1}>
                  <StyledButton>
                    <img width={30} src={searchIcon} />
                  </StyledButton>
                </Grid>
              </Grid>
              <br />
              {[1, 1, 1, 1].map(() => {
                return (
                  <JobCard padding={2} borderRadius={4} marginBottom={2}>
                    <div className="job-container">
                      <div className="job-title">Some job title</div>
                      <div className="job-date">Posted On 12/02/2023</div>
                    </div>
                    <div className="job-location">
                      <img src={locationIcon} width={15} />
                      &nbsp;London, UK
                    </div>
                    <div>
                      <ReadMoreBlock
                        text={paragraph}
                        minLength={200}
                        fontSize={14}
                      />
                    </div>
                    <div className="job-apply">
                      <StyledApplyButton>Apply</StyledApplyButton>
                    </div>
                  </JobCard>
                );
              })}
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}

const JobCard = styled(Box)({
  color: "var(--content)",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  "& .job-container": {
    display: "flex",
    justifyContent: "space-between",
    "& > div:last-child": {
      minWidth: 150,
      textAlign: "right",
    },
    marginBottom: 4,
  },
  "& .job-location": {
    marginBottom: 8,
  },
  "& .job-title": {
    fontSize: 18,
    fontWeight: "bold",
    color: "var(--navy)",
  },
  "& .job-date": {
    fontSize: 12,
  },
  "&:hover": {
    border: "1px solid var(--navy)",
  },
  "& .job-apply": {
    textAlign: "right",
  },
});

const StyledApplyButton = styled("button")({
  fontSize: 13,
  marginTop: 12,
  color: "white",
  border: "none",
  borderRadius: 4,
  padding: "4px 12px",
  fontWeight: "bold",
  backgroundColor: "var(--navy)",
  "&:hover": {
    color: "white",
    opacity: 0.7,
  },
});

const StyledButton = styled("button")({
  marginTop: -5,
  borderRadius: 4,
  padding: "0px 16px",
  backgroundColor: "white",
  height: "calc(100% + 5px)",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  "&:hover": {
    borderColor: "rgba(0, 0, 0, 0.87)",
  },
});

const Heading: any = styled(Typography)({
  fontSize: 42,
  color: "white",
  marginBottom: 32,
  fontWeight: "bold",
  padding: "42px 0px",
  textAlign: "center",
  textTransform: "uppercase",
  background:
    "linear-gradient(189deg, rgba(0,110,184,1) 0%, rgba(2,101,167,1) 100%)",
});

const Container = styled("div")({
  padding: "32px 0px",
});

const JobHeading = styled("h3")({
  fontSize: 29,
  marginBottom: 20,
  fontWeight: "bold",
});

export default Careers;
// Customizable Area End
