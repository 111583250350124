// Customizable Area Start

import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import {
  styled,
  Grid,
  Select,
  Checkbox,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { DatePicker, TimePicker } from "@material-ui/pickers";

import GetTruncateText from "../GetTruncateText";
import RoundedButton from "../Shared/RoundedButton";
import DiscountsController from "./DiscountFormController";

const configJs = require("./config");
const Strings = configJs.Strings;

class DiscountForm extends DiscountsController {
  render() {
    const { discountId } = this.props;
    const { item, validation, catalogueList } = this.state;
    const isEdit = discountId ? true : false;

    return (
      <Dialog
        open
        data-test-id="dialogBox"
        onClose={() => this.props.onClose()}
      >
        <DialogTitle>
          {!isEdit ? Strings.labels.formTitleAdd : Strings.labels.formTitleEdit}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item sm={6} xs={12}>
              <FormLabel>
                <div>
                  {Strings.labels.productId}
                  <RedLabel>*</RedLabel>
                </div>
                <div>:</div>
              </FormLabel>
            </Grid>
            <Grid item sm={6} xs={12}>
              <StyledSelect
                data-test-id="field-catalogues"
                variant="outlined"
                value={item.catalogues_id}
                onChange={(e) =>
                  this.onChangeItem("catalogues_id", e.target.value)
                }
              >
                <MenuItem key={"select-item-catalogue-empty"} value={""}>
                  {Strings.labels.select}
                </MenuItem>
                {catalogueList.map((item: any) => (
                  <MenuItem key={"catalogue-item-" + item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </StyledSelect>
              {validation.catalogues_id && (
                <ErrorLabel>{validation.catalogues_id}</ErrorLabel>
              )}
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormLabel>
                <div>
                  {Strings.labels.discountType}
                  <RedLabel>*</RedLabel>
                </div>
                <div>:</div>
              </FormLabel>
            </Grid>
            <Grid item sm={6} xs={12}>
              <StyledSelect
                data-test-id="field-discountType"
                value={item.discount_type}
                onChange={(e) =>
                  this.onChangeItem("discount_type", e.target.value)
                }
                variant="outlined"
              >
                <MenuItem value={"flat"}>{Strings.labels.flat}</MenuItem>
                <MenuItem value={"percentage"}>
                  {Strings.labels.percentage}
                </MenuItem>
              </StyledSelect>
              {validation.discount_type && (
                <ErrorLabel>{validation.discount_type}</ErrorLabel>
              )}
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormLabel>
                <div>
                  {Strings.labels.paymentBased}
                  <RedLabel>*</RedLabel>
                </div>
                <div>:</div>
              </FormLabel>
            </Grid>

            <Grid item sm={6} xs={12}>
              <StyledSelect
                data-test-id="field-paymentType"
                value={item.payment_based}
                onChange={(e) =>
                  this.onChangeItem("payment_based", e.target.value)
                }
                variant="outlined"
              >
                <MenuItem value="card_payment">
                  {Strings.labels.cardPayment}
                </MenuItem>
                <MenuItem value="sadad_payment">
                  {Strings.labels.sadadPayment}
                </MenuItem>
                <MenuItem value="cash_on_delivery">
                  {Strings.labels.cashOnDelivery}
                </MenuItem>
              </StyledSelect>
              {validation.payment_based && (
                <ErrorLabel>{validation.payment_based}</ErrorLabel>
              )}
            </Grid>

            {/* <Grid item sm={6} xs={12}>
              <FormLabel>
                <div>
                  {Strings.labels.quantity}
                  <RedLabel>*</RedLabel>
                </div>
                <div>:</div>
              </FormLabel>
            </Grid>

            <Grid item sm={6} xs={12}>
              <StyledInput
                data-test-id="field-quantity"
                value={item.quantity}
                onChange={(e) => this.onChangeItem("quantity", e.target.value)}
                variant="outlined"
                type="number"
              />
              {validation.quantity && (
                <ErrorLabel>{validation.quantity}</ErrorLabel>
              )}
            </Grid> */}

            <Grid item sm={6} xs={12}>
              <FormLabel>
                <div>
                  {item.discount_type === "percentage"
                    ? Strings.labels.percentage
                    : Strings.labels.couponAmount}
                  <RedLabel>*</RedLabel>
                </div>
                <div>:</div>
              </FormLabel>
            </Grid>

            <Grid item sm={6} xs={12}>
              <StyledInput
                data-test-id="field-discount"
                value={item.discount}
                onChange={(e) => this.onChangeItem("discount", e.target.value)}
                variant="outlined"
                type="number"
              />
              {validation.discount && (
                <ErrorLabel>{validation.discount}</ErrorLabel>
              )}
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormLabel>
                <div>
                  {Strings.labels.couponExpiry}
                  <RedLabel>*</RedLabel>
                </div>
                <div>:</div>
              </FormLabel>
            </Grid>

            <Grid item sm={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <StyledDatePicker
                    data-test-id="field-expiryDate"
                    fullWidth
                    disablePast
                    format="DD/MM/YYYY"
                    inputVariant="outlined"
                    value={item.expire_at}
                    onChange={(e) =>
                      this.onChangeItem(
                        "expire_at",
                        moment(e).format("YYYY-MM-DD HH:mm:ss")
                      )
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <StyledTimePicker
                    data-test-id="field-expiryTime"
                    fullWidth
                    inputVariant="outlined"
                    value={item.expire_at}
                    onChange={(e) =>
                      this.onChangeItem(
                        "expire_at",
                        moment(e).format("YYYY-MM-DD HH:mm:ss")
                      )
                    }
                  />
                </Grid>
              </Grid>
              {validation.expire_at && (
                <ErrorLabel>{validation.expire_at}</ErrorLabel>
              )}
            </Grid>

            <Grid item sm={6}>
              <FormLabel>
                <div>{Strings.labels.allowFreeShipping}</div>
                <div>:</div>
              </FormLabel>
            </Grid>

            <Grid item sm={6}>
              <div>
                <Checkbox
                  id="allowFreeShipping"
                  data-test-id="field-allowFreeShipping"
                  style={{ padding: 0, paddingRight: 8 }}
                  checked={item?.allow_free_shipping}
                  onChange={(e) =>
                    this.onChangeItem("allow_free_shipping", e.target.checked)
                  }
                />
              </div>
            </Grid>

            <Grid item sm={6}>
              <FormLabel>
                <div>
                  {Strings.labels.photo}
                  <RedLabel>*</RedLabel>
                  {item?.photo && "  " && (
                    <StyledViewImage to={item?.photo || "#"} target="_blank">
                      (View)
                    </StyledViewImage>
                  )}
                </div>
                <div>:</div>
              </FormLabel>
            </Grid>

            <Grid item sm={6}>
              <UploadLabel htmlFor="uploadButton">Upload</UploadLabel>

              <input
                data-test-id="field-photo"
                type="file"
                id="uploadButton"
                accept=".jpg, .jpeg, .png"
                style={{ display: "none" }}
                onChange={(e: any) =>
                  this.onChangeItem("photoDoc", e.target.files[0] ?? null)
                }
              />
              {/* @ts-ignore */}
              {item?.photoDoc?.name && (
                <>
                  <br />
                  {/* @ts-ignore */}
                  {GetTruncateText(item?.photoDoc?.name, 25)}
                </>
              )}
              {validation.photoDoc && (
                <ErrorLabel>{validation.photoDoc}</ErrorLabel>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <RoundedButton
                data-test-id="btn-cancel"
                text={Strings.actions.cancel}
                isActive={false}
                onClick={() => this.props.onClose()}
              />
            </Grid>
            <Grid item>
              <RoundedButton
                data-test-id="btn-submit"
                onClick={() => this.onSubmit()}
                text={
                  isEdit ? Strings.actions.saveChanges : Strings.actions.save
                }
                isActive={true}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
}

const StyledViewImage = styled(Link)({
  fontSize: 14,
  "&:hover": {
    color: "var(--navy)",
  },
});

const UploadLabel = styled("label")({
  cursor: "pointer",
  padding: "2px 12px",
  borderRadius: "4px",
  color: "var(--navy)",
  border: "2px solid var(--navy)",
});

const RedLabel = styled("span")({
  color: "red",
});

const StyledTimePicker = styled(TimePicker)({
  "& .MuiOutlinedInput-input": {
    padding: 8,
  },
});
const StyledDatePicker = styled(DatePicker)({
  "& .MuiOutlinedInput-input": {
    padding: 8,
  },
});

const FormLabel = styled("div")({
  margin: 0,
  padding: 0,
  fontSize: 16,
  color: "black",
  fontWeight: 500,
  display: "grid",
  gridTemplateColumns: "1fr 5px",

  "@media only screen and (max-width: 425px)": {
    "& > div:last-child": {
      display: "none",
    },
  },
});

const StyledInput = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-input": {
    padding: "6px 12px",
    paddingBottom: 8,
  },
});
const StyledSelect = styled(Select)({
  width: "100%",
  "& .MuiOutlinedInput-input": {
    padding: "6px 12px",
    paddingBottom: 8,
  },
});

const ErrorLabel = styled("div")({
  fontSize: 13,
  color: "#f44336",
  marginTop: 5,
});

export default DiscountForm;

// Customizable Area End
