// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";

import firebase from "firebase";
import GetApiMessage from "../GetApiMessage";
import ISuccessResponse from "../ISuccessResponse";
import ApiResponseMiddleware from "../ApiResponseMiddleware";

const configJs = require("./config");
const ApiUrls = configJs.ApiUrls;

interface Props {
  profile: any;
  history?: {
    push: Function;
  };
  navigation?: {
    navigate?: any;
    getParam?: Function;
  };
}
interface SS {}
interface S {
  searchQuery: string;
  notificationListMessageId: any;
  logoutMessageApiId: any;

  notificationList: Array<any>;
  notificationCount: number;
}

class HeaderController extends BlockComponent<Props, S, SS> {
  state = {
    searchQuery: "",
    logoutMessageApiId: null,
    notificationListMessageId: null,
    notificationList: [],
    notificationCount: 0,
  };

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    super.componentDidMount();
    this.getNotification();
    this.setupLiveNotificationStatus();

    setTimeout(() => {
      this.setupLiveNotificationStatus();
    }, 5000);
  }

  receive(from: string, message: Message): void {
    if (!this.isLoaded) return;
    if (!ApiResponseMiddleware(message)) return;

    let requestID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (requestID === this.state.logoutMessageApiId) {
      if (successResponse) {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        this.props.navigation?.navigate("login");
      }
    } else if (requestID === this.state.notificationListMessageId) {
      if (successResponse) {
        this.setState({ notificationList: successResponse.data || [] });
      }
    }
  }

  setupLiveNotificationStatus() {
    if (firebase.apps.length === 0) return;

    let database = firebase.database();
    database.goOnline();

    let notifications = database.ref("notifications");
    notifications.on("value", (snapshot) => {
      let data = snapshot.val();
      let userId = localStorage.getItem("userId") || "";
      let { count } = data[userId] || { count: 0 };

      if (this.state.notificationCount !== count) {
        this.setState({ notificationCount: count });
        this.getNotification();
      }
    });
  }

  onClickLogout() {
    let requestMessage = GetApiMessage({
      method: "delete",
      endpoint: ApiUrls.logout,
    });
    this.setState({ logoutMessageApiId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getNotification() {
    let requestMessage = GetApiMessage({
      endpoint: ApiUrls.notificationList + "?per_page=15",
    });
    this.setState({ notificationListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onClickSearch() {
    const { searchQuery } = this.state;
    if (searchQuery) {
      this.props?.history?.push("/d/search?s=" + searchQuery);
    }
  }
}

export default HeaderController;

// Customizable Area End
