import getActionString from "./GetActionString";

const prefix = "profile";

export interface iActions {
  SAVE: string;
  CLEAR: string;
  REFETCH: string;
}

const actions: iActions = {
  SAVE: getActionString(prefix, "save"),
  CLEAR: getActionString(prefix, "clear"),
  REFETCH: getActionString(prefix, "refetch"),
};

export default actions;
