// Customizable Area Start

import React from "react";
import "./Vendor.web.css";
import DateFnsUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import AppLayout from "./AppLayout/AppLayout.web";
import OrdersView from "./Orders/Orders.web";
import Inventory from "./Inventory/Inventory.web";
import EditProfile from "./EditProfile/EditProfile.web";
import OrderDetails from "./Orders/OrderDetails/OrderDetails.web";
import ProfileSettings from "./ProfileSettings/ProfileSettings.web";
import ProductsByCategory from "./ProductsByCategory/ProductsByCategory.web";
import VendorRegistration from "./VendorRegistration/VendorRegistration.web";
import Payments from "./Payments/Payments.web";
import AddInventoryItem from "./AddInventory/AddInventory.web";
import SubscriptionPlanForm from "./SubscriptionPlanForm/SubscriptionPlanForm.web";
import Notifications from "./Notifications/Notifications.web";
import NotificationsMenu from "./NotificationMenu/NotificationMenu.web";
import NotificationAdd from "./AddNotification/AddNotification.web";
import AdHocReporting from "./AdHocReporting/AdHocReporting.web";
import ContactUs from "./ContactUs/ContactUs.web";
import Discounts from "./Discounts/DiscountList.web";
import Subscriptions from "./Subscriptions/SubscriptionPlanList.web";

import VendorController from "./VendorController";

class Vendor extends VendorController {
  state = {
    routingPaths: [
      {
        path: "contact-us",
        component: <ContactUs />,
      },
      {
        path: "report",
        component: <AdHocReporting />,
      },
      {
        path: "notifications-add",
        // @ts-ignore
        component: <NotificationAdd isEdit={false} {...this.props} />,
      },
      {
        path: "notifications-edit",
        // @ts-ignore
        component: <NotificationAdd isEdit={true} {...this.props} />,
      },
      {
        path: "notifications-menu",
        component: <NotificationsMenu />,
      },
      {
        path: "notifications",
        component: <Notifications />,
      },
      {
        path: "add-subscription-plan",
        component: <SubscriptionPlanForm {...this.props} isEdit={false} />,
      },
      {
        path: "edit-subscription-plan",
        component: <SubscriptionPlanForm {...this.props} isEdit={true} />,
      },
      {
        path: "subscriptions",
        component: <Subscriptions {...this.props} />,
      },
      {
        path: "discounts",
        component: <Discounts {...this.props} />,
      },
      { path: "orders", component: <OrdersView /> },
      {
        path: "payments",
        component: (
          <Payments listType={null} onChangeList={null} {...this.props} />
        ),
      },
      { path: "inventory", component: <Inventory {...this.props} /> },
      { path: "menu", component: <ProductsByCategory /> },
      { path: "profile", component: <ProfileSettings /> },
      {
        path: "edit-profile",
        component: <EditProfile {...this.props} />,
      },
      {
        path: "add-inventory-item",
        component: (
          <AddInventoryItem
            navigation={this.props.navigation}
            inventoryId={null}
          />
        ),
      },
      {
        path: "edit-inventory-item",
        component: (
          <AddInventoryItem
            navigation={this.props.navigation}
            inventoryId={this.props.navigation.getParam("id")}
          />
        ),
      },
      {
        path: "order-details",
        component: (
          <OrderDetails
            selectedOrderId={this.props.navigation.getParam("id")}
          />
        ),
      },
      {
        path: "registration",
        component: <VendorRegistration {...this.props} />,
      },
    ],
  };

  render() {
    const currentRoute = this.props.navigation.getParam("page");
    let viewComponent = this.state.routingPaths.filter(
      ({ path }) => path === currentRoute
    );
    let renderComponent = <h4>Page Not Found</h4>;

    if (viewComponent.length) {
      renderComponent = viewComponent[0].component;
    }

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {/* @ts-ignore */}
        <AppLayout data-test-id="app-layout" {...this.props}>
          {renderComponent}
        </AppLayout>
      </MuiPickersUtilsProvider>
    );
  }
}

export default Vendor;

// Customizable Area End
