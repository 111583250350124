import getActionString from "./GetActionString";

const prefix = "dialogues";

export interface iActions {
  accountApproved: string;
}

const actions: iActions = {
  accountApproved: getActionString(prefix, "accountApproved"),
};

export default actions;
