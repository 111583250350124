// Customizable Area Start

import React from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  styled,
  Grid,
  Button,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";

import { iconUpload, iconDelete } from "../assets";

import AddInventoryController from "./AddInventoryController";
import RoundedButton from "../Shared/RoundedButton";
import GetTruncateText from "../GetTruncateText";
import RteEditor from "./RteDescriptionEditor";
import NewBrandModal from "./NewBrandModal";

const configJs = require("./config");
const Strings = configJs.Strings;

class AddInventoryItem extends AddInventoryController {
  render() {
    const { isLoading } = this.state;
    const isEdit = this.props.inventoryId !== null;
    const {
      mainViewImageLink,
      frontViewImageLink,
      backViewImageLink,
      leftViewImageLink,
      rightViewImageLink,
    } = this.state.product;
    const {
      productCode,
      selectedBrandId,
      categoryId,
      name,
      batchNo,
      expDate,
      weight,
      mfgDate,
      quantity,
      retailPrice,
      salesPrice,
      benefits,
      description,
      mainViewImage,
      leftViewImage,
      rightViewImage,
      frontViewImage,
      backViewImage,
      min_stock_qty,
      taxes,
      subCategory,
    } = this.state.validation;

    const {
      categoryList,
      brandList,
      isProductEditable,
      isBrandModal,
      subCategoryList,
    } = this.state;

    return (
      <div data-test-id="add-inventory-screen" className="inventory">
        <Helmet>
          <title>
            {isEdit ? Strings.header.titleEdit : Strings.header.titleAdd}
          </title>
        </Helmet>
        <div className="head">
          <h4 className="title">
            {isEdit ? Strings.actions.edit : Strings.actions.add}{" "}
            {Strings.actions.item}
          </h4>
        </div>

        {isBrandModal && (
          <NewBrandModal
            data-test-id="brand-modal"
            onSet={(id: number) => this.setState({ selectedBrandId: id })}
            selectedCategoryId={this.state.selectedCategoryId}
            brandList={brandList}
            onRefetch={() =>
              this.getBrandsFromApi(this.state.selectedCategoryId)
            }
            onClose={() =>
              this.setState({
                isBrandModal: false,
              })
            }
          />
        )}

        <Grid container spacing={2} alignItems="flex-start">
          <Grid item sm={6} xs={12}>
            <StyledInput
              select
              error={categoryId ? true : false}
              helperText={categoryId}
              data-test-id="field-category"
              value={this.state.selectedCategoryId}
              onChange={(e: any) => this.getBrandsFromApi(e.target.value)}
              label={Strings.labels.category}
              variant="standard"
              requiredField
            >
              {categoryList.map((item: any) => (
                <MenuItem key={"category-list-id-" + item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </StyledInput>
          </Grid>
          <Grid item sm={6} xs={12}>
            <StyledInput
              select
              error={subCategory ? true : false}
              helperText={subCategory}
              data-test-id="field-subCategory"
              value={this.state.product.subCategory}
              onChange={(e: any) =>
                this.onChangeProduct("subCategory", e.target.value)
              }
              label={Strings.labels.subCategory}
              variant="standard"
              requiredField
            >
              {subCategoryList.map((item: any) => (
                <MenuItem
                  key={"subCategory-list-id-" + item.id}
                  value={item.id}
                >
                  {item.name}
                </MenuItem>
              ))}
            </StyledInput>
          </Grid>
          <Grid item sm={6} xs={12}>
            <StyledInput
              select
              data-test-id="field-brand"
              value={this.state.selectedBrandId}
              onChange={(e: any) => this.getInventoryByBrand(e.target.value)}
              error={selectedBrandId ? true : false}
              helperText={selectedBrandId}
              label={Strings.labels.brand}
              variant="standard"
              requiredField
            >
              {!this.state.selectedCategoryId ? (
                <MenuItem disabled>
                  {Strings.messages.pleaseSelectCategory}
                </MenuItem>
              ) : (
                <MenuItem key={"create-new-brand"} value={"create-new-brand"}>
                  {Strings.messages.createNewBrand}
                </MenuItem>
              )}

              {brandList.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </StyledInput>
          </Grid>
          <Grid item sm={6} xs={12}>
            <StyledInput
              data-test-id="field-batch-no"
              onChange={(e: any) =>
                this.onChangeProduct("modalNo", e.target.value)
              }
              value={this.state.product.modalNo}
              error={batchNo ? true : false}
              helperText={batchNo}
              label={Strings.labels.batchNo}
              variant="standard"
              size="small"
              requiredField
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <StyledInput
              data-test-id="field-product-name"
              value={this.state.product.name}
              inputProps={{ list: "inventory-dropdown-list" }}
              onChange={(e: any) => this.onSelectProduct(e.target.value)}
              error={name ? true : false}
              helperText={name}
              label={Strings.labels.productName}
              variant="standard"
              size="small"
              requiredField
            />
            <datalist id="inventory-dropdown-list">
              {this.state.inventoryProductList.map(({ name }, index) => {
                return (
                  <option key={"ipl-" + index + "-" + name} value={name}>
                    {name}
                  </option>
                );
              })}
            </datalist>
          </Grid>
          <Grid item sm={6} xs={12}>
            <StyledInput
              data-test-id="field-product-code"
              onChange={(e: any) =>
                this.onChangeProduct("productCode", e.target.value)
              }
              disabled={isProductEditable}
              value={this.state.product.productCode}
              error={productCode ? true : false}
              helperText={productCode}
              label={Strings.labels.productCode}
              variant="standard"
              size="small"
              requiredField
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <StyledDatePicker2
              data-test-id="field-mfgDate"
              fullWidth
              disableFuture
              format="DD/MM/YYYY"
              onChange={(e: any) =>
                this.onChangeProduct("mfgDate", moment(e).format("YYYY-MM-DD"))
              }
              value={this.state.product.mfgDate}
              error={mfgDate ? true : false}
              helperText={mfgDate}
              label={Strings.labels.mfgDate}
              requiredField
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <StyledDatePicker2
              data-test-id="field-expDate"
              fullWidth
              format="DD/MM/YYYY"
              onChange={(e: any) =>
                this.onChangeProduct("expDate", moment(e).format("YYYY-MM-DD"))
              }
              value={this.state.product.expDate}
              error={expDate ? true : false}
              helperText={expDate}
              label={Strings.labels.expDate}
              requiredField
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <StyledInput
              data-test-id="field-retailPrice"
              InputProps={{
                type: "number",
              }}
              inputProps={{ min: 0 }}
              onKeyDown={(e: any) => {
                ["+", "-", "e"].includes(e.key) && e.preventDefault();
              }}
              onChange={(e: any) =>
                this.onChangeProduct("retailPrice", e.target.value)
              }
              value={this.state.product.retailPrice}
              error={retailPrice ? true : false}
              helperText={retailPrice}
              label={Strings.labels.retailPrice}
              variant="standard"
              size="small"
              requiredField
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <StyledInput
              data-test-id="field-salesPrice"
              InputProps={{
                type: "number",
              }}
              inputProps={{ min: 0 }}
              onKeyDown={(e: any) => {
                ["+", "-", "e"].includes(e.key) && e.preventDefault();
              }}
              onChange={(e: any) =>
                this.onChangeProduct("salesPrice", e.target.value)
              }
              value={this.state.product.salesPrice}
              error={salesPrice ? true : false}
              helperText={salesPrice}
              label={Strings.labels.salesPrice}
              variant="standard"
              size="small"
              requiredField
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <StyledInput
              data-test-id="field-quantity"
              onChange={(e: any) =>
                this.onChangeProduct("quantity", e.target.value)
              }
              InputProps={{
                type: "number",
              }}
              inputProps={{ min: 0 }}
              onKeyDown={(e: any) => {
                ["+", "-", "e"].includes(e.key) && e.preventDefault();
              }}
              value={this.state.product.quantity}
              error={quantity ? true : false}
              helperText={quantity}
              label={Strings.labels.quantity}
              variant="standard"
              size="small"
              requiredField
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <StyledInput
              data-test-id="field-minStock"
              onChange={(e: any) =>
                this.onChangeProduct("min_stock_qty", e.target.value)
              }
              InputProps={{
                type: "number",
              }}
              inputProps={{ min: 0 }}
              onKeyDown={(e: any) => {
                ["+", "-", "e"].includes(e.key) && e.preventDefault();
              }}
              value={this.state.product.min_stock_qty}
              error={min_stock_qty ? true : false}
              helperText={min_stock_qty}
              label={Strings.labels.minStockQuantity}
              variant="standard"
              size="small"
              requiredField
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <StyledInput
              data-test-id="field-weight"
              onChange={(e: any) =>
                this.onChangeProduct("weight", e.target.value)
              }
              inputProps={{ min: 0, type: "number" }}
              onKeyDown={(e: any) => {
                ["+", "-", "e"].includes(e.key) && e.preventDefault();
              }}
              value={this.state.product.weight}
              error={weight ? true : false}
              helperText={weight}
              label={Strings.labels.weight}
              variant="standard"
              size="small"
              requiredField
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <StyledInput
              data-test-id="field-benefits"
              onChange={(e: any) =>
                this.onChangeProduct("benefits", e.target.value)
              }
              multiline
              value={this.state.product.benefits}
              error={benefits ? true : false}
              helperText={benefits}
              label={Strings.labels.benefits}
              variant="standard"
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <h5 className="heading">
              <b>{Strings.labels.extraFields}</b>
            </h5>

            <Grid container justifyContent="flex-start" spacing={2}>
              {this.onGetExtraFieldList().map(
                ({ id, variant_name, variant_description, error }) => {
                  return (
                    <Grid item xs={6} key={"variant-list-" + id}>
                      <Grid container spacing={1} alignItems="flex-start">
                        <Grid item xs={11}>
                          <StyledInput
                            data-test-id={"field-extraField-" + id}
                            fullWidth
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={error ? true : false}
                            helperText={
                              error && Strings.labels.pleaseEnterValue
                            }
                            variant="standard"
                            label={variant_name}
                            value={variant_description}
                            placeholder={Strings.labels.enterValues}
                            onChange={(e: any) =>
                              this.onChangeExtraFieldValues(id, e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <img
                            data-test-id={"btn-extraField-" + id}
                            width={24}
                            src={iconDelete}
                            style={{ cursor: "pointer", marginTop: 22 }}
                            onClick={() => this.onRemoveExtraField(id)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                }
              )}

              <Grid item xs={12}>
                <Grid
                  container
                  spacing={1}
                  alignItems="stretch"
                  justifyContent="flex-start"
                >
                  {this.state.isExtraFieldsOpen && (
                    <>
                      <Grid item xs={4}>
                        <StyledInput
                          data-test-id="field-labelExtraField"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={
                            this.state.catalogueNameValidation ? true : false
                          }
                          value={this.state.catalogueName}
                          helperText={this.state.catalogueNameValidation}
                          onChange={(e: any) =>
                            this.setState({ catalogueName: e.target.value })
                          }
                          label={Strings.labels.fieldName}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <StyledInput
                          data-test-id="field-valueExtraField"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={
                            this.state.catalogueValueValidation ? true : false
                          }
                          value={this.state.catalogueValue}
                          helperText={this.state.catalogueValueValidation}
                          onChange={(e: any) =>
                            this.setState({ catalogueValue: e.target.value })
                          }
                          label={Strings.labels.fieldValue}
                          fullWidth
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={4}>
                    <Grid container justifyContent="flex-start" spacing={2}>
                      <Grid item>
                        <AddButton
                          data-test-id="btn-addExtraFields"
                          size="small"
                          variant="contained"
                          onClick={() => this.onSaveExtraField()}
                        >
                          {Strings.actions.addItem}
                        </AddButton>
                      </Grid>
                      {this.state.isExtraFieldsOpen && (
                        <Grid item>
                          <AddButton
                            data-test-id="btn-removeExtraFields"
                            size="small"
                            variant="contained"
                            onClick={() =>
                              this.setState({ isExtraFieldsOpen: false })
                            }
                          >
                            {Strings.actions.removeItem}
                          </AddButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {(!isLoading || !isEdit) && (
              <RteEditor
                data-test-id="field-description"
                placeholder={Strings.validations.description}
                label={Strings.labels.description}
                error={description ? true : false}
                helperText={description}
                onChange={(value: any) =>
                  this.onChangeProduct("description", value)
                }
                value={this.state.product.description}
                requiredField
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <StyledInput
              select
              placeholder="Taxes"
              error={taxes ? true : false}
              helperText={taxes}
              data-test-id="field-taxes"
              value={this.state.product.taxes}
              onChange={(e: any) =>
                this.onChangeProduct("taxes", e.target.value)
              }
              label={"Taxes"}
              variant="standard"
              requiredField
            >
              {["Exempted", "5%", "15%"].map((item: any) => (
                <MenuItem key={"tax-item-" + item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </StyledInput>
          </Grid>
          <Grid item xs={12}>
            <h5 className="heading">
              <b>{Strings.labels.productImages}</b>
            </h5>
            <div className="row mb-3">
              <div className="col-sm-12">
                <label className="vender-label mb-1">
                  {Strings.labels.mainView}
                  <RedLabel>{" * "}</RedLabel>
                  {mainViewImageLink && (
                    <a href={mainViewImageLink} target="_blank">
                      ({Strings.labels.viewFile})
                    </a>
                  )}
                </label>
                <div className="filesBox">
                  <input
                    data-test-id="field-mainImage"
                    accept=".jpeg, .jpg, .png"
                    type="file"
                    className="file"
                    onChange={(e: any) =>
                      this.onChangeProduct(
                        "mainViewImage",
                        e.target.files[0] ?? null
                      )
                    }
                  />
                  <UploadButtonText className="fileLabel">
                    <img className="upload" src={iconUpload} alt="upload" />
                    <div>{Strings.labels.uploadImage}</div>
                    <small>
                      {GetTruncateText(
                        // @ts-ignore
                        this.state.product?.mainViewImage?.name,
                        30
                      )}
                    </small>
                  </UploadButtonText>
                </div>
                {mainViewImage && (
                  <ErrorLabel data-test-id="main-image-error-label">
                    {mainViewImage}
                  </ErrorLabel>
                )}
              </div>
              <div className="col-sm-6">
                <label className="vender-label mb-1">
                  {Strings.labels.frontView}{" "}
                  {frontViewImageLink && (
                    <a href={frontViewImageLink} target="_blank">
                      ({Strings.labels.viewFile})
                    </a>
                  )}
                </label>
                <div className="filesBox">
                  <input
                    data-test-id="field-frontImage"
                    accept=".jpeg, .jpg, .png"
                    type="file"
                    className="file"
                    onChange={(e: any) =>
                      this.onChangeProduct(
                        "frontViewImage",
                        e.target.files[0] ?? null
                      )
                    }
                  />
                  <UploadButtonText className="fileLabel">
                    <img className="upload" src={iconUpload} alt="upload" />
                    <div>{Strings.labels.uploadImage}</div>
                    <small>
                      {GetTruncateText(
                        // @ts-ignore
                        this.state.product?.frontViewImage?.name,
                        30
                      )}
                    </small>
                  </UploadButtonText>
                </div>
                {frontViewImage && <ErrorLabel>{frontViewImage}</ErrorLabel>}
              </div>
              <div className="col-sm-6">
                <label className="vender-label mb-1">
                  {Strings.labels.backView}{" "}
                  {backViewImageLink && (
                    <a href={backViewImageLink} target="_blank">
                      ({Strings.labels.viewFile})
                    </a>
                  )}
                </label>
                <div className="filesBox">
                  <input
                    data-test-id="field-backImage"
                    type="file"
                    className="file"
                    accept=".jpeg, .jpg, .png"
                    onChange={(e: any) =>
                      this.onChangeProduct(
                        "backViewImage",
                        e.target.files[0] ?? null
                      )
                    }
                  />
                  <UploadButtonText className="fileLabel">
                    <img className="upload" src={iconUpload} alt="upload" />
                    <div>{Strings.labels.uploadImage}</div>
                    <small>
                      {GetTruncateText(
                        // @ts-ignore
                        this.state.product?.backViewImage?.name,
                        30
                      )}
                    </small>
                  </UploadButtonText>
                </div>
                {backViewImage && <ErrorLabel>{backViewImage}</ErrorLabel>}
              </div>
              <div className="col-sm-6">
                <label className="vender-label mb-1">
                  {Strings.labels.leftView}{" "}
                  {leftViewImageLink && (
                    <a href={leftViewImageLink} target="_blank">
                      ({Strings.labels.viewFile})
                    </a>
                  )}
                </label>
                <div className="filesBox">
                  <input
                    data-test-id="field-leftImage"
                    type="file"
                    className="file"
                    accept=".jpeg, .jpg, .png"
                    onChange={(e: any) =>
                      this.onChangeProduct(
                        "leftViewImage",
                        e.target.files[0] ?? null
                      )
                    }
                  />
                  <UploadButtonText className="fileLabel">
                    <img className="upload" src={iconUpload} alt="upload" />
                    <div>{Strings.labels.uploadImage}</div>
                    <small>
                      {GetTruncateText(
                        // @ts-ignore
                        this.state.product?.leftViewImage?.name,
                        30
                      )}
                    </small>
                  </UploadButtonText>
                </div>
                {leftViewImage && <ErrorLabel>{leftViewImage}</ErrorLabel>}
              </div>
              <div className="col-sm-6">
                <label className="vender-label mb-1">
                  {Strings.labels.rightView}{" "}
                  {rightViewImageLink && (
                    <a href={rightViewImageLink} target="_blank">
                      ({Strings.labels.viewFile})
                    </a>
                  )}
                </label>
                <div className="filesBox">
                  <input
                    data-test-id="field-rightImage"
                    type="file"
                    className="file"
                    accept=".jpeg, .jpg, .png"
                    onChange={(e: any) =>
                      this.onChangeProduct(
                        "rightViewImage",
                        e.target.files[0] ?? null
                      )
                    }
                  />
                  <UploadButtonText className="fileLabel">
                    <img className="upload" src={iconUpload} alt="upload" />
                    <div>{Strings.labels.uploadImage}</div>
                    <small>
                      {GetTruncateText(
                        // @ts-ignore
                        this.state.product?.rightViewImage?.name,
                        30
                      )}
                    </small>
                  </UploadButtonText>
                </div>
                {rightViewImage && <ErrorLabel>{rightViewImage}</ErrorLabel>}
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="filter">
              <div className="d-flex">
                <label className="checkbox-container checkbox">
                  <small>{Strings.labels.refundable}</small>
                  <input
                    data-test-id="field-isRefundable"
                    onChange={(e) =>
                      this.onChangeProduct("isRefundable", e.target.checked)
                    }
                    type="checkbox"
                    checked={this.state.product.isRefundable}
                  />
                  <span className="checkmark"></span>
                </label>
                <div>&nbsp;</div>
                <label className="checkbox-container checkbox">
                  <small>{Strings.labels.stockable}</small>
                  <input
                    data-test-id="field-stockable"
                    onChange={(e) =>
                      this.onChangeProduct("isStockable", e.target.checked)
                    }
                    type="checkbox"
                    checked={this.state.product.isStockable}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </Grid>
        </Grid>
        <br />

        <Grid container spacing={1}>
          <Grid item>
            <RoundedButton
              data-test-id="btn-submit"
              isActive={true}
              onClick={() => this.onSubmit()}
              text={isEdit ? Strings.actions.saveChanges : Strings.actions.ok}
            />
          </Grid>
          <Grid item>
            <Link to={"/vendor/inventory"}>
              <RoundedButton
                data-test-id="btn-cancel"
                isActive={false}
                onClick={() => this.onSubmit()}
                text={Strings.actions.cancel}
              />
            </Link>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const AddButton = styled(Button)({
  width: "100%",
  marginTop: 16,
});

const RedLabel = styled("span")({
  color: "red",
});

const StyledDatePicker2: any = styled(DatePicker)((props: any) => ({
  marginBottom: 10,
  "& .MuiFormLabel-root": {
    "&::after": {
      color: "red",
      content: props.requiredField && "' *'",
    },
  },
  "& .MuiInputLabel-formControl": {
    fontSize: 13,
    color: "var(--content)",
  },
  "& .MuiInput-input": {
    color: "var(--black)",
  },
  "& .MuiInput-underline::before": {
    borderBottom: "1px solid var(--content) !important",
  },
  "& .MuiInput-underline::after": {
    borderBottom: "2px solid var(--content) !important",
  },
  "& .MuiInput-underline.Mui-error::after": {
    borderBottom: "2px solid var(--content) !important",
  },
  "& .MuiInputBase-input": {
    fontWeight: "bold",
  },
}));

const UploadButtonText = styled("label")({
  // @ts-ignore
  fontWeight: "normal !important",
});

const StyledSelect = styled(Select)({
  width: "100%",
  "& .MuiSelect-select.MuiSelect-select": {
    fontSize: 13,
    fontWeight: 500,
    color: "var(--content)",
  },
  "&.MuiFormLabel-root.Mui-focused": {
    color: "var(--content)",
  },
  "&.MuiInput-underline::before": {
    borderBottom: "1px solid var(--content)",
  },
  "&.MuiInput-underline:hover:not(.Mui-disabled)::before": {
    borderBottom: "2px solid var(--content)",
  },
  "&.MuiInput-underline::after": {
    borderBottom: "2px solid var(--content)",
  },
  "&.MuiFormHelperText-root.Mui-error": {
    fontSize: 13,
  },
});

export const StyledInput = styled(TextField)((props: any) => ({
  width: "100%",
  "& .MuiFormLabel-root": {
    fontSize: 13,
    fontWeight: 500,
    color: "var(--content)",
    "&::after": {
      color: "red",
      content: props.requiredField && "' *'",
    },
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "var(--content)",
  },
  "& .MuiInput-underline::before": {
    borderBottom: "1px solid var(--content)",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
    borderBottom: "2px solid var(--content)",
  },
  "& .MuiInput-underline::after": {
    borderBottom: "2px solid var(--content)",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: 13,
  },
  "& .MuiInputBase-input": {
    fontWeight: "bold",
  },
}));

const StyledDatePicker = styled(StyledInput)({
  "& .MuiInputLabel-root": {
    transform: "translate(0, 1.5px) scale(0.75)",
  },
});

const ErrorLabel = styled("div")({
  fontSize: 13,
  color: "#f44336",
  marginTop: 5,
});

export default AddInventoryItem;

// Customizable Area End
