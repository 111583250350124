Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  footerContentApi: "/get_page_content?slug=",
};

exports.Strings = {
  noData: "No data found.",
  notFound: "Content Not Found (404)",
};
