// Customizable Area Start

import React from "react";
import { Link } from "react-router-dom";
import { styled, Grid, Avatar, Box, Typography } from "@material-ui/core";

import { iconTriangle } from "../../vendor/src/AppLayout/assets";

import TitleHeading from "../../vendor/src/Shared/TitleHeading";
import GetDateString from "../../vendor/src/GetDateString";
import StyledHR from "../../vendor/src/Shared/StyledHR";

interface Props {
  list: Array<Object>;
}
interface State {}
interface SS {}

class NotificationDropDown extends React.Component<Props, State, SS> {
  render() {
    return (
      <NotificationMenu className="vendor-header-menu-dropdown">
        <TopArrowStyled src={iconTriangle} width={20} />
        <NotificationContainer>
          <Grid container justifyContent="space-between">
            <Grid item>
              <TitleHeading text="Notification" />
            </Grid>
            <Grid item>
              <h3>&times;</h3>
            </Grid>
          </Grid>
          <StyledHR />

          <Grid container>
            {this.props.list?.map((notification: any) => {
              const item = notification?.attributes || {};
              const { created_by, created_at } = item;
              const { first_name, last_name, created_by_image } =
                created_by || {};

              return (
                <Grid item xs={12} key={notification?.id}>
                  <Grid
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    container
                  >
                    <Grid item xs={2}>
                      <Avatar src={created_by_image}>
                        {String(first_name || "U")
                          .toUpperCase()
                          .charAt(0)}
                      </Avatar>
                    </Grid>
                    <Grid item xs={8}>
                      <NotificationBodyText>
                        {first_name + " " + last_name}
                        <br />
                        <b>{item.headings}</b>
                        <br />
                        {item.contents}
                      </NotificationBodyText>
                    </Grid>
                    <Grid item xs={2}>
                      <NotificationBodyTimeText>
                        {GetDateString(created_at)}
                      </NotificationBodyTimeText>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>

          <div>
            <StyledViewAll to={"/vendor/notifications"}>View All</StyledViewAll>
          </div>
        </NotificationContainer>
      </NotificationMenu>
    );
  }
}

const TopArrowStyled = styled("img")({
  marginTop: 23,
  marginLeft: 208,
});

const StyledViewAll = styled(Link)({
  color: "var(--navy)",
  fontSize: 14,
  textAlign: "center",
  padding: 16,
  display: "block",
});

const NotificationBodyTimeText = styled(Typography)({
  fontSize: 13,
  color: "gray",
  fontWeight: 500,
  textAlign: "left",
});

const NotificationBodyText = styled(Typography)({
  fontSize: 15,
  color: "gray",
  fontWeight: 500,
  textAlign: "left",
});

const NotificationTimeText = styled(Typography)({
  fontSize: 15,
  color: "gray",
  fontWeight: 500,
  textAlign: "left",
  paddingBottom: 8,
});

const NotificationContainer = styled(Box)({
  padding: 8,
  paddingTop: 16,
  width: "100%",
  marginTop: -3,
  color: "black",
  overflowY: "scroll",
  backgroundColor: "white",
  maxHeight: "calc(100vh - 200px)",
  boxShadow: "0px 0px 10px var(--boxShadow)",
});

const NotificationMenu = styled(Box)({
  left: -200,
  width: 400,
  zIndex: 4,
  display: "none",
  minHeight: 500,
  textAlign: "left",
  position: "absolute",
  maxHeight: "calc(100vh - 200px)",
});

export default NotificationDropDown;

// Customizable Area End
