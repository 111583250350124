export const bellIcon = require("../../global_assets/bell.png");
export const circleIcon = require("../../global_assets/circle.png");
export const crossIcon = require("../../global_assets/cross.png");
export const img0d1664417b1a8be6616e81d90a53ff69de883619 = require("../../global_assets/0d1664417b1a8be6616e81d90a53ff69de883619.png");
export const imgcd0b8d517cb1ae286c17827768f1dcd1af9f05e2 = require("../../global_assets/cd0b8d517cb1ae286c17827768f1dcd1af9f05e2.png");
export const imgb7b49df8d1bc0388c9088a5fc66b770276f3ced4 = require("../../global_assets/b7b49df8d1bc0388c9088a5fc66b770276f3ced4.png");
export const imgcee2a237845666ab26f5aac94e64e51713c8de48 = require("../../global_assets/cee2a237845666ab26f5aac94e64e51713c8de48.png");
export const img987f9722257d381a0f37e5b0921ceba78b201884 = require("../../global_assets/987f9722257d381a0f37e5b0921ceba78b201884.png");
export const img9b66ebee4af0aaef26ac0f41951c5f425edf2a7b = require("../../global_assets/9b66ebee4af0aaef26ac0f41951c5f425edf2a7b.png");
export const imgaa1ccd86a4ac484ea67394e52f8fe2270ef192fe = require("../../global_assets/aa1ccd86a4ac484ea67394e52f8fe2270ef192fe.png");
export const imge197305f868f05d6e765805474732b168da8d3a6 = require("../../global_assets/e197305f868f05d6e765805474732b168da8d3a6.png");
export const imga49679ff0526fcda926f2885fa279e5cc9899232 = require("../../global_assets/a49679ff0526fcda926f2885fa279e5cc9899232.png");
export const img3e11797f43d9d56766eb816f28aa4f9b4ba9b27b = require("../../global_assets/3e11797f43d9d56766eb816f28aa4f9b4ba9b27b.png");
export const img71fd022ee7bdc62d4d1940a0ed7d47c2847171dd = require("../../global_assets/71fd022ee7bdc62d4d1940a0ed7d47c2847171dd.png");
export const imgefe7109df6b5fe53d7032f89df6eb7e6c8fea7fc = require("../../global_assets/efe7109df6b5fe53d7032f89df6eb7e6c8fea7fc.png");
export const img5b472e132f70ae1d272d298135131aec09444363 = require("../../global_assets/5b472e132f70ae1d272d298135131aec09444363.png");
export const img1eea57dc2d87192ce0240e1f6db3ae32a62e46ad = require("../../global_assets/1eea57dc2d87192ce0240e1f6db3ae32a62e46ad.png");
export const img2e5f39d3ad85cda7a4a0821aa591f25c719711bd = require("../../global_assets/2e5f39d3ad85cda7a4a0821aa591f25c719711bd.png");
export const imgcb3acc030126dd7a81e975d916e1bd51b17f98ba = require("../../global_assets/cb3acc030126dd7a81e975d916e1bd51b17f98ba.png");
export const img48054cfa6df5f4c6b49e9304b05d6e1ba8747a18 = require("../../global_assets/48054cfa6df5f4c6b49e9304b05d6e1ba8747a18.png");
export const imgc9334edaffb88edd9df54ef4fef5c9bc35fc14f6 = require("../../global_assets/c9334edaffb88edd9df54ef4fef5c9bc35fc14f6.png");
export const img595d5f996d5a88dcac4a44746bfb066b8c061a9c = require("../../global_assets/595d5f996d5a88dcac4a44746bfb066b8c061a9c.png");
export const imgcf4e1f490ab7ef27870ab3a22bf298c8ddbcaeb0 = require("../../global_assets/cf4e1f490ab7ef27870ab3a22bf298c8ddbcaeb0.png");
export const img734f0bbe72b92072b5f7be31c8f19228d63ae5cc = require("../assets/734f0bbe72b92072b5f7be31c8f19228d63ae5cc.png");
