Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.btnExampleTitle = "CLICK ME";
exports.wishlistApiURL = "/bx_block_catalogue/wish_lists?page={page}&per_page=10";
// Customizable Area End
