 // Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

import googleController, { GoogleWebDelegate } from "./GoogleWebController";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  loading: boolean;
  isRegistration: boolean;
  // Customizable Area Start
  isLogin: boolean;
  passwordShown: boolean;
  loginPasswordShown: boolean;
  confirmPasswordShown: boolean;
  signup1: boolean;
  signup2: boolean;
  signup3: boolean;
  OTP: boolean;
  loginInputValues: any;
  signupInputValues: any;
  commercial: any;
  tax: any;
  supporting: any;
  pharmacy: any;
  companyLogo?: any;
  OTPInputValues: any;
  loginValidation: any;
  signupValidation: any;
  otpValidation: any;
  mobileLoginNumber: any;
  mobileLoginValidation: any;
  vendorLoginError: any;
  vendorMobileLoginError: any;
  vendorSignupError: any;
  isDocSkip: boolean;
  otpTimer: any;
  showResendTimer: boolean;
  otpPhoneNumber: any;
  verifyOtpError: any;
  countries: any;
  states: any;
  cities:any;
  showActivatePopup: boolean;
  // Customizable Area End
}

interface SS {}

export default class SocialMediaAccountWebController
  extends BlockComponent<Props, S, SS>
  implements GoogleWebDelegate {
  createAccountAPICallId: any;
  googleUser: any;
  // Customizable Area Start
  vendorLoginAPICallId: any;
  vendorSignupAPICallId: any;
  vendorMobileLoginAPICallId: any;
  verifyOtpAPICallId: any;
  countryAPICallId: any;
  stateAPICallId: any;
  cityAPICallId: any;
  showThanksPage: boolean;
  vendorActivationAPICallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage)
    ]);

    this.state = {
      // Customizable Area Start
      isLogin: true,
      passwordShown: false,
      loginPasswordShown: false,
      confirmPasswordShown: false,
      signup1: false,
      signup2: false,
      signup3: false,
      OTP: false,
      loginInputValues: {
        loginEmail: "",
        loginPassword: ""
      },
      signupInputValues: {
        signupEmail: "",
        signupFname: "",
        signupLname: "",
        signupPassword: "",
        signupConfirmPassword: "",
        signupAddress: "",
        signupCity: "",
        signupState: "",
        signupCountry: "",
        signupBusinessName: "",
        signupPhoneNumber: "",
        signupBusinessCountry: "",
        signupTeamMember: "",
        signupFullPhoneNumber: ""
      },
      commercial: { name: "" },
      tax: { name: "" },
      supporting: { name: "" },
      pharmacy: { name: "" },
      companyLogo: { name: "" },
      OTPInputValues: { otp: "" },
      loginValidation: {
        loginEmail: "",
        loginPassword: ""
      },
      signupValidation: {
        signupEmail: "",
        signupFname: "",
        signupLname: "",
        signupPassword: "",
        signupConfirmPassword: "",
        signupAddress: "",
        signupCity: "",
        signupState: "",
        signupCountry: "",
        signupBusinessName: "",
        signupPhoneNumber: "",
        signupBusinessCountry: "",
        signupFullPhoneNumber: "",
        signupCommercial: "",
        signupTax: "",
        signupSupporting: "",
        signupPharmacy: ""
      },
      otpValidation: { otp: "" },
      mobileLoginNumber: { phoneNumber: "" },
      mobileLoginValidation: { phoneNumber: "" },
      vendorLoginError: "",
      vendorMobileLoginError: "",
      vendorSignupError: "",
      isDocSkip: false,
      otpTimer: 60,
      showResendTimer: false,
      otpPhoneNumber: '',
      verifyOtpError: '',
      countries: {},
      states: {},
      cities: {},
      showActivatePopup: false,
      // Customizable Area End
      loading: false,
      isRegistration: false
    };
  }

  //When facebook sends back the reponse this gets called
  googleUserStatusChanged(userInfo: any): void {
    if (this.state.isRegistration) {
      this.createAccountFromSocial(userInfo.email, userInfo.id, this.props);
    } else {
      this.logInWithSocial(
        userInfo.email,
        userInfo.email,
        userInfo.id,
        this.props
      );
    }
  }

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  responseFacebook = (response: any) => {
    this.stopLoading();
    if (this.state.isRegistration) {
      this.createAccountFromSocial(response.email, response.id, this.props);
    } else {
      this.logInWithSocial(
        response.email,
        response.email,
        response.id,
        this.props
      );
    }
    runEngine.debugLog(response);
  };

  googleLogIn = (isRegistration: boolean) => {
    const self = this;
    //@ts-ignore
    googleController.googleLogIn(this).then(
      function() {
        self.stopLoading();
        runEngine.debugLog("User SIGNED IN.");
      },
      function(error: any) {
        self.stopLoading();
        if (error.error === "popup_closed_by_user") {
          //handle window closed event
        }
      }
    );
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.stopLoading();

    if (getName(MessageEnum.SessionSaveMessage) === message.id) {
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createAccountAPICallId != null &&
      this.createAccountAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        this.saveLoggedInUserData(responseJson);
      } else if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    
    } else if (
      this.vendorLoginAPICallId ===
      message.properties.RestAPIResponceDataMessage
    ) {
      const loginResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (loginResponse.status === 200) {
        localStorage.setItem("token", loginResponse.token);
        localStorage.setItem("userId", loginResponse.id);
        localStorage.setItem('cartTotal', '0');
        if(loginResponse.is_deactivated === true){
          this.setState({showActivatePopup: true});
        }else{
          window.location.href = "/home";
        }
      } else {
        this.setState({ vendorLoginError: loginResponse.message });
      }
    } else if (
      this.vendorMobileLoginAPICallId ===
      message.properties.RestAPIResponceDataMessage
    ) {
      const mobileLoginResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (mobileLoginResponse?.status !== 200) {
        this.setState({ vendorMobileLoginError: mobileLoginResponse?.message});
      } else {
        this.showThanksPage = false;
        this.otpTimerFunc();
        this.setState({
          isLogin: false,
          signup1: false,
          signup2: false,
          signup3: false,
          OTP: true,
          showResendTimer: true,
          otpPhoneNumber: this.state.mobileLoginNumber.phoneNumber
        });
        localStorage.setItem('otpToken', mobileLoginResponse.token);
      }
    } else if (this.vendorSignupAPICallId === message.properties.RestAPIResponceDataMessage) {
      const signupResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (signupResponse?.errors) {
        const errors = this.state.signupValidation;
        signupResponse?.errors.forEach((item:any) => {
          const key = Object.keys(item)[0];
          const value = Object.values(item)[0];
          let errorKey = configJSON.signupServerValidation[key];
          if(errorKey){
            errors[errorKey] = value;
          }
        });
        this.setState({
          signupValidation: { ...this.state.signupValidation, errors }
        });
      } else if (signupResponse?.message) {
        this.setState({ vendorSignupError: signupResponse?.message });
      } else {
        this.showThanksPage = true;
        this.otpTimerFunc();
        this.setState({
          isLogin: false,
          signup1: false,
          signup2: false,
          signup3: false,
          OTP: true,
          showResendTimer: true,
          otpPhoneNumber: this.state.signupInputValues.signupFullPhoneNumber
        });
        localStorage.setItem('otpToken', signupResponse.meta.token);
      }
    } else if (this.verifyOtpAPICallId === message.properties.RestAPIResponceDataMessage) {
      const verifyOtpResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (verifyOtpResponse.status !== 200) {
        this.setState({ verifyOtpError: verifyOtpResponse.message });
      } else {
        localStorage.setItem("token", verifyOtpResponse.token);
        localStorage.setItem("userId", verifyOtpResponse.id);
        localStorage.setItem('cartTotal', '0');
        window.location.href = this.showThanksPage ? "/thanks" : "/home";
        if(this.showThanksPage){
          window.location.href = "/thanks";
        }else{
          if(verifyOtpResponse.is_deactivated === true){
            this.setState({showActivatePopup: true});
          }else{
            window.location.href = "/home";
          }
        }
      }
    } else if(this.countryAPICallId === message.properties.RestAPIResponceDataMessage){
      const countryResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (countryResponse && countryResponse.countries) {
        this.setState({countries: countryResponse.countries});
      } else {
        this.setState({countries: {}});
      }
    } else if(this.stateAPICallId === message.properties.RestAPIResponceDataMessage){
      const stateResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (stateResponse && stateResponse.states) {
        this.setState({states: stateResponse.states});
      } else {
        this.setState({states: {}});
      }
    } else if(this.cityAPICallId === message.properties.RestAPIResponceDataMessage){
      const cityResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (cityResponse && cityResponse.cities) {
        this.setState({cities: cityResponse.cities});
      } else {
        this.setState({cities: {}});
      }
    } else if(this.vendorActivationAPICallId === message.properties.RestAPIResponceDataMessage){
      const activationResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (activationResponse && activationResponse.data) {
        window.location.href = '/home';
      }
      // Customizable Area End
    } else {
      runEngine.debugLog("GOIT");
    }
  }

  async createAccountFromSocial(
    incomingEmail: String,
    incomingId: String,
    props: Props
  ) {
    if (
      !incomingEmail ||
      incomingEmail.length === 0 ||
      !incomingId ||
      incomingId.length === 0
    ) {
      runEngine.debugLog("createAccountFromSocial empty info");
      return;
    }

    this.startLoading();

    const header = {
      "Content-Type": configJSON.urlHeaderTypeJSON
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createAccountURL
    );

    const data = {
      type: "social_account",
      attributes: {
        email: incomingEmail,
        password: incomingEmail,
        unique_auth_id: incomingId
      }
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postHttpRequest
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async logInWithSocial(
    incomingEmail: string,
    incomingPassword: string,
    incomingId: string,
    props: Props
  ) {
    if (
      !incomingEmail ||
      incomingEmail.length === 0 ||
      !incomingId ||
      incomingId.length === 0
    ) {
      runEngine.debugLog("createAccountFromSocial empty info");
      return;
    }

    this.startLoading();

    const header = {
      "Content-Type": "application/json"
    };

    const attrs = {
      email: incomingEmail,
      password: incomingPassword,
      unique_auth_id: incomingId
    };

    const data = {
      type: "social_account",
      attributes: {
        email: incomingEmail,
        password: incomingEmail,
        unique_auth_id: incomingId
      }
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAccountURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigate() {
    runEngine.debugLog("this.isRegistration");
    runEngine.debugLog(this.state.isRegistration);
    if (this.state.isRegistration) {
      runEngine.debugLog("Registration");
      runEngine.debugLog(this.state.isRegistration);
      this.navigateToSignup();
    } else {
      runEngine.debugLog("Registration");
      runEngine.debugLog(this.state.isRegistration);
      this.navigateToLogin();
    }
  }

  navigateToSignup() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  navigateToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );

      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg = new Message(
      getName(
        this.state.isRegistration
          ? MessageEnum.AccoutResgistrationSuccess
          : MessageEnum.AccoutLoginSuccess
      )
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  //Create response callback.
  responseInfoCallback(error: any, result: any) {
    if (error) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorDescription + error.toString()
      );
    } else {
      runEngine.debugLog("Result Name: " + result.name);
    }
  }

  btnFacebookLogInProps = {
    onPress: () => {
      this.startLoading();
    },
    callback: this.responseFacebook
  };

  btnGoogleLogInProps = {
    onPress: () => {
      this.googleLogIn(this.state.isRegistration);
      this.startLoading();
    }
  };

  btnNavigateProps = {
    onPress: () => this.navigate()
  };
  // Customizable Area Start
  showLogin() {
    this.setState({
      isLogin: true,
      signup1: false,
      signup2: false,
      signup3: false,
      OTP: false
    });
  }
  showSignup1() {
    this.setState({
      isLogin: false,
      signup1: true,
      signup2: false,
      signup3: false,
      OTP: false
    });
  }
  signup2Back(){
    this.setState({
      isLogin: false,
      signup1: false,
      signup2: true,
      signup3: false,
      OTP: false
    });
  }
  showSignup2() {
    const checkFirstValidation = this.checkSignupValidationFirstPhase();
    if (checkFirstValidation === true) {
      this.setState({
        isLogin: false,
        signup1: false,
        signup2: true,
        signup3: false,
        OTP: false
      });
    } else {
      return false;
    }
  }
  showSignup3() {
    const checkSecondValidation = this.checkSignupValidationSecondPhase();
    if (checkSecondValidation === true) {
      this.setState({
        isLogin: false,
        signup1: false,
        signup2: false,
        signup3: true,
        OTP: false,
        isDocSkip: false
      });
      this.getCountries();
    } else {
      return false;
    }
  }
  showSignup3WithSkip() {
    this.setState({
      isLogin: false,
      signup1: false,
      signup2: false,
      signup3: true,
      OTP: false,
      isDocSkip: true
    });
  }
  showOTP() {
    const checkThirdValidation = this.checkSignupValidationThirdPhase();
    if (checkThirdValidation === true) {
      const SignupData = {
        signupEmail: this.state.signupInputValues.signupEmail,
        signupFname: this.state.signupInputValues.signupFname,
        signupLname: this.state.signupInputValues.signupLname,
        signupPassword: this.state.signupInputValues.signupPassword,
        signupConfirmPassword: this.state.signupInputValues.signupConfirmPassword,
        signupAddress: this.state.signupInputValues.signupAddress,
        signupCity: this.state.signupInputValues.signupCity,
        signupState: this.state.signupInputValues.signupState,
        signupCountry: this.state.signupInputValues.signupCountry,
        signupBusinessName: this.state.signupInputValues.signupBusinessName,
        signupPhoneNumber: this.state.signupInputValues.signupPhoneNumber,
        signupBusinessCountry: this.state.signupInputValues.signupBusinessCountry,
        signupTeamMember: this.state.signupInputValues.signupTeamMember ? '[' + this.state.signupInputValues.signupTeamMember + ']' : '[]' ,
        signupFullPhoneNumber: this.state.signupInputValues.signupFullPhoneNumber
      };
      this.vendorSignup(SignupData, '');
    } else {
      return false;
    }
  }
  togglePassword = () => {
    this.setState({
      passwordShown: !this.state.passwordShown
    });
  };
  toggleLoginPassword = () => {
    this.setState({
      loginPasswordShown: !this.state.loginPasswordShown
    });
  };
  toggleConfirmPassword = () => {
    this.setState({
      confirmPasswordShown: !this.state.confirmPasswordShown
    });
  };
  handleLoginChange = (e: any) => {
    this.setState({
      loginInputValues: {
        ...this.state.loginInputValues,
        [e.target.name]: e.target.value
      }
    });
  };
  handleMobileLoginChange = (e: any) => {
    this.setState({
      mobileLoginNumber: {
        ...this.state.mobileLoginNumber,
        [e.target.name]: e.target.value
      }
    });
  };
  handlePhoneNumberChange=(value:string)=>{
    this.setState(prev=>({
      mobileLoginNumber:{
        ...prev.mobileLoginNumber,
        phoneNumber:value
      }
    }))
  } 
  handleSignupPhoneNumberChange=(key:string,value:string)=>{
    this.setState({
      signupInputValues: {
        ...this.state.signupInputValues,
        [key]:value
      }
    });
  }
  logIn = (e: any) => {
    e.preventDefault();
    const isSuccess = this.checkLoginValidation();
    if (isSuccess) {
      this.vendorLogin(
        this.state.loginInputValues.loginEmail,
        this.state.loginInputValues.loginPassword,
        ""
      );
    } else {
      return false;
    }
  };
  handleSignupChange(e: any, type = '') {
    if(type === 'country'){
      this.setState({states: {}, cities: {}});
      this.getStates(e.target.value);
    }
    if(type === 'state'){
      this.setState({cities: {}});
    }
    this.setState({
      signupInputValues: {
        ...this.state.signupInputValues,
        [e.target.name]: e.target.value
      }
    });
  }
  uploadCommercial(event: any) {
    const fileData = event.target.files[0];
    if (fileData !== undefined) {
      this.setState({ commercial: fileData });
    }
  }
  uploadTax(event: any) {
    const fileData = event.target.files[0];
    if (fileData !== undefined) {
      this.setState({ tax: fileData });
    }
  }
  uploadSupporting(event: any) {
    const fileData = event.target.files[0];
    if (fileData !== undefined) {
      this.setState({ supporting: fileData });
    }
  }
  uploadPharmacy(event: any) {
    const fileData = event.target.files[0];
    if (fileData !== undefined) {
      this.setState({ pharmacy: fileData });
    }
  }
  uploadCompanyLogo(event: any) {
    const fileData = event.target.files[0];
    if (fileData !== undefined) {
      this.setState({ companyLogo: fileData });
    }
  }
  handleOTPChange(e: any) {
    this.setState({
      OTPInputValues: {
        ...this.state.OTPInputValues,
        [e.target.name]: e.target.value
      }
    });
  }

  checkLoginValidation() {
    const formdata = this.state.loginInputValues;
    const errors = this.state.loginValidation;
    // email validation
    const emailCond = configJSON.emailValidation;
    if (!formdata.loginEmail) {
      errors.loginEmail = "Email is required";
    } else if (!formdata.loginEmail.match(emailCond)) {
      errors.loginEmail = "Please enter valid email address";
    } else {
      errors.loginEmail = "";
    }

    //password validation
    const password = formdata.loginPassword;
    const passReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#.\$%\^&\*])(?=.{6})/;
    if (!password) {
      errors.loginPassword = "Password is required";
    }
    else if (!password.match(passReg)) {
      errors.loginPassword = "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
    }
    else {
      errors.loginPassword = "";
    }
    this.setState({
      loginValidation: { ...this.state.loginValidation, errors }
    });
    if (errors.loginEmail === "" && errors.loginPassword === "") {
      return true;
    } else {
      return false;
    }
  }
  checkSignupValidationFirstPhase() {
    const formdata = this.state.signupInputValues;
    const errors = this.state.signupValidation;
    // email validation
    const emailCond = configJSON.emailValidation;
    if (!formdata.signupEmail) {
      errors.signupEmail = "Email is required";
    } else if (!formdata.signupEmail.match(emailCond)) {
      errors.signupEmail = "Please enter valid email address";
    } else {
      errors.signupEmail = "";
    }

    const alphabetCond = /^[a-zA-Z ]*$/;
    if (!formdata.signupFname) {
      errors.signupFname = "First name is required";
    } else if (formdata.signupFname.trim() === '') {
      errors.signupFname = "First name is required";
    } else if (!formdata.signupFname.match(alphabetCond)) {
      errors.signupFname = "Please enter valid first name";
    } else {
      errors.signupFname = "";
    }

    if (!formdata.signupLname) {
      errors.signupLname = "Last name is required";
    } else if (formdata.signupLname.trim() === '') {
      errors.signupLname = "Last name is required";
    } else if (!formdata.signupLname.match(alphabetCond)) {
      errors.signupLname = "Please enter valid last name";
    } else {
      errors.signupLname = "";
    }
    if (!formdata.signupFullPhoneNumber) {
      errors.signupFullPhoneNumber = "Number is required";
    }else {
      errors.signupFullPhoneNumber = "";
    }
    //password validation
    const signupPassword = formdata.signupPassword;
    const passReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#.\$%\^&\*])(?=.{6})/;
    if (!signupPassword) {
      errors.signupPassword = "Password is required";
    } else if (!signupPassword.match(passReg)) {
      errors.signupPassword =
        "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
    } else {
      errors.signupPassword = "";
    }

    const confirmPassword = formdata.signupConfirmPassword;
    if (!confirmPassword) {
      errors.signupConfirmPassword = "Confirm Password is required";
    } else if (!confirmPassword.match(passReg)) {
      errors.signupConfirmPassword =
        "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
    } else if (signupPassword !== confirmPassword) {
      errors.signupConfirmPassword = "Both password should match";
    } else {
      errors.signupConfirmPassword = "";
    }

    this.setState({
      signupValidation: { ...this.state.signupValidation, errors }
    });
    if (
      errors.signupEmail === "" &&
      errors.signupFname === "" &&
      errors.signupLname === "" &&
      errors.signupFullPhoneNumber === "" &&
      errors.signupPassword === "" &&
      errors.signupConfirmPassword === ""
    ) {
      return true;
    } else {
      return false;
    }
  }
  checkSignupValidationSecondPhase() {
    const errors = this.state.signupValidation;
    if (!this.state.commercial.name) {
      errors.signupCommercial = "Please Upload document";
    } else {
      errors.signupCommercial = "";
    }
    if (!this.state.tax.name) {
      errors.signupTax = "Please Upload document";
    } else {
      errors.signupTax = "";
    }
    if (!this.state.pharmacy.name) {
      errors.signupPharmacy = "Please Upload document";
    } else {
      errors.signupPharmacy = "";
    }
    if (!this.state.supporting.name) {
      errors.signupSupporting = "Please Upload document";
    } else {
      errors.signupSupporting = "";
    }
    this.setState({
      signupValidation: { ...this.state.signupValidation, errors }
    });
    if (
      errors.signupCommercial === "" &&
      errors.signupTax === "" &&
      errors.signupPharmacy === "" &&
      errors.signupSupporting === ""
    ) {
      return true;
    } else {
      return false;
    }
  }
  checkSignupValidationThirdPhase() {
    const formdata = this.state.signupInputValues;
    const errors = this.state.signupValidation;

    const alphabetCond = /^[a-zA-Z ]*$/;
    if (!formdata.signupAddress) {
      errors.signupAddress = "Address is required";
    } else if (formdata.signupAddress.trim() === '') {
      errors.signupAddress = "Address is required";
    } else {
      errors.signupAddress = "";
    }
    if (!formdata.signupCity) {
      errors.signupCity = "City is required";
    } else if (formdata.signupCity.trim() === '') {
      errors.signupCity = "City is required";
    } else if (!formdata.signupCity.match(alphabetCond)) {
      errors.signupCity = "Please enter valid city.";
    } else {
      errors.signupCity = "";
    }

    if (!formdata.signupState) {
      errors.signupState = "State is required";
    } else if (formdata.signupState.trim() === '') {
      errors.signupState = "State is required";
    }else {
      errors.signupState = "";
    }

    if (!formdata.signupCountry) {
      errors.signupCountry = "Country is required";
    } else if (formdata.signupCountry.trim() === '') {
      errors.signupCountry = "Country is required";
    }else {
      errors.signupCountry = "";
    }

    if (!formdata.signupBusinessName) {
      errors.signupBusinessName = "Business name is required";
    } else if (formdata.signupBusinessName.trim() === '') {
      errors.signupBusinessName = "Business name is required";
    } else  {
      errors.signupBusinessName = "";
    }
    if (!formdata.signupPhoneNumber) {
      errors.signupPhoneNumber = "Number is required";
    }else {
      errors.signupPhoneNumber = "";
    }

    if (!formdata.signupBusinessCountry) {
      errors.signupBusinessCountry = "Country is required";
    } else if (formdata.signupBusinessCountry.trim() === '') {
      errors.signupBusinessCountry = "Country is required";
    } else if (!formdata.signupBusinessCountry.match(alphabetCond)) {
      errors.signupBusinessCountry = "Please enter valid business country.";
    } else {
      errors.signupBusinessCountry = "";
    }
    this.setState({
      signupInputValues: { ...this.state.signupInputValues, errors }
    });
    if (
      errors.signupAddress === "" &&
      errors.signupCity === "" &&
      errors.signupState === "" &&
      errors.signupCountry === "" &&
      errors.signupBusinessName === "" &&
      errors.signupPhoneNumber === "" &&
      errors.signupBusinessCountry === ""
    ) {
      return true;
    } else {
      return false;
    }
  }
  mobileLogin() {
    const formdata = this.state.mobileLoginNumber;
    const errors = this.state.mobileLoginValidation;
    if (!formdata.phoneNumber.trim()) {
      errors.phoneNumber = "Number is required";
    }else {
      errors.phoneNumber = "";
    }
    if (errors.phoneNumber === "") {
      this.vendorMobileLogin(formdata.phoneNumber, '');
    } else {
      this.setState({
        mobileLoginValidation: { ...this.state.mobileLoginValidation, errors }
      });
      return false;
    }
  }

  OtpValidation(e: any) {
    e.preventDefault();
    const formdata = this.state.OTPInputValues;
    const errors = this.state.otpValidation;
    const numberCond = /^[0-9\b]+$/;
    if (!formdata.otp.trim()) {
      errors.otp = "OTP is required";
    } else if (!formdata.otp.match(numberCond)) {
      errors.otp = "Please enter valid otp";
    } else {
      errors.otp = "";
    }
    if (errors.otp === "") {
      this.verifyOtp(formdata.otp, '');
    } else {
      this.setState({
        otpValidation: { ...this.state.otpValidation, errors }
      });
      return false;
    }
  }

  async verifyOtp(incomingOtp: any, props: string) {
    if (!incomingOtp || incomingOtp.length === 0) {
      runEngine.debugLog("OTP is not validated");
      return;
    }
    this.startLoading();
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem('otpToken')
    };

    const attrs = {
      otp: incomingOtp
    };

    const data = {
      otp: incomingOtp
    };

    // const httpBody = data;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyOtpAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.vendorVerifyOTP
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  otpTimerFunc() {
    const timer = setInterval(() => {
      if (this.state.otpTimer > 0) {
        const newTime = this.state.otpTimer - 1;
        this.setState({
          otpTimer: newTime,
          showResendTimer: true
        });
      } else {
        this.setState({
          showResendTimer: false
        });
      }
    }, 1000);
  }

  async vendorLogin(
    incomingEmail: string,
    incomingPassword: string,
    props: string
  ) {
    if (!incomingEmail || incomingEmail.length === 0 || !incomingPassword) {
      runEngine.debugLog("createAccountFromSocial empty info");
      return;
    }

    this.startLoading();

    const header = {
      "Content-Type": "application/json"
    };

    const attrs = {
      email: incomingEmail,
      password: incomingPassword
    };

    const data = {
      email: incomingEmail,
      password: incomingPassword
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.vendorLoginAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAccountURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async vendorMobileLogin(incomingPhoneNumber: any, props: string) {
    if (!incomingPhoneNumber || incomingPhoneNumber.length === 0) {
      runEngine.debugLog("Mobile number is not validated");
      return;
    }
    this.startLoading();
    const header = {
      "Content-Type": "application/json"
    };

    const attrs = {
      full_phone_number: incomingPhoneNumber
    };

    const data = {
      full_phone_number: incomingPhoneNumber
    };

    // const httpBody = data;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.vendorMobileLoginAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.mobileLoginURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async vendorSignup(incomingSignUp: any, props: string) {
    if (!incomingSignUp) {
      runEngine.debugLog("signup field is not validated");
      return;
    }
    this.startLoading();
    const header = {
    };

    const signupData = new FormData();
    signupData.set('email', incomingSignUp.signupEmail);
    signupData.set('first_name', incomingSignUp.signupFname);
    signupData.set('last_name', incomingSignUp.signupLname);
    signupData.set('password', incomingSignUp.signupPassword);
    signupData.set('password_confirmation', incomingSignUp.signupConfirmPassword);
    signupData.set('business_city', incomingSignUp.signupCity);
    signupData.set('business_state', incomingSignUp.signupState);
    signupData.set('business_address', incomingSignUp.signupAddress);
    signupData.set('business_country', incomingSignUp.signupCountry);
    signupData.set('business_name', incomingSignUp.signupBusinessName);
    signupData.set('business_phone_no', incomingSignUp.signupPhoneNumber);
    signupData.set('legal_bussiness_country', incomingSignUp.signupBusinessCountry);
    signupData.set('team_members', incomingSignUp.signupTeamMember);
    signupData.set('full_phone_number', incomingSignUp.signupFullPhoneNumber);
    signupData.set('platform', 'web');
    signupData.set('device_token', '9');

    if (this.state.isDocSkip === false) {
      if (this.state.commercial) {
        signupData.set('commercial_registration_doc', this.state.commercial);
      }
      if (this.state.tax) {
        signupData.set('tax_registration_doc', this.state.tax);
      }
      if (this.state.supporting) {
        signupData.set('supporting_doc', this.state.supporting);
      }
      if (this.state.pharmacy) {
        signupData.set('pharmacy_registration_doc', this.state.pharmacy);
      }
    }
    if (this.state.companyLogo?.name) {
      signupData.set('company_logo', this.state.companyLogo);
    }
    // const httpBody = data;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.vendorSignupAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.vendorSignupURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      signupData
    );

    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "post"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCountries() {
    const header = {}
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryAPIURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getStates(country:string){
    const header = {}
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.stateAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.stateAPIURL + country
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCities(country:string, state:string){
    let url = configJSON.cityAPIURL;
    url = url.replace('{country}', country);
    url = url.replace('{state}', state);
    const header = {}
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cityAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  openForgotPassword(){
    window.location.href = '/forgot-password';
  }
  activateAccount(type: string){
    if(type == 'no'){
      window.location.href = '/';
    }else{
      this.activateVendorAccount();
    }
  }
  activateVendorAccount(){
    this.startLoading();
    const header = {
      token: localStorage.getItem('token')
    };

    const activatedData = new FormData();
    // @ts-ignore
    activatedData.set("is_deactivated", false);
    // const httpBody = data;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.vendorActivationAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.vendorActivationApiURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      activatedData
    );

    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), '');

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "patch"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
// Customizable Area End