Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  viewOrder:
    "/bx_block_inventorymanagement25/inventory_management/order_detail",
};

exports.Strings = {
  header: {
    title: "JHC | Order # ",
    heading: "Order Details",
  },
  labels: {
    orderNumber: "Order Number",
    time: "Time",
    address: "Address",
    itemName: "Item Name",
    noOfPackage: "No. of Package",
    price: "Price",
    customerName: "Customer Name",
    phone: "Phone Number",
    paymentMode: "Payment Mode",
    orderStatus: "Order Status",
    reasonFor: "Reason for",
  },
};
