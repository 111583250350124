import cartActions, { iActions as iCartAction } from "./cartActions";
import profileActions, { iActions as iProfileAction } from "./profileActions";
import dialoguesActions, {
  iActions as IDialoguesActions,
} from "./dialoguesActions";

export interface iRootActions {
  cart: iCartAction;
  profile: iProfileAction;
  dialogues: IDialoguesActions;
}

const rootActions: iRootActions = {
  cart: cartActions,
  profile: profileActions,
  dialogues: dialoguesActions,
};

export default rootActions;
