import { Message } from '../../../framework/src/Message'
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum"

const globalConfig = require('../../../framework/src/config')
const baseUrl = globalConfig?.baseURL


const GetApiMessage = ({ method = 'GET', endpoint, data = null, extraHeaders = {} }: any) => {

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    if (method) {
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
    }

    if (endpoint) {
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            baseUrl + endpoint
        );
    }

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
            token: localStorage.getItem('token'),
            ...extraHeaders
        })
    );

    if (data) {
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            data
        );
    }

    return requestMessage

}

export default GetApiMessage