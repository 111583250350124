Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  submitContact: "/bx_block_settings/contact_us",
  getList: "/bx_block_settings/contact_us/list_contact_us_queries",
};

exports.Strings = {
  header: {
    title: "JHC | Contact Us",
  },
  labels: {
    contact: "Contact",
    firstName: "First Name *",
    lastName: "Last Name *",
    email: "Email *",
    phone: "Phone *",
    howCanIHelpYou: "How can we help you? *",
    description: "Description *",
    selectReason: "Select reason",
    alert: "Alert!",
  },
  message: {
    forAllQueries: "For all enquires, Please email us using the form below",
    sentSuccess: "Sent Successfully",
  },
  validation: {
    phone: "Please enter phone",
    email: "Please enter email",
    reason: "Please select reason",
    lastName: "Please enter last name",
    firstName: "Please enter first name",
    validPhone: "Please enter valid phone",
    description: "Please enter description",
    validEmail: "Please enter valid email id",
  },
  actions: {
    continue: "Continue",
    goBack: "Go Back",
  },
};
