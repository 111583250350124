// Customizable Area Start

import React from 'react'
import { styled } from '@material-ui/core'
import { BlockComponent } from '../../../../framework/src/BlockComponent'

interface Props {}
interface State {}
interface SS {}

class StyledHR extends BlockComponent<Props, State, SS> {
    render() {
        return <HR />
    }
}

const HR = styled('hr')({
    height: 0,
    margin: 0,
    width: '100%',
    border: 'none',
    marginTop: 5,
    marginBottom: 10,
    background: 'black',
    borderBottom: '1px solid black',
})

export default StyledHR

// Customizable Area End