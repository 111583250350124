Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ElasticSearch";
exports.labelBodyText = "ElasticSearch Body";

exports.btnExampleTitle = "CLICK ME";
exports.searchAPIURL = "/bx_block_catalogue/catalogues/filtering?page={page}&per_page={per_page}";
exports.brandAPIURL = "/bx_block_filter_items/filters/brands?page=1&per_page=100";
exports.newArrivalsApiURL = "/bx_block_catalogue/catalogues/new_arrivals?page={page}&per_page={per_page}";
exports.specialProductsApiURL = "/bx_block_catalogue/catalogues/specials?page={page}&per_page={per_page}";
exports.bestSellersApiURL = "/bx_block_catalogue/catalogues/best_sellers?page={page}&per_page={per_page}";
exports.todayDealsApiURL = "/bx_block_catalogue/catalogues/today_deal?page={page}&per_page={per_page}";
exports.beautyPersonalApiURL = "/bx_block_catalogue/catalogues/frequnatly_repurchased_in_beauty_and_personal_care?page={page}&per_page={per_page}";
exports.browsingHistoryApiURL = "/bx_block_catalogue/catalogues/get_user_browsing_history?page={page}&per_page={per_page}";
exports.buyItAgainApiURL = "/bx_block_catalogue/catalogues/buy_it_again?page={page}&per_page={per_page}";
exports.manufacturersApiURL = "/bx_block_filter_items/filters/load_manufacturer";
exports.locationApiURL = "/bx_block_filter_items/filters/load_locations";
exports.offerApiURL = "/bx_block_coupon_cg/offers/{offer_id}?page={page}&per_page={per_page}";
exports.brandPageApiURL = "/bx_block_filter_items/filters/brands/{brand_id}?page={page}&per_page={per_page}";
// Customizable Area End