// Customizable Area Start

import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { IBlock } from "../../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";

import GetApiMessage from "../../GetApiMessage";
import ISuccessResponse from "../../ISuccessResponse";
import ApiResponseMiddleware from "../../ApiResponseMiddleware";

const configJs = require("./config");
const ApiUrls = configJs.ApiUrls;

interface Props {
  onSetStatistics: Function;
}
interface SS {}
interface S {
  rejectItemFlag: any;
  selectedOrderId: any;
  orderList: Array<any>;
  reasonList: Array<any>;
  rejectReasonMessage: any;
  rejectOrderMessageId: any;
  orderToDeliveryMessageId: any;
  rejectOrderReasonListMessageId: any;
  deliverMessageId: string;

  currentPage: number;
  lastPage: number;
}

class OrderToDeliveryController extends BlockComponent<Props, S, SS> {
  state = {
    currentPage: 1,
    lastPage: 1,

    rejectItemFlag: false,
    rejectReasonMessage: "",
    selectedOrderId: "",

    // message ids
    rejectOrderReasonListMessageId: null,
    orderToDeliveryMessageId: null,
    rejectOrderMessageId: null,
    deliverMessageId: "",

    // list
    orderList: [],
    reasonList: [],
  };

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
    ]);
  }

  componentDidMount(): any {
    this.getReasonList();
    this.getOrderData();
  }

  receive(from: string, message: Message): void {
    if (!ApiResponseMiddleware(message)) return;

    const {
      deliverMessageId,
      rejectOrderMessageId,
      rejectOrderReasonListMessageId,
      orderToDeliveryMessageId,
    } = this.state;

    let requestID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (deliverMessageId === requestID) {
      if (successResponse) {
        this.getOrderData();
      }
    }

    if (orderToDeliveryMessageId === requestID) {
      if (successResponse) {
        const {
          meta,
          in_progress,
          failed_order,
          pending_order,
          completed_task,
        } = successResponse;

        if (meta) {
          this.setState({ lastPage: meta.last_page });
        }
        let data: any = successResponse?.data || [];

        data = data.map((item: { id: any; attributes: any }) => {
          return { id: item.id, ...item.attributes };
        });
        this.setState({ orderList: data });
        this.props.onSetStatistics(
          {
            in_progress,
            failed_order,
            pending_order,
            completed_task,
          },
          "order-to-delivery"
        );
      }
    }

    if (rejectOrderReasonListMessageId === requestID) {
      if (successResponse) {
        // @ts-ignore
        let data: any = successResponse?.data || [];
        data = data.map((item: any) => {
          return item.attributes;
        });
        this.setState({ reasonList: data });
      }
    }

    if (rejectOrderMessageId === requestID) {
      if (successResponse) {
        this.getOrderData();
      }
    }
  }

  getReasonList() {
    let requestMessage = GetApiMessage({
      method: "get",
      endpoint: ApiUrls.getReasonList,
    });
    this.setState({
      rejectOrderReasonListMessageId: requestMessage.messageId,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getOrderData(page: number = this.state.currentPage) {
    let requestMessage = GetApiMessage({
      method: "get",
      endpoint: ApiUrls.getOrderToDelivery + "?per_page=10&page=" + page,
    });
    this.setState({ orderToDeliveryMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onRejectOrderClick(id: any) {
    this.setState({ selectedOrderId: id, rejectItemFlag: true });
  }

  onAcceptOrderClick(id: any) {
    this.setState({ selectedOrderId: id });
    this.onRejectOrder("confirmed", id);
  }

  onRejectOrder(
    status: string = "rejected",
    orderId: any = this.state.selectedOrderId
  ) {
    let form = new FormData();
    form.set("status", status);
    form.set("rejection_id", this.state.rejectReasonMessage);

    let requestMessage = GetApiMessage({
      data: form,
      method: "post",
      endpoint: ApiUrls.rejectOrder + "/" + orderId,
    });

    this.setState({
      rejectOrderMessageId: requestMessage.messageId,
      rejectItemFlag: false,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onChangePage(page: number) {
    this.setState({ currentPage: page });
    this.getOrderData(page);
  }

  onDeliverOrder(orderId: number) {
    let requestMessage = GetApiMessage({
      endpoint: ApiUrls.deliverOrderApi + orderId,
    });

    this.setState({ deliverMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

export default OrderToDeliveryController;

// Customizable Area End
