import React from "react";
import { styled } from "@material-ui/core";
import GetTruncateText from "./GetTruncateText";

interface Props {
  text: string;
  fontSize?: number;
  minLength: number;
}

interface State {
  expend: boolean;
  isLinksRequired: boolean;
}

class ReadMoreBlock extends React.Component<Props, State> {
  state = {
    expend: false,
    isLinksRequired: false,
  };

  componentDidMount(): void {
    let { text, minLength } = this.props;
    let flag = text.toString().length <= minLength;
    this.setState({ isLinksRequired: !flag });
  }

  getFormattedText() {
    const { text, minLength } = this.props;
    const { isLinksRequired, expend } = this.state;
    if (expend) return text;
    else if (isLinksRequired) return GetTruncateText(text, minLength);
    else return text;
  }

  render(): React.ReactNode {
    const { fontSize } = this.props;
    const { isLinksRequired, expend } = this.state;
    return (
      <>
        {this.getFormattedText()}
        {isLinksRequired && (
          <StyledLink
            // @ts-ignore
            fontSize={fontSize}
            onClick={() => this.setState({ expend: !expend })}
          >
            {expend ? " See less" : "See more"}
          </StyledLink>
        )}
      </>
    );
  }
}

const StyledLink = styled("span")((props: { fontSize: number | any }) => ({
  fontSize: props.fontSize && props.fontSize,
  color: "var(--navy)",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
}));

export default ReadMoreBlock;
