// Customizable Area Start

const GetTruncateText = (text: string, length: number) => {
  if (!text) return "";
  if (!length) return text;
  if (String(text).length <= length) return text;

  let newString = String(text).substring(0, length - 3) + "...";

  return newString;
};

export default GetTruncateText;

// Customizable Area End
