Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "InvoiceBilling";
exports.labelBodyText = "InvoiceBilling Body";

exports.btnExampleTitle = "CLICK ME";
exports.invoiceAPIURL = "/bx_block_order_management/orders/get_order_invoice/";
// Customizable Area End