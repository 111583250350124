// Customizable Area Start

import React from "react";
import { Chart } from "react-google-charts";
import ReportWrapper from "./ReportWrapper";
import AdHocReportingController from "./AdHocReportingController";

const configJs = require("./config");
const Strings = configJs.Strings;

const options = {};

class InventoryReport extends AdHocReportingController {
  render() {
    const { inventoryDownloadLink,filter,loading ,dateRanges} = this.state;
    return (
      <ReportWrapper
        visibleMonthSelector={true}
        downloadLink={inventoryDownloadLink}
        mainTitle={Strings.labels.inventoryReport}
        dateRangeKey="inventoryReport"
        onChangeDate={this.onChangeDateRange}
        currentFilter={filter["inventoryReport"]}
        onChangeFilter={this.onFilterChange}
        loading={loading["inventoryReport"]}
        rangeValue={dateRanges["inventoryReport"]}
      >
        <Chart
          height={"400px"}
          options={options}
          chartType="PieChart"
          data={this.getInventoryChartData()}
        />
      </ReportWrapper>
    );
  }
}

export default InventoryReport;

// Customizable Area End
