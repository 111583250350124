// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";

import phone from "phone";
import GetApiMessage from "../GetApiMessage";
import ISuccessResponse from "../ISuccessResponse";
import ApiResponseMiddleware from "../ApiResponseMiddleware";
import GetErrorMessages from "../GetApiErrorsWithKeys";
import BlockHelpers from "../../../utilities/src/BlockHelpers";

const configJs = require("./config");
const Strings = configJs.Strings;
const ApiUrls = configJs.ApiUrls;
const MappedErrorKeys = configJs.MappedErrorKeys;

interface Props {
  navigation: any;
}
interface SS {}
interface S {
  isLoading: boolean;
  profile: {
    tax_registration_doc: any;
    supporting_doc: any;
    pharmacy_registration_doc: any;
    commercial_registration_doc: any;
    store_name: any | string;
    email: any | string;
    country_code: any;
    phone_number: any;
    business_name: any;
    business_address: any;
    business_city: any;
    business_state: any;
    business_pin_code: any;
    business_country: any;
    company_logo: any;
    // documents
    companyLogoDoc:
      | any
      | {
          name: any;
        };
    commercialDoc:
      | any
      | {
          name: any;
        };
    taxRegistrationDoc:
      | any
      | {
          name: any;
        };
    supportingDoc:
      | any
      | {
          name: any;
        };
    pharmacyDoc:
      | any
      | {
          name: any;
        };
  };
  validation: {
    store_name: any;
    email: any;
    country_code: any;
    phone_number: any;
    business_name: any;
    business_address: any;
    business_city: any;
    business_state: any;
    business_pin_code: any;
    business_country: any;
    // commercial
    commercialDoc: any;
    taxRegistrationDoc: any;
    supportingDoc: any;
    pharmacyDoc: any;
  };

  // api ids
  apiCallId: any;
  viewApiCallId: any;
  saveApiMessageId: any;
  stateListMessageId: string;
  countryListMessageId: string;

  countryList: Array<any>;
  stateList: Array<any>;
  cityList: Array<any>;
  isStoreMember: boolean;
}

class VendorRegistrationController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      countryList: [],
      stateList: [],
      cityList: [],

      profile: {
        tax_registration_doc: null,
        supporting_doc: null,
        pharmacy_registration_doc: null,
        commercial_registration_doc: null,
        store_name: null,
        email: null,
        company_logo: null,
        country_code: "+971",
        phone_number: null,
        business_name: null,
        business_address: null,
        business_city: null,
        business_state: null,
        business_pin_code: null,
        business_country: null,
        // documents
        companyLogoDoc: null,
        commercialDoc: null,
        taxRegistrationDoc: null,
        supportingDoc: null,
        pharmacyDoc: null,
      },
      validation: {
        store_name: null,
        email: null,
        country_code: null,
        phone_number: null,
        business_name: null,
        business_address: null,
        business_city: null,
        business_state: null,
        business_pin_code: null,
        business_country: null,
        // commercial
        commercialDoc: null,
        taxRegistrationDoc: null,
        supportingDoc: null,
        pharmacyDoc: null,
      },
      // api ids
      countryListMessageId: "",
      stateListMessageId: "",
      saveApiMessageId: null,
      viewApiCallId: null,
      apiCallId: null,
      isStoreMember: false
    };

    this.subScribedMessages = [
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.receive = this.receive.bind(this);
  }

  componentDidMount(): any {
    super.componentDidMount();
    this.getCountryList();
    this.getStateList();
    this.getUserData();
  }

  receive(from: string, message: Message): void {
    if (!this.isLoaded) return;
    if (!ApiResponseMiddleware(message)) return;

    const {
      viewApiCallId,
      saveApiMessageId,
      stateListMessageId,
      countryListMessageId,
    } = this.state;

    this.setState({ isLoading: false });

    let requestID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (viewApiCallId === requestID) {
      if (successResponse) {
        const { id, attributes } = successResponse.data;
        const data = { id, ...attributes };
        this.setState({
          profile: {
            ...data,
            phone_number: data.business_phone_no,
            email: data.business_email,
            country_code: data.country_code || "+971",
          }, isStoreMember: successResponse?.meta?.store_member_login
        });
        this.getStateList(data?.business_country);
      }
    } else if (saveApiMessageId === requestID) {
      const { status, message } = successResponse;

      if (successResponse) {
        if (status === 422) {
          let errors =
            message
              ?.map((item: any) => {
                let key = Object.keys(item)[0];
                return item[key];
              })
              .join(", ") ?? "";

          BlockHelpers.showAlert(Strings.labels.error, errors);
          return;
        }

        let errorMessage = GetErrorMessages(message, MappedErrorKeys);
        if (errorMessage) {
          // @ts-ignore
          this.setState({ validation: errorMessage });
          BlockHelpers.showAlert(
            Strings.labels.error,
            errorMessage.extraMessages
          );
        } else {
          this.getUserData();
          BlockHelpers.showAlert(
            Strings.labels.success,
            Strings.messages.vendorDetails
          );
        }
      }
    } else if (countryListMessageId === requestID) {
      if (successResponse) {
        const { countries } = successResponse;
        if (countries) {
          let countryList: any = [];
          Object.keys(countries).map((key) => {
            countryList.push({ id: key, value: countries[key] });
          });
          this.setState({ countryList });
        }
      }
    } else if (stateListMessageId === requestID) {
      if (successResponse) {
        const { states } = successResponse;
        if (states) {
          let stateList: any = [];
          Object.keys(states).map((key) => {
            stateList.push({ id: key, value: states[key] });
          });
          this.setState({ stateList });
        }
      }
    }
  }

  onChangeProfile(key: string, value: string) {
    this.setState({
      profile: {
        ...this.state.profile,
        [key]: value,
      },
    });
  }

  getUserData() {
    this.setState({ isLoading: true });

    const requestMessage = GetApiMessage({
      method: "get",
      endpoint: ApiUrls.viewProfile,
    });

    this.setState({
      viewApiCallId: requestMessage.messageId,
      apiCallId: "",
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  isValid() {
    let errorMessages: Object = {};

    const saveError = (key: string, value: any) => {
      errorMessages = {
        ...errorMessages,
        [key]: value,
      };
    };

    const numericRegex = /^[0-9]+$/;
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const {
      pharmacy_registration_doc,
      supporting_doc,
      tax_registration_doc,
      commercial_registration_doc,
      business_name,
      business_address,
      business_city,
      business_state,
      business_pin_code,
      business_country,
      phone_number,
      email,
      store_name,
      commercialDoc,
      taxRegistrationDoc,
      supportingDoc,
      pharmacyDoc,
    } = this.state.profile;

    if (!business_name)
      saveError("business_name", Strings.validation.businessName);
    if (!business_address)
      saveError("business_address", Strings.validation.businessAddress);
    if (!business_city)
      saveError("business_city", Strings.validation.businessCity);
    if (!business_state)
      saveError("business_state", Strings.validation.businessState);
    if (!business_pin_code)
      saveError("business_pin_code", Strings.validation.businessPinCode);
    if (!business_country)
      saveError("business_country", Strings.validation.businessCountry);
    if (!email) saveError("email", Strings.validation.email);
    if (!store_name) saveError("store_name", Strings.validation.storeName);
    if (!phone_number) saveError("phone_number", Strings.validation.phone);

    if (!commercial_registration_doc)
      if (!commercialDoc)
        saveError("commercialDoc", Strings.validation.commercialDoc);
    if (!tax_registration_doc)
      if (!taxRegistrationDoc)
        saveError("taxRegistrationDoc", Strings.validation.taxRegistrationDoc);
    if (!supporting_doc)
      if (!supportingDoc)
        saveError("supportingDoc", Strings.validation.supportingDoc);
    if (!pharmacy_registration_doc)
      if (!pharmacyDoc)
        saveError("pharmacyDoc", Strings.validation.pharmacyDoc);

    if (business_pin_code)
      if (!numericRegex.test(business_pin_code))
        saveError("business_pin_code", Strings.validation.validPinCode);

    if (email)
      if (!emailRegex.test(email))
        saveError("email", Strings.validation.validEmail);

    // @ts-ignore
    this.setState({ validation: errorMessages });

    // @ts-ignore
    return !Object.keys(errorMessages).length > 0;
  }

  onSubmit() {
    if (!this.isValid()) return;

    const {
      companyLogoDoc,
      commercialDoc,
      pharmacyDoc,
      supportingDoc,
      taxRegistrationDoc,
      store_name,
      email,
      country_code,
      phone_number,
      business_name,
      business_address,
      business_city,
      business_state,
      business_pin_code,
      business_country,
    } = this.state.profile;

    let form = new FormData();

    form.set("business_email", email);
    form.set("store_name", store_name);
    form.set("country_code", country_code);
    form.set("business_name", business_name);
    form.set("business_city", business_city);
    form.set("business_state", business_state);
    form.set("business_phone_no", phone_number);
    form.set("business_address", business_address);
    form.set("business_pin_code", business_pin_code);
    form.set("business_country", business_country);

    // @ts-ignore
    commercialDoc && form.set("commercial_registration_doc", commercialDoc);
    // @ts-ignore
    pharmacyDoc && form.set("pharmacy_registration_doc", pharmacyDoc);
    // @ts-ignore
    taxRegistrationDoc && form.set("tax_registration_doc", taxRegistrationDoc);
    // @ts-ignore
    supportingDoc && form.set("supporting_doc", supportingDoc);
    // @ts-ignore
    companyLogoDoc && form.set("company_logo", companyLogoDoc);

    let requestMessage = GetApiMessage({
      method: "patch",
      endpoint: ApiUrls.editProfile,
      data: form,
    });
    this.setState({ saveApiMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCountryList() {
    let requestMessage = GetApiMessage({ endpoint: ApiUrls.listCountry });
    this.setState({ countryListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStateList(id: any = this.state.profile.business_country) {
    let country = id || "AE";
    let requestMessage = GetApiMessage({
      endpoint: ApiUrls.listState + `country=${country}`,
    });
    this.setState({ stateListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

export default VendorRegistrationController;

// Customizable Area End
