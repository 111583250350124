export const emptyMyOrdersIcon = require("../assets/emptyMyOrdersIcon.png");
export const infoIcon = require('../assets/info.svg')
export const img1091c4968699c39b90e4e6e1f860db75dcfe98bb = require("../../global_assets/1091c4968699c39b90e4e6e1f860db75dcfe98bb.png");
export const imgd078cb37419bf7263793bb772747275f987492b3 = require("../../global_assets/d078cb37419bf7263793bb772747275f987492b3.png");
export const imgc08a197f56ef1d5ebda2eabb088dc31d93c7a44d = require("../../global_assets/c08a197f56ef1d5ebda2eabb088dc31d93c7a44d.png");
export const img308dca2d8ff863fca7f6e3d37f7ffc14ad8eb15d = require("../../global_assets/308dca2d8ff863fca7f6e3d37f7ffc14ad8eb15d.png");
export const imga680398bc47bd635fccee385c34e228735d558ed = require("../../global_assets/a680398bc47bd635fccee385c34e228735d558ed.png");
export const img199208486bb051fa94d0d83d1094ebe18827c227 = require("../../global_assets/199208486bb051fa94d0d83d1094ebe18827c227.png");
export const img46b0638d4c5240b3ca0df7431b9263e2d628b26d = require("../../global_assets/46b0638d4c5240b3ca0df7431b9263e2d628b26d.png");
export const imge3c2c6478b22a15f0f930f4e1b35b2bb10134893 = require("../../global_assets/e3c2c6478b22a15f0f930f4e1b35b2bb10134893.png");
export const img8cf3cfaf3e0b4e878a81fb56435d89a6aa7dcdf3 = require("../../global_assets/8cf3cfaf3e0b4e878a81fb56435d89a6aa7dcdf3.png");
export const imgc2388b698006f63ce43ab68382e1aad840dfd9aa = require("../../global_assets/c2388b698006f63ce43ab68382e1aad840dfd9aa.png");
export const img2676fee58fbf7796ccff3bcbf1d2635c381985c2 = require("../../global_assets/2676fee58fbf7796ccff3bcbf1d2635c381985c2.png");
export const img22b0cc9fcd64c92d79afe3311c0f820bdb90a4e5 = require("../../global_assets/22b0cc9fcd64c92d79afe3311c0f820bdb90a4e5.png");
export const imgbe44f931b0edd23ba244e4dde9bcddf023682eea = require("../../global_assets/be44f931b0edd23ba244e4dde9bcddf023682eea.png");
export const img0d1664417b1a8be6616e81d90a53ff69de883619 = require("../../global_assets/0d1664417b1a8be6616e81d90a53ff69de883619.png");
export const img74a41fc6e948bc53ca0510a95316b54e3624ca23 = require("../../global_assets/74a41fc6e948bc53ca0510a95316b54e3624ca23.png");
export const img272b88f661769888d6cdf2837a5cb78d2353b9ed = require("../../global_assets/272b88f661769888d6cdf2837a5cb78d2353b9ed.png");
export const img50efd9822b5a523dc0e1532471282689cb37095c = require("../../global_assets/50efd9822b5a523dc0e1532471282689cb37095c.png");
export const imgc551a99f769ef5270043ade1058fb82183c27ac4 = require("../../global_assets/c551a99f769ef5270043ade1058fb82183c27ac4.png");
export const img9c8053053b6054de2d46d43d96d9f1b58688defc = require("../../global_assets/9c8053053b6054de2d46d43d96d9f1b58688defc.png");
export const img2b396d02b62200631b6d95c2871233d847c5bba2 = require("../../global_assets/2b396d02b62200631b6d95c2871233d847c5bba2.png");
export const imgcd0b8d517cb1ae286c17827768f1dcd1af9f05e2 = require("../../global_assets/cd0b8d517cb1ae286c17827768f1dcd1af9f05e2.png");
export const imgb7b49df8d1bc0388c9088a5fc66b770276f3ced4 = require("../../global_assets/b7b49df8d1bc0388c9088a5fc66b770276f3ced4.png");
export const imgcee2a237845666ab26f5aac94e64e51713c8de48 = require("../../global_assets/cee2a237845666ab26f5aac94e64e51713c8de48.png");
export const img987f9722257d381a0f37e5b0921ceba78b201884 = require("../../global_assets/987f9722257d381a0f37e5b0921ceba78b201884.png");
export const img9b66ebee4af0aaef26ac0f41951c5f425edf2a7b = require("../../global_assets/9b66ebee4af0aaef26ac0f41951c5f425edf2a7b.png");
export const imgaa1ccd86a4ac484ea67394e52f8fe2270ef192fe = require("../../global_assets/aa1ccd86a4ac484ea67394e52f8fe2270ef192fe.png");
export const imge197305f868f05d6e765805474732b168da8d3a6 = require("../../global_assets/e197305f868f05d6e765805474732b168da8d3a6.png");
export const imga49679ff0526fcda926f2885fa279e5cc9899232 = require("../../global_assets/a49679ff0526fcda926f2885fa279e5cc9899232.png");
export const img3e11797f43d9d56766eb816f28aa4f9b4ba9b27b = require("../../global_assets/3e11797f43d9d56766eb816f28aa4f9b4ba9b27b.png");
export const img71fd022ee7bdc62d4d1940a0ed7d47c2847171dd = require("../../global_assets/71fd022ee7bdc62d4d1940a0ed7d47c2847171dd.png");
export const imgefe7109df6b5fe53d7032f89df6eb7e6c8fea7fc = require("../../global_assets/efe7109df6b5fe53d7032f89df6eb7e6c8fea7fc.png");
export const img5b472e132f70ae1d272d298135131aec09444363 = require("../../global_assets/5b472e132f70ae1d272d298135131aec09444363.png");
export const img1eea57dc2d87192ce0240e1f6db3ae32a62e46ad = require("../../global_assets/1eea57dc2d87192ce0240e1f6db3ae32a62e46ad.png");
export const img2e5f39d3ad85cda7a4a0821aa591f25c719711bd = require("../../global_assets/2e5f39d3ad85cda7a4a0821aa591f25c719711bd.png");
export const imgbf726de01cc124ea50bc1c57b4fa81e70811a6b1 = require("../../global_assets/bf726de01cc124ea50bc1c57b4fa81e70811a6b1.png");
export const imgcb3acc030126dd7a81e975d916e1bd51b17f98ba = require("../../global_assets/cb3acc030126dd7a81e975d916e1bd51b17f98ba.png");
export const img8b85ee40e1eeba575c82aec099a4aa68ff4da90f = require("../../global_assets/8b85ee40e1eeba575c82aec099a4aa68ff4da90f.png");
export const img5aaf80b13d19fd96031541d34851f2d38bb02d8a = require("../assets/5aaf80b13d19fd96031541d34851f2d38bb02d8a.png");
export const img09c79f54a05db28f8a9096539bdcf19fd9f4b6bd = require("../assets/09c79f54a05db28f8a9096539bdcf19fd9f4b6bd.png");
export const img2b813a62bf6a4aa75141af9e331712d5e100f3b3 = require("../assets/2b813a62bf6a4aa75141af9e331712d5e100f3b3.png");
export const imgc58b30ebd35697bc6fe62beca22f1b514890890b = require("../assets/c58b30ebd35697bc6fe62beca22f1b514890890b.png");
export const img1ad0d1d1755bad52bd0dbf368ed0c9f23f6a778b = require("../assets/1ad0d1d1755bad52bd0dbf368ed0c9f23f6a778b.png");
export const img5e5c9a4693ed7c7de8d363e09d3ffb0201e16b91 = require("../assets/5e5c9a4693ed7c7de8d363e09d3ffb0201e16b91.png");
export const img18b9aa6fff4d18fce636f1756039f4226757b684 = require("../assets/18b9aa6fff4d18fce636f1756039f4226757b684.png");
export const imgf2983b0c9c155c1db4c1b5acdac6f020600a3835 = require("../assets/f2983b0c9c155c1db4c1b5acdac6f020600a3835.png");
export const img4ea232c961d0db6b538472dff7b79962f271ff2f = require("../assets/4ea232c961d0db6b538472dff7b79962f271ff2f.png");
export const imgeff9d7a7e729a2f1617edeff3b89f2147ba7d74d = require("../assets/eff9d7a7e729a2f1617edeff3b89f2147ba7d74d.png");
export const imga31ea96f6935dce3102a83f4cfc499e528dc123c = require("../assets/a31ea96f6935dce3102a83f4cfc499e528dc123c.png");
export const img16b3448f80406ffca1baac0e1eeb2c5134812d47 = require("../assets/16b3448f80406ffca1baac0e1eeb2c5134812d47.png");
export const img4184c16e0a193b10368dcda8b1fce5b1afa35f63 = require("../assets/4184c16e0a193b10368dcda8b1fce5b1afa35f63.png");
export const img3ab82dc9a0e3ff030e32d4fb07e6ce9633e3586b = require("../assets/3ab82dc9a0e3ff030e32d4fb07e6ce9633e3586b.png");
export const img89be6f44f12be1507779f18a68a5aab001a95eef = require("../assets/89be6f44f12be1507779f18a68a5aab001a95eef.png");
export const imgd787d8fe5d1214cafc993940d7752dcb5147bdd3 = require("../assets/d787d8fe5d1214cafc993940d7752dcb5147bdd3.png");
export const img023e7c953e6b3f87f8ec4d9cb4c01ec52b1111e1 = require("../assets/023e7c953e6b3f87f8ec4d9cb4c01ec52b1111e1.png");
export const imgd9d1353984a2e114c43de7119b8d978ca284500d = require("../assets/d9d1353984a2e114c43de7119b8d978ca284500d.png");
export const img4541867c7632c0415b0374b6f7626918e9856dda = require("../assets/4541867c7632c0415b0374b6f7626918e9856dda.png");
export const img3ac488f2c76c872d62b2ceee9255be8e776d3df1 = require("../assets/3ac488f2c76c872d62b2ceee9255be8e776d3df1.png");
