Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  rejectOrder:
    "/bx_block_inventorymanagement25/inventory_management/accept_reject_order/",
  getReasonList:
    "/bx_block_inventorymanagement25/inventory_management/load_reasons",
  getOrderToDelivery:
    "/bx_block_inventorymanagement25/inventory_management/order_to_deliver",
  deliverOrderApi: "/bx_block_order_management/orders/order_delivered/",
};

exports.Strings = {
  table: {
    actions: "action",
    paymentMode: "payment mode",
    orderStatus: "order status",
    orderDetails: "order details",
    productDetails: "product details",
    customerDetails: "customer details",
    noData: "Data not found",
    orderNumber: "Order Number",
    time: "Time",
    itemName: "Item Name",
    noOfPackage: "No. of Package",
    price: "Price",
    customerName: "Customer Name",
    address: "Address",
    phoneNumber: "Phone Number",
    cod: "COD",
    onlinePayment: "Online Payment",
  },
  actions: {
    view: "View",
    accept: "Accept",
    reject: "Reject",
    submit: "Submit",
    cancel: "Cancel",
    delivered: "Delivered",
  },
};
