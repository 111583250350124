// Customizable Area Start

import React from "react";
import { Helmet } from "react-helmet";

import PaymentsController from "./PaymentsController";
import PaymentHeader from "./PaymentHeaders/PaymentHeaders.web";
import PaymentOnlineList from "./OnlinePaymentList/OnlinePaymentList.web";
import CashOnDeliveryList from "./CashOnDeliveryList/CashOnDeliveryList.web";

const configJs = require("./config");
const Strings = configJs.Strings;

class Payments extends PaymentsController {
  render() {
    const { listType } = this.state;

    return (
      <div data-test-id="payments-screen" className="inventory">
        <Helmet>
          <title>{Strings.header.title}</title>
        </Helmet>
        <PaymentHeader
          data-test-id="payment-header"
          listType={listType}
          onChangeList={(type: any) => this.onChangeList(type)}
        />
        {listType === "COD" ? (
          <CashOnDeliveryList listType={null} onChangeList={null} />
        ) : (
          <PaymentOnlineList listType={null} onChangeList={null} />
        )}
      </div>
    );
  }
}

export default Payments;

// Customizable Area End
