// Customizable Area Start
import React, { Component } from "react";
import RichTextEditor from "react-rte";
import { styled } from "@material-ui/core";

interface Props {
  value: any;
  error: boolean;
  label: string;
  onChange: Function | any;
  helperText: string | any;
  placeholder: string | any;
  requiredField: boolean | any;
}

interface State {
  value: any;
}

class MyStatefulEditor extends Component<Props, State> {
  state = {
    value: RichTextEditor.createEmptyValue(),
  };

  onChange(value: any) {
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value.toString("html"));
    }
  }

  componentDidMount(): void {
    let value: string = this.props.value;
    if (!value.startsWith("<p>")) {
      value = `<p>${value}</p>`;
    }

    this.setState({
      value:
        RichTextEditor.createValueFromString(value, "html") ||
        RichTextEditor.createEmptyValue(),
    });
  }

  render() {
    const { error, helperText, label, placeholder, requiredField } = this.props;
    return (
      <>
        <LabelText>
          {label}
          {requiredField && <RedLabel>{" * "}</RedLabel>}
        </LabelText>
        <RichTextEditor
          data-test-id="rte-editor"
          placeholder={placeholder}
          value={this.state.value}
          editorStyle={{ minHeight: 200 }}
          onChange={(e: any) => this.onChange(e)}
        />
        {error && <ErrorLabel>{helperText}</ErrorLabel>}
      </>
    );
  }
}

export default MyStatefulEditor;

const RedLabel = styled("span")({
  color: "red",
});

const LabelText = styled("div")({
  fontWeight: 500,
  display: "block",
  fontSize: "13px",
  letterSpacing: "0.5px",
  color: "var(--content)",
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
});

const ErrorLabel = styled("div")({
  fontSize: 13,
  marginTop: 5,
  color: "#f44336",
});

// Customizable Area End
