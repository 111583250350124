export const iconHOC = require("./assets/sidebar/hoc.png");
export const iconHome = require("./assets/sidebar/Home.png");
export const iconMenu = require("./assets/sidebar/Menu.png");
export const iconVendor = require("./assets/sidebar/Vendor Form.png");
export const iconSearch = require("./assets/header/search.png");
export const iconOrders = require("./assets/sidebar/All order.png");
export const iconPayment = require("./assets/sidebar/Payment.png");
export const iconTriangle = require("./assets/header/triangle.svg");
export const iconSettings = require("./assets/sidebar/Settings.png");
export const iconDiscounts = require("./assets/sidebar/Discount.png");
export const iconContact = require("./assets/sidebar/Contact Us.png");
export const iconInventory = require("./assets/sidebar/Inventory.png");
export const iconProfileSmall = require("./assets/sidebar/profileS.png");
export const iconArrowRight = require("./assets/sidebar/arrow-right.svg");
export const iconProfile = require("./assets/header/profile.png");
export const iconNotification = require("./assets/header/notice.png");
export const iconNotificationSmall = require("./assets/sidebar/Notification.png");
