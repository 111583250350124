import { combineReducers } from "@reduxjs/toolkit";

import actionReducer from "./actionReducers";
import AllActions, {
  iRootActions as actionsReducerReturnType,
} from "../actions";
import cartReducer, {
  iReturnType as cartReducerReturnType,
} from "./cartReducer";
import profileReducer, {
  iReturnType as profileReducerReturnType,
} from "./profileReducer";
import dialoguesReducer, {
  iReturnType as dialoguesReducerReturnType,
} from "./dialoguesReducers";

export interface IRootReducer {
  cart: cartReducerReturnType;
  actions: actionsReducerReturnType;
  profile: profileReducerReturnType;
  dialogues: dialoguesReducerReturnType;
}

const rootReducer: any = combineReducers({
  cart: cartReducer,
  actions: actionReducer,
  profile: profileReducer,
  dialogues: dialoguesReducer,
});

export default rootReducer;
