// Customizable Area Start

import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import GetDateString from "../GetDateString";
import NotificationMenuController, { Cols } from "./NotificationMenuController";

import {
  styled,
  Box,
  Grid,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
} from "@material-ui/core";

import { iconDelete, iconEdit } from "../assets";

import StyledHR from "../Shared/StyledHR";
import Pagination from "../Shared/Pagination";
import TitleHeading from "../Shared/TitleHeading";
import RoundedButton from "../Shared/RoundedButton";

import ConfirmDeleteModal from "./ConfirmDeleteModal";

const configJs = require("./config");
const Strings = configJs.Strings;

class NotificationMenu extends NotificationMenuController {
  render() {
    const { notificationList, deleteModalFlag } = this.state;
    return (
      <div data-test-id="notification-menu-screen">
        <TitleHeading text={Strings.header.heading} />
        <StyledHR />
        <Helmet>
          <title>{Strings.header.title}</title>
        </Helmet>
        {deleteModalFlag && (
          <ConfirmDeleteModal
            data-test-id="modal-delete"
            onOk={() => this.onClickDelete()}
            onClose={() => this.setState({ deleteModalFlag: false })}
          />
        )}

        <StyledTableContainer>
          <Table size="small">
            <StyledTableHead>
              <TableRow>
                {Cols.map((name) => (
                  <StyledTableHeadCell key={"table-cols-" + name}>
                    {name}
                  </StyledTableHeadCell>
                ))}
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {notificationList.length === 0 && (
                <StyledTableRow>
                  <TableCell
                    align="center"
                    valign="middle"
                    colSpan={Cols.length}
                    style={{ height: 80 }}
                  >
                    {Strings.table.noData}
                  </TableCell>
                </StyledTableRow>
              )}
              {notificationList.map((item: any) => {
                const { first_name, last_name, email } = item?.created_by;
                return (
                  <StyledTableRow key={item?.id}>
                    <TableCell>{item?.headings}</TableCell>
                    <TableCell>{item?.recipent}</TableCell>
                    <TableCell>
                      <div>
                        {GetDateString(item?.updated_at)} by{" "}
                        {`${first_name} ${last_name}`}
                      </div>
                      <div>{email}</div>
                    </TableCell>
                    <TableCell>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>
                          <Link to={"/vendor/notifications-edit/" + item?.id}>
                            <img src={iconEdit} width={22} />
                          </Link>
                        </Grid>
                        <Grid item>
                          <img
                            data-test-id={"btn-delete-" + item.id}
                            onClick={() => {
                              this.setState({
                                selectedId: item?.id,
                                deleteModalFlag: true,
                              });
                            }}
                            src={iconDelete}
                            width={22}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <StatusText>{Strings.table.sent}</StatusText>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
              <TableRow>
                <TableCell colSpan={5}>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Pagination
                        data-test-id="pagination"
                        currentPage={this.state.currentPage}
                        lastPage={this.state.lastPage}
                        onChangePage={(page: number) =>
                          this.getNotificationsByApi(page)
                        }
                      />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </StyledTableContainer>

        <br />

        <Link to={"/vendor/notifications-add"}>
          <RoundedButton text={Strings.label.notificationAdd} isActive={true} />
        </Link>
      </div>
    );
  }
}

const StatusText = styled(Box)({
  borderRadius: 4,
  padding: "4px 16px",
  color: "var(--navy)",
  display: "inline-block",
  textTransform: "capitalize",
  border: "2px solid var(--navy)",
});

const StyledTableHead = styled(TableHead)({
  color: "var(--white)",
  textTransform: "uppercase",
  background: "var(--gradient2)",
  "& .MuiCheckbox-root": {
    color: "white",
  },
});

const StyledTableHeadCell = styled(TableCell)({
  fontWeight: 400,
  fontSize: 15,
  color: "white",
});

const StyledTableRow = styled(TableRow)({
  boxShadow: "0 0 5px var(--boxShadow)",
});

const StyledTableContainer = styled(TableContainer)({
  boxShadow: "0px 0px 10px var(--boxShadow)",
});

export default NotificationMenu;

// Customizable Area End
