const ApiUrls = {
  categoryApiURL: "/bx_block_filter_items/filters/categories",
  browsingHistoryApiURL: "/bx_block_catalogue/catalogues/get_user_browsing_history",
  cartAPIURL: "/bx_block_order_management/orders/list_cart",
  notificationsList: '/bx_block_notifications/notifications',
  viewProfile: '/accounts/show_profile',
  addToCartAPIURL : "/bx_block_order_management/orders",
  offerApiURL: "/bx_block_coupon_cg/offers/home_offers",
  offerPageApiURL: "/bx_block_coupon_cg/offers",
  homeDataApiURL: "/bx_block_catalogue/catalogues/slider",
  brandsPageApiURL: "/bx_block_filter_items/filters/brands?page=1&per_page=15",
  addToWishlistApiURL : "/bx_block_catalogue/wish_lists",
  removeFromWishlistApiURL: "/bx_block_catalogue/wish_lists/remove_from_wishlist",
  guestTokenApiURL: "/login/login",
  email: "guest_user@gmail.com",
  loginType: "guest_login",
  logout:"/accounts/logout"
}

export default ApiUrls
