import Actions from "../actions";

const { profile: profileActions } = Actions;

export interface iReturnType {
  data: Object | null;
  refetch: boolean;
}

const initialState: iReturnType = { data: null, refetch: false };

interface iAction {
  type: string;
  payload: any;
}

const profileReducer = (state = initialState, action: iAction): iReturnType => {
  switch (action.type) {
    case profileActions.REFETCH:
      return {
        ...state,
        refetch: !state.refetch,
      };

    case profileActions.CLEAR:
      return {
        ...state,
        data: null,
      };
    case profileActions.SAVE:
      let { id, attributes } = action.payload || {};
      return {
        ...state,
        data: {
          id,
          ...attributes,
        },
      };
    default:
      return state;
  }
};

export default profileReducer;
