Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  editProfile: "/accounts/edit_profile",
  viewProfile: "/accounts/show_profile",
  listCountry: "/bx_block_state_cities/state_cities/countries",
  listState: "/bx_block_state_cities/state_cities/states?",
  listCity: "/bx_block_state_cities/state_cities/cities?",
};

exports.Strings = {
  header: {
    title: "JHC | Vendor Form",
    heading: "Vendor Registration",
  },
  labels: {
    success: "Success!",
    error: "Errors!",
    loading: "Loading..",
    organization: "Organization/Business",
    address: "Address",
    country: "Country",
    state: "State/Province",
    city: "City",
    postal: "Postal/Zipcode",
    phone: "Phone Number",
    email: "Email Address",
    storeName: "Store Name",
    documents: "Documents",
    image: "Image",
    companyLogo: "Company Logo",
    uploadImage: "Upload Image",
    uploadDocument: "Upload Documents",
    viewDocument: "View Document",
    viewLogo: "View Logo",
    commercialDocuments: "Commercial Documents",
    registrationTaxDoc: "Registration Tax Documents",
    supportDocuments: "Supporting Documents",
    pharmacyDocuments: "Pharmacy Registration Documents",
  },
  messages: {
    vendorDetails: "Vendor details are saved!",
  },
  actions: {
    save: "Save",
  },
  validation: {
    email: "Please enter email",
    phone: "Please enter phone number",
    storeName: "Please enter store name",
    validPhone: "Please enter valid phone",
    businessCity: "Please enter business city",
    businessState: "Please enter business state",
    pharmacyDoc: "Please enter pharmacy documents",
    validEmail: "Please enter valid email address",
    validPinCode: "Only numeric values are allowed",
    businessCountry: "Please enter business country",
    businessAddress: "Please enter business address",
    businessPinCode: "Please enter business pin code",
    commercialDoc: "Please enter commercial documents",
    supportingDoc: "Please enter supporting documents",
    businessName: "Please enter business / organization name",
    taxRegistrationDoc: "Please enter tax registration documents",
  },
};

exports.MappedErrorKeys = {
  business_email: "email",
  business_phone_no: "phone_number",
  tax_registration_doc: "tax_registration_doc",
  supporting_doc: "supporting_doc",
  pharmacy_registration_doc: "pharmacy_registration_doc",
  commercial_registration_doc: "commercial_registration_doc",
  store_name: "store_name",
  company_logo: "company_logo",
  country_code: "country_code",
  business_name: "business_name",
  business_address: "business_address",
  business_city: "business_city",
  business_state: "business_state",
  business_pin_code: "business_pin_code",
  business_country: "business_country",
};
