// Customizable Area Start

import { BlockComponent } from "../../../framework/src/BlockComponent";

interface Props {
  navigation: {
    getParam: Function;
    navigate: Function;
  };
}
interface State {}
interface SS {}

class VendorController extends BlockComponent<Props, State, SS> {}

export default VendorController;

// Customizable Area End
