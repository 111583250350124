import Actions, { iRootActions } from "../actions";

const actionReducers = (
  state = Actions,
  action: { type: string; payload: any }
): iRootActions => {
  return state;
};

export default actionReducers;
