Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.apiContentType = 'application/json'
exports.getApiMethod = 'GET'
exports.postApiMethod = 'POST'
exports.deleteApiMethod = 'DELETE'

exports.getOrdersApiEndPoint = 'bx_block_order_management/orders/list_cart'
exports.createOrderItemApiEndPoint = 'shopping_cart/order_items'
exports.deleteOrderItemApiEndPoint = '/bx_block_order_management/orders/remove_item'

exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'
exports.addToCartAPIURL = "/bx_block_order_management/orders";
// Customizable Area End
