import { ReactNode } from "react";
import { connect } from "react-redux";
import { IReduxState } from "../../../web/src/redux";

export interface IReduxWrapper {
  reduxState?: IReduxState;
  reduxDispatch: Function;
}

const mapStateToProps = (state: any) => ({ reduxState: state });
const mapDispatchToProps = (dispatch: any) => {
  return {
    reduxDispatch: dispatch,
  };
};

const withReduxWrapper = (Component: any): ReactNode => {
  return connect(mapStateToProps, mapDispatchToProps)(Component);
};

export default withReduxWrapper;
