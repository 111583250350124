export const slider1 = require("../assets/slider-1.jpg");
export const slider2 = require("../assets/slider-2.jpg");
export const slider3 = require("../assets/slider-3.jpg");
export const img1091c4968699c39b90e4e6e1f860db75dcfe98bb = require("../../global_assets/1091c4968699c39b90e4e6e1f860db75dcfe98bb.png");
export const imgd078cb37419bf7263793bb772747275f987492b3 = require("../../global_assets/d078cb37419bf7263793bb772747275f987492b3.png");
export const imgc08a197f56ef1d5ebda2eabb088dc31d93c7a44d = require("../../global_assets/c08a197f56ef1d5ebda2eabb088dc31d93c7a44d.png");
export const img308dca2d8ff863fca7f6e3d37f7ffc14ad8eb15d = require("../../global_assets/308dca2d8ff863fca7f6e3d37f7ffc14ad8eb15d.png");
export const imga680398bc47bd635fccee385c34e228735d558ed = require("../../global_assets/a680398bc47bd635fccee385c34e228735d558ed.png");
export const img199208486bb051fa94d0d83d1094ebe18827c227 = require("../../global_assets/199208486bb051fa94d0d83d1094ebe18827c227.png");
export const img46b0638d4c5240b3ca0df7431b9263e2d628b26d = require("../../global_assets/46b0638d4c5240b3ca0df7431b9263e2d628b26d.png");
export const imge3c2c6478b22a15f0f930f4e1b35b2bb10134893 = require("../../global_assets/e3c2c6478b22a15f0f930f4e1b35b2bb10134893.png");
export const img8cf3cfaf3e0b4e878a81fb56435d89a6aa7dcdf3 = require("../../global_assets/8cf3cfaf3e0b4e878a81fb56435d89a6aa7dcdf3.png");
export const imgc2388b698006f63ce43ab68382e1aad840dfd9aa = require("../../global_assets/c2388b698006f63ce43ab68382e1aad840dfd9aa.png");
export const img2676fee58fbf7796ccff3bcbf1d2635c381985c2 = require("../../global_assets/2676fee58fbf7796ccff3bcbf1d2635c381985c2.png");
export const img22b0cc9fcd64c92d79afe3311c0f820bdb90a4e5 = require("../../global_assets/22b0cc9fcd64c92d79afe3311c0f820bdb90a4e5.png");
export const imge5f74238c2a08d80d0cf21a4a9d6b9da0efcf668 = require("../../global_assets/e5f74238c2a08d80d0cf21a4a9d6b9da0efcf668.png");
export const img801494fbab36c49180a7a02aa50c5e840cff8482 = require("../../global_assets/801494fbab36c49180a7a02aa50c5e840cff8482.png");
export const img4202e72d821397686aea436089a73c7862a9827d = require("../../global_assets/4202e72d821397686aea436089a73c7862a9827d.png");
export const imgb93d10414a676ac06a1e79f71fa8e3a4349d1466 = require("../../global_assets/b93d10414a676ac06a1e79f71fa8e3a4349d1466.png");
export const img8595c5420a383c5be98218601d83a03832810eec = require("../../global_assets/8595c5420a383c5be98218601d83a03832810eec.png");
export const img6d8ce453efb76a803f8ca058543856891eed1996 = require("../../global_assets/6d8ce453efb76a803f8ca058543856891eed1996.png");
export const img2f131aaebfc3c4898e64f5a9d5a0709a2c3bbadd = require("../../global_assets/2f131aaebfc3c4898e64f5a9d5a0709a2c3bbadd.png");
export const img69317545dfd04faeeccc6555c99281c0dccb2ef6 = require("../../global_assets/69317545dfd04faeeccc6555c99281c0dccb2ef6.png");
export const imgf973e369da5d33be4b75e3c04a4b5e3b5e19ff57 = require("../../global_assets/f973e369da5d33be4b75e3c04a4b5e3b5e19ff57.png");
export const imgc92c90ce1ec69fd7bdd7ec477a4b1b0539ea75ff = require("../../global_assets/c92c90ce1ec69fd7bdd7ec477a4b1b0539ea75ff.png");
export const img0b6818b37dca9dc20c8bc846cf01aa688b77dc2e = require("../../global_assets/0b6818b37dca9dc20c8bc846cf01aa688b77dc2e.png");
export const img7a720906b8774bb0b88a0adbef68abfd064f40c7 = require("../../global_assets/7a720906b8774bb0b88a0adbef68abfd064f40c7.png");
export const imgab72e5d36a0a71b0b538f7ef256c36b060c308c5 = require("../../global_assets/ab72e5d36a0a71b0b538f7ef256c36b060c308c5.png");
export const imge4223d26d108a8cd023df3f2518cc4eab70478b8 = require("../../global_assets/e4223d26d108a8cd023df3f2518cc4eab70478b8.png");
export const imge461d3a298bbf3c05a5074a41adab3e56f39fddd = require("../../global_assets/e461d3a298bbf3c05a5074a41adab3e56f39fddd.png");
export const imgea33234833ffa357b65859929f657afbf9eaaa92 = require("../../global_assets/ea33234833ffa357b65859929f657afbf9eaaa92.png");
export const imgd75ec14ae6e1ceb2526337a4056dab9909061b9d = require("../../global_assets/d75ec14ae6e1ceb2526337a4056dab9909061b9d.png");
export const img505886fbb19f8bcc61b5bd247af46b98fb33fc8c = require("../../global_assets/505886fbb19f8bcc61b5bd247af46b98fb33fc8c.png");
export const img5a0b4165aff3cdf19d394b6e3aaa651418955429 = require("../../global_assets/5a0b4165aff3cdf19d394b6e3aaa651418955429.png");
export const img1483799cc09b7d75a215cbb2d79ffae8b7bcb237 = require("../../global_assets/1483799cc09b7d75a215cbb2d79ffae8b7bcb237.png");
export const imgd5cb1f0c1ee76959e73a8ca9b14735b55170f40d = require("../../global_assets/d5cb1f0c1ee76959e73a8ca9b14735b55170f40d.png");
export const img0d34f1908dd79acca5254615290529c0fce93cb5 = require("../../global_assets/0d34f1908dd79acca5254615290529c0fce93cb5.png");
export const img67e9b94035c01faa5c7af1b3a3a56004eb6ee3ae = require("../../global_assets/67e9b94035c01faa5c7af1b3a3a56004eb6ee3ae.png");
export const imge0a9813f927644ac14adaabe01a356c1fbd5edd7 = require("../../global_assets/e0a9813f927644ac14adaabe01a356c1fbd5edd7.png");
export const img871ab16037d1d55391d78aeebd427b5829fcda27 = require("../../global_assets/871ab16037d1d55391d78aeebd427b5829fcda27.png");
export const img01d314a6feb9b97081a11628619495b4fee7ff06 = require("../../global_assets/01d314a6feb9b97081a11628619495b4fee7ff06.png");
export const imgae3cefc0cc80be0efce7f5159d111e0514ab9ee5 = require("../../global_assets/ae3cefc0cc80be0efce7f5159d111e0514ab9ee5.png");
export const img1a39fe719cb92da5f0966ced61578f4f92358078 = require("../../global_assets/1a39fe719cb92da5f0966ced61578f4f92358078.png");
