// Customizable Area Start
import React  from 'react';
import { Link } from 'react-router-dom';
import { BlockComponent } from '../../../framework/src/BlockComponent';
interface Props { }
interface SS { }
interface S { }
class SignupThanks extends BlockComponent<Props, S, SS>{
  render() {
    return (
        <section className='thanks'>
        <div className='popup'>
            <h4 className='logo'> Company<br/> Logo</h4>
            <div>
                <div className='thanks-icon'>
                    <img  src='/frontend/thanks.png' alt='thanks'/>
                </div>
                <h4 className='heading'>Thank You</h4>
                <p className='description'>Your account is under review please allow 24 to 48 hours to receive an email for confirmation</p>
            </div>
            <Link className='white-btn' to='/home'>Continue</Link>
        </div>
    </section>
    )
  }
}


export default SignupThanks;
// Customizable Area End
