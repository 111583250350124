// Customizable Area Start

import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Button, styled } from "@material-ui/core";

interface Props {
  "data-test-id"?: string;
  text: string;
  isActive: boolean;
  onClick?: any;
  disabled?: any;
  isLoading?: boolean;
  loadingText?: string;
}
interface S {}
interface SS {}

class RoundedButton extends BlockComponent<Props, S, SS> {
  render() {
    const testId = this.props["data-test-id"] ?? "btn-rounded";
    const { text, isActive, onClick, disabled, isLoading, loadingText } =
      this.props;
    return (
      <StyledButton
        data-test-id={testId}
        onClick={onClick ?? null}
        disabled={disabled || isLoading}
        variant={isActive ? "contained" : "outlined"}
      >
        {!isLoading ? text || "" : loadingText || ""}
      </StyledButton>
    );
  }
}

const StyledButton = styled(Button)({
  minWidth: 100,
  fontWeight: 600,
  borderRadius: 4,
  color: "var(--navy)",
  boxShadow: "none",
  padding: "5px 20px",
  lineHeight: "unset",
  textTransform: "unset",
  border: "2px solid var(--navy)",
  "& .MuiButton-label": {
    fontSize: "16px !important",
  },
  "&.MuiButton-contained": {
    color: "white",
    fontWeight: 500,
    backgroundColor: "var(--navy)",
  },
  "&.Mui-disabled": {
    opacity: 0.7,
  },
});

export default RoundedButton;

// Customizable Area End
