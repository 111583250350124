// Customizable Area Start
import React from "react";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import CommonCard from "../../FrontLayout/src/CommonCard.web";
import FrontPagination from "../../FrontLayout/src/Pagination.web";
import WishlistController, {
  Props,
  configJSON
} from "./WishlistController";

export class Wishlist extends WishlistController {
  constructor(props: Props) {
    super(props);
  }

  render() {
      const {wishlistItems} = this.state;
    return (<>
    <div className='frontContent'>
   <div className='pt-5 mb-4'><span className='heading'>My Wishlist</span></div>
   <div className='result-flex'>
    {wishlistItems?.data?.length > 0 && wishlistItems?.data?.map((result: any, i: number) => (
    <CommonCard price={result?.attributes?.price} image={result?.attributes?.images?.length > 0 ? result?.attributes?.images[0]?.url : '/frontend/product1.png'} product={result?.attributes?.name} description={result?.attributes?.description} deal={''} bestSeller={''} resultDeal={''} key={'common-card-item-' + result?.id} slug={result?.id} accountIsApproved={this.props.reduxState?.profile?.data?.status} itemData={result} openApproved={this.openApproved.bind(this)} wishlist={true}/>
    ))}
   {(!wishlistItems?.data || wishlistItems?.data?.length === 0) && (<h3 className="w-100 m-5 text-center">Not found.</h3>)}
   </div></div>
   {wishlistItems?.data?.length > 0 && (
      <Grid container justifyContent="center" className="mb-5">
        <Grid item>
          <br />
          <FrontPagination
            count={wishlistItems.meta.last_page}
            onChange={(page: number) => this.wishListData(page)}
            data-test-id='wishlistPagination'
          />
        </Grid>,
      </Grid>
    )}
    </>)
  }
}
const mapStateToProps = (state: any) => ({ reduxState: state });
const mapDispatchToProps = (dispatch: any) => {
  return {
    reduxDispatch: { dispatch },
  };
};
const reduxWrapper: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(Wishlist);

export default reduxWrapper;
// Customizable Area End
