export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const img1091c4968699c39b90e4e6e1f860db75dcfe98bb = require("../../global_assets/1091c4968699c39b90e4e6e1f860db75dcfe98bb.png");
export const imgd078cb37419bf7263793bb772747275f987492b3 = require("../../global_assets/d078cb37419bf7263793bb772747275f987492b3.png");
export const imgc08a197f56ef1d5ebda2eabb088dc31d93c7a44d = require("../../global_assets/c08a197f56ef1d5ebda2eabb088dc31d93c7a44d.png");
export const img308dca2d8ff863fca7f6e3d37f7ffc14ad8eb15d = require("../../global_assets/308dca2d8ff863fca7f6e3d37f7ffc14ad8eb15d.png");
export const imga680398bc47bd635fccee385c34e228735d558ed = require("../../global_assets/a680398bc47bd635fccee385c34e228735d558ed.png");
export const img199208486bb051fa94d0d83d1094ebe18827c227 = require("../../global_assets/199208486bb051fa94d0d83d1094ebe18827c227.png");
export const img46b0638d4c5240b3ca0df7431b9263e2d628b26d = require("../../global_assets/46b0638d4c5240b3ca0df7431b9263e2d628b26d.png");
export const imge3c2c6478b22a15f0f930f4e1b35b2bb10134893 = require("../../global_assets/e3c2c6478b22a15f0f930f4e1b35b2bb10134893.png");
export const img8cf3cfaf3e0b4e878a81fb56435d89a6aa7dcdf3 = require("../../global_assets/8cf3cfaf3e0b4e878a81fb56435d89a6aa7dcdf3.png");
export const imgc2388b698006f63ce43ab68382e1aad840dfd9aa = require("../../global_assets/c2388b698006f63ce43ab68382e1aad840dfd9aa.png");
export const imgbe44f931b0edd23ba244e4dde9bcddf023682eea = require("../../global_assets/be44f931b0edd23ba244e4dde9bcddf023682eea.png");
export const imgcb3acc030126dd7a81e975d916e1bd51b17f98ba = require("../../global_assets/cb3acc030126dd7a81e975d916e1bd51b17f98ba.png");
export const img8b85ee40e1eeba575c82aec099a4aa68ff4da90f = require("../../global_assets/8b85ee40e1eeba575c82aec099a4aa68ff4da90f.png");
