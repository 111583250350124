// Customizable Area Start

import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { IBlock } from "../../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";

import ApiResponseMiddleware from "../../ApiResponseMiddleware";
import ISuccessResponse from "../../ISuccessResponse";
import GetApiMessage from "../../GetApiMessage";

const configJs = require("./config");
const ApiUrls = configJs.ApiUrls;

interface Props {
  selectedOrderId: any;
}
interface SS {}
interface S {
  viewOrderMessageId: any;
  orderData: {
    order_number: any;
    order_date: any;
    address: any;
    itemNames: any;
    total: any;
    order_items: Array<Object>;
    customer_details: any;
    phone_number: any;
    payment_mode: any;
    order_status: string;
    pickup_address: string;
    delivery_address: string;
    reason_for: string;
  };
}

class OrderDetailsController extends BlockComponent<Props, S, SS> {
  state = {
    viewOrderMessageId: null,
    orderData: {
      order_number: "",
      order_date: "",
      address: "",
      itemNames: "",
      total: "",
      order_items: [],
      customer_details: "",
      phone_number: "",
      payment_mode: "",
      order_status: "",
      delivery_address: "",
      pickup_address: "",
      reason_for: "",
    },
  };

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
    ]);
  }

  receive(from: string, message: Message): void {
    if (!ApiResponseMiddleware(message)) return;

    const { viewOrderMessageId } = this.state;

    let requestID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (requestID === viewOrderMessageId) {
      if (successResponse) {
        let data = successResponse?.data;
        // @ts-ignore
        data = {
          id: data.id,
          ...data.attributes,
          // @ts-ignore
          reason_for: successResponse?.reason_for,
        };
        // @ts-ignore
        data.itemNames = data?.order_items
          ?.map((item: any) => {
            return item.attributes.name;
          })
          .filter((item: any) => item)
          .join(", ");

        // @ts-ignore
        this.setState({ orderData: data });
      }
    }
  }

  componentDidMount(): any {
    this.getOrderData();
  }

  getOrderData() {
    let requestMessage = GetApiMessage({
      method: "get",
      endpoint: ApiUrls.viewOrder + "/" + this.props.selectedOrderId,
    });
    this.setState({ viewOrderMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

export default OrderDetailsController;

// Customizable Area End
