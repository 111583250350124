// Customizable Area Start
import React from "react";
import { connect } from "react-redux";
import { NavLink as Link, withRouter } from "react-router-dom";
import { Box, styled } from "@material-ui/core";
import FrontHeaderController from "./FrontHeaderController";
import NotificationDropDown from "./NotificationDropDown";
import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
export class FrontHeader extends FrontHeaderController {
  // used only for code coverage
  testReduxMethods() {
    mapStateToProps({});
    const dispatch = () => null;
    let { reduxDispatch } = mapDispatchToProps(dispatch);
    reduxDispatch.dispatch();
  }

  render() {
    let iconProfile = "/frontend/user.png";
    let userProfileImage = iconProfile;
    let profile = this.state.profileData;
    let loggedInUser=false;
    if (profile) {
      // @ts-ignore
      const { profile_photo } = profile;
      userProfileImage = profile_photo ?? iconProfile;
      loggedInUser=true;
    }
    const {
      searchText,
      homeCategoryList,
      notificationList,
      notificationCount,
    } = this.state;

    const cartCount = this.props.reduxState?.cart?.count || 0;

    return (
      <>
        <header className="headerContainer fixed-top" id="top">
          <div className="frontHeader">
            <Link to="/home">
              <img alt="logo" className="logo" src="/frontend/logo.png" />
            </Link>
            
            <div className="right-container">
            {loggedInUser ? (<><div className="dropdown dropdown-menu-sm-end dropdown-menu-start profile-icon-box">
              <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                  <img alt="icons" src={userProfileImage} />
                  <KeyboardArrowDownIcon style={{color:"#008ac1",fontSize:"40px"}}/>
              </button>
              <ul className="dropdown-menu ">
                  <li>
                    <Link activeClassName="active" className="dropdown-item" to={"/vendor/menu"}>
                    <PersonIcon/><p>My Profile</p>
                    </Link>
                  </li>
                  <li><Link activeClassName="active" className="dropdown-item" to={"/orders"}><DescriptionIcon/><p> My Orders</p></Link></li>
                  <li><Link activeClassName="active" className="dropdown-item" to={"/my-subscriptions"}><DescriptionIcon/><p> My Subscriptions</p></Link></li>
                  <li><div onClick={this.handleLogout} style={{cursor:"pointer"}} className="dropdown-item" ><ExitToAppIcon/><p>Logout</p></div></li>
              </ul>
            </div>
              <div className="icon-box">
                <MenuWrapper>
                  <img
                    className="icons"
                    alt="icons"
                    src="/frontend/notification.png"
                  />
                  <NotificationDropDown list={notificationList} />
                </MenuWrapper>
                {notificationCount > 0 && (
                  <span className="count">{notificationCount}</span>
                )}
              </div>
              <div className="icon-box">
              <Link to="/wishlist"><img
                  className="icons"
                  alt="icons"
                  src="/frontend/wishlist.png"
                /></Link>
              </div>
              <div className="icon-box">
                <Link to={"/cart"}>
                  <img className="icons" alt="icons" src="/frontend/cart.png" />
                  <span className="count">{cartCount}</span>
                </Link>
              </div></>) : (
              <div>
                   <Link
                      to="/login"
                      className="login_button"
                    >
                      Login
                   </Link>
              </div>
              )}
            </div>
          </div>
          <nav className="menu">
            <div className="d-flex">
              <div className="categoryOption">
                <h6 className="category">Shop By Category</h6>
                <div className="submenu">
                  {homeCategoryList?.length > 0 &&
                    homeCategoryList?.map((result: any, i: number) => (
                      <div
                        className="submenu-item"
                        key={"homeCategory-item" + result.id + "-" + i}
                      >
                        <div
                          onClick={() => {
                            this.navToCategory(result?.id);
                          }}
                        >
                          <h6>{result?.attributes?.name}</h6>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="main-menu">
                <Link to="/landing-page" className="menu-item">
                  Home
                </Link>
                <Link to="/brands" className="menu-item">
                  Brands
                </Link>
                <Link to="/offers" className="menu-item">
                  Offers
                </Link>
              </div>
            </div>

            <div className="searchBox">
              <input
                type="text"
                className="search"
                value={searchText}
                placeholder="Search..."
                onChange={(e) => this.handleSearchChange(e)}
                onKeyDown={(e) => e.key === "Enter" && this.onClickSearchAll()}
              />
              <button
                className="searchBtn"
                onClick={() => this.onClickSearchAll()}
              >
                <img src="/frontend/search.png" alt="search" />
              </button>
            </div>
          </nav>
        </header>
        <div className="headerSpace"></div>
        {this.props.reduxState?.dialogues?.isApproved && (
          <div className="modalBox">
            <div className="modalContent">
              <button
                type="button"
                className="btnClose"
                onClick={() => this.closeApprovedModal()}
              >
                &times;
              </button>
              <div className="modalBody">
                <div className="message">
                  <h5 className="text-center mb-4">
                    To place an order, you are required to
                    <br />
                    register and verify your business
                  </h5>
                  <div className="buttonBox">
                    <button
                      className="buttons filled"
                      type="button"
                      onClick={() => this.closeApprovedModal()}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const MenuWrapper: any = styled(Box)({
  position: "relative",
  "&:hover .vendor-header-menu-dropdown": {
    display: "block !important",
  },
});

const mapStateToProps = (state: any) => ({ reduxState: state });
const mapDispatchToProps = (dispatch: any) => {
  return {
    reduxDispatch: { dispatch },
  };
};

// @ts-ignore
const routerWrapper: any = withRouter(FrontHeader);
const reduxWrapper: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(routerWrapper);

const FrontHeaderWithWrappers: any = reduxWrapper;

export default FrontHeaderWithWrappers;

// Customizable Area End
