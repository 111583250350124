Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  updateDiscount: "/bx_block_coupon_cg/discounts/",
  createDiscount: "/bx_block_coupon_cg/discounts",
  listOwnCatalogues: "/bx_block_catalogue/catalogues/list_own_catalogues",
  listDiscounts: "/bx_block_coupon_cg/discounts/vendor/",
  showDiscount: "/bx_block_coupon_cg/discounts/",
};

exports.Strings = {
  header: {
    title: "JHC | Discounts",
  },
  labels: {
    photo: "Image ",
    quantity: "Quantity ",
    paymentBased: "Payment Type ",
    cashOnDelivery: "Cash On Delivery",
    sadadPayment: "Saddad Payment",
    cardPayment: "Card Payment",
    heading: "Discounts",
    alert: "Alert!",
    success: "Success!",
    flat: "Flat",
    select: "Select",
    percentage: "Percentage",
    formTitleAdd: "Add Discount",
    formTitleEdit: "Edit Discount",
    productId: "Product ID ",
    discountType: "Discount Type ",
    couponAmount: "Amount ",
    couponPercentage: "Percentage",
    couponExpiry: "Expiry ",
    allowFreeShipping: "Allow Free Shipping",
  },
  message: {
    discountSaved: "Discount is saved successfully!",
    discountUpdated: "Discount is updated successfully!",
  },
  actions: {
    save: "Save",
    cancel: "Cancel",
    add: "Add Discount",
    saveChanges: "Save Changes",
    subscriptionPlan: "Subscription Plan",
    discountAndOffers: "Discounts & Offers",
  },
  validation: {
    flatDiscount: 'Please enter valid amount',
    percentageDiscount: "Please enter valid percentage",
    photo: "Please select offer image",
    quantity: "Please select quantity",
    catalogue: "Please select catalogue",
    discount: "Please enter amount",
    paymentBased: "Please select payment type",
    discountType: "Please select discount type",
    expiry: "Please select expire date and time",
  },
  table: {
    value: "Value",
    action: "Action",
    expiry: "Expiry",
    loading: "Loading...",
    noData: "No data found",
    createdAt: "Created At",
    productName: "Product Name",
    discountType: "Discount Type",
    freeShipping: "Free Shipping",
  },
};

exports.MappedErrorKeys = {
  discount: "discount",
  expire_at: "expire_at",
  discount_type: "discount_type",
  catalogues_id: "catalogues_id",
  allow_free_shipping: "allow_free_shipping",
  payment_based: "payment_based",
  quantity_based: "quantity",
  photo: "photo",
};
