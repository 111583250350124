import React from "react";
import moment from "moment";
import { styled } from "@material-ui/core";

import { RefundableItemsImagesWrapper } from "./ChooseReason.web";

interface Props {
  onSubmit: Function;
  onChange: Function;
  orderDetailsData: any;
  setChangeAddressModal: Function;
  selectedItems: Array<any>;
  returnReasonList: Array<any>;
  mailYourReturnError: string;
  selectedAddress: { id: number; address: string };
  mailYourReturn: string;
}

class ConfirmReturn extends React.Component<Props> {
  render() {
    const {
      onSubmit,
      onChange,
      selectedItems,
      returnReasonList,
      orderDetailsData,
      mailYourReturnError,
      setChangeAddressModal,
      selectedAddress,
      mailYourReturn,
    } = this.props;

    const orderItems = orderDetailsData.order_items.map((item: any) => ({
      id: item.id,
      ...item.attributes,
    }));

    const selectedProducts: Array<any> = orderItems.filter((item: any) => {
      let isSelected =
        selectedItems.filter((selected) => selected.id === item.id).length > 0;
      return isSelected;
    });

    let reasonList: any = selectedItems.map((item) => item.reason);
    reasonList = Array.from(new Set(reasonList));
    reasonList = reasonList.map((id: number) => {
      let list = returnReasonList.filter((item) => item.id === id);
      if (list.length > 0) return list[0].issue;
      else return "";
    });

    const refundSubTotal: number = selectedProducts
      .map((item) => ({ price: item.unit_price, quantity: item.quantity }))
      .map((item) => Number(item.price) * Number(item.quantity))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    return (
      <div className="shoppingCart">
        <div className="title">Confirm your return</div>
        <div className="flex-box">
          <div className="flex-column1">
            <div className="card rounded-0 mb-2">
              <div className="card-body pt-3">
                <div className="row m-0 p-0">
                  <div className="col-sm-6">
                    <h5 className="mb-0">
                      <strong>Why are you returning this?</strong>
                    </h5>
                  </div>
                  <div className="col-sm-6">
                    {reasonList.map((label: string) => {
                      return (
                        <h5 key={"reason-list-" + label} className="mb-0">
                          {label}
                        </h5>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="card rounded-0 mb-2">
              <div className="card-body pt-3">
                <div className="row m-0 p-0">
                  <div className="col-sm-6">
                    <h5 className="mb-0">
                      <strong>How can we make it right?</strong>
                    </h5>
                  </div>
                  <div className="col-sm-6">
                    <h5 className="mb-0">
                      Return to your bank account{" "}
                      <a href="#" className="blueLinks float-end">
                        Change
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="card rounded-0 mb-2">
              <div className="card-body pt-3">
                <div className="row m-0 p-0">
                  <div className="col-sm-6">
                    <h5 className="mb-0">
                      <strong>How will you mail your return?</strong>
                    </h5>
                  </div>
                  <div className="col-sm-6">
                    <h6>
                      <label className="d-block" htmlFor="mine">
                        <input
                          type="radio"
                          value="mine"
                          name="radio-group-1"
                          onClick={() => onChange("I'll ship & Pay")}
                        />
                        &nbsp;&nbsp; I'll Ship & pay
                      </label>
                    </h6>
                    <h6 className="mb-0">
                      <label className="d-block" htmlFor="vendor">
                        <input
                          type="radio"
                          value="vendor"
                          name="radio-group-1"
                          data-test-id="vendor-pickup-option"
                          onClick={() => onChange("Vendor Pickup")}
                        />
                        &nbsp;&nbsp; Vendor Pickup
                      </label>
                    </h6>
                    {mailYourReturnError.length > 0 && (
                      <RedLabel>{mailYourReturnError}</RedLabel>
                    )}
                    <br />
                    <h6 className="mini-text text-start mb-3">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.{" "}
                    </h6>
                    {mailYourReturn === "Vendor Pickup" && (
                      <>
                        <h6 className="mb-0">
                          <strong>Pickup Address &nbsp;&nbsp;</strong>
                          <a
                            data-test-id="pick-address-modal-button"
                            href="#"
                            onClick={() => setChangeAddressModal(true)}
                            className="blueLinks d-inline"
                          >
                            Change
                          </a>
                        </h6>
                        <p className="mini-text text-start m-0">
                          {selectedAddress?.address}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-column2 productReturn pt-0">
            <div className="card rounded-0">
              <div className="card-body pt-4">
                <div className="buttonBox">
                  <button
                    type="button"
                    data-test-id="confirm-your-return"
                    className="buttons filled"
                    onClick={() => onSubmit()}
                  >
                    Confirm your return
                  </button>
                </div>
                <h6 className="mini-text">
                  Return by {moment().format("DD/MM/YYYY")}
                </h6>
                <hr />
                <h6 className="mb-3">
                  <strong>Refund Summary</strong>
                </h6>
                <h6>
                  Refund Subtotal{" "}
                  <strong className="float-end">
                    AED {refundSubTotal.toFixed(2)}
                  </strong>
                </h6>
                <h6 className="mb-3">
                  Shipping Vendor Pickup{" "}
                  <strong className="float-end text-red">AED 00</strong>
                </h6>
                <h6>
                  <strong>
                    Total Estimated Refund{" "}
                    <span className="float-end">
                      AED {refundSubTotal.toFixed(2)}
                    </span>
                  </strong>
                </h6>

                <hr />
                <h6>
                  <strong>Items you're returning</strong>
                </h6>
                <RefundableItemsImagesWrapper>
                  {selectedProducts.map((item: any) => {
                    return (
                      <img
                        key={item.id}
                        className="return-product border"
                        alt={
                          item?.catalogue?.attributes?.name + " is to be return"
                        }
                        src={
                          item?.catalogue?.attributes?.images[0]?.url ||
                          "/frontend/s1.png"
                        }
                      />
                    );
                  })}
                </RefundableItemsImagesWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const RedLabel = styled("div")({
  color: "red",
});

export default ConfirmReturn;
