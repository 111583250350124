// Customizable Area Start
import React from "react";
import Loader from "../../../components/src/Loader";
import ForgotPasswordWebController from "../../forgot-password/src/ForgotPasswordWebController";

class ForgotPassword extends ForgotPasswordWebController {
  render() {
    const {
      loading,
      forgotInputValues,
      forgotValidation,
      forgotMessage,
      tabs,
      otpEmail,
      verifyOtpError,
      OTPInputValues,
      otpValidation,
      changePasswordError,
      passwordShown,
      changePasswordValidation,
      confirmPasswordShown,
      changePassword
    } = this.state;
    return (
      <>
        <Loader loading={loading} />
        <section className="user">
          <div className="popup" id="forgot">
            <img className="logo" src="/images/logo.png" alt="logo" />
            {tabs.first === true ? (
              <>
                {forgotMessage && <p className="formErrors">{forgotMessage}</p>}
                <div className="inputGroups">
                  <label className="icons">
                    <img src="/images/email.png" alt="email" />
                  </label>
                  <input
                    className="inputs"
                    name="email"
                    type="text"
                    data-test-id = "email"
                    placeholder="Enter Email Address"
                    value={forgotInputValues.email}
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>
                {forgotValidation.email && (
                  <p className="formErrors">{forgotValidation.email}</p>
                )}
                <div className="buttons">
                  <button
                    className="btnSubmit"
                    data-test-id = "checkEmail"
                    onClick={() => {
                      this.checkForgotEmail();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
            {tabs.second === true ? (
              <>
                <h6 className="otpSent">
                  OTP send to your email address{" "}
                  <span className="blueLinks">{otpEmail}</span>
                </h6>
                {verifyOtpError && (
                  <p className="formErrors">{verifyOtpError}</p>
                )}
                <div className="inputGroups">
                  <label className="icons">
                    <img src="/images/key.png" alt="key" />
                  </label>
                  <input
                    className="inputs loose"
                    name="otp"
                    type="text"
                    data-test-id = "otp"
                    placeholder="Enter OTP"
                    value={OTPInputValues.otp}
                    onChange={(e) => this.handleOTPChange(e)}
                  />
                </div>
                {otpValidation.otp && (
                  <p className="formErrors">{otpValidation.otp}</p>
                )}
                <div className="buttons">
                  <button
                    className="btnSubmit"
                    data-test-id = "otpValidation"
                    onClick={() => this.OtpValidation()}
                  >
                    Verify
                  </button>
                </div>
              </>
            ) : (
              ""
            )}

            {tabs.third === true ? (
              <>
                <p className="formErrors">
                  {changePasswordError ? changePasswordError : ""}
                </p>
                <div className="inputGroups">
                  <label className="icons">
                    <img src="/images/lock.png" alt="password" />
                  </label>
                  <input
                    className="inputs"
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    data-test-id = "password"
                    placeholder="Password*"
                    value={changePassword.password}
                    onChange={(e) => this.handleChangePasswordChange(e)}
                  />
                  <img
                    className="passwordIcon"
                    src={
                      passwordShown
                        ? "/images/showPassword.png"
                        : "/images/hidePassword.png"
                    }
                    alt="eye"
                    onClick={() => {
                      this.togglePassword();
                    }}
                  />
                </div>
                {changePasswordValidation.password && (
                  <p className="formErrors">
                    {changePasswordValidation.password}
                  </p>
                )}
                <div className="inputGroups">
                  <label className="icons">
                    <img src="/images/lock.png" alt="password" />
                  </label>
                  <input
                    className="inputs"
                    type={confirmPasswordShown ? "text" : "password"}
                    name="confirmPassword"
                    data-test-id = "confirmPassword"
                    placeholder="Confirm Password*"
                    value={changePassword.confirmPassword}
                    onChange={(e) => this.handleChangePasswordChange(e)}
                  />
                  <img
                    className="passwordIcon"
                    src={
                      confirmPasswordShown
                        ? "/images/showPassword.png"
                        : "/images/hidePassword.png"
                    }
                    alt="eye"
                    onClick={() => {
                      this.toggleConfirmPassword();
                    }}
                  />
                </div>
                {changePasswordValidation.confirmPassword && (
                  <p className="formErrors">
                    {changePasswordValidation.confirmPassword}
                  </p>
                )}
                <div className="buttons">
                  <button
                    className="btnSubmit"
                    data-test-id = "changePassword"
                    onClick={() => {
                      this.changePassword();
                    }}
                  >
                    Change Password
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </section>
      </>
    );
  }
}
export default ForgotPassword;
// Customizable Area End
