// Customizable Area Start
import { ReactNode } from "react";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";

import GetApiMessage from "../GetApiMessage";
import { IReduxWrapper } from "../withReduxWrapper";
import ApiResponseMiddleware from "../ApiResponseMiddleware";

const configJs = require("./config");
const ApiUrls = configJs.ApiUrls;

interface Props extends IReduxWrapper {
  children?: Element | ReactNode;
  navigation?: {
    navigate?: any;
    getParam?: Function;
  };
}
interface SS {}
interface S {
  profileData: any;
  apiCallId: any;
}

class AppLayoutController extends BlockComponent<Props, S, SS> {
  state = {
    apiCallId: null,
    profileData: null,
  };

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);
  }

  componentDidMount(): any {
    super.componentDidMount();
    this.getUserData();
    this.removeIFrames();
  }

  removeIFrames() {
    if (process.env.REACT_APP_MODE !== "development") return;

    setTimeout(() => {
      let element = document.querySelectorAll("iframe");
      for (let i = 0; i < element.length; i++) {
        // @ts-ignore
        document.body.removeChild(document.querySelector("iframe"));
      }
    }, 3000);
  }

  getUserData() {
    const requestMessage = GetApiMessage({
      method: "get",
      endpoint: ApiUrls.viewProfile,
    });

    this.setState({ apiCallId: requestMessage.messageId });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async receive(from: string, message: Message) {
    if (
      !ApiResponseMiddleware(message, this.props.navigation?.navigate ?? null)
    )
      return;

    let requestID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (this.state.apiCallId === requestID) {
      let successResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (successResponse) {
        this.setState({
          profileData: successResponse?.data?.attributes,
        });
        this.onSaveProfileInSession(successResponse?.data?.id);
        const { reduxDispatch, reduxState } = this.props;
        reduxDispatch({
          type: reduxState?.actions.profile.SAVE,
          payload: successResponse.data,
        });
      }
    }
  }

  onSaveProfileInSession(userId: any) {
    let requestMessage = new Message(getName(MessageEnum.SessionSaveMessage));
    requestMessage.addData("userId", userId);
    localStorage.setItem("userId", userId);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

export default AppLayoutController;

// Customizable Area End
