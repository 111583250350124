Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.ApiUrls = {
  editProfile: "/accounts/edit_profile",
  viewProfile: "/accounts/show_profile",
};

exports.Strings = {
  header: {
    title: "JHC | Edit Profile",
  },
  labels: {
    success: "Success!",
    errors: "Errors!",
    title: "Edit Profile",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email Address",
    password: "Password",
    changePassword: "Change Password",
    phoneNumber: "Phone Number",
    dateOfBirth: "Date of Birth",
    state: "State",
    address: "Address",
    city: "City",
    pinCode: "Pin code",
  },
  actions: {
    save: "Save",
    saving: "Saving",
    cancel: "Cancel",
  },
  message: {
    profileSaved: "Profile changes are saved!",
  },
  validation: {
    city: "Please enter city",
    email: "Please enter email",
    phone: "Please enter phone",
    state: "Please enter state",
    address: "Please enter address",
    pinCode: "Please enter pin code",
    dob: "Please enter date of birth",
    lastName: "Please enter last name",
    firstName: "Please enter first name",
    validEmail: "Please enter valid email",
    validPhone: "Please enter valid phone",
    confirmPassword: "Please enter change password",
    confirmPasswordNotMatch: "Change password does not match",
  },
};

exports.MappedErrorMessages = {
  dob: "dob",
  email: "email",
  password: "password",
  last_name: "lastName",
  first_name: "firstName",
  full_phone_number: "phone",
  business_pin_code: "pinCode",
  business_address: "address",
  business_state: "state",
  business_city: "city",
};
