// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import GetApiMessage from "../../utilities/src/GetApiMessage";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  
}

interface S {
  subscriptions: any;
  subscriptionsMessageId: any;
  subscriptionsPagination: number;
  cancelSubscriptionMessageId: any;
}

interface SS {
  id: any;
}

export default class SubscriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      subscriptions: [],
      subscriptionsMessageId: '',
      subscriptionsPagination: 1,
      cancelSubscriptionMessageId: ''
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.subscriptionItems(1);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      runEngine.debugLog("API Message Received", JSON.stringify(message));

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.state.subscriptionsMessageId) {
        this.setState({subscriptions: responseJson});
      } else if (apiRequestCallId === this.state.cancelSubscriptionMessageId) {
        if(responseJson.data){
          this.subscriptionItems(this.state.subscriptionsPagination);
        }
      }
    }
  }
 
  async subscriptionItems(page = 1) {
    const requestMessage = GetApiMessage({ method: 'get', endpoint: configJSON.subscriptionApiURL + '?per_page=5&page=' + page });
    this.setState({ subscriptionsMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  showPaginationData(type: string, paginationType: string) {
    if (type === 'subscription') {
      const newPage = (paginationType === 'previous') ? this.state.subscriptionsPagination - 1 : this.state.subscriptionsPagination + 1;
      this.subscriptionItems(newPage);
      this.setState({ subscriptionsPagination: newPage });
    }
  }
  cancelSubscription(subscriptionId: number){
    const requestMessage = GetApiMessage({ method: 'put', endpoint: configJSON.cancelSubscriptionApiURL + subscriptionId });
    this.setState({ cancelSubscriptionMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
// Customizable Area End