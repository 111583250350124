import React from "react";
import UserLoginModalController from "./UserLoginModalController.web";
import {Modal,Paper} from '@material-ui/core'
class UserLoginModal extends UserLoginModalController {
  
  render() {
     const {open}=this.state;
    return (
        <Modal
            style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
          open={open}
          onClose={() => this.handleClose()}
        >
        <Paper
          style={{ maxWidth: "550px",maxHeight:"75vh", padding: "20px", borderRadius: "20px",overflowY:"auto" }}
        >
          <div style={{width:"100%"}} className="modalContent">
              <button
                type="button"
                className="btnClose"
                onClick={() => this.handleClose()}
              >
                &times;
              </button>
              <div className="modalBody">
                <div style={{height:"150px"}} className="message">
                  <h5 className="text-center mb-4">
                   Please Login to continue.
                  <br />
                  </h5>
                  <div className="buttonBox">
                      <button
                        className="buttons filled"
                        type="button"
                        onClick={() => this.navigateToLogin()}
                      >
                        Login or Signup
                      </button>
                  </div>
                </div>
              </div>
            </div>
        </Paper>
    
    </Modal>
     )
  }
}

export default UserLoginModal;
