import GetErrorMessages from "./GetErrorMessageFromResponse";

const isMappedKeyFound = (error: any, key: any) => {
  return error[key] ? true : false;
};

const GetApiErrorsWithKeys = (response: any, mappedErrorKeys: any) => {
  let errorsObj:any = { extraMessages: "" };
  let messages: Array<any> = [];
  if (response.data?.type === "error") {
    // @ts-ignore
    let errors = response?.data?.attributes?.errors;
    Object.keys(errors).map((key: any) => {
      let isKeyMapped = isMappedKeyFound(mappedErrorKeys, key);
      let item = errors[key];
      if (Array.isArray(item)) {
        // @ts-ignore
        if (isKeyMapped) errorsObj[mappedErrorKeys[key]] = item?.join(", ");
        else messages.push(item.join(", "));
      } else {
        // @ts-ignore
        if (isKeyMapped) errorsObj[mappedErrorKeys[key]] = item[key];
        else messages.push(item[key]);
      }
    });
    if (messages.length > 0) errorsObj.extraMessages = messages.join(", ");
    return errorsObj;
  } else {
    let errors = GetErrorMessages(response, true);
    if (errors) {
      errorsObj.extraMessages = errors;
      return errorsObj;
    }
  }
  return false;
};

export default GetApiErrorsWithKeys;
