import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import Header from "./FrontHeader.web";
import Footer from "./FrontFooter.web";

interface Props {
  children: any;
  isProtected: boolean;
  isRouteChange: boolean;
}
interface State {}
interface SS {}

class LandingPageLayout extends BlockComponent<Props, State, SS> {
  render() {
    const { isProtected, isRouteChange } = this.props;
    return (
      <div>
        {/* @ts-ignore */}
        <Header isRouteChange={isRouteChange} isProtected={isProtected} />
        <div>{this.props.children}</div>
        <Footer />
      </div>
    );
  }
}

export default LandingPageLayout;
