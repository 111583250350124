Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.Strings = {
  labels: {
    orderToDelivery: "Order To Deliver",
    trackOrder: "Track Order",
    completed: "Completed",
    task: "Task",
    in: "In",
    progress: "Progress",
    pending: "Pending",
    order: "Order",
    failed: "Failed",
  },
};
