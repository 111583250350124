// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";

// It handles common api errors like invalid token
const ApiResponseMiddleware = (
  message: Message,
  navigate: any = null,
  history: any = null
): boolean => {
  let response: any = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
  if (!response) return false;

  let errors: any = response.errors;
  let flag: boolean = false;

  if (Array.isArray(errors)) {
    errors.forEach((item) => {
      let key: string = Object.keys(item)[0];
      if (["token"].includes(key)) flag = true;
    });
  }

  if (flag) {
    if (navigate) navigate("Home");
    else if (history) history.push("/");
    else window.location.href = "/";
  }

  return !flag;
};

export default ApiResponseMiddleware;

// Customizable Area End
