// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";

import ApiResponseMiddleware from "../ApiResponseMiddleware";
import ISuccessResponse from "../ISuccessResponse";
import GetApiMessage from "../GetApiMessage";

const configJs = require("./config");
const ApiUrls = configJs.ApiUrls;

interface Props {
  navigation: {
    navigate: Function;
  };
}
interface State {
  listMessageId: string;

  selectedDiscountId: number | any;
  discountList: Array<any>;
  discountModal: boolean;
  currentPage: number;
  isLoading: boolean;
  lastPage: number;
}
interface SS {}

class DiscountListController extends BlockComponent<Props, State, SS> {
  state = {
    // logical
    isLoading: false,
    discountModal: false,

    // message ids
    listMessageId: "",

    // other
    selectedDiscountId: null,
    discountList: [],

    // pagination
    currentPage: 1,
    lastPage: 1,
  };

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    super.componentDidMount();
    this.getDiscountList();
  }

  receive(from: string, message: Message): void {
    if (!ApiResponseMiddleware(message)) return;

    const { listMessageId } = this.state;

    let requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (listMessageId === requestId) {
      if (successResponse) {
        this.setState({
          isLoading: false,
          lastPage: successResponse?.meta?.last_page || 1,
          discountList: successResponse?.data || [],
        });
      }
    }
  }

  getDiscountList(page: number = this.state.currentPage) {
    let requestMessage = GetApiMessage({
      endpoint:
        ApiUrls.listDiscounts +
        localStorage.userId +
        "?per_page=10&page=" +
        page,
    });
    this.setState({ listMessageId: requestMessage.messageId, isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onChangePage(page: number) {
    this.getDiscountList(page);
    this.setState({ currentPage: page });
  }
}

export default DiscountListController;
// Customizable Area End
