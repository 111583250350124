// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../../framework/src/Message";

import GetApiMessage from "../GetApiMessage";
import ISuccessResponse from "../ISuccessResponse";
import ApiResponseMiddleware from "../ApiResponseMiddleware";
import { toast } from "react-toastify";

const configJs = require("./config");
const ApiUrls = configJs.ApiUrls;

export interface ISubscriptionListRow {
  id: number;
  price: String;
  valid_from: String;
  description: String;
  valid_up_to: String;
  account_type: String;
  expired: boolean;
  catalogues: {
    name:string;
  };
  subscribed: boolean;
  isSelected: boolean;
  updated_at:string;
}

interface Props {
  navigation: {
    navigate: Function;
  };
}
interface State {
  // Pagination
  currentPage: number;
  lastPage: number;

  subscriptionList: Array<ISubscriptionListRow>;
  subscriptionListMessageId: string;
  selectedSubscription:number;
  isSearching:boolean;
  searchText:string;
}
interface SS {}

class SubscriptionController extends BlockComponent<Props, State, SS> {
  searchSubscriptionApiCallId:string="";
  state = {
    lastPage: 1,
    currentPage: 1,

    subscriptionList: [],
    subscriptionListMessageId: "",
    selectedSubscription:0,
    isSearching:false,
    searchText:""
  };

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  receive(from: string, message: Message): void {
    if (!ApiResponseMiddleware(message)) return;

    const { subscriptionListMessageId } = this.state;

    let requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (successResponse) {
      if (requestId === subscriptionListMessageId) {
        if (successResponse.data) {
          this.setState({
            isSearching:false,
            lastPage: successResponse?.meta?.last_page || 1,
            subscriptionList: successResponse?.data.map((item: any) => {
              return {
                id: item.id,
                isSelected: false,
                ...item.attributes,
              };
            }),
          });
        }
      } 
      
      if (requestId === this.searchSubscriptionApiCallId) {
        if (successResponse.data) {
          this.setState({
            isSearching:true,
            lastPage: successResponse?.meta?.last_page || 1,
            subscriptionList: successResponse?.data.map((item: any) => {
              return {
                id: item.id,
                isSelected: false,
                ...item.attributes,
              };
            }),
          });
        }
      }
    }
  }

  componentDidMount(): any {
    this.getSubscriptionList();
  }

  getSubscriptionList(page: number = this.state.currentPage) {
    let requestMessage = GetApiMessage({
      endpoint: ApiUrls.listSubscriptions + "?page=" + page + "&per_page=5",
    });
    this.setState({ subscriptionListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  handleOnSearch=(text:string)=>{
      this.setState({
        searchText:text,
        currentPage:1
      },()=>this.filterSubscription())
  }

  filterSubscription(page:number=this.state.currentPage) {
    const {searchText}=this.state;
    if(!searchText){
      this.getSubscriptionList();
    }
    let requestMessage = GetApiMessage({
      endpoint: `${ApiUrls.listSubscriptions}?page=${page}&per_page=5&key=${searchText}`,
    });
    this.searchSubscriptionApiCallId=requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSelection=(e:any,id:number)=>{
    const selectedId=e.target.checked?id:0;
    this.setState({
      selectedSubscription:selectedId
    })
  }
  navigateToEditSubscription=()=>{
     const {selectedSubscription}=this.state;
     if(selectedSubscription!==0){
       this.props.navigation.navigate("VendorSub", {
        page: "edit-subscription-plan",
        id: selectedSubscription,
      })
     }else{
      toast.warn("Please select subscription to edit")
     }
  }
  handleItemClick=(id:number)=>{
     this.setState((prev)=>({
      selectedSubscription:prev?.selectedSubscription===id?0:id
     }))

  }

  onChangePage(page: number) {
    const {isSearching}=this.state;
    this.setState({ currentPage: page });
    if(isSearching){
      this.filterSubscription(page);
      return;
    }
    this.getSubscriptionList(page);
  }
}

export default SubscriptionController;

// Customizable Area End
