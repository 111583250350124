Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.Strings = {
  header: {
    title: "Payments",
  },
  labels: {
    cod: "Cash On Delivery",
    selectDate: "Select Date...",
    onlinePayment: "Online Payment",
  },
};
