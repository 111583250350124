// Customizable Area Start

import React from "react";
import { Link } from "react-router-dom";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { styled } from "@material-ui/core";
import {
  iconHOC,
  iconHome,
  iconMenu,
  iconVendor,
  iconOrders,
  iconContact,
  iconPayment,
  iconSettings,
  iconDiscounts,
  iconInventory,
  iconArrowRight,
  iconProfileSmall,
  iconNotificationSmall,
} from "./assets";

const configJs = require("./config");
const Strings = configJs.Strings;

interface Props {
  profile: any;
  navigation?: {
    navigate?: any;
    getParam?: Function;
  };
}
interface SS {}
interface S {}

class Sidebar extends BlockComponent<Props, S, SS> {
  render() {
    // @ts-ignore
    const currentPath: string | any = this.props.navigation?.getParam("page");

    const prefixUrl = "/vendor/";
    // note: must add / before path in noPrefixUrl: true option
    const sidebarRoutes = [
      {
        path: "/home",
        icon: iconHome,
        label: Strings.sidebar.home,
        noPrefixUrl: true,
      },
      { path: "menu", icon: iconMenu, label: Strings.sidebar.menu },
      {
        path: "inventory",
        icon: iconInventory,
        label: Strings.sidebar.inventoryManagement,
      },
      { path: "orders", icon: iconOrders, label: Strings.sidebar.allOrders },
      {
        path: "discounts",
        icon: iconDiscounts,
        label: Strings.sidebar.discounts,
      },
      { path: "payments", icon: iconPayment, label: Strings.sidebar.payments },
      {
        icon: iconSettings,
        label: Strings.sidebar.settings,
        hasSubMenu: true,
        defaultPath: "profile",
        subMenus: [
          {
            path: "profile",
            icon: iconProfileSmall,
            label: Strings.sidebar.profileSettings,
          },
          {
            path: "registration",
            icon: iconVendor,
            label: Strings.sidebar.vendorForm,
          },
          {
            path: "notifications-menu",
            icon: iconNotificationSmall,
            label: Strings.sidebar.notification,
          },
        ],
      },
      {
        path: "contact-us",
        icon: iconContact,
        label: Strings.sidebar.contactUs,
      },
      {
        path: "report",
        icon: iconHOC,
        label: Strings.sidebar.adHocReporting,
      },
    ];

    return (
      <StyledSideBar className="sidebar">
        {sidebarRoutes.map(
          ({
            path,
            icon,
            label,
            subMenus,
            defaultPath = "#",
            hasSubMenu = false,
            noPrefixUrl = false,
          }) => {
            if (hasSubMenu) {
              const isSubPathOpened =
                // @ts-ignore
                subMenus?.filter(
                  (item: { path: string }) => item?.path === currentPath
                ).length > 0;

              // change path to default path when open sub menu
              const onChangeToDefault = (e: any) => {
                if (defaultPath !== "#") {
                  if (e.target.checked)
                    this.props.navigation?.navigate("Vendor", {
                      page: defaultPath,
                    });
                }
              };

              return (
                <div className="dropdown" key={label}>
                  <StyledSideBarItem to={"#"}>
                    <img src={icon} alt="icon" />
                    {label}
                  </StyledSideBarItem>
                  <input
                    defaultChecked={isSubPathOpened}
                    type="checkbox"
                    onChange={(e: any) => onChangeToDefault(e)}
                  />
                  <div className="submenu">
                    {subMenus?.map(({ path, icon, label }) => {
                      const isCurrentPath: any = path === currentPath;
                      return (
                        <StyledSideBarItemSub
                          className="sub-menu-item"
                          key={label}
                          to={(noPrefixUrl ? path : prefixUrl + path) || "#"}
                        >
                          <img src={icon} alt="icon" />
                          {label}
                          {isCurrentPath && (
                            <SideBarItemActiveImage>
                              <img src={iconArrowRight} />
                            </SideBarItemActiveImage>
                          )}
                        </StyledSideBarItemSub>
                      );
                    })}
                  </div>
                </div>
              );
            } else {
              const isCurrentPath: any = path === currentPath;
              return (
                <StyledSideBarItem
                  className="sub-item"
                  key={label}
                  to={(noPrefixUrl ? path : prefixUrl + path) || "#"}
                >
                  <img src={icon} alt="icon" />
                  {label}
                  {isCurrentPath && (
                    <SideBarItemActiveImage>
                      <img src={iconArrowRight} />
                    </SideBarItemActiveImage>
                  )}
                </StyledSideBarItem>
              );
            }
          }
        )}
      </StyledSideBar>
    );
  }
}

export default Sidebar;

const SideBarItemActiveImage: any = styled("div")({
  top: 4,
  right: 0,
  bottom: 0,
  display: "flex",
  paddingRight: 6,
  alignItems: "center",
  position: "absolute",
});

const StyledSideBarItem: any = styled(Link)({
  fontSize: 16,
  display: "flex",
  padding: "10px 15px",
  position: "relative",
  alignItems: "center",
  borderTop: "solid 1px var(--white)",
  "& > img": {
    marginRight: 15,
    width: 16,
  },
});

const StyledSideBarItemSub: any = styled(StyledSideBarItem)({
  paddingLeft: 32,
});

const StyledSideBar = styled("nav")({
  width: "100%",
  height: "100%",
  listStyle: "none",
  color: "var(--white)",
  background: " var(--gradient)",
  minHeight: "calc(100vh - 100px)",
});

// Customizable Area End
