// Customizable Area Start
import moment from 'moment'

const GetDateString = (dateTo: string) => {
    let oldD = moment().subtract(1, 'd').format('YYYY-MM-DD')
    let isFromNow = moment(oldD).isSameOrBefore(dateTo)
    let dateToShow = moment(dateTo)
    let dateString = isFromNow
        ? dateToShow.fromNow()
        : dateToShow.format('DD/MM/YYYY hh:mm A')

    return dateString
}

export default GetDateString

// Customizable Area End