// Customizable Area Start
import React from "react";
import { Helmet } from "react-helmet";
import { styled, Grid } from "@material-ui/core";

import GetTruncateText from "../GetTruncateText";
import StoreMemberList from "./StoreMembers/StoreMemberList.web";
import VendorRegistrationController from "./VendorRegistrationController";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { iconEdit, iconUpload } from "../assets";

const configJs = require("./config");
const Strings = configJs.Strings;

class VendorRegistration extends VendorRegistrationController {
  render() {
    const validation = this.state.validation;
    const {
      tax_registration_doc,
      supporting_doc,
      pharmacy_registration_doc,
      commercial_registration_doc,
      store_name,
      email,
      country_code,
      phone_number,
      business_name,
      business_address,
      business_city,
      business_state,
      business_pin_code,
      business_country,
      company_logo,
    } = this.state.profile ?? {};
    const { isStoreMember } =  this.state;

    return (
      <div data-test-id="vendor-registration-screen" className="inventory">
        <Helmet>
          <title>{Strings.header.title}</title>
        </Helmet>
        <div className="head">
          <h4 className="title">{Strings.header.heading}</h4>
          <div className="right-container">
            <span className="edit" style={{ cursor: "none" }}>
              <img src={iconEdit} alt="icon" />
            </span>
          </div>
        </div>
        {this.state.isLoading ? (
          <h4>{Strings.labels.loading}</h4>
        ) : (
          <>
            <div className="row">
              <div className="col-sm-6">
                <label className="vender-label">
                  {Strings.labels.organization}
                </label>
                <input
                  data-test-id="field-business_name"
                  onChange={(e) =>
                    this.onChangeProfile("business_name", e.target.value)
                  }
                  value={business_name || ""}
                  type="text"
                  className="vender-inputs"
                  placeholder="Enter Name"
                />
                {validation.business_name && (
                  <ErrorLabel>{validation.business_name}</ErrorLabel>
                )}
              </div>
              <div className="col-sm-6">
                <label className="vender-label">{Strings.labels.address}</label>
                <input
                  data-test-id="field-business_address"
                  type="text"
                  onChange={(e) =>
                    this.onChangeProfile("business_address", e.target.value)
                  }
                  value={business_address || ""}
                  className="vender-inputs"
                  placeholder="Enter address"
                />
                {validation.business_address && (
                  <ErrorLabel>{validation.business_address}</ErrorLabel>
                )}
              </div>
              <div className="col-sm-6">
                <label className="vender-label">{Strings.labels.country}</label>
                <select
                  data-test-id="field-business_country"
                  onChange={(e) => {
                    this.onChangeProfile("business_country", e.target.value);
                    this.getStateList(e.target.value);
                  }}
                  style={{ backgroundColor: "white" }}
                  value={business_country || ""}
                  className="vender-inputs"
                >
                  <option key={"country-select-item"} value="">
                    Select
                  </option>
                  {this.state.countryList.map((item) => {
                    return (
                      <option key={"scl-item-" + item.id} value={item.id}>
                        {item.value}
                      </option>
                    );
                  })}
                </select>
                {validation.business_country && (
                  <ErrorLabel>{validation.business_country}</ErrorLabel>
                )}
              </div>

              <div className="col-sm-6">
                <label className="vender-label">{Strings.labels.state}</label>
                <select
                  data-test-id="field-business_state"
                  style={{ backgroundColor: "white" }}
                  onChange={(e) =>
                    this.onChangeProfile("business_state", e.target.value)
                  }
                  value={business_state || ""}
                  className="vender-inputs"
                >
                  <option key={"state-select-item"} value="">
                    Select
                  </option>
                  {this.state.stateList.map((item: any) => {
                    return (
                      <option
                        key={"state-list-item-" + item.id}
                        value={item.id}
                      >
                        {item.value}
                      </option>
                    );
                  })}
                </select>
                {validation.business_state && (
                  <ErrorLabel>{validation.business_state}</ErrorLabel>
                )}
              </div>

              <div className="col-sm-6">
                <label className="vender-label">{Strings.labels.city}</label>
                <input
                  data-test-id="field-business_city"
                  onChange={(e) =>
                    this.onChangeProfile("business_city", e.target.value)
                  }
                  value={business_city || ""}
                  type="text"
                  className="vender-inputs"
                  placeholder="Enter city"
                />
                {validation.business_city && (
                  <ErrorLabel>{validation.business_city}</ErrorLabel>
                )}
              </div>
              <div className="col-sm-6">
                <label className="vender-label">{Strings.labels.postal}</label>
                <input
                  data-test-id="field-business_pin_code"
                  onChange={(e) =>
                    this.onChangeProfile("business_pin_code", e.target.value)
                  }
                  value={business_pin_code || ""}
                  type="text"
                  className="vender-inputs"
                  placeholder="Enter pin code"
                  maxLength={12}
                />
                {validation.business_pin_code && (
                  <ErrorLabel>{validation.business_pin_code}</ErrorLabel>
                )}
              </div>

              <div className="col-sm-6">
                 <label className="vender-label">
                    {Strings.labels.phone} <RedLabel>*</RedLabel>
                  </label>
                <PhoneInput
                  inputStyle={{
                    width:"100%",
                    border:"0",
                    borderBottom:"1px solid var(--lightgray)",
                    borderRadius:"0px"
                  }}
                  inputClass="vender-inputs"
                  containerClass="vendor-phone-input"
                  placeholder="Enter phone"
                  value={phone_number || ""}
                  onChange={value=>this.onChangeProfile("phone_number", value)}
                  />
                {validation.phone_number && (
                  <ErrorLabel>{validation.phone_number}</ErrorLabel>
                )}
              </div>
              <div className="col-sm-6">
                <label className="vender-label">
                  {Strings.labels.email} <RedLabel>*</RedLabel>
                </label>
                <input
                  data-test-id="field-email"
                  onChange={(e) =>
                    this.onChangeProfile("email", e.target.value)
                  }
                  value={email || ""}
                  type="text"
                  className="vender-inputs"
                  placeholder="Enter email"
                />
                {validation.email && (
                  <ErrorLabel>{validation.email}</ErrorLabel>
                )}
              </div>
              <div className="col-sm-6">
                <label className="vender-label">
                  {Strings.labels.storeName}
                </label>
                <input
                  data-test-id="field-store_name"
                  onChange={(e) =>
                    this.onChangeProfile("store_name", e.target.value)
                  }
                  value={store_name || ""}
                  type="text"
                  className="vender-inputs"
                  placeholder="Enter store name"
                />
                {validation.store_name && (
                  <ErrorLabel>{validation.store_name}</ErrorLabel>
                )}
              </div>
            </div>
            <br />
            <h5 className="heading">{Strings.labels.companyLogo}</h5>

            <div className="row mb-3">
              <div className="col-lg-5 col-sm-6">
                <label className="vender-label mb-1">
                  {Strings.labels.image}
                  {company_logo && (
                    <>
                      {" ("}
                      <SmallLinkText target="_blank" href={company_logo}>
                        {Strings.labels.viewLogo}
                      </SmallLinkText>
                      {")"}
                    </>
                  )}
                </label>

                <div className="filesBox">
                  <input
                    data-test-id="field-companyLogoDoc"
                    onChange={(e: any) =>
                      this.onChangeProfile(
                        "companyLogoDoc",
                        e?.target?.files[0] ?? null
                      )
                    }
                    accept=".jpg, .jpeg, .png"
                    type="file"
                    className="file"
                    id="companyLogo"
                    name="companyLogo"
                  />
                  <label className="fileLabel">
                    <img className="upload" src={iconUpload} alt="upload" />
                    <UploadText> {Strings.labels.uploadImage}</UploadText>
                    <small>
                      {GetTruncateText(
                        this.state.profile?.companyLogoDoc?.name,
                        30
                      )}
                    </small>
                  </label>
                </div>
              </div>
            </div>

            <br />
            <h5 className="heading">{Strings.labels.documents}</h5>
            <div className="row mb-3">
              <div className="col-lg-5 col-sm-6">
                <label className="vender-label mb-1">
                  {Strings.labels.commercialDocuments}
                  <RedLabel>{" * "}</RedLabel>
                  {commercial_registration_doc && (
                    <>
                      {"("}
                      <SmallLinkText
                        target="_blank"
                        href={commercial_registration_doc}
                      >
                        {Strings.labels.viewDocument}
                      </SmallLinkText>
                      {")"}
                    </>
                  )}
                </label>

                <div className="filesBox">
                  <input
                    data-test-id="field-commercialDoc"
                    onChange={(e: any) =>
                      this.onChangeProfile(
                        "commercialDoc",
                        e?.target?.files[0] ?? null
                      )
                    }
                    accept=".jpg, .jpeg, .png, .doc, .pdf"
                    type="file"
                    className="file"
                    id="commercial"
                    name="commercial"
                  />
                  <label className="fileLabel">
                    <img className="upload" src={iconUpload} alt="upload" />
                    <UploadText> {Strings.labels.uploadDocument}</UploadText>
                    <small>
                      {GetTruncateText(
                        this.state.profile?.commercialDoc?.name,
                        30
                      )}
                    </small>
                  </label>
                </div>
                {validation.commercialDoc && (
                  <ErrorLabel>{validation.commercialDoc}</ErrorLabel>
                )}
              </div>
              <div className="col-lg-5 col-sm-6">
                <label className="vender-label mb-1">
                  {Strings.labels.registrationTaxDoc}
                  <RedLabel>{" * "}</RedLabel>
                  {tax_registration_doc && (
                    <>
                      {"("}
                      <SmallLinkText
                        target="_blank"
                        href={tax_registration_doc}
                      >
                        {Strings.labels.viewDocument}
                      </SmallLinkText>
                      {")"}
                    </>
                  )}
                </label>

                <div className="filesBox">
                  <input
                    data-test-id="field-taxRegistrationDoc"
                    onChange={(e: any) =>
                      this.onChangeProfile(
                        "taxRegistrationDoc",
                        e?.target?.files[0] ?? null
                      )
                    }
                    accept=".jpg, .jpeg, .png, .doc, .pdf"
                    type="file"
                    className="file"
                    id="registration"
                    name="registration"
                  />
                  <label className="fileLabel">
                    <img className="upload" src={iconUpload} alt="upload" />
                    <UploadText>{Strings.labels.uploadDocument}</UploadText>
                    <small>
                      {GetTruncateText(
                        this.state.profile?.taxRegistrationDoc?.name,
                        30
                      )}
                    </small>
                  </label>
                </div>
                {validation.taxRegistrationDoc && (
                  <ErrorLabel>{validation.taxRegistrationDoc}</ErrorLabel>
                )}
              </div>
              <div className="col-lg-5 col-sm-6">
                <label className="vender-label mb-1">
                  {Strings.labels.supportDocuments}
                  <RedLabel>{" * "}</RedLabel>
                  {supporting_doc && (
                    <>
                      {"("}
                      <SmallLinkText href={supporting_doc} target="_blank">
                        {Strings.labels.viewDocument}
                      </SmallLinkText>
                      {")"}
                    </>
                  )}
                </label>

                <div className="filesBox">
                  <input
                    data-test-id="field-supportingDoc"
                    onChange={(e: any) =>
                      this.onChangeProfile(
                        "supportingDoc",
                        e?.target?.files[0] ?? null
                      )
                    }
                    accept=".jpg, .jpeg, .png, .doc, .pdf"
                    type="file"
                    className="file"
                    id="supporting"
                    name="supporting"
                  />
                  <label className="fileLabel">
                    <img className="upload" src={iconUpload} alt="upload" />
                    <UploadText>{Strings.labels.uploadDocument}</UploadText>
                    <small>
                      {GetTruncateText(
                        this.state.profile?.supportingDoc?.name,
                        30
                      )}
                    </small>
                  </label>
                </div>
                {validation.supportingDoc && (
                  <ErrorLabel>{validation.supportingDoc}</ErrorLabel>
                )}
              </div>
              <div className="col-lg-5 col-sm-6">
                <label className="vender-label mb-1">
                  {Strings.labels.pharmacyDocuments}
                  <RedLabel>{" * "}</RedLabel>
                  {pharmacy_registration_doc && (
                    <>
                      {"("}
                      <SmallLinkText
                        href={pharmacy_registration_doc}
                        target="_blank"
                      >
                        {Strings.labels.viewDocument}
                      </SmallLinkText>
                      {")"}
                    </>
                  )}
                </label>

                <div className="filesBox">
                  <input
                    data-test-id="field-pharmacyDoc"
                    onChange={(e: any) =>
                      this.onChangeProfile(
                        "pharmacyDoc",
                        e?.target?.files[0] ?? null
                      )
                    }
                    accept=".jpg, .jpeg, .png, .doc, .pdf"
                    type="file"
                    className="file"
                    id="pharmacy"
                    name="pharmacy"
                  />
                  <label className="fileLabel">
                    <img className="upload" src={iconUpload} alt="upload" />
                    <UploadText>{Strings.labels.uploadDocument}</UploadText>
                    <small>
                      {GetTruncateText(
                        this.state.profile?.pharmacyDoc?.name,
                        30
                      )}
                    </small>
                  </label>
                </div>
                {validation.pharmacyDoc && (
                  <ErrorLabel>{validation.pharmacyDoc}</ErrorLabel>
                )}
              </div>
            </div>
            {!isStoreMember && (<div className="buttonBox justify-content-start">
              <button
                data-test-id="btn-submit"
                onClick={() => this.onSubmit()}
                className="buttons filled"
                type="button"
              >
                {Strings.actions.save}
              </button>
            </div>)}
          </>
        )}
         <StoreMemberList {...this.props} isStoreMember={isStoreMember}/>
      </div>
    );
  }
}

export default VendorRegistration;

const RedLabel = styled("span")({
  color: "red",
});

const UploadText = styled("div")({
  // @ts-ignore
  fontWeight: "normal !important",
});

const ErrorLabel = styled("div")({
  fontSize: 13,
  color: "#f44336",
  marginTop: 5,
});

const SmallLinkText = styled("a")({
  fontSize: 12,
  cursor: "pointer",
  color: "var(--navy)",
  textAlign: "center",
  "&:visited, &:hover, &:active": {
    color: "var(--navy)",
  },
});

// Customizable Area End
