// Customizable Area Start

import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";

import GetApiMessage from "../GetApiMessage";
import ISuccessResponse from "../ISuccessResponse";
import ApiResponseMiddleware from "../ApiResponseMiddleware";

const configJs = require("./config");
const ApiUrls = configJs.ApiUrls;

interface Props {}
interface SS {}
interface S {
  menuList: Array<any>;
  currentPage: number;
  lastPage: number;
  menuListMessageId: any;
}

class ProductsByCategoryController extends BlockComponent<Props, S, SS> {
  state = {
    menuList: [],
    lastPage: 1,
    currentPage: 1,
    menuListMessageId: null,
  };

  constructor(props: Props) {
    super(props);

    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ]);

    this.receive = this.receive.bind(this);
  }

  componentDidMount(): any {
    this.getMenuListApi();
  }

  receive(from: string, message: Message): void {
    if (!ApiResponseMiddleware(message)) return;

    let requestID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (requestID === this.state.menuListMessageId) {
      if (successResponse) {
        if (successResponse.meta) {
          this.setState({ lastPage: successResponse.meta.last_page });
        }

        // @ts-ignore
        let data = successResponse.data?.map((item: any) => {
          return item.attributes;
        });
        if (data) this.setState({ menuList: data });
      }
    }
  }

  getMenuListApi(page: any = this.state.currentPage) {
    let requestMessage = GetApiMessage({
      method: "GET",
      endpoint: ApiUrls.showMenu + "?per_page=10&page=" + page,
    });
    this.setState({ menuListMessageId: requestMessage.messageId });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onChangePage(page: number) {
    this.setState({ currentPage: page });
    this.getMenuListApi(page);
  }
}

export default ProductsByCategoryController;

// Customizable Area End
