// Customizable Area Start
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { IBlock } from "../../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";

import GetApiMessage from "../../GetApiMessage";
import ISuccessResponse from "../../ISuccessResponse";
import BlockHelpers from "../../../../utilities/src/BlockHelpers";

const configJs = require("./config");
const ApiUrls = configJs.ApiUrls;
const Strings = configJs.Strings;

interface Props {
  navigation: { navigate: Function };
  isStoreMember:boolean;
}
interface SS {}
interface S {
  data: any;
  isLoading: boolean;
  addVendor: boolean;
  activateMessageId: string;
  storeMemberList: Array<any>;
  storeMemberListMessageId: string;
  selectedStoreMemberItemId: number | boolean;
  storeMemberDeleteMessageId: string;
}

class StoreMemberListController extends BlockComponent<Props, S, SS> {
  state = {
    data: null,
    isLoading: false,
    addVendor: false,
    activateMessageId: "",
    storeMemberListMessageId: "",
    storeMemberList: [],
    selectedStoreMemberItemId: false,
    storeMemberDeleteMessageId: "",
  };

  constructor(props: Props) {
    super(props);

    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
    ]);

    this.receive = this.receive.bind(this);
  }

  receive(from: string, message: Message): void {
    const {
      activateMessageId,
      storeMemberListMessageId,
      storeMemberDeleteMessageId,
    } = this.state;

    let requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let successResponse: ISuccessResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!successResponse) return;

    if (storeMemberListMessageId === requestId) {
      this.setState({ isLoading: false });
      let list = successResponse?.data;
      this.setState({ storeMemberList: list ?? [] });
    } else if (activateMessageId === requestId) {
      this.getMembersList();
    } else if (storeMemberDeleteMessageId === requestId) {
      BlockHelpers.showAlert(
        Strings.labels.success,
        Strings.messages.storeMemberDeleted
      );
      this.getMembersList();
    }
  }

  componentDidMount(): any {
    this.getMembersList();
  }

  getMembersList() {
    this.setState({ isLoading: true });
    let message = GetApiMessage({ endpoint: ApiUrls.listStoreMembers });
    this.setState({ storeMemberListMessageId: message.messageId });
    runEngine.sendMessage(message.id, message);
  }

  onChangeStatus(id: number, status: string) {
    let form = new FormData();
    form.set("status", status);

    let message = GetApiMessage({
      data: form,
      method: "put",
      endpoint: ApiUrls.activateStatus + id,
    });

    this.setState({ activateMessageId: message.messageId });

    runEngine.sendMessage(message.id, message);
  }

  onDeleteStoreMember() {
    const { selectedStoreMemberItemId: memberId } = this.state;
    let message = GetApiMessage({
      method: "delete",
      endpoint: ApiUrls.deleteStoreMember + `[${memberId}]`,
    });

    this.setState({ storeMemberDeleteMessageId: message.messageId });
    runEngine.sendMessage(message.id, message);
    this.setState({ selectedStoreMemberItemId: false });
  }
}

export default StoreMemberListController;

// Customizable Area End
